.negativity-levels-container {
  &__header {
    padding-top: 1rem;
  }

  &__explanation {
    padding: .5rem 0;
  }

  &__checkbox-group {
    padding: .5rem 0 2.5rem 0;
    display: inline-block;

    .user-preference-checkbox {

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        + .preference-checkbox-label {
          &:before {
            width: 1.4rem;
            height: 1.4rem;
            top: 0.5rem;
          }
        }
      }

      &:nth-child(even){
        margin: 2rem 0;
      }
    }
  }
}