.search-type {
  display: flex;
  justify-content: center;
  padding: 7rem 0 0;
  column-gap: 7rem;

  .label-for-person,
  .label-for-company {
    display: flex;
    font-family: $secondary-font-stack;
    color: $default-text-color;
    font-size: 1.9rem;
    font-weight: 600;
    line-height: 1.2;
    padding: .9rem .6rem .8rem .7rem;
    position: relative;
    z-index: 1;
    border-radius: 1rem;

    &::before {
      width: 100%;
      height: 100%;
      left: 0;
      top: 0;
      content: '';
      position: absolute;
      z-index: -1;
      filter: blur(.4rem);
    }

    &::after {
      transition: all .3s ease-in-out;
      content: '';
      position: absolute;
      height: .5rem;
      background-color: transparent;
      bottom: -.5rem;
      left: 0;
      right: 0;
    }

    &:hover,
    &.label-active {
      cursor: pointer;
      font-weight: 800;

      &::after {
        background-color: $secondary-warning-color;
      }
    }
  }

  .search-type-person {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: 3.5rem;
    }

    .button-search-people {
      display: none;
    }
  }

  .search-type-company {
    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-left: 3.5rem;
    }

    .button-search-company {
      display: none;
    }
  }

  .devider {
    display: none;
    height: 0;
  }
}

.devider {
  height: 6rem;
  width: 0;
  border-style: solid;
  border-left-width:1px;
  opacity: 0.3;
  margin: 5rem 1rem 0;
}


