.reports-list-child-row-container {
  .reports-list-child-row {
    display: flex;
    flex-flow: row;
    justify-content: flex-start;
    font-size: 1.5rem;
    line-height: 2.8rem;
    background-color: $white;

    @keyframes droppedAnimation {
      0% {
        background-color: inherit;
      }
      50% {
        background-color: $dropped-drag-and-drop-color;
      }
      100% {
        background-color: inherit;
      }
    }

    &.dropped {
      animation: droppedAnimation 2s;
      animation-iteration-count: 1;

      .child-report-title-date {
        .reports-list-article-title:after {
          background-color: $dropped-drag-and-drop-color;
          animation: droppedAnimation 2s;
          animation-iteration-count: 1;
        }
      }
    }

    &.drop-top {
      border-top: 2px solid $header-menu-dropdown-hover;
    }
    &.drop-bottom {
      border-bottom: 2px solid $header-menu-dropdown-hover;
    }

    &.dragging {
      color: $disabled-drag-and-drop-color;
      background-color: inherit !important;
      border: 1px dashed $default-border-color;
      cursor: move;

      .reports-list-article-row-actions {
        .report-list-article-action-button {
          color: $disabled-drag-and-drop-color;
        }
      }
    }

    .child-report-title-date {
      width: 100%;

      .la-Menu {
        font-size: 2rem;
        padding-right: 1rem;
        width: 3.5rem;
      }

      .la-Menu,
      .la-DeliveryOptions {
        padding-top: 0.4rem;
      }

      .child-report-title-index {
        width: 100%;
        display: flex;
        flex-flow: row;

        .combined-report-content__list__report {
          width: 100%;
        }
      }
    }

    .reports-list-article-date {
      width: 30%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      max-height: 5.6rem;
    }
  }

  &.selected-row,
  &.with-multiple-items:not(.drag-and-drop-disabled):hover {
    cursor: move;

    .reports-list-child-row {
      background-color: $selected-drag-and-drop-color;
    }
  }

  &.drag-and-drop-disabled {
    pointer-events: none;
    cursor: not-allowed;

    &:hover {
      cursor: initial;
    }
  }
}
