.custom-news-searches {

  .user-preferences-category-container__row {
    margin: 0;
  }

  .user-preferences-category-container__explanation {
    margin: 0 0 .5rem;
  }

  .generic-table {
    margin-top: 1.5rem;
    width: 52rem;
    
    .table {
      .headers {
        padding: .5rem .5rem .5rem 1rem;
        border: .1rem solid $table-main-border-color;
        border-top-left-radius: .2rem;
        border-top-right-radius: .2rem;
      }
      .rows {
        .row {
          padding: .1rem 0 .5rem 1rem;
          border-left: .1rem solid $table-main-border-color;
          border-right: .1rem solid $table-main-border-color;
          border-bottom: none;
          flex-wrap: wrap;
          align-items: center;

          &:first-child {
            padding-top: .5rem;
          }

          &::after {
            content: '';
            display: block;
            margin: 0 auto;
            margin-right: 1rem;
            margin-top: .3rem;
            width: 98%;
            border-bottom: .1rem solid $default-border-color;
          }

          &:last-child {
            border-bottom-left-radius: .2rem;
            border-bottom-right-radius: .2rem;
            border-bottom: .1rem solid $table-main-border-color;

            &::after {
              content: none;
            }
          }

          input[type="checkbox"] {
            + .preference-checkbox-label {
              display: flex;
              align-items: center;
              
              &:before {
                flex-basis: 1.4rem;
                height: 1.4rem;
              }
              
              span {
                display: block;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
                flex-basis: 85%;
              }
            }

            &:checked {
              + .preference-checkbox-label {  
                  &:before {
                    @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
                      background-position: 0;
                    }
                  }
             }
          }

            &:disabled {
              + .preference-checkbox-label {
                color: $custom-news-search-disabled-color;
                
                &:before {
                  color: $custom-news-search-disabled-color;
                }
              }
            }
          }
        }
      }

      .header-name, .col-name {
        flex: 4;
        margin-right: 2rem;
      }

      .header-created, .col-created {
        flex: 2;
      }

      .header-action, .col-action {
        flex: 1;
      }

      .col-created {
        .disabled {
          color: $custom-news-search-disabled-color;
        }
      }

      .col-name {
        overflow: hidden;

      }
    }
  }

  .custom-news-search-action-wrapper {
    display: flex;
    align-items: flex-end;
    justify-content: flex-start;
    margin-left: .5rem;

    .trigger-icon {
      width: 1.5rem;
      .row-action-btn {
        background-color: inherit;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
          padding-top: .5rem;
      }

        .la-DeliveryOptions {
          color: $default-text-color;
        }

        &:disabled {
          .la-DeliveryOptions {
            color: $custom-news-search-disabled-color;
          }
        }
      }
    }

    .admin-lock {
      width: 2rem;
      height: 0;
      margin-left: 0;
      font-size: 2rem;
      border: none;

      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        position: relative;
        bottom: 2rem;
    }

      &.unlocked {
        background-color: inherit;
        border: none;

        &:hover {
          background-color: inherit;
          color: $default-text-color;
          &:after {
            background-color: $white;
          }
        }

        &:after {
          top: .165rem;
          width: 1.2rem;
          height: .8rem;
          left: 1.3rem;

          @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            top: .13rem;
          }
        }
      }

      &.locked {
        background-color: inherit;
        border: none;
        color: $default-text-color;

        &:hover {
          background-color: inherit;
          border: none;
        }
      }

      &:disabled {
        color: #707070;
      }
    }
  }

  .custom-news-query-selection {
    margin-right: 1rem;
    margin-top: .5rem;
  }

  &__query-container{
    display: flex;
    flex-direction: column;
    width: 100%;
    padding-bottom: 1.5rem;

    &__subtitle{
      margin-bottom: 1.5rem;
      font-size: 16px;
    }


    &__add-query {
      min-width: 220px;
      margin-top: 1rem;

      .la-ZoomIn{
        font-size: 2rem;
      }

      .label{
        vertical-align: top;
        margin-left: 0.5rem;
        border-top-width: .5px;
        line-height: 1.4;
      }
    }

    .horizontal-divider{
      width: 50%;
      opacity: 0.8;
      margin: 2rem 0;

    }
  }
}

@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .section-disabled {
    .custom-news-searches {
      .trigger-icon {
        .row-action-btn {
          background-color: $light-border-color;
        }
      }
    }
  }
}

  .dil-dropdown.custom-news-menu-dropdown {
    z-index: 80;
    .dropdown__row {
      width: 10.8rem;
    }
  }