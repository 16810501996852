.manage-alerts-single-alert {
  background-color: $white;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  padding: 2rem 1rem 2rem;
  border-bottom: 1px solid $default-border-color;


  .select-alert {
    width: 2%;
  }

  .manage-alerts-table-single-alert {

    &__name {
      width: 14%;

      .migrated-message {
        font-size: 1.1rem;
        //position: absolute;
        color: $placeholder-color;
      }
    }

    &__search-term {
      width: 8%;
    }

    &__sources {
      width: 20%;
      padding-right: 2rem;
    }

    &__frequency {
      width: 9%;
    }

    &__status {
      width: 7%;

      .alert-pill-active {
        background-color: $active-pill-color;
        padding: .2rem 1rem .2rem;
        color: $white;
        border-radius: 15px;
        display:inline-block;
        font-size: 1.4rem;
        white-space: nowrap;
        width: content-box;
      }

      .alert-pill-paused {
        background-color: $button-color-disabled;
        padding: .2rem 1rem .2rem;
        color: $white;
        border-radius: 15px;
        display:inline-block;
        font-size: 1.4rem;
        white-space: nowrap;
        width: content-box;
      }

    }

    &__recipients {
      width: 20%;
      padding-right: 2rem;
    }

    &__created {
      width: 11%;
    }

    &__actions {
      width: 9%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .trigger-icon {
        width: 100%;
      }

      .manage-alert-action-button {
        height: 28px;
        background-color: $white;
        color: $button-color-active;
        border: 0;
        font-size: 1.4rem;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        text-align: left;
        margin-right: 1rem;
        display: flex;
        align-items: center;

        &:hover {
          color: $button-color-hover;
          cursor: pointer;
        }

        &:active {
          color: $button-color-active;
        }

        &:disabled,
        &.disabled {
          color: $button-color-disabled;
          cursor: not-allowed;
        }
      }

    }


    &__publicRecordName {
      width: 20%;
    }

    &__lastRun {
      width: 20%;
    }

    &__publicRecordButton {
      width: 50%;

      .publicRecord-alert-check-for-results-button {
        height: 2.8rem;
        width: 18rem;
        background-color: $white;
        border: 1px solid  $button-color-active;
        color: $button-color-active;
        font-size: 1.4rem;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        text-align: left;
        margin-right: 1rem;
        display: flex;
        align-items: center;

        .la-Restore {
          margin-right: .5rem;
          color: $button-color-active;
        }
      }
    }

    &__publicRecordsActions {
      width: 8%;
      display: flex;
      flex-direction: column;
      justify-content: flex-start;

      .trigger-icon {
        width: 100%;
      }

      .manage-alert-action-button {
        height: 28px;
        background-color: $white;
        color: $button-color-active;
        border: 0;
        font-size: 1.4rem;
        font-weight: normal;
        cursor: pointer;
        outline: none;
        text-align: left;
        margin-right: 1rem;
        display: flex;
        align-items: center;

        &:hover {
          color: $button-color-hover;
          cursor: pointer;
        }

        &:active {
          color: $button-color-active;
        }

        &:disabled,
        &.disabled {
          color: $button-color-disabled;
          cursor: not-allowed;
        }
      }
    }

  }
}

.publicRecord-alert-check-for-results-button {
  height: 2.8rem;
  width: 18rem;
  background-color: $white;
  border: 1px solid  $button-color-active;
  color: $button-color-active;
  font-size: 1.4rem;
  font-weight: normal;
  cursor: pointer;
  outline: none;
  text-align: left;
  margin-right: 1rem;
  display: flex;
  align-items: center;

  .la-Restore {
    margin-right: .5rem;
    color: $button-color-active;
  }
}
