.ubo-document-body {

  #whatIsTradeup {
    text-decoration: underline;
  }

  .__react_component_tooltip {
    width: 300px;
    line-height: normal;
    text-align: left;
    font-size: 14px;
  }
  padding: 3rem 4rem 5rem !important;

  .tabs-view-article-title {
    margin: 0 !important;
    padding: 0 0 0;
    text-align: left !important;
    font-size: 21px;
  }

  .tabs {
    border-bottom: 1px solid $light-border-color;
    background-color: #FFFFFF;
    margin: 0;
    padding: 0 2rem 0;

    .tab {
      font-size: 15px;
      font-weight: bold;
    }
  }

}
#ubo-document, #uboDocumentReportView {
  .with-error {
    width: 100%;
    height: 50rem;
    background-color: #FFF;
    margin-top: 2rem;
  }

  .ubo-error {
    @include alert-message($popup-bg-warning-light, $popup-user-error-color);
    position: relative;
    max-height: 10rem;

      &__icon {
          color: $popup-user-error-color;
          padding-right: 1.5rem;
          display: flex;
          align-items: center;
      }
    
      &__message {
          border-left: 0.1rem solid $popup-user-error-color;
          padding-left: 1.5rem;
          text-align: start;
      }
    
      &.dark-mode {
          background: $popup-bg-warning-dark;
          color: $popup-text-color-dark;
          border-bottom: 0.1rem solid $popup-border-color-warning-dark;

          .ubo-error {
            &__message {
              border-left: 0.1rem solid $popup-border-color-warning-dark;
            }
          }
      }
  }

  .tradeup-container {
    @include alert-message($popup-bg-warning-light, $popup-user-error-color);
    position: relative;
    margin: 0rem 0rem 4rem 0rem;

      &__icon {
          color: $popup-user-error-color;
          padding-right: 1.5rem;
          display: flex;
          align-items: center;
      }
    
      &__message {
          border-left: 0.1rem solid $popup-user-error-color;
          padding-left: 1.5rem;
      }
    
      &.dark-mode {
          background: $popup-bg-warning-dark;
          color: $popup-text-color-dark;
          border-bottom: 0.1rem solid $popup-border-color-warning-dark;

          .tradeup-container {
            &__message {
              border-left: 0.1rem solid $popup-border-color-warning-dark;
            }
          }
      }
  }

  #ubo-graph-container {
    &__nodes-limit-exceeded {
      display: flex;
      flex-direction: column;
      align-items: flex-end;

      .box-error__message{
        margin: 0;
      }
    }
  }
}
