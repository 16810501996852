/**
 * SCSS GLOBAL VARIABLES
 *
 * @version 0.1
 */

$white: #fff;
$black: #000;
$dark-blue: #0067b1;
$rebranding-dark-blue: #0e66b4;
$dark-navy: #00172e;

// Dark theme
$dark-theme-background: #00182e;
$dark-theme-color: $white;
$dark-theme-content-background: #0a2136;
$dark-theme-button-bg: #002C3F;
// Font stacks
// --------------------------------------------------
$default-font-stack: Arial, Helvetica, sans-serif;
$secondary-font-stack: Lato, Lato-Regular, sans-serif;
$third-font-stack: Lato, Arial, Helvetica, sans-serif;
$fourth-font-stack: Lato-Light, Lato, sans-serif;

// Font sizes
// --------------------------------------------------
$font10: 1rem;
$font12: 1.2rem;
$font13: 1.3rem;
$font14: 1.4rem;
$font15: 1.5rem;
$font16: 1.6rem;
$font18: 1.8rem;
$font20: 2rem;
$font22: 2.2rem;
$font24: 2.4rem;
$font32: 3.2rem;

// Buttons colors
// --------------------------------------------------
$button-color-active: #2b3b60;
$button-color-hover: #1a304d;
$button-color-click: #132a43;
$button-color-disabled: #bdbdbd;
$primary-button-text-color: #ffffff;
$button-color-remove: #ca0000;
$secondary-button-text-color: #0077cc;
$button-green-active: #2ecc71;
$button-green-hover: #27ae60;
$button-color-tag: #d4effa;
$button-color-tag-hover: #cde6e0;
$active-pill-color: #8ac224;
$navigation-button-color-hover: #f8f8f8;
$navigation-button-color-disabled: #efefef;
$toggle-button-color: #e6e6e7;
$toggle-button-color-lighter: #f4f7f7;
$button-secondary-color: #616161;
$button-secondary-lighter: #626366;
$button-secondary-color-darken: #007986;
$button-link-primary-color: #006ebb;
$button-focus-border: #29b6f6;
$button-hover-border: #e2e2e2;
$report-articles-circle: #f50457;
$button-padding: 0 2rem;
$button-small-height: 4.4rem;
$button-default-height: 3.6rem;
$button-dropdown-box-shadow: #75757580;
$button-sort-color: #455A64;

// Text colors
// --------------------------------------------------
$default-text-color: #212121;
$default-text-color-lighter: #202020;
$subfield-text-color: #616161;
$button-text-color: #ffffff;
$disabled-text-color: #d2d4d5;
$placeholder-color: #757575;
$version-color: #c3c3c3;
$primary-color: #009ddb;
$primary-color-hover: #0076a4;
$secondary-color: #ed1c24;
$secondary-warning-color: #e43935;
$tertiary-color: #797979;
$modal-close-button-color: #6d6e71;
$character-count-color: #525355;
$caret-enabled-color: #373739;
$caret-active-color: #171718;
$caret-inactive-color: #b5b5bc;
$checkbox-hover-bg-color: #e3f2fd;
$shared-manage-alert-color: #8f2b8c;
$checkbox-disabled-color: #757575;
$radio-disabled-color: #757575;
$custom-news-search-disabled-color: #939393;
$batch-upload-inline-error-color: #fd191d;
$batch-report-entity-error-color: #ad1457;
$preview-slider-top-text-color: #667482;
$comment-title-text-color: #424242;
$comment-section-textarea-color: #9d9d9d;

// Background colors
// --------------------------------------------------
$default-bg-color: #ffffff;
$primary-bg-color: #009ddb;
$secondary-bg-color: #ed1c24;
$third-bg-color: #d6effa;
$gray-bg-color: #f4f4f5;
$gray-bg-color-darker: #f5f5f5;
$gray-bg-color-darker-document: #f7f7f7;
$light-blue-bg-color: #aadef3;
$footer-link-active-bg-color: #a8aaac;
$keyword-highlight-bg-color: #fff099;
$errorPage-title-bg-color: #fff099;
$body-main-bg-color: rgba(244, 244, 245, 1);
$dropdown-search-bar-header: #f8f8f8;
$checkbox-hover-bg-color: #eaf7fc;
$select-bg-color: #e6e6e6;
$existing-note: #f4f5f7;
$selected-drag-and-drop-color: #e3f2fd;
$disabled-drag-and-drop-color: #d0d0d0;
$dropped-drag-and-drop-color: #e8f5e9;
$submenu-background-color: $gray-bg-color;
$tooltip-background-color: #2b3b60;
$tooltip-risk-background-color: #f2f2f2;
$ubo-suggestions-header-bg: #eaeaea;
$ubo-suggestions-bottom-bg: #ececec;
$ubo-suggestions-stroke-color: #eeeeee;
$ubo-suggestions-selected: #e5e5e5;
$ubo-suggestions-hover: #deedff;
$hover-color: #eaf7fc;
$theme-switcher-bg-color: #091a2c;
$theme-switcher-bg-color-lighter: #162b45;
$dark-cell-color: #f9f9f9;
$light-cell-color: #fff;
$batch-example-table-header-color: #f9f9f9;
$transparent50: rgba(255, 255, 255, 0.5);
$comment-section-greyed-color: #f4f4f4;
$red-tag: #f6271c;
$red-tag-gradient-2: #ec407a;
$light-popup-message-bg-color: #E2F2FD;
$list-item-hover-bg-color: #ECEFF1;
$list-item-drop-highlight-color: #cce2ff;

// Message popup colors
$popup-bg-color: #ebeef0;
$popup-success-color: #4BAB52;
$popup-system-error-color: #F6271C;
$popup-user-error-color: #F5AE47;
$popup-info-color: #1584D4;
$popup-dismiss-button-hover: #ced7db;
$popup-dismiss-button-active: #afbdc4;
$popup-dismiss-vertical-divider-color: #ced7db;
$popup-bg-color-dark-mode: #00172E;
$popup-text-color-dark: #CFD8DC;

//transparent without overlay
$popup-bg-color-success: #2175280a;
$popup-bg-color-critical: #CF261D1F;
$popup-bg-color-info: #006EBB1F;
$popup-bg-color-warning: #F5AE471F;

//overlay included
$popup-bg-info-dark: #01213F;
$popup-bg-success-dark: #04222D;
$popup-bg-warning-dark: #1D2931;
$popup-bg-critical-dark: #19192C;
$popup-bg-info-light: #DFEEF7;
$popup-bg-success-light: #E4EEE5;
$popup-bg-warning-light: #FFF5E9;
$popup-bg-critical-light: #FAE4E4;

// Border colors
// --------------------------------------------------
$default-border-color: #e0e0e0;
$default-border-color-lighter: #dfdfdf;
$default-border-color-darker: #9e9e9e;
$light-border-color: #e9e9ea;
$table-main-border-color: #bcbcbc;
$primary-border-color: #0d47a1;
$secondary-border-color: #ed1c24;
$delete-article-hover-border-color: #f36368;
$delete-article-focus-border-color: #c61017;
$header-menu-primary-border-color: #5a5e5e;
$header-sparator-border-color: #242525;
$input-focus-color: #0e66b4;
$text-area-hover-border-color: #0e66b4;
$text-area-focus-border-color: #0067b1;
$thick-border-color: $secondary-border-color;
$ubo-just-search: #004eaa;
$default-dropdown-border-color: #767676;
$theme-switcher-border-color: #4d6974;
$active-border-color: #0a2136;
$document-view-content-border-color: #d7dadb;
$document-view-content-border-color: #d7dadb;
$preferences-active-button-border-color: #33acb9;
$default-section-divider-color: #ededed;
$border-light-with-opacity: #dcdcdce6;
$border-dark-with-opacity: #00000033;
$border-homepage-button: #0189A7;

// message popup border colors
$popup-border-color-info-dark: #A3D9FF;
$popup-border-color-success-dark: #A5D9A8;
$popup-border-color-warning-dark: #FFD08A;
$popup-border-color-error-dark: #FCBCBB;
$popup-border-color-info-light: #006EBB;
$popup-border-color-success-light: #217528;
$popup-border-color-warning-light: #F5AE47;
$popup-border-color-error-light: #CF261D;

// Shadow colors
// --------------------------------------------------
$shadow-color: #aaa;
$shadow-color-risk-tooltip: #00000029;

// Link colors
// --------------------------------------------------
$link-color: #009ddb;
$link-hover-color: #0076a4;
$link-color-dark: #0077cc;
$link-visited-color: #8f2b8c;
$link-secondary-color: #1384D4;

//Z-index values
// --------------------------------------------------
$z-message-popups: 110;
$z-app-modal: 100;
$z-app-modal-close-button: 100;
$z-header: 90;
$z-overlay: 85;
$z-footer: 90;
$z-back-to-top: 105;
$z-loading-spinner: 75;
$z-loading-spinner-inner: 40;
$z-login-help-arrow: 10;

// Back to top
// --------------------------------------------------
$backToTop-bg-color: #525355;
$rebranding-backToTop-bg-color: #132a43;
$backToTop-hover-color: #ed1c24;

// Search
// --------------------------------------------------
$search-icon-color: #6d6d6e;
$search-icon-bg-color: #e8e8e9;
$search-error-icon-color: #ed1c24;

// Upload Wizard specific
// --------------------------------------------------
$uploadWizard-link-light-color: #4c9fdb;
$rebranding-uploadWizard-disabled-text: #757575;
$uploadWizard-disabled-text: #939598;

//Header specific
// --------------------------------------------------
$header-wrapper-bg: #373737;
$rebranding-header-wrapper-bg: #132a43;
$rebranding-header-nav-item-hover: #223556;
$rebranding-header-menu-dropdown-hover: #485b7b;
$product-switcher-item-color: $dark-navy;
$header-menu-dropdown: #525355;
$header-menu-dropdown-hover: #6d6e71;
$header-subheader-link: #1b1c1c;
$header-submenu-border: #d2d4d5;
$header-submenu-border-red: #ed1d25;
$header-submenu-border-green: #00cc33;
$header-diligence-specific-color: #0097a7;
$header-diligence-specific-color-lighter: #00bcd4;
$header-switcher-color: #8ba9bc;

// Start Page specific
// --------------------------------------------------
$startPage-pod-border: #939598;
$startPage-link-color: #0077cc;
$startPage-gray-border: #a7a9ab;
$startPage-icon1-grad1: #8cc63f;
$startPage-icon1-grad2: #7fb636;
$startPage-icon2-grad1: #ed1c24;
$startPage-icon2-grad2: #de1219;
$startPage-icon3-grad1: #00aeef;
$startPage-icon3-grad2: #009bd6;
$startPage-helpers-bottom-bg: #f9f9fa;

// Dashboard specific
// --------------------------------------------------
$startPage-pestle-red: #ed1c24;

// Errors and Notifications modals
// --------------------------------------------------
$startPage-error-icon: #ed3d2d;
$startPage-error-bg: #fde9e9;
$startPage-error-border: #ed3d2d;
$startPage-error-dismiss-bg: #fbd2d3;
$ubo-error: #ffed96;

// Review Companies specific
// --------------------------------------------------
$reviewCompanies-subscription-warning: #ff0000;

// Break points for responsiveness
// --------------------------------------------------

$break-point-extra-large: 1440px;
$break-point-large: 1200px;
$break-point-specific-medium: 1190px;
$break-point-medium: 680px;

// Calendar colors
$calendar-week-dates-color: #868689;
$calendar-arrows-color: #78797c;
$calendar-day-hover: #d5eff9;
$calendar-selected: #0067b1;

// UBO colors
$ubo-graph-percentage: #0a8029;
$ubo-graph-individual-share: #2d75cd;
$ubo-graph-zoom-color: #bebfc1;
$ubo-graph-root-node-color: #0d2571;
$ubo-graph-degree-bg: #cac9ca;
$ubo-graph-degree-text: #002363;
$ubo-graph-bo-color: #5a5a5a;

//BOV colors
$bov-tabs-bg-color: #fff;
$bov-tabs-bottom-border: #e9e9ea;
$bov-graph-legend-active-company: #e3f2fd;
$bov-graph-legend-inactive-company: #bcbec0;
$bov-graph-main-company-fill-active: #e3f2fd;
$bov-graph-main-company-stroke-active: #87b4d5;
$bov-graph-main-company-fill-inactive: #bcbec0;
$bov-graph-main-company-stroke-inactive: #bcbec0;
$bov-graph-main-company-fill-unknown: #fff;
$bov-graph-main-company-stroke-unknown: #000;
$bov-graph-zoom-color: #bebfc1;
$bov-graph-main-company-fill: #e3f2fd;
$bov-graph-main-company-stroke: #b8d9f1;
$bov-graph-hovercard-rect-fill: #fff;
$bov-graph-hovercard-rect-stroke: #ababab;
$bov-graph-person-node-fill: #4050b5;
$bov-graph-company-node-stroke: #b8d9f1;
$bov-graph-company-node-fill: #e3f2fd;
$bov-graph-links-color: #a9a9a9;
$bov-graph-default-text-color: #212121;

// Elements values
$header-height: 8rem;
$footer-height: 7rem;

//NNV colors
$negativity-level-pill-background: #f1f1f2;
$negativity-level-pill-high: #bc0001;
$negativity-level-pill-medium: #2874ba;
$negativity-level-pill-low: #616161;
$negativity-default-color: #676769;
$negativity-pill-language-color: #3d3d3f;
$negativity-level-pill-selected-background-high: #faf2f2;
$negativity-level-pill-selected-background-medium: #f0f5fa;
$negativity-level-pill-disabled-background: #f1f1f2;
$negativity-level-pill-disabled: #c9cacd;

//Pills & Chips
$pill-color-default: #212121;
$pill-selection-icon-default: #bcbec0;
$pill-selection-icon-selected: #10479f;
$pill-border-color: #616161;
$beta-pill-background-color: #0f6bc2;

// Popups
$popup-color-default: #212121;
$popup-overlay-background-color: rgba(32, 32, 32, 0.7);
$popup-text-font-size: 1.6rem;
$popup-overall-padding: 3rem;
$popup-header-bottom-padding: 2rem;
$popup-section-bottom-padding: 2rem;
$popup-close-icon-size: 2.5rem;
$popup-close-icon-color: #9d9d9d;
$popup-textarea-border-color: #9e9e9e;

//ESG Ratings
$esg-ratings-0-color: #bc0000;
$esg-ratings-30-color: #e65100;
$esg-ratings-40-color: #ffb300;
$esg-ratings-50-color: #7e57c0;
$esg-ratings-60-color: #1565c0;
$esg-ratings-80-color: #2b8642;
$esg-ratings-subtitle-color: #667482;

// Alert Message
$info-banner-color: #2296F3;
$warning-banner-color: #F5AE47;
$banner-description-text-color: rgba(69, 90, 100, 1);