.simple-table {
    position: relative;
    width: 100%;
    max-height: 39rem;
    border-collapse: separate;

    thead {
        width: 100%;

        tr>th {
            position: sticky;
            top: 0;
            z-index: 1;
            vertical-align: middle;
            border-bottom-width: 0.1rem;
            border-bottom-style: solid;
            border-color: #A3AFBF;
            padding: 1.6rem;
            background-color: $white;
        }
    }

    tbody {
        tr {
            &:nth-child(odd) {
                background-color: $white;
            }

            &:nth-child(even) {
                background-color: #F0F3F5;
            }

            td {
                vertical-align: middle;
                padding: 1.6rem;
                width: 30rem;

                .no-data-found {
                    width: 100%;
                    display: flex;
                    flex-direction: column;
                    justify-content: center;
                    align-items: center;
                }
            }
        }
    }
}