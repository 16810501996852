$marketing-tooltip-margin: 4rem;
$marketing-tooltip-arrow: 6px;


/* Wrapping */
.marketing-tooltip-wrapper {
    position: relative;
}
  
/* Absolute positioning */
.marketing-tooltip-tip {
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: .8rem 1.5rem;
    color: $white;
    font-size: $font14;
    opacity: 1;
    background: $red-tag;
    background: linear-gradient(90deg, $red-tag 0%, $red-tag-gradient-2 100%);
    line-height: 1;
    z-index: 100;
    white-space: nowrap;

    &:before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: $marketing-tooltip-arrow;
        margin-left: calc($marketing-tooltip-arrow * -1);
      }
}
  
  
/* Absolute positioning */
.marketing-tooltip-tip.top {
    top: calc($marketing-tooltip-margin * -1);

    &:before {
        top: 100%;
        border-top-color: $red-tag;
    }
}
  
/* Absolute positioning */
.marketing-tooltip-tip.right {
    left: calc(100% + $marketing-tooltip-margin);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &:before {
        left: calc($marketing-tooltip-arrow * -1);
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-right-color: $red-tag;
    }
}

/* Absolute positioning */
.marketing-tooltip-tip.bottom {
    left: 0%;
    bottom: calc($marketing-tooltip-margin * -1);

    &:before {
        bottom: 100%;
        border-bottom-color: #f1344c;
        margin-left: 35px;
    }
}
  
/* Absolute positioning */
.marketing-tooltip-tip.left {
    left: auto;
    right: calc(100% + $marketing-tooltip-margin);
    top: 50%;
    transform: translateX(0) translateY(-50%);

    &:before {
        left: auto;
        right: -12px;
        top: 50%;
        transform: translateX(0) translateY(-50%);
        border-left-color: $red-tag;
    }
}