/**
 * MESSAGE POPUP SCSS STYLES
 *
 * @version 0.1
 */

.message-popups-wrapper {
    left: 0;
    position: fixed;
    top: 0px;
    width: 100%;
    z-index: $z-message-popups;

    &.google-translate-toolbar-on {
        top: 38px;
    }
}

.message-popup {
    font-size: 1rem;
    height: 0;
    margin: 0 auto;
    opacity: 0;
    overflow: hidden;
    position: relative;
    top: -14rem;
    transition: all 0.3s linear;
    width: 100%;

    &.slide-down {
        height: 8rem;
        max-height: 40rem;
        overflow-y: auto;
        margin: -2.4rem auto 0 auto;
        opacity: 1;
        top: 2.4rem;
    }

    &__text {
        display: flex;
        min-height: 6rem;

        .navigation-link {
            text-decoration: underline;
            color: $rebranding-dark-blue;
        }
    }

    &__button {
        display: block;
        position: absolute;
        right: 1.5rem;
        top: 2rem;
        cursor: pointer;
        transition: all 0.2s ease-in-out;
        background: none;
        border: none;

        &:hover {
            transform: scale(1.01);
        }
    }

    &__message-container {
        display: flex;
        font-size: 1.4em;
        padding: 2rem;
        align-items: center;

        .__icon {
            margin-top: 0.7rem;
        }

        h3 {
            font-size: 1.5rem;
            margin-bottom: 1rem;
        }

        h4 {
            font-weight: bolder;
            font-size: 1.5rem;
            padding: 0 0.7rem;
        }

        p {
            margin-left: 4rem;
            margin-top: 0.4rem;
            color: $banner-description-text-color;
        }

        a {
            color: $default-text-color;

            &:hover {
                text-decoration: underline;
            }
        }
    }

    &.multiple-messages {
        .message-popup__text {
            margin-left: 4rem;

            .message-popup__message-container {
                padding: 0 0 0.3rem 0;

                li:last-child {
                    padding-bottom: 0;
                }
            }
        }
    }

    &.success-popup {
        background-color: $popup-bg-color-success;
        border-bottom: 0.1rem solid $popup-success-color;

        &:before {
            @include popup-message-vertical-strip-new-design($popup-success-color);
        }

        &:after {
            @include popup-overlay(white);
            z-index: -1;
        }

        &.dark-mode {
            @include popup-dark-mode();
        }
    }

    &.system-error-popup {
        background-color: $popup-bg-color-critical;
        border-bottom: 0.1rem solid $popup-system-error-color;

        &:before {
            @include popup-message-vertical-strip-new-design($popup-system-error-color);
        }

        &:after {
            @include popup-overlay(white);
            z-index: -1;
        }

        &.dark-mode {
            @include popup-dark-mode();
        }
    }

    &.user-error-popup {
        background-color: $popup-bg-color-warning;
        border-bottom: 0.1rem solid $popup-user-error-color;

        &:before {
            @include popup-message-vertical-strip-new-design($popup-user-error-color);
        }

        &:after {
            @include popup-overlay(white);
            z-index: -1;
        }

        &.dark-mode {
            @include popup-dark-mode();
        }
    }

    &.info-popup {
        background-color: $popup-bg-color-info;
        border-bottom: 0.1rem solid $popup-info-color;

        &:before {
            @include popup-message-vertical-strip-new-design($popup-info-color);
        }

        &:after {
            @include popup-overlay(white);
            z-index: -1;
        }
        
        &.dark-mode {
            @include popup-dark-mode();
        }
    }
}