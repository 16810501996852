.negative-terms {
  &-container {
    padding: 2.5rem 2rem .5rem;

    &-headers,
    &-item {
      display: flex;
      justify-content: space-between;
    }

    &-headers {
      margin-bottom: .7rem;

      &-label {
        font-size: 1.6rem;
        font-weight: bold;
      }
    }

    &-item {
      font-size: 1.3rem;
      font-weight: bold;
      color: $button-link-primary-color;

      &-label {
        max-width: 18rem;

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;

          &:hover {
            cursor: pointer;
          }
        }
      }

      &-count-value {
        display: flex;
        align-items: center;
        column-gap: 1rem;
        justify-content: flex-end;

        &::after {
          content: '';
          width: 1rem;
          height: 1rem;
          display: inline-block;
          border-radius: 50%;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            margin-left: 1rem;
          }
        }

        &.high {
          &::after {
            background-color: $negativity-level-pill-high;
          }
        }

        &.medium {
          &::after {
            background-color: $negativity-level-pill-medium;
          }
        }

        &.low {
          &::after {
            background-color: $negativity-level-pill-low;
          }
        }
      }
    }

    &:not(.snapshot) {
      .negative-terms-container-headers {
        .negative-terms-container-headers-label:nth-child(2) {
          padding-left: 1rem;
        }
      }
    }
  }
}