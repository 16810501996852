.no-results-0 {

  padding: 2rem;
  display: flex;
  flex-direction: column;

  .no-documents-row {
    display: flex;
    justify-content: space-between;

    .no-documents-title {
      font-size: 1.8rem;
    }

    .no-documents-button {
      padding-bottom: 2rem;
    }

  }

  .no-documents-search-terms {
    padding-top: 1rem;

    .search-terms {
      font-weight: bold;

    }
  }

  .no-documents-source {
    padding-top: 1rem;

    .source-highlight {
      font-weight: bold;
    }
  }

  .information-no-results {
    border: 0;
    background-color: white;
    outline: 0;
    cursor: pointer;
  }
}