.__react_component_tooltip.type-light {
  opacity: 1;
}
.tooltips {
  &.create-alert-disabled {
      width: 200px;
      line-height: normal;
      text-align: center;
  }
}

.edit-search-link {
  font-size: 70%;
  margin-left: 20px;
  color: $button-link-primary-color;
  padding: 0 0.5rem;

  &:hover{
    text-decoration: underline;
  }
}

.articles-list-general-header {
  position: relative;
  padding: 0 0 0 2.5rem;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;

  h4 {
    color: $default-text-color;
    font-size: $font18;
    line-height: 1.2;
    border-bottom: $default-bg-color;
    padding-bottom: 0;
  }

  p {
    font-weight: 400;
    color: $secondary-color;

    span {
      color: $black;
    }

  }

  .report-button-wrapper {
    height: $button-default-height;
    width: auto;
    padding: $button-padding;
    background-color: white;
    border: solid 0.2rem $button-color-click;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-left: auto;
    }

    &.disabled {
      background-color: $button-color-disabled;
      border: .1rem solid $button-color-disabled;
      cursor: not-allowed;

      .report-button {
        color: $white;

        &:hover {
          text-decoration: none;
        }
      }
    }

    &:hover:not(.disabled) {
          box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
        }

    .report-button {
      border: none;
      outline: 0;
      font-size: 1.6rem;
      font-weight: bold;
      padding: .8rem 0 .9rem;
      line-height: 1.5rem;
      color: $button-color-click;
      text-align: center;
      white-space: nowrap;

      &:active {
        text-decoration: none;
      }
    }

    .ui-spinner-count {
      display: inline-block;
      width: 3rem;
      height: 3rem;
      margin: .6rem;
      padding-top: .2rem;
    }

    .report-articles-circle {
      align-items: center;
      background-color: $header-diligence-specific-color;
      border: 0.2rem solid $header-diligence-specific-color;
      border-radius: 50%;
      display: flex;
      height: 2.5rem;
      justify-content: center;
      margin-left: 0.8rem;
      width: 2.5rem;

      .report-articles-count {
        color: $white;
        line-height: 1;
        font-size: 1.3rem;
      }
    }


    &:focus {
      outline: none;
    }
  }
}

.results-list-header{
  &_create-alert{
    width: auto;
    height: $button-default-height;
    color: $white;
    border: 0.3rem solid $button-color-click;
    background-color: $button-color-click;
    border-radius: 0.5rem;
    font-size: 1.5rem;
    font-weight: bold;
    line-height: 1.2;
    padding: 0 2rem;
    display: flex;
    align-items: center;

    &:hover {
      box-shadow: 0 0 0 0.3rem $button-hover-border;
      cursor: pointer;
      color: $white;
    }

    &.disabled {
        background-color: $button-color-disabled;
        border: 0.1rem solid $button-color-disabled;
        color: $white;
        cursor: not-allowed;
    }

    .la-Alerts{
      color: $primary-button-text-color;
      font-size: 2.5rem;
      padding-right: 1rem;
      position: relative;
      vertical-align: middle;
      font-weight: bold;

      &.disabled {
          color: $white;
          cursor: not-allowed;
      }
    }
    &.save-alert {
      border: 1px solid $button-color-active;
      background-color: $button-color-active;
      color: $white;
      cursor: pointer;
      outline: none;

      span {
        color: $white;
      }

      &:focus {
        box-shadow: 0 0 0 0.3rem $button-focus-border;
        outline: none;
      }

      &:hover {
        box-shadow: 0 0 0 0.3rem $button-hover-border;
        cursor: pointer;
      }

      &:active {
        background-color: $button-color-active;
        border: 1px solid $button-color-active;
      }
    }
  }

  .save-search, .add-to-multiple-entities-btn {
    margin-right: 1.4rem;
  }
}
