.combined-report{
  background-color: $white;

  .combined-report-content {
    padding: 1rem 0;
    .horizontal-divider{
      margin-left: 4rem;
      width: calc(100% - 8rem);
    }

    &__heading-container{
      padding: 0 4rem;
      display: flex;
    }

    &__heading {
      font-size: 16px;
      padding-bottom: 1rem;
    }

    &__subheading{
      padding: 1.5rem 4rem;
      display: flex;
      font-size: 1.4rem;
      font-weight: 600;

      * {
        width: 50%;
      }
    }

    &__list{
      font-size: 1.4rem;
      line-height: 2.8rem;
      max-height: 28rem;
      overflow-y: auto;
      padding: 0 4rem;

      .report-overlay {
        background-color: rgba(255,255,255,.5);
        z-index: $z-overlay;
        height: 100%;
        width: 99%;
        padding-right: 4rem;
        position: absolute;
        max-height: 34rem;
      }

      .reports-list-childReports-table{
        &.with-menu-open {
          min-height: 15rem;
        }
      }

      &__report{
        display: flex;

        .section {
          width: 50%;

          &.left-aligned {
            display: flex;
            align-items: center;
            white-space: nowrap;

            .draggable-icon {
              margin: 0 1.5rem;
            }

            span {
              overflow: hidden;
              text-overflow: ellipsis;
              width: 100%;
            }
          }

          &.right-aligned{
            display: flex;
            justify-content: space-between;

            .vertical-divider{
              margin: 0 .5rem
            }
            .action-link{
                &:hover{
                  text-decoration: underline;
              }
            }
          }
        }

        &.disabled{
          justify-content: space-between;

          .section {
            width: auto;

            &.disabled{
              color:$disabled-text-color;
            }
          }
        }

      }
    }

    .report-note-wrapper{
      padding: 2rem 4rem 4rem 4rem;
    }

    .articles-list-footer{
      padding: 0 4rem;
    }
  }
}