.toggle-exclude-news-wires,
.toggle-exclude-non-business-news {
  display: flex;
  align-items: center;

  .custom-toggle {
    margin-right: 1rem;
  }
}

.toggle-exclude-news-wires {
  padding-top: 1rem;
  padding-bottom: 1.5rem;
}

.toggle-exclude-non-business-news {
  padding-bottom: 2rem;
}

.app-modal.exclude-non-business-news {
  .app-modal__content-box {
    height: 45rem;

    > .app-modal__content .information-item {
      padding-bottom: 0.5rem;
    }
  }
}