.manage-alerts-actions {
  display: flex;
  flex-direction: column;

  .manage-alerts-actions-container {
    background-color: $white;
    padding: 2rem 0 0;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .manage-alerts-actions-buttons {
      display: flex;
      flex-direction: row;

      .selected-articles-dropdown-container {

        .selected-articles-count {
          padding: 0;
        }

        .selected-articles-dropdown {
          width: 38.5rem;
        }
      }

      .select-all-alerts-checkbox {
        margin: 0 1rem 0;
      }

      .action-button {
        min-width: 8.3rem;
        max-width: max-content;
        height: 2.8rem;
        background-color: $white;
        color: $button-color-active;
        border: 1px solid $button-color-active;
        font-size: 1.4rem;
        font-weight: normal;
        display: inline-block;
        cursor: pointer;
        outline: none;
        margin-right: 1rem;

        .la-Pause, .la-Play, .la-Delete, .la-Share {
          color: $button-color-active;
          font-size: 1.4rem;
        }

        &:hover {
          color: $button-color-hover;
          border: 0.1rem solid $button-color-hover;
          cursor: pointer;

          .la-Pause, .la-Play, .la-Delete, .la-Share {
            color: $button-color-hover;
          }
        }

        &:active {
          color: $button-color-active;
          border: 1px solid $button-color-active;

          .la-Pause, .la-Play, .la-Delete, .la-Share {
            color: $button-color-active;
          }
        }

        &:disabled,
        &.disabled {
          background-color: $button-color-disabled;
          border: 0.1rem solid $button-color-disabled;
          color: $white;
          cursor: not-allowed;

          .la-Pause, .la-Play, .la-Delete, .la-Share {
            background-color: $button-color-disabled;
            color: $white;
          }
        }
      }
    }

    .manage-alerts-find-alert {
      padding-right: 2rem;
      display: flex;
      flex-direction: row;
    }
  }

  .manage-alerts-actions-showing-number {
    padding: 1.5rem  2rem 1.5rem;
  }
}
