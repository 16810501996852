.background-message-wrapper {
  position: fixed;
  left: 1%;
  bottom: $footer-height;
  margin-bottom: .5rem;
  background-color: $header-wrapper-bg;
  min-height: 6rem;
  color: #fff;
  z-index: 80;
  border: 1px solid $header-submenu-border;
  display: flex;
  flex-direction: row;


  .background-message-spinner {
    display: flex;
    justify-content: flex-start;
    width: 10%;
    min-width: 6rem;

    .ui.loader {
      left: 0;
      right: 0;
      margin-left: 2.9rem;
    }
  }

  .hidden {
    visibility: hidden;
    display: none;
  }

  .background-message-icon {
    display: flex;
    justify-content: flex-start;
    width: 10%;
    min-width: 6rem;
    font-size: 3rem;
    padding: 1.4rem;
  }

  .la-DeliveryComplete {
    color: $button-green-hover;
  }

  .background-message-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 0;
    font-size: 1.4rem;
    -webkit-transition: width 1s ease-out; /* For Safari 3.1 to 6.0 */
    transition: width 1s ease-out;

    &.shrink {
      width: 23rem;
    }

    .background-message-box {
      width: 100%;
      overflow: hidden;

      .background-message-title {
        padding: .5rem 0 0;
      }

      .background-message-link-for-download {
        color: $white;
        text-decoration: underline;
        cursor: pointer;
      }

      .background-message-button-for-download {
        color: $white;
        text-decoration: underline;
        cursor: pointer;
        background: none;
        border: none;
        outline: none;
        padding: 0;
      }

      .background-message-body {
        padding: 0 0 0;
      }

      .background-message-action-buttons {
        margin: 1rem 1rem 1rem 0;

        .button-primary-sm {
          width: 8.5rem;
          height: 3.4rem;
          font-size: 1.4rem;
          margin-right: 1rem;
        }

        .button-secondary-sm {
          width: 8.5rem;
          height: 3.4rem;
          font-size: 1.4rem;
        }
      }
    }

    .background-message-button {
      color: $white;
      padding: .5rem;
      cursor: pointer;
      font-size: 2rem;

      &:hover {
        opacity: .5;
      }

      &:active {
        opacity: .5;
      }
    }
  }

  &.noTitle {
    .background-message-box {
      display: flex;

      .background-message-title {
        display: none;
      }

      .background-message-body {
        margin-top: auto;
        margin-bottom: auto;
      }
    }
  }

}
