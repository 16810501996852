/**
 * MODAL SCSS STYLES
 *
 * @version 0.1
 */

.app-slider {

  @include full-screen(fixed);

  font-size: 1.4rem;
  z-index: $z-message-popups;

  &__wrapper {
    position: absolute;
    text-align: right;
    width:100%;

    &.fade-background {
      background: $popup-overlay-background-color;
    }
    .small {
      width: 45%;
    }

    //&__medium {
    //  width: 65%;
    //}
    //
    //&__large {
    //  width: 80%
    //}
  }

  &__content-box {
    background-color: $default-bg-color;
    display: inline-block;
    position: relative;
    text-align: left;
    height: 100vh;
    overflow-y: scroll;
    right: -100rem;
    box-shadow: 5px 10px 8px 10px rgba(0, 0, 0, 0.2);
    animation: slide 0.5s forwards;
    animation-delay: .1s;
    border-radius: .4rem 0 0 .4rem;

    &__header{
      display: flex;
      justify-content: space-between;

      &__close-button {
        background: none;
        border: none;
        color: $modal-close-button-color;
        cursor: pointer;
        font-size: 2rem;
        font-weight: bold;
        text-align: center;
        margin: 2rem;
        align-self: flex-end;
        position: absolute;
        top: .6rem;
        right: 0;
        z-index: $z-app-modal-close-button;

        &:hover {
          opacity: .5;
        }

        &:active,
        &:focus,
        &:visited{
          border: none;
          outline-color: $default-bg-color;
        }
      }

    }
    &__body {

      .slider-modal-header {
        color: $default-text-color;
        padding: 2rem;
        position: relative;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-bottom: 2rem;

        .article-view-disclaimer {
          margin-bottom: 4rem;
        }

        .slider-text-top {
          color: $preview-slider-top-text-color;
          font-weight: bold;
          font-size: $font16;
          span {
            margin: 0 .3rem;
          }
          margin: 2rem 0 1.4rem .5rem;
        }

        h4{
          margin-top: 2rem;
          border-bottom: none;
          text-align: center;
          font-size: $font32;
          font-weight: bold;
          padding: 0 4rem;
          line-height: 1.2;
        }

        .add-to-report-btn{
          margin-bottom: 2rem;
          align-self: flex-start;
        }

        .slider-modal-header-logo {
          text-align: left;
          align-self: flex-start;
          margin-bottom: 2rem;
          max-width: 100%;
          max-height: 80px;
        }

      }

      .slider-modal-body {
        margin: 2rem;
        padding: 0 4rem;

        #Dil_DocumentTitle {
          display: none;
        }

        .SS_Banner {
          color: $default-text-color;
          font-weight: bold;
          border-bottom: .4rem solid $default-text-color;
          line-height: 2.8rem;
          font-size: $font24;
          margin-bottom: 2.7rem;
          padding-top: 5.7rem;
        }
      }
    }

    .box-error-message{
      position: relative;
      margin: 1.5rem;
      background-color: $popup-bg-color;

      &:before {
        @include popup-message-vertical-strip($popup-system-error-color)
      }
    }
  }

  .slide-out{
    right:0;
    animation: slideOut 0.5s forwards;
    animation-delay: .1s;
  }

  @keyframes slide {
    100% { right:0; }
  }

  @keyframes slideOut {
    100% {   right: -100rem;}
  }

}