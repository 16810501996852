.generic-table {
  font-size: 1.4rem;

  .table {
    clear: both;
    display: flex;
    flex-direction: column;

    .headers {
      display: flex;
      flex-direction: row;
      width: 100%;
      align-items: flex-start;
      justify-content: flex-start;
      border-top: 0.1rem solid $default-border-color;
      border-bottom: 0.1rem solid $default-border-color;
      font-weight: bold;
    }

    .rows {
      .row {
        width: 100%;
        display: flex;
        flex-direction: row;
        border-bottom: 0.1rem solid $default-border-color;
        align-items: flex-start;
        justify-content: flex-start;
      }
    }

    .cell {
      flex: 1;
      hyphens: auto;
      word-break: break-word; // Non standard, for webkit only
      word-wrap: break-word;
      overflow-wrap: break-word;
      padding: 0.5rem;
      box-sizing: border-box;
    }

    &.withSelection {
      .headers {
        padding-left: 3rem;
      }

      .row {
        position: relative;
        box-sizing: border-box;
        padding-left: 3rem;

        .custom-checkbox {
          position: absolute;
          top: 1rem;
          left: 1rem;
        }
      }
    }
  }

  .action-button {
    padding: $button-padding;
    height: $button-default-height;
    border-radius: 0.5rem;
    border: solid 0.2rem $button-color-click;
    background-color: $white;
    color: $button-color-click;
    font-size: 1.5rem;
    display: inline-block;
    cursor: pointer;
    outline: none;
    margin-right: 1rem;

    .la-Pause,
    .la-Play,
    .la-Delete,
    .la-Share {
      color: $button-color-click;
      font-size: 1.5rem;
      padding-right: 0.5rem;
    }

    &:focus {
      box-shadow: 0 0 0 0.3rem $button-focus-border;
      outline: none;
    }

    &:hover:not(:disabled):not(.disabled) {
      box-shadow: 0 0 0 0.3rem $button-hover-border;
      cursor: pointer;
    }

    &:active {
      color: $button-color-active;
      border: 0.2rem solid $button-color-active;
    }

    &:disabled,
    &.disabled {
      background-color: $default-bg-color;
      border: 0.1rem solid $button-color-disabled;
      color: $button-color-disabled;
      cursor: not-allowed;

      .la-Pause,
      .la-Play,
      .la-Delete,
      .la-Share {
        color: $button-color-disabled;
      }
    }
  }

  .trigger-icon {
    width: 100%;
    display: block;
  }

  .row-action-btn,
  .sort-action-btn {
    min-height: 28px;
    background-color: $white;
    color: $button-link-primary-color;
    border: 0;
    font-size: 1.4rem;
    font-weight: normal;
    cursor: pointer;
    outline: none;
    text-align: left;
    margin-right: 1rem;
    display: flex;
    align-items: center;

    &__text {
      &:hover {
        text-decoration: underline;
        cursor: pointer;
      }
    }

    &:active {
      color: $button-color-active;
    }

    &:disabled,
    &.disabled {
      color: $button-color-disabled;
      cursor: not-allowed;
    }
  }

  .table-count {
    clear: both;
    margin: 2rem 0 1.5rem 3.5rem;
  }

  .selection-header {
    display: flex;
    flex-direction: row;
    float: left;
    margin: 2rem 0 1.5rem 1rem;

    .custom-checkbox {
      margin: 0.3rem 1.3rem 0 0;
    }
  }

  .download-header,
  .clear-header {
    @extend .selection-header;

    padding: 0 0.8rem 0 0.5rem;
    cursor: pointer;
    outline: 0;
    align-items: center;
    width: auto;
    position: relative;
    margin-left: 1.4rem;
    float: right;

    &.button-primary-sm {
      padding: $button-padding;
      background-color: $white;
      border-radius: 0.5rem;
      border: solid 0.2rem $button-color-click;

      &:disabled,
      &.disabled {
        cursor: initial;
        opacity: 0.5;
        pointer-events: none;
      }
    }

    span {
      padding-left: 0.5rem;
      font-size: 1.5rem;
      color: $button-color-click;
    }

    .la-Download {
      color: $button-color-click;
      font-size: 2.5rem;
    }
  }

  .filter-search-box {
    float: right;
    margin: 2rem 2rem 0 0;
  }

  // Specifics

  // Specifics
  &.ubo-table {
    font-size: 1.4rem;

    .headers {
      background-color: #f4f4f5;

      .header-text {
        float: left;
        max-width: 75%;
      }

      .sort-action-btn {
        background-color: #f4f4f5;
        color: #000000;
        padding: 0 0.3rem 0 0;
        margin: 0;
        float: right;
        font-size: 1.6rem;
      }
    }

    .filter-dropdown {
      float: right;
      margin: 1rem 0 1rem 0;
      height: 3rem;
    }

    .table {
      margin-top: 2rem;
      border-left: 0.1rem solid $default-border-color;
      border-right: 0.1rem solid $default-border-color;
      flex-flow: row wrap;

      .rows {
        flex-flow: row wrap;
        display: flex;
        width: 100%;
      }

      .cell {
        height: 100%;

        &:not(:last-of-type) {
          border-right: 0.1rem solid $default-border-color;
        }
      }
    }

    .ubo-no-filtering-results {
      padding-top: 2.5rem;
      text-align: center;
    }
  }

  &.alerts-table,
  &.history-table {
    font-size: 1.5rem;

    .table {
      .headers {
        padding-top: 2rem;
        padding-bottom: 2rem;
      }

      .rows {
        .row {
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }

        & > .loader {
          margin-top: 3rem;
          margin-bottom: 3rem;
        }
      }

      &.withSelection {
        .row {
          .custom-checkbox {
            top: 1.9rem;
            left: 1rem;
          }
        }
      }
    }
  }

  &.batch-screening-table {
    font-size: 1.5rem;
    margin: 0 2rem;

    .table-actions {
      display: flex;
      align-items: center;
      gap: 1rem;

      .vertical-divider {
        max-height: 1.5rem;
      }
    }

    .table {
      border-radius: 1rem;
      -webkit-box-shadow: 8px 8px 20px -18px $shadow-color;
      box-shadow: 8px 8px 20px -18px $shadow-color;
      margin-bottom: 6rem;
      .headers {
        background-color: $white;
        padding-top: 2rem;
        padding-bottom: 2rem;
        max-height: 10rem;
        border-top-width: 0;
        border-radius: 1rem 1rem 0 0;

        .cell {
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;

          @supports (-webkit-line-clamp: 2) {
            white-space: initial;
            text-overflow: ellipsis;
            overflow: hidden;
            display: -webkit-box;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }
        }
      }

      .rows {
        .row {
          display: flex;
          align-items: center;
          padding-top: 1.5rem;
          padding-bottom: 1.5rem;
        }

        & > .loader {
          margin-top: 3rem;
          margin-bottom: 3rem;
        }
      }

      &.withSelection {
        .rows {
          .row {
            border: none;

            &:nth-child(odd) {
              background-color: $light-cell-color;
            }
            &:nth-child(even) {
              background-color: $dark-cell-color;
            }
            &:last-child {
              border-radius: 0 0 1rem 1rem;
            }
          }
        }

        .row {
          .custom-checkbox {
            top: 50%;
            left: 1rem;
            transform: translate(0, -50%);
          }
        }
      }
    }
  }

  &.myAlerts-table {  
    padding-bottom: 0.5rem;

    .selection-header {
      margin:0;
      padding: 1.5rem 0 1.5rem 1rem;
      position: sticky;
      top: 8rem;
      background: $white;
      z-index: 100;
      width: calc(100% - 27.5rem);
      border-bottom: 0.1rem solid $default-border-color;

      .custom-checkbox {
        margin-top: 0.5rem;
      }
      .selected-articles-dropdown-container {
        margin-top: 0.3rem;
      }

      .table-actions {
        display: flex;

        .add-to-multiple-entities-btn {
          font-weight: normal;

          .icon {
            color: inherit;
          }

          &:disabled {
            pointer-events: initial;
            cursor: not-allowed;
            border-width: 0.1rem;
            opacity: 1;
            color: $button-color-disabled;
            border-color:$button-color-disabled;
            &:hover {
              box-shadow: none;
            }

            .icon {
              color: $button-color-disabled;
            }
          }
        }
      }
    }

    .filter-search-box {
      position: sticky;
      top: 8rem;
      background: $white;
      z-index: 100;
      border-bottom: 0.1rem solid $default-border-color;
      margin: 0 0 0 0;
      padding: 1.65rem 2rem 1.65rem 0;
    }

    .table-count {
      margin: 0;
      padding: 1.5rem 0 1.5rem 3.5rem;
    }

    .chevron-wrapper {
      margin-top: -0.5rem;
    }

    button.sort-action-btn {
      font-size: 2.5rem;
      color: $caret_inactive-color;
      margin-top: -0.2rem;

      &.alerts-active-sort-field {
        color: $caret-active-color;
      }

      &.la-TriangleUp {
        &.la-TriangleUp-active {
          color: $caret-active-color;
        }
      }

      &.la-TriangleDown {
        margin-top: -1.5rem;

        &.la-TriangleDown-active {
          color: $caret-active-color;
        }
      }
    }

    .header-name,
    .col-name {
      display: flex;
      flex: 2;
    }

    .col-searchTerm {
      flex: 2 1;

      .alerts-search-query {
        font-weight: bold;
        @include multi-line-ellipsis(20px, 2, 2);
      }

      .alerts-search-query-type {
        font-size: 13px;
      }
    }

    .header-searchTerm {
      display: flex;
      flex: 2;
    }

    .header-sources,
    .col-sources {
      display: flex;
      flex: 4;
    }

    .header-active,
    .col-active {
      display: flex;
      flex: 2;

      .status-frequency {
        text-align: center;

        span {
          line-height: 5rem;
        }
      }
    }

    .header-recipients,
    .col-recipients {
      display: flex;
      flex: 3;
    }

    .header-createdDate,
    .col-createdDate {
      display: flex;
      flex: 1;
      min-width: 15rem;
      max-width: 16rem;

      .formatted-date {
        display: inline-grid;
      }
    }

    .header-lastUpdated,
    .col-lastUpdated {
      display: flex;
      flex: 1;
      min-width: 15rem;
      max-width: 16rem;

      .formatted-date {
        display: inline-grid;
      }
    }

    .header-actions,
    .col-actions {
      flex: 1;
      min-width: 12rem;
    }
  }

  &.PRAlerts-table {
    .access-type-kind {
      display: none;
    }

    .header-name,
    .col-name {
      flex: 5;
    }

    .header-lastRun,
    .col-lastRun {
      flex: 5;
    }

    .header-actions,
    .col-actions {
      flex: 1;
      min-width: 12rem;
      max-width: 20rem;
    }
  }

  &.myHistory-table {
    min-height: 8rem;
    position: relative;

    .download-header,
    .clear-header {
      margin-top: -5rem;
      margin-right: 3.5rem;
    }

    .clear-header + .download-header {
      margin-right: 0;
    }

    .header-check,
    .col-check {
      flex: 2;
    }

    .header-sourceType,
    .col-sourceType {
      flex: 2;
      min-width: 14rem;
    }

    .header-searchTerms,
    .col-searchTerms {
      flex: 4;

      .link-button {
        padding: unset;
      }
    }

    .header-numberOfDocs,
    .col-numberOfDocs {
      flex: 1;
    }

    .header-expirationDate,
    .col-expirationDate {
      flex: 2;
    }

    .header-costCode,
    .col-costCode {
      flex: 1;
    }

    .legend {
      background: $disabled-text-color;
      padding: 0.5rem;
    }

    .history-search-query {
      font-weight: 700;
      font-size: 14px;
    }

    .history-search-query-type {
      font-size: 13px;
      font-weight: 400;
    }
  }

  .empty-table-msg {
    padding: 3rem 0;
  }

  .empty-table-batch-screening {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    height: 48vh;
    background-color: $light-cell-color;
    border-radius: 0 0 1rem 1rem;
    padding: 3rem 0;

    img {
      height: 7rem;
      opacity: 0.8;
    }

    span {
      max-width: 33.5rem;
      font-size: $font16;
      text-align: center;

      a {
        margin: 0 0.5rem;
      }
    }
  }

  .box-error-message {
    position: relative;
    margin-right: 1.5rem;
    margin-bottom: 2rem;
    padding: 2rem;
  }

  .overlay {
    background-color: rgba(255, 255, 255, 0.5);
    position: absolute;
    bottom: 0;
    left: 0;
    height: 100%;
    width: 100%;
    z-index: $z-overlay;

    .ui.massive.loader {
      position: fixed;
      margin-left: 50%;
      margin-top: 30%;
    }
  }

  .link-button {
    color: $button-link-primary-color;

    &:hover {
      text-decoration: underline;
    }
  }

  &.custom-news-queries {
    .rows {
      max-height: 29rem;
      overflow-y: auto;
      border-bottom: 0.1rem solid $table-main-border-color;

      .row:last-of-type {
        border-bottom: none !important;
      }
    }
  }
}

.status-pill {
  .active {
    background-color: $active-pill-color;
    padding: 0.2rem 1rem 0.2rem;
    color: $white;
    border-radius: 1.5rem;
    display: inline-block;
    font-size: 1.4rem;
    white-space: nowrap;
    width: content-box;
  }

  .paused {
    background-color: $button-color-disabled;
    padding: 0.2rem 1rem 0.2rem;
    color: $white;
    border-radius: 1.5rem;
    display: inline-block;
    font-size: 1.4rem;
    white-space: nowrap;
    width: content-box;
  }
}

.new-label {
  display: inline-block;
  font-size: 1rem;
  background-color: $beta-pill-background-color;
  border-radius: 3rem;
  color: $white;
  padding: 0.2rem 0.6rem;
  line-height: 1;
  margin: 0 0.5rem;
  height: 1.5rem;
}
