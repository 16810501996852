.report-title {
  width: 41%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  margin-right: 2.5rem;

  .report-list-title {

    font-size: 2.1rem;
    font-family: $secondary-font-stack;
    margin: 0 1rem;
    padding-right: 12px;
    width: 95%;
    display: flex;
    flex-direction: column;

    /* the report title tooltip  */
    #truncated-title {
      font-size: 13px;
      max-width: 40rem;
      font-weight: 500;
      word-break: break-all;
      -ms-word-break: break-all;
      display: inline-block;
    }

    .truncated-title {
      width: calc(100% - 20px);
      max-width: calc(100% - 20px);
      display: inline-flex;

      span {
        padding-right: 12px;
        cursor: pointer;
        @include multi-line-ellipsis(2.5rem, 1);
      }
    }


    .title{
      display: flex;

      .la-Edit {
        display: none;
      }

      &:hover {
        .la-Edit {
          display: inline;
        }
      }
    }


  }

  .text-field-standard-sm {
    font-family: $secondary-font-stack;
    font-size: 2.1rem;
    line-height: 2.4rem;
    width: 95%;
    overflow: hidden;
    position: relative;
    text-align: justify;
    margin-right: 6px;
    padding-right: 12px;
    background: none;
  }


  .count{
    color: $tertiary-color;
    font-size: 14px;
    margin-top: .3rem;
  }
}
