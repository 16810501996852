/**
 * SCSS GENERAL PURPOSE MIXINS
 *
 * @version 0.1
 */

@mixin full-screen($position-type: absolute) {
  bottom: 0;
  height: 100%;
  left: 0;
  position: $position-type;
  right: 0;
  top: 0;
  width: 100%;
}

@mixin vertical-align($position-type) {
  position: $position-type;
  top: 50%;
  transform: translateY(-50%);
}

@mixin fadeInOut() {
  @keyframes fadeInOut {
    0% {
      opacity: 0;
    }

    5% {
      opacity: 1;
    }

    95% {
      opacity: 1;
    }

    100% {
      opacity: 0;
    }
  }
}

/*
Works properly only for non-IE browsers
For IE, 'ellipsis' is supported only for a single text line
*/
@mixin multi-line-ellipsis($lineHeight, $lineCount, $flexValue: 1) {
  display: block;
  flex: $flexValue 1;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  line-height: $lineHeight;

  @media screen and (-webkit-min-device-pixel-ratio: 0) {
    /* autoprefixer: ignore next */
    -webkit-box-orient: vertical;
    display: -webkit-box;
    -webkit-line-clamp: $lineCount;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: normal;
    word-break: break-all;
  }
}

@mixin link-button($button-color) {
  width: auto;
  height: 3rem;
  background: none;
  border: none;
  outline: none;
  padding: 0.5rem;
  text-align: right;
  color: $button-color;
  cursor: pointer;
}

@mixin popup-message-vertical-strip($color) {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 0.4rem;
  background-color: $color;
}

@mixin popup-message-vertical-strip-new-design($color) {
  content: "";
  position: absolute;
  top: 2.5rem;
  left: 6.5rem;
  height: 35%;
  width: 0.1rem;
  background-color: $color;
}

@mixin slideTransition($property) {
  transition: $property 0.9s cubic-bezier(0.3, 1, 0.4, 1) 0s;
}

@mixin alert-message($backgroundColor, $borderColor) {
  display: inline-flex;
  align-items: center;
  padding: 2rem;
  width: 100%;
  background-color: $backgroundColor;
  border-bottom: 0.1rem solid $borderColor;
}

@mixin batch-screening-action-button() {
  position: relative;
  right: 0.2rem;
  font-size: 2.5rem;
  cursor: pointer;
}

@mixin popup-overlay($color) {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: $color ;
  z-index: -1;
}

@mixin popup-dark-mode() {
  p {
    color: $popup-text-color-dark;
  }

  &:after {
    @include popup-overlay($popup-bg-color-dark-mode);
    z-index: -1;
  }
}