.app-modal-edit-subcategories {
  color: $button-link-primary-color;
  .app-modal__content-box {
    width: 75rem;

    > .app-modal__content {
      height: inherit;

      button.la-CloseRemove {
        outline: 0;
        padding-right: 0.5rem;
        margin-top: $popup-overall-padding;
      }

      .popup-modal-body {
        margin: 1.5rem 0 0;
        padding: 0 $popup-overall-padding;

        .edit-subcategories-container-list {
          -ms-flex-direction: row;
          flex-direction: row;
          display: -ms-flexbox;
          display: flex;
          -ms-flex-wrap: wrap;
          flex-wrap: wrap;
          -ms-flex-pack: flex-start;
          justify-content: space-between;
          padding-bottom: 1rem;

          &.negativeNews-subcategories {
            &:after {
              content: "";
              flex: auto;
            }

            .edit-subcategories-list-item {
              margin: 1rem 0 1rem;
              width: 25%;
            }
          }

          .edit-subcategories-list-item {
            margin: 1rem 0 1rem;
          }
        }

        .multi-language-checkbox {
          .user-preference-checkbox {
            margin-top: 0.5rem;
          }
        }
      }

      .popup-modal {
        height: inherit;
        max-height: inherit;

        .select-all-links {
          padding-left: $popup-overall-padding;
          font-size: 1.5rem;

          a {
            margin-right: 1rem;
            color: $button-link-primary-color;

            &.disabled {
              pointer-events: none;
              cursor: not-allowed;
              color: $disabled-text-color;
            }
          }
        }
      }

      .information-item {
        font-size: 16px;
        padding-bottom: 1.5rem;
      }
    }
  }
}

.edit-subcategories {
  color: $button-link-primary-color;
  padding: 0 0.5rem;
}

.edit-subcategories-dropdown {
  background-color: #fff;
  min-height: 3.2rem;
  width: 20rem;
  margin-top: 1rem;
  margin-left: 2rem;
  position: relative;
  padding: 0 1rem;
  
  .user-preferences-category-container__list {
    justify-content: space-between;
  }

  .closed {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: inherit;
    height: 3.5rem;
    width: inherit;
    border: 0.1rem solid $default-dropdown-border-color;
    border-radius: 0.2rem;
    background: url("~/public/assets/TriangleDown.svg") no-repeat right $white;
    background-size: 15px;
    background-position-x: calc(100% - 10px);
  }

  .selected-sources-dropdown-content {
    position: absolute;
    background-color: inherit;
    width: inherit;
    border-radius: 0.2rem;
    border: 0.1rem solid $default-dropdown-border-color;
    z-index: 1;
    max-height: 24rem;
    overflow-y: auto;
  }

  .edit-subcategories-list-item {
    padding: 1rem;
    cursor: pointer;
    
    &:hover {
      background-color: $hover-color;
    }
  }
  
}
