
.active-filter-container {
  display: flex;
  flex-direction: row;
  height: auto;

  .active-filters-wrapper {
    width: 32rem;
    min-width: 32rem;
    max-width: 32rem;
    flex: 1;
    -ms-flex: 1; /* IE 10 */
    flex-basis: 25%;

    @media screen and (min-width: 1366px) {
      width: 35rem;
      min-width: 35rem;
      max-width: 35rem;
    }

    .active-filters {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      background-color: $button-color-active;
      border-radius: 2px;
      color: white;
      margin: 1.5rem 0 1.5rem 3rem;


      &__name {
        line-height: 2.2rem;
        padding: .8rem 1.1rem 0.8rem;
        text-align: center;
        display: flex;
        flex-direction: row;

        .la-FilterToggle {
          color: $white;
          font-size: 2.2rem;
          margin-right: 1rem;
        }
      }

      &__count {
        width: 1.8rem;
        height: 1.8rem;
        border-radius: 50%;
        background-color: $white;
        display: inline-block;
        margin: 1.1rem 1rem 1rem;
        text-align: center;

        .number-count {
          color: $button-color-active;
          line-height: 2.4rem;
          font-size: 1.4rem;
          margin-top: -0.3rem;
        }
      }
    }
  }

  .active-filter-list {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    min-height: 6.8rem;
    padding: 1.8rem 1rem 0 1rem;
    height: auto;

    .clear-filters-button {
      background-color: transparent;
      margin: 0 0 1rem 1rem;
      border: 0;
      color: $button-link-primary-color;
      font-size: 1.6rem;
      outline: 0;
      cursor: pointer;
    }

    .category-filter {
      background-color: $default-bg-color;
      margin: 0 0 1rem 1rem;
      height: 3.2rem;
      padding: .7rem 1.4rem .8rem 1.6rem;
      color: $pill-color-default;
      border-radius: 6rem;
      font-size: 1.4rem;
      line-height: 1;
      border: .2rem solid $primary-border-color;
    }

    .filters-list {
      display: flex;
      flex-direction: row;
      flex-wrap: wrap;
    }

    .active-filter-item {
      background: $default-bg-color;
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 0 0 .9rem .9rem;
      border-radius: 6rem;
      border: .2rem solid $primary-border-color;
      padding: .7rem 1.4rem .8rem 1.4rem;
      height: 3.2rem;

      &__name{
        font-size: 1.4rem;
        color: $pill-color-default;
      }

      &__label {
        font-weight: bold;
      }

      .la-CloseRemove {
        border: 0;
        display: inline-block;
        padding: 0 0 0 1.1rem;
        background: transparent;
        color: $primary-border-color;
        outline: none;
        cursor: pointer;
        font-weight: 600;
      }

    }

    .active-filter-items-group{
      @extend .active-filter-item;
      background-color: $default-bg-color;
      cursor: pointer;
      position: relative;

        &__name{
          @extend .active-filter-item__name;
          font-weight: bold;
          padding-right: 0.5rem;
          line-height: 1;
        }

        &__trimmed-values{
          @extend .active-filter-item__name;
          padding-left: 0;
          line-height: 1;
        }

      .tooltips {
         max-width: 80rem;
      }
    }
  }
}
