.match-container {
  position: relative;
  .float-container {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 10;
  }
  &.start-page-ubo {
    width: 75%;
    margin: -1.8rem auto 1.8rem;
    font-size: 1.3rem;
    .float-container {
      border: 1px solid $ubo-suggestions-stroke-color;
      box-shadow: $default-border-color 0 3px 2px;
    }
  }
  .match-header {
    display: flex;
    line-height: 1.6;
  }
  .match-header-title {
  }
  .match-list {
    padding-right: 0;
    max-height: 29rem;
    overflow-y: scroll;
  }
  .match-item {
    display: flex;
    border-bottom: 1px solid $default-border-color;
    cursor: pointer;
    &:last-child {
      border-bottom: 0;
    }
    &:hover {
      background-color: $ubo-suggestions-hover;
    }
    &.selected {
      .match-toggle-item {
        color: $button-text-color;
        border-color: $button-text-color;
      }
    }
    .item-content {
      flex: 10;
    }
    .item-controls {
      min-width: 11rem;
      max-width: 11rem;
      display: flex;
      align-content: center;
      align-items: center;
    }
    &.selected {
      background-color: $ubo-suggestions-selected;
    }
    &.hidden {
      display: none;
    }
    &.ubo-company-match-item {
      line-height: 3.5rem;
      .item-content {
        display: flex;
        flex-direction: row;
        .company-name {
          flex: 5;
          padding-left: 2rem;
        }
        .company-address {
          flex: 4;
          &.is-hq {
            font-weight: bold;
          }
        }
        .company-duns {
          flex: 1;
          max-width: 11rem;
          min-width: 11rem;
        }
      }
    }
  }
  .match-toggle-item {
    border: 1px solid $button-color-active;
    color: $button-color-active;
    width: 11rem;
    text-align: center;
    padding: 0.5rem;
    cursor: pointer;
    @extend .text-as-button;
  }
  .just-search-for {
    color: $ubo-just-search;
    background-color: $ubo-suggestions-header-bg;
    line-height: 3rem;
    padding-left: 2rem;
    cursor: pointer;
    &.selected,
    &:hover {
      background-color: $ubo-suggestions-selected;
    }
  }
  .company-match-header {
    display: flex;
    flex-direction: row;
    background-color: $ubo-suggestions-header-bg;
    line-height: 3rem;
    .match-header-name {
      flex: 5;
      display: flex;
      flex-direction: row;
      padding-left: 2rem;
      font-weight: bold;
    }
    .match-header-address {
      flex: 2;
    }
    .match-header-duns {
      flex: 1;
      max-width: 12rem;
      min-width: 12rem;
      padding-right: 1rem;
    }
  }
}

.tagify__dropdown {
  //padding-top: 3rem;
}

#ubo-graph-container,
#ubo-simple-graph-container {
  width: 100%;
  height: 820px;
  overflow: hidden;
  text-align: center;
  position: relative;
  .leafs {
    &:hover {
      cursor: pointer;
    }
  }
  .root_node {
    color: #fff;
    text-align: center;
    padding: 1rem;
    font-size: 20px;
  }
  svg {
    margin: 0 auto;
    .line {
      stroke-width: 2;
      stroke: $ubo-graph-bo-color;
      fill: none;
      &.hover {
        stroke: $ubo-graph-root-node-color;
        fill: none;
      }
    }
    .line-rev {
      fill: none;
      stroke: none;
    }
    .shares-box {
      fill: #fff;
    }
    .shares {
      stroke: $ubo-graph-percentage;
    }
    .ownership {
      stroke: $ubo-graph-individual-share;
    }
    .businessNode {
      display: flex;
      align-items: center;
      height: 100%;
      background-color: #3e75d2;
      fill: #3e75d2;
      &.baseNode {
        background-color: #5d34b0;
        fill: #5d34b0;
      }
      span {
        margin: 0 auto;
        text-align: center;
        display: block;
        color: #fff;
        font-weight: bold;
        font-size: 10pt;
      }
    }
    .rootNode {
      background-color: $ubo-graph-root-node-color;
      border-radius: 50%;
      width: 20rem;
      height: 20rem;
      color: #fff;
    }
    .leafs:hover {
      .nodeDetails {
        display: block;
        visibility: visible;
      }
      .bo-circle {
        fill: $ubo-graph-root-node-color !important;
      }
    }

    /*#container {
      transition: all 2s;
      transform-origin: 50% 50%;
    }*/
  }
  .ubo-graph-controls {
    position: absolute;
    background-color: #fff;
    right: 1rem;
    top: 20rem;
    border: 1px solid $ubo-graph-zoom-color;
    border-radius: 5px;
    max-width: 3.5rem;
    padding: 2px;
    text-align: center;
    box-shadow: $default-border-color 0 0 4px;
    .zoom-control {
      display: inline-block;
      color: $ubo-graph-zoom-color;
      line-height: 3rem;
      font-weight: bold;
      font-size: 25px;
      cursor: pointer;
      border-bottom: 1px solid $default-border-color;
      &:last-child {
        border-bottom: none;
      }
    }
  }
  .tooltips {
    font-size: 1.6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: auto;
  }
}

#ubo-graph-container,
#ubo-simple-graph-container {
  &.over25 {
    .leafs {
      visibility: hidden;
      .nodeDetails {
        visibility: hidden;
        opacity: 0;
      }
      &.over25,
      &.over50 {
        visibility: visible;
        .nodeDetails {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .lines {
      visibility: hidden;
      &.over25,
      &.over50 {
        visibility: visible;
      }
    }
  }
  &.over50:not(.root_node) {
    .leafs {
      visibility: hidden;
      .nodeDetails {
        visibility: hidden;
        opacity: 0;
      }
      &.over50 {
        visibility: visible;
        .nodeDetails {
          visibility: visible;
          opacity: 1;
        }
      }
    }
    .lines {
      visibility: hidden;
      &.over50 {
        visibility: visible;
      }
    }
  }
  .leafs {
    &:hover {
      cursor: default;
    }
  }
  &.over25,
  &.over50 {
    .leafs.root_node {
      visibility: visible;
    }
  }
  .graph-filter-container {
    float: right;
    select {
      margin: 1rem 0 1rem 0;
      height: 3rem;
      font-size: 14px;
    }
  }
}

#graph-key {
  .container {
    margin: 2rem;
  }
  h2 {
    font-size: 16px;
    text-align: center;
    text-decoration: underline;
  }
  .legend {
    display: inline-block;
    font-size: 12px;
    font-weight: bold;
    text-align: left;
    padding: 0 2rem;
    span {
      display: inline-block;
      margin-right: 5px;
      line-height: 1;
    }
    &.indirectOwnership {
      background: url("~/public/assets/IndirectOwnership.svg") no-repeat left;
      span {
        padding-left: 30px;
      }
    }
    &.degreesOfSeparation {
      span {
        background-color: $ubo-graph-degree-bg;
        border-radius: 50%;
        color: $ubo-graph-degree-text;
        padding: 3px 5px;
        margin-right: 7px;
      }
    }
    &.directOwnership {
      background: url("~/public/assets/DirectOwnership.svg") no-repeat left;
      span {
        padding-left: 30px;
      }
    }
  }

  .legend:last-child {
    padding-left: 0;
  }
}

.visible_graph {
  visibility: visible;
}
.hidden_graph {
  visibility: hidden;
  height: 0;
  overflow: hidden;
}
.center {
  text-align: center;
}
