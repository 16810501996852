.search-bar-header {
  height: 4.5rem;
  color: $default-bg-color;
  margin-top: 1.5rem;
  margin-bottom: 2rem;
  display: flex;
  justify-content: space-between;
  background-color: $default-bg-color;
  width: 100%;
  border-radius: .5rem;

  .search-box-container {
    width: 100%;
    max-width: calc(100% - 21rem);

    .la-CloseRemove {
      font-size: 1.4rem;
      height: 4.3rem;
      padding-right: 1rem;
      margin: 0.1rem -0.4rem 0 0;
      cursor: pointer;
    }

    .search-input-trimmed-container {
      max-height: 4.5rem;

      .search-input-trimmed {
        font-size: 1.4rem;
        line-height: 1.2;
        color: $black;
        padding: 1.4rem 0 1.4rem 1rem;
        width: calc(100% - 3.4rem);
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
      }
    }

    .search-input-container {
      outline: none;
      width: 100%;
      box-shadow: none;

      .search-input-full {
        padding: 1.4rem 3.4rem 1rem 1rem;
        color: $black;
        font-size: 1.4rem;
        min-height: 4.3rem;
        border-bottom: 1px solid transparent;
        border-top-left-radius: .5rem;
        border-bottom-left-radius: .5rem;
        box-shadow: 0 .3rem .7rem 0 rgba(0, 0, 0, 0.05);
      }
    }
  }

  .la-SWResearch {
    background-image: linear-gradient(53deg, $header-diligence-specific-color 27%, $header-diligence-specific-color-lighter 107%);
    border: none;
    color: $white;
    font-size: 2.5rem;
    font-weight: bold;
    width: 6.8rem;
    cursor: pointer;
    position: relative;
    outline: 0;
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem;
  }

  .search-dropdown {
    display: block;
    cursor: pointer;
    height: 3.3rem;
    margin: .6rem .94rem 0 .2rem;
    background: $default-bg-color;
    color: $black;
    font-size: 1.2rem;
    flex: 0 0 12.18rem;
    border-radius: 10rem;
    border: solid 2px $header-diligence-specific-color;
    padding-top: 0.6rem;

    span {
      white-space: nowrap;
      display: flex;
      justify-content: space-between;
      width: 100%;
      max-width: 12.18rem;

      P {
        padding-left: 1.6rem;
        margin: 0;
        color: $header-diligence-specific-color;
        font-weight: bold;
        font-stretch: normal;
        font-style: normal;
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }

      .la-TriangleDown {
        font-size: 1.4rem;
        font-weight: bolder;
        display: inline-block;
        padding: .3rem 1.6rem 0 .6rem;
        color: $header-diligence-specific-color;
      }

      .la-TriangleUp {
        font-size: 1.4rem;
        font-weight: bolder;
        display: inline-block;
        padding: .3rem 1.6rem 0 .6rem;
        color: $header-diligence-specific-color;
      }
    }

    .select-list {
      z-index: 10;
      transition: height 3s ease-in-out 0s;

      li {
        background-color: $default-bg-color;
        box-sizing: border-box;
        -webkit-box-sizing: border-box;
        height: 4rem;
        min-width: 11rem;
        position: absolute;
        margin-top: 1.5rem;
        border: .1rem solid $light-border-color;
        border-radius: .5rem;
        box-shadow: 0 .6rem 1rem -.2rem rgba(0,0,0,0.15);
        -moz-box-shadow: 0 .6rem 1rem -.2rem rgba(0,0,0,0.15);
        -webkit-box-shadow: 0 .6rem 1rem -.2rem rgba(0,0,0,0.15);
        z-index: 100;
        padding: .5rem;

        span {
          font-size: 1.2rem;
          font-weight: bold;
          line-height: 1rem;
          padding: .5rem .5rem .5rem .75rem;
          border: 0.2rem solid transparent;
          
            &:hover {
              background-color: $hover-color;
              border: 0.2rem solid $white;
              border-radius: 0.5rem;
            }
        }
      }
    }
  }
}


