.popover {
    background-color: $default-bg-color;
    border: 1px solid $default-border-color;
    border-radius: 5px;
    box-shadow: 0 0 5px $shadow-color;
    min-height: 3rem;
    width: 50rem;
    outline: none;
    position: absolute;

    &__header {
        align-content: center;
        background-color: $gray-bg-color;
        border-bottom: 1px solid $white;
        border-radius: 5px 5px 0 0;
        color: $default-text-color;
        display: flex;
        font-size: 1.8rem;
        font-weight: normal;
        height: 4rem;
        justify-content: space-between;
        position: relative;

        &:after {
            border-bottom: 1px solid $default-border-color;
            bottom: -1px;
            content: "";
            left: 0;
            position: absolute;
            width: 100%;
        }

        .title {
            font-size: 1.8rem;
            line-height: 4rem;
            padding: 0 1.6rem;
        }

        .app-modal__close-button {
            margin: 1rem;
        }
    }

    &__content {
        padding: 1.6rem;
        font-size: 1.4rem;

        .terms-definitions-topics-matched {
            display: flex;
            flex-direction: row;
            justify-content: space-between;

            &__name {
                padding-top: 1rem;
                font-weight: bold;
                width: 30%;
            }

            &__def {
                padding-top: 1rem;
                width: 70%;
            }
        }
    }

    &__arrow--shadow, &__arrow {
        background: $default-bg-color;
        height: 2rem;
        position: absolute;
        right: -1.1rem;
        top: 5rem;
        transform: rotate(45deg);
        width: 2rem;
    }

    &__arrow {
        border-right: 1px solid $default-border-color;
        border-top: 1px solid $default-border-color;
        z-index: 2;
    }

    &__arrow--shadow {
        box-shadow: 0 0 5px $shadow-color;
        z-index: -1;
    }

    &.left-arrow {
        .popover__arrow--shadow, .popover__arrow {
            right: auto;
            left: -1.1rem;
        }

        .popover__arrow {
            border-right: 0;
            border-top: 0;
            border-left: 1px solid $default-border-color;
            border-bottom: 1px solid $default-border-color;
        }
    }

}
.topics-matched-help {
    width: 1.8rem;
    cursor: pointer;

    .la-Help {
        padding: 1rem;
        font-size: 1.5rem;
        font-weight: bold;
    }
}