.article-actions {
  display: flex;
  justify-content: space-between;
  align-items: center;
  border-bottom: .1rem solid $default-border-color;
  position: relative;
  margin-left: 2.5rem;
  margin-top: 1.9rem;
  padding-bottom: 2rem;

  .left-actions {
    display: flex;
    flex-direction: row;
    align-items: center;

    .select-all-checkbox {
      margin-right: 1.2rem;

      .custom-checkbox {
        input {
          + .checkbox-label {
            &:before {
              display: inline-block;
              position: relative;
              margin-right: .8rem;
            }
          }
        }
      }
    }

    .icon-box {
      padding: 0 1.5rem 0;
      display: inline-block;
      border: none;
      border-left: 1px solid $default-border-color;
      cursor: pointer;
      outline: 0;
      background-color: transparent;

      .la-Print,
      .la-Email,
      .la-AddToReport,
      .la-Download {
        font-size: 2.3rem;
        color: $subfield-text-color;
      }

      &:active {
        opacity: 0.5;
      }

      &:disabled {
        opacity: 0.5;
        cursor: initial;
      }

      &:first-child {
        border: none;
      }
    }

    .selected-articles-count {
      padding-bottom: 0;
    }
  }

  .right-actions {
    display: flex;
    align-items: center;
    justify-content: space-between;

    .sort-box {
      display: flex;
      align-items: center;
      margin-left: 1.2rem;
      font-family: $secondary-font-stack;

      span {
        margin-right: 1em;
        font-size: 1.3rem;
        font-weight: bold;

        @media screen and (min-width: 1260px) {
          font-size: 1.5rem;
        }
      }

      select {
        font-family: $secondary-font-stack;
        width: 13.5rem;
        height: 3.6rem;
        padding: 0 4rem 0 1.8rem;
        border: .1rem solid $header-submenu-border;
        border-radius: .3rem;
        font-size: 1.6rem;
        text-overflow: ellipsis;
        
        &:hover {
          cursor: pointer;
        }

        option {
          font-family: $secondary-font-stack;
        }
      }
    }
  }

  .delivery-actions {
    display: flex;
  }

  &-disabled-action {
    display: flex;
    border-left: .1rem solid $default-border-color;

    &:first-of-type {
      border: none;
    }

    &.actionWrapperBorder {
      border-left: .1rem solid $default-border-color;
    }
  }
}
