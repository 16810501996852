.sort-button-container {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-top: 0.1rem;
  width: 2rem;
  align-items: center;

  .sort-button-top {
    margin-bottom: -1rem;
  }

  .sort-button-bottom {
    margin-top: -0.3rem;
  }

  .sort-button-icon-container {
    width: 1rem;
    height: 1rem;

    .sort-button-icon {
      stroke: $button-sort-color;
      stroke-width: 0.15rem;
      fill: none;

      &.filled {
        fill: $button-sort-color;
      }
    }
  }
}
.load-icon-container {
  margin-left: 1rem;
  margin-top: 0.1rem;
  position: relative;
  width: 2.5rem;
  height: 2rem;
}