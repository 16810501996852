.simple-list {
    padding: 1.4rem 3.3rem 1.7rem 3.3rem;

    &__table {
        width: 100%;

        &__header {
            &__row {
                border-bottom: 0.1rem solid #EDEDED;
                text-align: left;

                &__element {
                    font-weight: bold;
                    padding: 0.7rem 1rem;
                }

                &__element:first-child {
                    padding: 0.7rem 1rem 0.7rem 0;
                }

                &__element:last-child {
                    padding: 0.7rem 0 0.7rem 1rem;
                }
            }
        }

        &__body {
            &__row {
                border-bottom: 0.1rem solid #EDEDED;

                &__element {
                    padding: 0.7rem 1rem;
                }

                &__element:first-child {
                    padding: 0.7rem 1rem 0.7rem 0;
                }

                &__element:last-child {
                    padding: 0.7rem 0 0.7rem 1rem;
                }
            }
        }
    }

    &__no-data {
        padding: 1rem;
    }
}