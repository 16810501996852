.user-preferences-similar-names {
  .user-preferences-similar-names-topic {
    padding-top: 2rem;
    font-weight: bold;
  }

  .fuzzy-threshold-content {
    .fuzzy-threshold-radio-group {
      display: inline-block;
    }
  }

  .fuzzy-threshold-content[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }

  .include-similar-name-match {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0 2rem;

    .custom-toggle {
      margin-right: 2rem;
    }
  }

  .fuzzy-match-description {
    @media only screen and (min-width: 1300px) {
      max-width: 80%;
    }
    margin: 1rem 0;
    background-color: #f4f4f5;
    padding: 1rem 2.3rem;
  }

  .include-fuzzy-match-explanation1 {
    font-weight: bold;
    margin-bottom: 0.5rem;
  }

  .include-fuzzy-match-explanation2 {
    margin-bottom: 1.5rem;
  }

  .include-fuzzy-match-explanation3 {
    margin-bottom: 1.5rem;
  }

  .include-similar-match-help {
    margin-bottom: 2rem;
  }

  .custom-radio {
    margin: 1rem 0;
  }
}
