.alert-message-info {
    @include alert-message($light-popup-message-bg-color, $info-banner-color);
    position: relative;

    .alert-message__icon {
        color: $info-banner-color;
        padding-right: 1.5rem;
    }

    .alert-message__message {
        border-left: 0.1rem solid $info-banner-color;
        padding-left: 1.5rem;
    }

    &.dark-mode {
        background: $popup-bg-info-dark;
        color: $popup-text-color-dark;

        .alert-message-info {
            .alert-message__message {
              border-left: 0.1rem solid $popup-border-color-info-dark;
            }
          }
    }
}

.alert-message-warning {
    @include alert-message(#F5AE471F, $warning-banner-color);
    position: relative;
    margin-bottom: 2rem;

    .alert-message__message {
        padding-left: 1.5rem;
        border-left: 0.1rem solid $warning-banner-color;
    }

    .alert-message__icon {
        padding-right: 1.5rem;
    }

    &.dark-mode {
        background-color: $popup-bg-warning-dark;
        color: $popup-text-color-dark;
        border-bottom: 0.1rem solid $popup-border-color-warning-dark;

        .alert-message-warning {
            .alert-message__message {
              border-left: 0.1rem solid $popup-border-color-warning-dark;
            }
          }
    }
}