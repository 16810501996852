.reports-wrapper {
  .selection-header {
    background-color: $white;
    padding: 1rem 3rem .8rem;
    display: flex;
    flex-direction: row;
    -webkit-box-shadow: 0 6px 4px -2px rgba(0,0,0,0.15);
    -moz-box-shadow: 0 6px 4px -2px rgba(0,0,0,0.15);
    box-shadow: 0 6px 4px -2px rgba(0,0,0,0.15);
    z-index: 100;
    width: 100%;

    &.js-is-stuck {
        position: fixed !important;
        top: 59px !important;
        bottom: auto !important;
    }

    .custom-checkbox {
      margin: 0.7rem 2rem 0 4rem;
    }
    .selected-articles-dropdown-container {
      margin-top: 0.5rem;
    }
  }
}