.sanctions-table-header {
    display: flex;
    flex-direction: row;
}

.sanctions-table-header p {
    font-weight: bold;
    gap: 1rem;
}

.sanctions-table-cell,
.sanctions-table-results,
.sanctions-table-risk {
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    white-space: break-spaces;
}

.rg-sanctionsHeader-cell p,
.rg-sanctionsCell-cell p {
    padding-left: 0.7rem;
}

.sanctions-table-results {
    &-content {
        padding-left: 1rem;
        display: -webkit-box;
        max-width: 100%;
        -webkit-line-clamp: 3;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}

.sanctions-table-cell {

    .sanctions-document-metadata-outer-container {
        display: flex;
        flex-direction: column;
        padding-left: 0.3rem;
        .sanctions-document-metadata-container {
            display: flex;
            flex-direction: row;
            gap: 3rem;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
              

            .sanctions-document-metadata-title {
                width: 5rem;
                font-weight: bold;
            }
        }
    }

}
