.negativityLevelPill {
  &Container {
    display: flex;
    column-gap: 1rem;
  }

  &Wrapper {
    padding: .7rem 1rem;
    background-color: $negativity-level-pill-background;
    border-radius: 1.5rem;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      margin-right: 1rem;

      &:last-child {
        margin-right: 0;
      }
    }

    &.interactive {
      &:hover {
        cursor: pointer;
      }
    }

    &.selected {
      &.high {
        border: 1px solid $negativity-level-pill-high;
        background: $negativity-level-pill-selected-background-high;
      }

      &.medium {
        border: 1px solid $negativity-level-pill-medium;
        background: $negativity-level-pill-selected-background-medium;
      }

      &.low {
        border: 1px solid $negativity-level-pill-low;
        background: $negativity-level-pill-background;
      }
    }

    &.deselected {
      border: 1px solid transparent;
    }

    &.disabled {
      background: $negativity-level-pill-disabled-background;
      color: $negativity-level-pill-disabled;

      &:hover {
        cursor: unset;
      }

      span:before {
        opacity: 0.5;
      }
    }
  }

  &Label {
    display: flex;
    font-size: 1.1rem;
    line-height: normal;
    font-weight: bold;
    align-items: center;
    column-gap: .5rem;

    &:before {
      content: '';
      width: 1.3rem;
      height: 1.3rem;
      display: inline-block;
      border-radius: 50%;

      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
        margin-right: .5rem;
      }
    }

    &.high {
      &:before {
        background-color: $negativity-level-pill-high;
      }
    }

    &.medium {
      &:before {
        background-color: $negativity-level-pill-medium;
      }
    }

    &.low {
      &:before {
        background-color: $negativity-level-pill-low;
      }
    }
  }
}