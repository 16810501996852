.create-alert-wrapper {
  margin-bottom: 7rem;

  &_header {
    background-color: $default-bg-color;
    padding: 1rem 0 1rem 3rem;
    color: $default-text-color;

    .icon-box {
      padding: 0;
      display: inline-block;
      border: none;
      cursor: pointer;
      outline: 0;
      background-color: $white;

      .la-TriangleLeft {
        position: relative;
        right: 0.2rem;
        font-size: 2.5rem;
        cursor: pointer;

        &:hover {
          color: $disabled-text-color;
        }
      }
    }

    h2 {
      display: inline-block;
    }
  }

  &_body {
    margin: 2rem 2rem 0 2rem;
    padding: 0.2rem 2rem 2rem 2rem;
    color: $default-text-color;
    background-color: $default-bg-color;

    .create-alert_label {
      font-size: 18px;
    }

    .create-alert_input {
      margin-top: 1.5rem;
      width: 100%;
    }

    .create-alert_select {
      margin: 1.5rem 1rem 0 0;
      width: 15rem;
      height: 3.5rem;
    }

    .validationAlertMessage {
      font-size: 1.4rem;

      &+.create-alert_input {
        margin-top: .5rem;
      }
    }

    .create-alert-delivery-options-container {
      margin-top: 3rem;

      &_body {
        margin-top: 1.5rem;
        display: flex;
        flex-direction: column;

        .alert-delivery-options_alert-name {
          display: flex;
          flex-direction: column;
          margin-bottom: 2rem;
          width: 70%;

        }

        .alert-delivery-options_send-to {
          @extend .alert-delivery-options_alert-name;

          &_note {
            font-size: 12px;
          }

          &_recent-emails {
            position: relative;
            background-color: $white;
            border: 1px solid $default-border-color;
            width: 50rem;
            max-height: fit-content;
            overflow-y: auto;
            z-index: 1;

            &_header {
              font-size: $font14;
              font-weight: bolder;
            }

          }

          .emails-dropdown-item {
            list-style: none;
            padding: 0.5rem 0 0.5rem 0;

            &-title {
              background-color: inherit;
              border: none;
              width: 100%;
              height: inherit;
              text-align: left;
              padding: 0.5rem 0 0.5rem 1rem;
              cursor: pointer;

              &:active {
                border: none;
              }
            }

            &:hover {
              background-color: $gray-bg-color;
            }
          }
        }

        .email-note {
          background-color: $popup-bg-color;
          padding: 1.5rem 1rem;
          font-size: 1.4rem;

          &:before {
            @include popup-message-vertical-strip($popup-user-error-color)
          }
        }

        .alert-delivery-options_comments {
          @extend .alert-delivery-options_alert-name;

          &-subHeader {
            display: flex;
            font-size: 1.2rem;
            justify-content: space-between;

            .characters-remaining {
              color: $placeholder-color;
              font-style: italic;
            }
          }

          .validationAlertMessage {
            margin-bottom: .5rem;
          }
        }
      }
    }

    .create-alert-content-container {
      margin: 3rem 0 3rem 0;
    }

    .create-alert-sending-options-container {
      @extend .create-alert-content-container;
    }

    .create-alert-alert-frequency-container {
      @extend .create-alert-content-container;

      &_body {
        display: flex;
        flex-direction: column;

        &__frequency {
          display: flex;
          flex-direction: column;
          width: 100%;

          &__options {
            display: flex;
            align-items: baseline;

            &__connector {
              margin-right: 1rem;
              width: fit-content;
            }
          }

        }

        .create-alert-sending-options-container_send-as {
          display: flex;
          flex-direction: row;

          .create-alert-format {
            display: flex;
            flex-direction: column;
            margin: 3rem 3rem 3rem 0;

          }

          .create-alert-send-as {
            @extend .create-alert-format
          }
        }

        .toggle-no-results-alerts {
          @extend .create-alert-sending-options-container_send-as;

          &_message {
            margin-left: 1.5rem;
          }
        }
      }
    }

    .create-alert-actions {
      @extend .create-alert-content-container;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;

      .button-primary-sm {
        margin-right: 1rem;
        min-width: 7.4rem;

        .save-alert {
          display: flex;
          flex-direction: row;
          justify-content: center;
          text-align: center;

          .icon {
            font-size: 1.7rem;
            color: $primary-button-text-color;
            padding: 0.4rem 0.5rem 0 0;
          }

          .save-alert-name {
            line-height: 2.5rem;
          }
        }

        // IE
        &.btn-saving {
          background-color: $button-color-click;
          border: 1px solid $button-color-click;

          .save-alert {
            text-align: center;

            .ui.centered.inline.loader.active {
              display: inline-block;
              width: 100%;
              margin-left: unset;
              margin-right: unset;
            }
          }
        }

        &.btn-saved-msg {
          background-color: $button-color-click;
          border: 1px solid $button-color-click;

          &:hover {
            box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
          }
        }
      }
    }

  }

}

.alert-warning-message {
  position: relative;
  margin: 1rem 0 1rem;
  background-color: $popup-bg-warning-light;
  width: 100%;
  height: 8rem;
  display: flex;
  border-bottom: 0.1rem solid $popup-user-error-color;
  align-items: center;

  &:before {
    content: "";
    position: absolute;
    top: 2.2rem;
    left: 6rem;
    height: 45%;
    width: 0.1rem;
    background-color: $popup-user-error-color;
    z-index: 2;
  }

  &__text {
    display: inline-block;
    margin-left: 3rem;
    z-index: 2;

  }

  &__icon {
    display: inline-block;
    margin-left: 2rem;
    margin-top: 1rem;
    z-index: 2;
  }


  &.dark-mode {
    background-color: $popup-bg-warning-dark;
    color: $popup-text-color-dark;
  }
}