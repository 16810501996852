  .dateRanges-dropdown-search-bar {
    display: flex;

    .dateRanges-dropdown-search-bar-header{
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem .3rem;

      .dateRanges-dropdown-arrow{
        display: none;
      }

      .dateRanges-dropdown-button {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-left: 2rem;
        padding-right: 1.4rem;
        width: 14.5rem;
        height: 3.5rem;
        font-size: $font14;
        line-height: 1.2;
        font-weight: bold;
        color: $button-secondary-color-darken;
        background-color: rgba(39, 95, 99, 0.05);
        border: .2rem solid $button-secondary-color-darken;
        border-radius: 10rem;

        &__text {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
        }

        &:hover {
          cursor: pointer;
        }

        &::after {
          display: flex;
          align-items: center;
          font-family: 'GVS_Icons', sans-serif;
          content: "\e60a";
          font-size: $font14;
          font-weight: 900;
          color: $button-secondary-color-darken;
          margin-left: .6rem;
        }

        &.selected {
          &::after {
            content: "\e607";
          }
        }
      }
    }

    .dateRanges-dropdown-content-wrapper{
      position: absolute;
      top: 8rem;
      right: 0;
      z-index: $z-message-popups;

      .dateRanges-dropdown-content{
        width: 42rem;
        max-height: 32.6rem;
        background-color: $white;
        border: none;
        border-radius: 0.5rem;
        box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
        overflow-y: auto;
        overflow-x: hidden;
        z-index: 91;
        padding: 1.65rem;
        display: flex;
        flex-direction: column;

        &-header{
          font-size: $font16;
          font-weight: bold;
          color: $default-text-color;
          padding-bottom: 1rem;
        }

        &-body{
          padding-bottom: 1rem;
          display: flex;
          flex-direction: row;
          flex-wrap: wrap;
          min-width: 38rem;
          align-items: flex-start;

          .dateRanges-dropdown-content-body-date-group:nth-child(even){
            .custom-date-picker{
              margin-left: -19.35rem;
            }

          }
          .dateRanges-dropdown-content-body-date-group{
            min-height: 8rem;

            &.legalDateRange,
            &.companyDateRange{
              padding-top: 1.5rem;

              .horizontal-divider {
                display: none;
              }
            }
          }



          .dropdown{
            display: flex;
          }

          .disabled {
            color: #bcbec0;
          }

          &-date-group{
            width: 50%;
            display: flex;
            flex-direction: column;
            font-size: $font14;
            color: $default-text-color;
            padding-bottom: 1rem;

            .date-ranges-dropdown {
              width: 18rem;
              flex-direction: column;

              .sort-date-range {
                width: 100%;

                select {
                  margin-top: .5rem;
                  padding: 1rem 3rem 1rem 1rem;
                  border: solid 0.1rem #bcbec0;
                  border-radius: 0.3rem;
                  outline: none;
                  font-size: 14px;
                  font-weight: normal;
                  color: $default-text-color;
                  text-overflow: ellipsis;
                  width: 100%;

                  &:disabled{
                    color: #bcbec0;
                    border-color: #bcbec0;
                  }

                }
              }

              &.custom-date-selected{
                .custom-date-picker{
                  flex: none;
                  margin-top: 1.5rem;

                  .date-range-select{

                    .date-select {
                      display: flex;
                      flex: none;

                      &:first-child {
                        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
                          margin-right: 5rem;
                        }
                      }

                      label {
                        padding-top: .5rem;
                      }

                      input{
                        max-width: 11rem;
                      }
                      i{
                        position: unset;
                      }

                    }
                  }

                  .react-calendar{
                    top: -3.5rem;
                    left: -31.5rem;
                  }
                }

                .horizontal-divider{
                  display: none
                }
              }

            }
          }
        }

        &-link{
          display: flex;
          align-items: center;
          justify-content: center;
          cursor: pointer;

          .la-TriangleUp, .la-TriangleDown {
            font-weight: bolder;
          }

          * {
            color: $button-link-primary-color;
            font-size: $font14;
            padding-right: .5rem;
          }

          &:hover {
            span:first-of-type {
              text-decoration: underline;
            }
          }
        }

      }
    }
  }

