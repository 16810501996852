/**
 * INFORMATION MODAL SCSS STYLE
 *
 * @version 0.1
 */

.app-modal-information {
  .app-modal__content-box {
    width: 70rem;
    height: 40rem;

    > .app-modal__content {
      height: inherit;

      button.la-CloseRemove {
        outline: 0;
        padding-right: 0.5rem;
        margin-top: $popup-overall-padding;
      }

      .popup-modal-body {
        padding: 0 $popup-overall-padding;
      }

      .popup-modal {
        height: inherit;
      }

      .information-content-header {
        font-weight: bold;
        font-size: 16px;
        margin-bottom: 2rem;
      }

      .information-item {
        font-size: 16px;
        padding-bottom: 1.5rem;
      }
    }
  }

  &.fuzzy-search {
    .app-modal__content-box {
      width: 50rem;
      height: auto;

      .popup-modal-body {
        margin-bottom: 4rem;

        div {
          display: inline;
          font-size: 1.6rem;

          a {
            color: $link-color;
          }
        }

        span {
          padding-left: 0.5rem;
          font-size: 16px;
        }
      }
    }
  }
}