.type-filter-wrapper {
  flex: 1;
  z-index: 1;
  -ms-flex: 1; /* IE 10 */
  background: $white;
  color: $default-text-color;
  flex-basis: 25%;
  font-weight: 200;
  flex-direction: row;
  width: 35rem;
  position: fixed;
  overflow-y: auto;
  max-height: calc(100vh - 13rem);
  scrollbar-width: thin;  // applies to Firefox' scrollbar
  @include slideTransition(width);

  @media screen and (min-width: 1366px) {
    width: 39rem;
  }

  &.collapsed {
    width: 0;
    -ms-overflow-style: none;


    .type-filter-category-container {
      .dateRange-postfilter {
        .radios-wrapper {
          .default-list-radio-option {
            max-height: 3.2rem;
          }
        }
      }
    }
  }

  &::-webkit-scrollbar {
    width: .7rem; // applies to Chromium based browsers' scrollbar (Chrome, Safari, Opera, Edge)
  }

  &.js-is-stuck {
    position: fixed !important;
    top: 59px !important;
    bottom: auto !important;
  }

  .type-filter-narrowby-container {
    align-items: center;
    border-bottom: solid 1px $default-border-color;
    height: auto;

    .type-filter-narrowby {
      font: $secondary-font-stack;
      font-size: 1.6rem;
      font-style: normal;
      font-weight: 400;
      margin-left: 1rem;
      margin-top: 1.5rem;
      outline: none;
    }
    .type-filter-search-category {
      background: #e2e2e2;
      border-radius: 10%/50%;
      color: $black;
      display: flex;
      flex-wrap: wrap;
      font: $secondary-font-stack;
      font-size: 1.5rem;
      font-style: normal;
      font-weight: 400;
      height: auto;
      justify-content: center;
      line-height: 1.5rem;
      margin: 1rem 1rem 1rem 1rem;
      max-width: 50%;
      padding: 1rem;

    }
  }

  .type-filter-category-container {
    height: auto;
    border-bottom: solid 1px $default-border-color;
    border-right: solid 1px $light-border-color;
    padding-left: 3.35rem;

    .search-within-radio-buttons {
      display: flex;
      flex-direction: row;
      margin-bottom: 1rem;

      .custom-radio {
        margin-right: 4rem;
      }
    }

    .search-within-radio-buttons[disabled] {
      pointer-events: none;
      opacity: 0.5;
    }

    .search-within-input-container {
      display: flex;
      flex-direction: row;
      border: 1px solid $default-border-color;
      border-radius: 2px;
      font-size: 1.4rem;
      line-height: 1.5rem;
      height: 4rem;
      padding: 1rem;
      margin: .5rem 1rem 2.5rem;

      &:hover {
        border: solid 1px $input-focus-color;
      }

      &:focus {
        border: solid 1px $dark-blue;
        outline: 0;
      }

      &:disabled {
        border: solid 1px $default-border-color;
      }

      &.text-field-error {
        border-color: $secondary-warning-color;
      }

      .search-within-input {
        font-family: $secondary-font-stack;
        width: 90%;
        border: 0;
        outline: 0;

        &::placeholder {
          font-style: italic;
        }

        &:-ms-input-placeholder {
          font-style: italic;
        }
      }

      .la-SWResearch {
        font-size: 1.8rem;
        font-weight: bold;
        margin: -1rem;
        background-color: $white;
        border: none;
        color: $button-color-click;
        cursor: pointer;
        outline: 0;
        width: 5rem;

        &:hover {
          color: $button-color-hover;
        }
      }
    }

    .type-filter-category {
      padding: 1.7rem 0 1.5rem 0;
      display: flex;
      justify-content: space-between;

      &.with-info-icon {
        justify-content: flex-start;
        align-items: center;

        .information-icon {
          margin-left: 0.8rem;
          margin-right: 1rem;
          margin-top: 2px;
        }
      }

      .type-filter-category-dropdown {
        background: none;
        border: none;
        color: $caret-enabled-color;
        cursor: pointer;
        font-family: $secondary-font-stack;
        font-size: 1.6rem;
        font-weight: bold;
        font-style: normal;
        outline: none;
        text-align: left;
        padding: 0;
        display: flex;
        align-items: flex-start;

        .la-TriangleUp,
        .la-TriangleDown {
          color: $caret-enabled-color;
          font-size: 1.7rem;
          font-weight: bold;
          margin-right: 1.35rem;
        }
      }
    }

    .reset-filter-category-dropdown {
      background: none;
      border: none;
      cursor: pointer;
      font-family: $secondary-font-stack;
      font-size: 1.6rem;
      font-weight: bold;
      font-style: normal;
      outline: none;
      text-align: right;
      color: $button-link-primary-color;
      margin-right: 1.3rem;
      padding: 0;
    }

    .type-filter-list {
      margin-left: 2rem;
    }

    .category-checkbox-list {
      font-family: $secondary-font-stack;
      font-size: 1.4rem;
      font-style: normal;
      font-weight: normal;
      margin: 0 0 .8rem 3.2rem;
      outline: none;

      .category-list-item {
        display: flex;

        flex-direction: row;
        justify-content: space-between;

        .custom-radio {
          font-size: 1.4rem;
          position: relative;
          margin: 0 0 1.4rem 2rem;
          width: 80%;
          span {
            white-space: normal;
            flex-direction: column;
          }

          input[type="radio"] {
            position: absolute;
            margin: .5rem;
            + .radio-label {
              &:before {
                position: absolute;
                left: -2rem;
              }
            }
          }
        }
        .category-count-number {
          width: 20%;
          text-align: right;
          font-size: 1.4rem;
          font-weight: normal;
          margin-right: .2rem;

          &.checked {
            font-weight: bold;
          }
        }
      }

      .legal-subcategory-checkbox {
        margin-left: 3rem;
        line-height: 1.4rem;

        .custom-radio {
          margin: 0 0 1rem 1rem;
          padding-top: .5rem;

          input[type="radio"] {
            position: absolute;
            margin: 0;

            + .radio-label {
              &:before {
                position: absolute;
                left: -2rem;
              }
            }
          }
        }

        .category-count-number {
          padding-top: .5rem;
        }
      }

      .category-list-item + .legal-subcategory-checkbox {
        margin-top: -1rem;
      }
    }

    .validationAlertMessage {
      font-size: $font14;
    }

    .validationAlertMessage,
    .search-within-radio-buttons,
    .search-within-input-container {
      margin-left: 3.2rem;
      margin-right: 1.6rem;
    }

    .search-within-input-container {
      margin-right: 3.9rem;
    }
  }

  .type-filter-category-container[disabled] {
    pointer-events: none;
    opacity: 0.5;
  }
}
