.entities-used-wrapper {
    display: flex;
    height: 1.5em;
    align-items: center;

    .information-icon {
        height: 100%;
        display: flex;
        align-items: center;
        color: #2296f3;

        &:hover {
            cursor: default;
        }
    }

    .entity-used-label {
        font-weight: bold;
        height: 100%;
        display: flex;
        align-items: center;
        margin-right: 10px;
    }
        
    .entity-used-value {
        color: $tertiary-color;
        font-weight: lighter;
        height: 100%;
        display: flex;
        align-items: center;
    }

    .divider {
        height: 100%; 
        width: 1px; 
        background-color: #2296f3;
        display: flex; 
        align-items: center;
        margin: 0 10px;
    }
}