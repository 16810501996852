.diligence-header__user-preferences {
  @extend .diligence-banner-message;
  transition: all 0.3s linear;
  display: none;
  padding-left: 2rem;
  max-height: 10rem;
  color: $black;


  &.slide {
    position: absolute;
    display: flex;
    top: $header-height;
    left: 0;
    z-index: $z-overlay;
  }

  &__icon {
    color: $info-banner-color;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__message {
    margin-right: 1rem;
    height: 6rem;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    padding: 0 1.5rem;
    border-left: 0.1rem solid $info-banner-color;
    line-height: 2.3rem;
    overflow-y: auto;

    &__header {
      font-size: $font14;
      padding: 0 8rem 1rem 0;
    }
  }

  &__button {
    background: transparent;
    border: none;
    cursor: pointer;
    color: $default-text-color;

    &:hover {
      color: $popup-dismiss-button-hover;
    }

    &:active {
      color: $popup-dismiss-button-hover;
    }
  }

  &.dark-mode {
    background: $popup-bg-info-dark;
    color: $popup-text-color-dark;
    border-bottom: 0.1rem solid $popup-border-color-info-dark;

    .diligence-header {
      &__user-preferences {
        &__message {
          border-left: 0.1rem solid $popup-border-color-info-dark;
        }
      }
    }
  }

}