.sidebar-section-header {
      width: 30%;


  .sidebar-section-header-element {
    margin: 7rem 0 3rem 3.2rem;

    .sidebar-section-header-element-title {
      margin-bottom: 1.6rem;
      font-size: 2.2rem;
      font-weight: bold;

      .entity-title:first-letter {
        text-transform: capitalize;
      }
    }

    .sidebar-element-name {
      font-size: 1.6rem;
      font-weight: bolder;

      &_mentioned{
        font-weight: normal;
      }
    }

    .sidebar-section-header-element-content {
      font-size: $font16;

      & > div {
        margin-bottom: 1.2rem;

        &:last-child {
          margin-bottom: 0;
        }
      }
    }

    .sidebar-element-value {
      font-size: 1.6rem;

      .sidebar-element-value-name {
        min-width: 2rem;
      }
    }
  }
}
