@font-face {
  font-family: "Lato";
  src: url("./fonts/lato/lato-regular-webfont.woff2") format("woff2"),
    url("./fonts/lato/lato-regular-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Lato-Light";
  src: url("./fonts/lato/lato-light-webfont.woff2") format("woff2"),
    url("./fonts/lato/lato-light-webfont.woff") format("woff");
  font-style: normal;
}

@font-face {
  font-family: "GVS_Icons";
  src: url("./fonts/gvs-icons/GVS_Icons.eot?tul3vc");
  src: url("./fonts/gvs-icons/GVS_Icons.eot?tul3vc#iefix")
      format("embedded-opentype"),
    url("./fonts/gvs-icons/GVS_Icons.ttf?tul3vc") format("truetype"),
    url("./fonts/gvs-icons/GVS_Icons.woff?tul3vc") format("woff"),
    url("./fonts/gvs-icons/GVS_Icons.svg?tul3vc#GVS_Icons") format("svg");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Theme_Switcher_Icons";
  src: url("./fonts/theme-switcher-icons/theme_switcher_icons.eot?tul3vc");
  src: url("./fonts/theme-switcher-icons/theme_switcher_icons.eot?tul3vc#iefix")
      format("embedded-opentype"),
    url("./fonts/theme-switcher-icons/theme_switcher_icons.ttf?tul3vc")
      format("truetype"),
    url("./fonts/theme-switcher-icons/theme_switcher_icons.woff?tul3vc")
      format("woff"),
    url("./fonts/theme-switcher-icons/theme_switcher_icons.svg?tul3vc#theme_switcher_icons")
      format("svg");
  font-weight: normal;
  font-style: normal;
}
