#ubo-document {
  .tabs {
    background: url("~/public/assets/dunsLogo.png") no-repeat right;
    background-size: 25%;

    .tab:nth-child(2) {
      margin: 0 1.5rem;
    }
  }
}
