.error-message-wrapper {

  background-color: $default-bg-color;
  width: 60%;
  margin: auto;
  position: relative;
  top: 10rem;

  .error-message-content {
    padding: 2rem;

    &__header {

    }
    &__body {
      display: flex;
      flex-wrap: wrap;
      flex-direction: column;
      font-size: 14px;
      margin-top: 1.5rem;

      &__button {
        margin-left: auto;
        margin-top: 5rem;
      }
    }
  }
}

.embedded-message {
  position: relative;
  font-size: 1.5rem;
  height: 8rem;
  padding: 1.5rem;
  margin: 1rem;
  display: flex;
  overflow: hidden;


  &__icon {
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__message {
    padding-left: 1.5rem;
    min-width: 70%;
    max-height: 5rem;
    overflow-y: auto;
  }

  &.error {
    @include alert-message($popup-bg-critical-light, $popup-system-error-color);

    .embedded-message__message {
      border-left: 0.1rem solid $popup-border-color-error-light;
    }
    &.dark-mode {
      background-color: $popup-bg-critical-dark;
      color: $popup-text-color-dark;
      border-bottom: 0.1rem solid $popup-border-color-error-dark;

      .embedded-message__message {
        border-left: 0.1rem solid $popup-border-color-error-dark;
      }
    }
  }

  a {
    color: $link-secondary-color;
    text-decoration: none;
  }

  a:hover {
    text-decoration: underline;
  }
}
