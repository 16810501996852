.uploaded-item-wrapper {
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  height: 4.8rem;
  background: #ededed 0% 0% no-repeat padding-box;
  border-radius: 0.3rem;
  margin: 1.5rem;
  padding: 0.5rem 1rem;

  .uploaded-item-info {
    display: flex;
    justify-content: center;

    &__excel-icon {
      background: url(~/public/assets/excel_icon.svg) no-repeat center;
      width: 2.8rem;
      height: 2.8rem;
    }

    &__content {
      display: flex;
      flex-direction: column;
      justify-content: center;
      margin-left: 1rem;

      .uploaded-item-info-title {
        font-size: $font14;
        font-weight: bold;
        line-height: 2.8rem;
        color: $default-text-color;
        margin-top: -0.7rem;
      }

      .uploaded-item-info-size {
        font-size: 1.2rem;
        line-height: 1.8rem;
        color: $default-text-color;
        margin-top: -0.7rem;
      }
    }
  }

  .uploaded-item-actions-area {
    display: flex;
    align-items: center;
    gap: 1.1rem;

    .uploaded-item-status__completed-icon {
      background: url(~/public/assets/CheckmarkIcon.svg) no-repeat center;
      width: 2.5rem;
      height: 2.5rem;
    }
  }
}