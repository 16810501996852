
.more-search-options{
    background-color: $default-bg-color;
    backdrop-filter: blur(4.8rem);
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    border-radius: .5rem;

    .boolean-terms-wrapper {
        width: 100%;
        padding: 2.5rem 2.7rem 3.3rem;
    }

    .boolean-terms-row {
        display: flex;
        justify-content: space-between;
        width: 100%;

        &:first-child {
            margin-bottom: 1.5rem;
        }
    }

    .boolean-terms-item {
        width: 100%;

        label {
            font-size: 1.6rem;
            line-height: 1.56;
        }

        &:first-child {
            margin-right: 0.8rem;
        }
        &:last-child {
            margin-left: 0.8rem;
        }

        .include-exact-phrase {
            font-size: 1.6rem;
            columns: $default-text-color;
        }

        .boolean-terms-input {
            display: flex;
            flex-direction: row;
            margin-top: 1.2rem;

            .text-field-standard-lg {
                width: 100%;
                padding: 1.5rem 1.8rem;
                font-size: 1.4rem;
                line-height: 1.2;
                height: auto;
                max-height: 4.8rem;
                border: .1rem solid $default-border-color-darker;
                border-radius: .4rem;

                &::-webkit-input-placeholder { /* Edge */
                    color: $default-text-color;
                }

                &:-ms-input-placeholder { /* Internet Explorer 10-11 */
                    color: $default-text-color;
                }

                &::placeholder {
                    color: $default-text-color;
                }

                &.text-field-error:focus {
                    border-color: $default-border-color-darker !important;
                }

            }

            .button-secondary-lg {
                margin-left: 1.2rem;
                height: auto;
                min-width: auto;
                white-space: nowrap;
                border: .1rem solid $button-color-click;
                border-radius: .4rem;
                font-size: 1.4rem;
                font-weight: bold;
                color: $button-color-click;
                padding: 1.5rem 2rem;
                background-color: $white;

                &:hover:not(:disabled):not(.disabled) {
                    box-shadow: 0 0 0 0.3rem $button-hover-border;
                    cursor: pointer;
                }

                &:active {
                    background-color: $white;
                    border: .1rem solid $button-color-click;
                    color: $button-color-click;
                }

                &:disabled {
                    background-color: $default-bg-color;
                    border: .1rem solid $button-color-disabled;
                    color: $button-color-disabled;
                    cursor: not-allowed;
                }
            }
        }
    }

}

// Dark theme
.dark-theme {
    .more-search-options .boolean-terms-item .boolean-terms-input .button-secondary-lg {
        &:hover:not(:disabled):not(.disabled) {
            box-shadow: 0 0 0 0.2rem $dark-theme-color;
        }
    }
}