.toggle-for-fuzzy-names {
  .toggle-for-fuzzy-names-wrapper {
    display: flex;

    .custom-toggle {
      margin-left: 0.5rem;
      margin-right: 1rem;
    }
    &__container {
      margin: 2rem 0 1rem;
    }
  }

  .pill-list {
    margin: 2rem 0 1rem;
  }
}

