.snackbar-row {
    width: 100%;
    height: 3.5rem;
    display: flex;
    justify-content: flex-start;
    opacity: 0;
    transition: opacity .8s;
  
    .snackbar-icon-container {
      .snackbar-spinner-content {
        position: relative;
        width: 100%;
        height: 100%;
      }
      .ui.loader {
        left: 0.3rem;
        top: 0.6rem;
      }
      .la-DeliveryComplete {
          font-size: 2.3rem;
          color: $button-green-hover;
      } 
   }
   .snackbar-close-icon-container {
      .la-CloseRemove {
          font-size: 1rem;
          color: white;
      }
   }
}
.snackbar-show {
  opacity: 1;
} 