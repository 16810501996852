.search-box-container {
    width: calc(100% - 44rem);
    z-index: 80;
    position: relative;

    .la-CloseRemove {
        font-size: 2rem;
        outline: none;
        border: none;
        background-color: white;
        height: 5.8rem;
        position: absolute;
        right: 0;
        top: 0;
        padding-right: 0;
        padding-left: 1rem;

    }

    .search-input-placeholder {
        font-style: italic;
        color: $placeholder-color;
    }

    .search-input-trimmed-container {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        .search-input-trimmed {
            font-size: 1.8rem;
            font-family: $secondary-font-stack;
            padding: 2.2rem 0 2.2rem 2.7rem;
            background: $default-bg-color;
            border: none;
            width: calc(100% - 4rem);
            border-bottom-left-radius: .5rem;
            border-top-left-radius: .5rem;

            div {
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
            }
        }
    }

    .search-input-container {
        display: flex;
        flex-direction: row;
        border: none;
        border-radius: .5rem;
        outline: none;

        .search-input-full {
            width: 100%;
            padding: 2.2rem 4rem 2.2rem 2.7rem;
            background-color: white;
            border: none;
            font-family: $secondary-font-stack;
            font-size: 1.8rem;
            line-height: 1.2;
            overflow-y: hidden;
            min-height: 6.5rem;
            max-height: 30rem;
            max-width: 100%;
            resize: none;
            border-bottom-left-radius: .5rem;
            border-bottom-right-radius: .5rem;
            border-top-left-radius: .5rem;

            &:focus {
                outline: 0;
            }
        }
    }
}

// Dark theme
.dark-theme {
    .search-box-container .search-input-container {
        box-shadow: none;

        .search-input-full {
            box-shadow: none;
        }
    }
}