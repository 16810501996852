.report-list-articles-list-container {
  padding: 0 1rem;

  .category-overlay {
    background-color: rgba(255, 255, 255, 0.5);
    z-index: $z-overlay;
    height: 100%;
    width: 99%;
    padding-right: 4rem;
    position: absolute;
    max-height: 34rem;
  }

  .article-list-header {
    padding: 1.5rem 3rem 1.5rem;
    display: flex;
    font-size: $font16;
    font-weight: 600;
    width: calc(100% - 0.8rem);

    &__title {
      width: 45%;
    }

    &__date {
      padding-left: 0.5rem;
      width: 55%;
    }
  }

  .reports-list-articles-table {
    max-height: 28rem;
    overflow-y: auto;

    &.with-menu-open {
      min-height: 15rem;
    }

    .reports-list-article-row-container {
      // if list has draggable docs
      &[draggable="true"] {
        .reports-list-article-row
          .document-title-source-harvest-date
          .document-source-harvest-date {
          padding-left: 7.5rem;
        }

        .note-on-article .existing-note-on-article {
          padding-left: 10.5rem;
        }
      }

      .reports-list-article-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        font-size: 1.5rem;
        line-height: 2.8rem;
        background-color: $white;
        padding: 0 3rem;

        @keyframes droppedAnimation {
          0% {
            background-color: inherit;
          }
          50% {
            background-color: $dropped-drag-and-drop-color;
          }
          100% {
            background-color: inherit;
          }
        }

        &.dropped {
          animation: droppedAnimation 2s;
          animation-iteration-count: 1;
        }

        &.drop-top {
          border-top: 2px solid $header-menu-dropdown-hover;
        }
        &.drop-bottom {
          border-bottom: 2px solid $header-menu-dropdown-hover;
        }

        &.dragging {
          color: $disabled-drag-and-drop-color;
          cursor: move;

          .reports-list-article-row-actions {
            .report-list-article-action-button {
              color: $disabled-drag-and-drop-color;
            }
            .add-note:disabled {
              color: $white !important;
              background-color: $white !important;
            }
          }

          .note-on-article {
            .existing-note-on-article-buttons {
              background-color: $disabled-drag-and-drop-color;
            }
          }
        }

        .document-title-source-harvest-date {
          width: 45%;

          .draggable-icon {
            margin: 0.6rem 1.5rem 0 1.5rem;
          }

          .la-DeliveryOptions {
            padding-top: 0.4rem;
          }

          .document-title-index {
            display: flex;
            flex-flow: row;

            .index-of-report-article {
              width: 3rem;
              padding-right: 2rem;
            }

            .reports-list-no-article-title {
              max-height: 5.6rem;
              white-space: normal;
              position: relative;
              text-align: justify;
              padding-right: 1.5rem;
            }

            .reports-list-article-title {
              overflow: hidden;
              max-height: 5.6rem;
              white-space: normal;
              position: relative;
              text-align: justify;
              padding-right: 1.5rem;

              &:not(.disabled-click) {
                color: $button-link-primary-color;
                cursor: pointer;
                
                &:hover {
                  color: $primary-color-hover;
                  text-decoration: underline;
                }
  
                &:active {
                  opacity: 0.5;
                  text-decoration: underline;
                }
              }
              
            }

            .reports-list-article-title-public-records {
              @extend .reports-list-article-title;
              color: $default-text-color;
              cursor: move;

              &:hover {
                color: $default-text-color;
                text-decoration: none;
              }

              &:active {
                opacity: 0.5;
                text-decoration: none;
              }
            }
          }
          .document-source-harvest-date {
            display: flex;
            flex-flow: row;
            padding-left: 3rem;

            .vertical-divider {
              height: 1.5rem;
              margin: 0.5rem 1rem 0.5rem;
            }
          }
        }

        .reports-list-article-date {
          width: 35%;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          max-height: 5.6rem;
          padding-left: 0.3rem;
        }

        .reports-list-article-row-actions {
          display: flex;
          flex-direction: row-reverse;
          width: 20%;

          .vertical-divider {
            height: 1rem;
            margin: 1rem 0 0;
          }

          .report-list-article-action-button {
            @include link-button($button-link-primary-color);

            &:hover {
              color: $button-link-primary-color;
              text-decoration: underline;
            }
            &:active {
              opacity: 0.5;
              text-decoration: underline;
            }

            &:disabled {
              color: $disabled-text-color;
              cursor: not-allowed;
              text-decoration: none;
            }
          }

          .add-note {
            &:disabled {
              background-color: $white;
              color: $white;
              cursor: initial;
            }
          }
        }
      }

      &.selected-row,
      &.with-multiple-items:not(.drag-and-drop-disabled):hover {
        cursor: move;

        .reports-list-article-row {
          background-color: $selected-drag-and-drop-color;

          .reports-list-article-row-actions {
            .add-note {
              &:disabled {
                color: transparent;
                background-color: transparent;
              }
            }
          }
        }

        .note-on-article {
          background-color: $checkbox-hover-bg-color;
        }
      }

      &.drag-and-drop-disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover {
          cursor: initial;
        }
      }

      .reports-list-article-row.dropped {
        .reports-list-article-row-actions {
          .add-note:disabled {
            color: transparent;
            background-color: transparent;
          }
        }
      }
    }

    .reports-list-article-row-container.dragging {
      border: 1px dashed $default-border-color;
      background-color: $white;

      .reports-list-article-row.dragging {
        background-color: $white !important;
        cursor: move;

        .document-title-source-harvest-date {
          .document-title-index {
            .reports-list-article-title {
              color: $disabled-drag-and-drop-color;
            }
          }
        }
      }
    }
  }
}
