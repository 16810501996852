.popup-modal {
  &__reorder-items {
    display: flex;
    flex-direction: column;

    &.with-menu-open {
      min-height: 15rem;
    }

    .modal-reorderable-item-container {
      .modal-reorderable-item-row {
        display: flex;
        flex-flow: row;
        justify-content: flex-start;
        font-size: 1.5rem;
        line-height: 2.8rem;
        background-color: $white;

        @keyframes droppedAnimation {
          0% {
            background-color: inherit;
          }
          50% {
            background-color: $dropped-drag-and-drop-color;
          }
          100% {
            background-color: inherit;
          }
        }

        &.dropped {
          animation: droppedAnimation 2s;
          animation-iteration-count: 1;
        }

        &.drop-top {
          border-top: 2px solid $header-menu-dropdown-hover;
        }
        &.drop-bottom {
          border-bottom: 2px solid $header-menu-dropdown-hover;
        }

        &.dragging {
          color: $disabled-drag-and-drop-color;
          background-color: inherit !important;
          border: 1px dashed $default-border-color;
          cursor: move;
        }

        .reorderable-item-title {
          width: 100%;
          padding-left: 0.5rem;

          .draggable-icon {
            margin: 0 1.5rem;
          }

          .la-DeliveryOptions {
            padding-top: 0.4rem;

            .items-order-menu {
              margin-left: -4.5px;

              &::after {
                left: 0.32rem;
              }
            }
          }

          .reorderable-item-title-index {
            width: 100%;
            display: flex;
            flex-flow: row;
            padding: 0.2rem 0;
            align-items: center;

            .item-title {
              max-width: 80%;
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              text-align: justify;
              cursor: pointer;
              display: inline-block;
            }
          }
        }
      }

      &.selected-row,
      &.with-multiple-items:not(.drag-and-drop-disabled):hover {
        cursor: move;

        .modal-reorderable-item-row {
          background-color: $selected-drag-and-drop-color;
        }
      }

      &.drag-and-drop-disabled {
        pointer-events: none;
        cursor: not-allowed;

        &:hover {
          cursor: initial;
        }
      }
    }
  }
}
