.user-preferences-similar-names {
  .user-preferences-similar-names-topic {
    padding-top: 2rem;
  }

  .include-similar-name-match {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0 2rem;

    .custom-toggle {
      margin-right: 2rem;
    }
  }

  .include-similar-match-explanation {
    margin-bottom: 1rem;
  }

  .include-similar-match-explanation2 {
    margin-bottom: 2rem;
  }

  .custom-radio {
    margin: 1rem 0;
  }
}
