/**
 * START PAGE SCSS STYLES
 *
 * @version 0.1
 */

.start-page-wrapper {
  display: block;

  &:before {
    background-color: hsla(240, 4%, 95%, 1); 
    background-image:
      radial-gradient(at 5% 6%, hsla(184, 100%, 26%, 0.21) 0px, transparent 50%),
      radial-gradient(at 60% 0%, hsla(198, 100%, 75%, 0.15) 0px, transparent 50%),
      radial-gradient(at 95% 15%, hsla(198, 100%, 75%, 0.35) 0px, transparent 50%); 
    background-size: cover;
    background-repeat: no-repeat;
    content: "";
    height: 100%;
    width: 100%;
    left: 0;
    top: 0;
    position: fixed;
    will-change: transform;
    z-index: -1;
  }

  .tabs-wrapper {
    .tabs {
      display: none;
    }
  }
}

.start-page-container {
  width: auto;
  max-width: 67%;
  height: 100%;
  margin: 0 auto;
  margin-top: 4.5rem;
  transition: 0.3s;
  padding-bottom: 5rem;

  .search-term-error {
    bottom: -3.8rem;
    color: $secondary-warning-color;
    font-size: $font14;
    left: 0;
    position: absolute;

    & ~ div .toggle-for-fuzzy-names {
      margin-top: 6rem;
    }
  }

  .boolean-term-error {
    color: $secondary-warning-color;
    padding-top: 1.6rem;
  }

  .more-data-link {
    display: flex;
    margin: 3rem auto 1.9rem;
    justify-content: center;

    .la-TriangleDown {
      display: none;
    }

    .la-TriangleUp {
      display: none;
    }

    .text-as-button {
      display: flex;
      background: none;
      border: none;
      cursor: pointer;
      font-family: $secondary-font-stack;
      outline: none;
      padding: 0;
    }

    &-label {
      display: flex;
      align-items: center;
      font-size: 1.4rem;
      line-height: 1.2;
      font-weight: bold;
      padding: 0.8rem 2.4rem 0.8rem 2.5rem;
      border-radius: 10rem;
      background-color: transparent;
      color: $button-color-click;
      border: 0.2rem solid $border-homepage-button;

      &::after {
        font-family: "GVS_Icons", sans-serif;
        display: inline-block;
        content: "\e60a";
        font-size: 1.3rem;
        font-weight: bold;
        color: $button-color-click;
        margin-left: 0.6rem;
      }
    }
  }
}

.start-page {
  &-title {
    font-size: 4.8rem;
    font-family: $secondary-font-stack;
    font-weight: bold;
    color: $default-text-color;
    line-height: 1.2;
    padding: 13.6rem 0 0;
  }
}

// Dark theme
.dark-theme {
  .start-page-wrapper {
    &:before {
      background-color: hsl(202, 100%, 9%);
      background-image:
        radial-gradient(at 76% 44%, hsla(191, 100%, 9%, 0.91) 0px, transparent 50%),
        radial-gradient(at 3% 5%, hsla(227, 55%, 50%, 0.47) 0px, transparent 50%),  
        radial-gradient(at 96% 4%, hsla(210, 100%, 43%, 0.39) 0px, transparent 50%),
        radial-gradient(at 58% 3%, hsla(174, 100%, 43%, 0.16) 0px, transparent 50%),
        radial-gradient(at 3% 97%, hsla(184, 100%, 26%, 0.58) 0px, transparent 50%); 
    }

    .suggested-names {
      color: $dark-theme-color;
    }

    .search-bar {
      border: 0.1rem solid $dark-theme-background;
    }

    .my-search-preferences-button {
      background-color: transparent;
      color: $gray-bg-color;
      border: 0.2rem solid $border-homepage-button;
    }
  
    .more-data-link-label {
      background-color: transparent;
      color: $gray-bg-color;
      border: 0.2rem solid $border-homepage-button;
    }

    .more-data-link-label:after {
      color: $dark-theme-color;
    }

    .search-type {
      .label-for-company,
      .label-for-person {
        color: $dark-theme-color;
      }
    }

    .boolean-terms-wrapper {
      background-color: $dark-theme-content-background;

      .boolean-terms-item label {
        color: $dark-theme-color;
      }
    }
  }
}
