.notifications-container-layout {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-end;
    position: fixed;
    left: 2rem;
    bottom: 7rem;
    margin-bottom: 0.5rem;
    z-index: 80;
    height: 10rem;
    min-width: 38rem;
    background-color: $dark-navy;
    border-radius: 0.6rem;

    &.single-notification {
        height: 6.5rem;
    }
}
.notifications-header {
    width: 100%;
    height: 0;
    position: relative;
    z-index: 100;
}
.notifications-body {
    border-radius: 0.6rem;
    width: 100%;
    height: 100%;
    overflow-x: hidden;
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
}
.notifications-body::-webkit-scrollbar {
    width: 0.8rem;
}
.notifications-body::-webkit-scrollbar-thumb {
    background-color: #41586d;
}