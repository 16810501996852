.custom-cost-code-inputBox {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 1rem;

  .user-specified-cost-input-container {
    display: flex;
    flex-direction: row;

    .text-field-standard-lg {
      min-width: 20rem;
    }

    .button-primary-sm {
      height: 4.1rem;
      min-width: auto;
      margin-left: 2.4rem;
      white-space: nowrap;
    }
  }

  .user-specified-cost-code-warnings {
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    .max-number-of-characters {
      justify-content: flex-end;
      color: $placeholder-color;
      font-style: italic;
      font-size: 1.4rem;
    }

    .validationMessage {
      justify-content: flex-start;
      color: $secondary-warning-color;
      font-size: 1.4rem;
    }
  }
}