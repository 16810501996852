.diligence-header__login-history {
  @extend .diligence-banner-message;
  transition: all 0.3s linear;
  display: none;
  position: relative;
  width: 100%;

  &.slide {
    position: absolute;
    display: flex;
    top: $header-height;
    left: 0;
    z-index: $z-overlay;
  }

  &__message-container {
    display: flex;

    a {
      position: absolute;
      text-decoration: none;
      right: 10rem;
      color: $button-link-primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  &__icon {
    color: $info-banner-color;
    padding-right: 1.5rem;
    display: flex;
    align-items: center;
  }

  &__message {
    border-left: 0.1rem solid $info-banner-color;
    padding-left: 1.5rem;
  }

  &__hyperlink {
    color: $button-link-primary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    position: absolute;
    background: transparent;
    border: none;
    right: 2rem;
    top: 1.75rem;
    cursor: pointer;

    &:hover {

      color: $popup-dismiss-button-hover;
    }

    &:active {
      color: $popup-dismiss-button-hover;
    }
  }

  &.dark-mode {
    background: $popup-bg-info-dark;
    color: $popup-text-color-dark;
    border-bottom: 0.1rem solid $popup-border-color-info-dark;

    .diligence-header {
      &__login-history {
        &__message {
          border-left: 0.1rem solid $popup-border-color-info-dark;
        }
      }
    }
  }
}