.bov-document-view {
  .tabs {
    background: url("~/public/assets/brazilian_ownership/PoweredbyAkire.png")
      no-repeat right;
    background-color: $bov-tabs-bg-color;
    background-size: 10rem;
    border-bottom: 0.1px solid $bov-tabs-bottom-border;
    margin: 0;
    padding: 0;

    .tab {
      font-size: 1.5rem;
      font-weight: 700;
      padding: 1rem 0;
    }

    .tab:nth-child(2) {
      margin: 0 1.5rem;
    }
  }
}
