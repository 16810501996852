.search-name-party-entity {
  .admin-lock-wrapper {
    height: 4.5rem;
    display: flex;
    align-items: center;

    .user-section-lock {
      position: absolute;
      left: 28rem;
    }

    .user-preference-checkbox {

      input[type="checkbox"] {
        position: absolute;
        opacity: 0;

        + .preference-checkbox-label {
          &:before {
            width: 1.4rem;
            height: 1.4rem;
            top: 0.5rem;
          }
        }
      }
    }

    &.is-locked {
      &:not(.is-editable) {
        background-color: #e9e9ea;
        margin-left: -1.6rem;
        margin-right: -1rem;
        padding-left: 1.6rem;
        padding-right: 1rem;
        cursor: not-allowed;

        .user-preference-checkbox {
          input,
          label {
            pointer-events: none;
          }
        }

        .user-section-lock {
          left: 49rem;
        }
      }
    }

    &:not(.is-locked):not(.is-editable) {
      span.user-section-lock {
        display: none;
      }
    }
  }
}

.addHoc-scrollable-area {
  .default-sources-container:last-of-type {
    flex-wrap: wrap;

    .search-name-party-entity {
      width: 50%;
      margin-left: auto;
      margin-right: 5%;

      .admin-lock-wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;
        width: 100%;
        margin-right: 0;
        margin-left: 0;

        &.is-locked {
          flex-direction: row-reverse;
        }

        .user-section-lock {
          position: static;

          .admin-lock {
            width: 85%;
          }
        }

        .user-preferences-checkbox {
          display: inline-block;
        }
      }
    }
  }
}