.card {
  display: flex;
  flex: 1;
  flex-direction: column;
  font-family: $secondary-font-stack;
  min-height: 20rem;
  background-color: $white;
  border-radius: 0.8rem;
  box-shadow: 0 0.3rem 1rem 0 rgba(0, 0, 0, 0.04);
  width: 100%;

  &.new {
    box-shadow: $default-border-color 0 0 12px;

    .card-title {
      display: flex;
      flex-direction: row;
    }
  }

  &-wrapper {
    display: flex;

    &.hasRisk {
      grid-column: auto / span 4;

      .card-title-wrap {
        justify-content: space-between;
      }
    }

    &:not(.hasRisk) {
      .send-feedback-link {
        line-height: 1.6rem;
      }
    }
  }

  &-disclaimer {
    font-size: 1.2rem;
    padding: 0 1.6rem 0 2.2rem;
    margin-left: auto;
    font-weight: 600;
    color: $negativity-default-color;

    a {
      margin-left: 0.2rem;
      color: $button-link-primary-color;

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .segment {
    height: 6rem;
    margin: 1rem;
  }

  .card-title-wrap {
    align-items: flex-start;
    display: flex;
    justify-content: space-between;
    margin: 2rem 2rem 0;

    .card-title {
      height: 5rem;
      white-space: normal;
      width: 90%;

      &__label {
        color: $default-text-color;
        display: inline-block;
        font-size: 2rem;
        font-weight: bold;
        line-height: 1.2;
      }

      .send-feedback-link {
        display: none;
      }
    }

    .icon-box {
      padding: 0 1rem;
      display: inline-block;
      border: none;
      cursor: pointer;
      outline: 0;
      background-color: $white;
      height: auto;

      .la-Download {
        font-size: 2.4rem;
      }

      &:active {
        opacity: 0.5;
      }

      &:disabled {
        opacity: 0.5;
        cursor: initial;
      }
    }
  }

  .display-count {
    color: $caret-enabled-color;
    font-size: 4.2rem;
    font-weight: 400;
    height: 7rem;
    line-height: 7rem;
    text-align: left;
    padding-left: 1.6rem;
    align-items: center;

    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin: 0;

    &-value {
      font-size: 4.2rem;
      font-weight: 400;
      line-height: 7rem;
      padding-left: 0;
      display: flex;
      align-items: center;
    }

    &-negativity-risk {
      font-size: 1.6rem;
      font-weight: 600;
      color: $negativity-default-color;
      text-transform: capitalize;
      padding-top: 0.5rem;
    }

    .display-count-title {
      font-size: 1.6rem;
      font-weight: 600;
      color: $subfield-text-color;
      text-transform: capitalize;
      padding-left: 1.6rem;
      padding-top: 0.5rem;
    }

    .display-count-level {
      font-size: 1.6rem;
      font-weight: 600;
      color: $negativity-default-color;
      text-transform: capitalize;
      padding-top: 0.5rem;

      &-pill {
        color: $negativity-default-color;
        font-weight: 600;
        padding-top: 0.5rem;

        &-low {
          color: $negativity-level-pill-low;
        }

        &-medium {
          color: $negativity-level-pill-medium;
        }

        &-high {
          color: $negativity-level-pill-high;
        }
      }
    }
  }


  .link-button {
    background: none;
    border: none;
    color: $button-link-primary-color;
    cursor: pointer;
    font-family: $fourth-font-stack;
    font-size: calc(1.8vw + 1.8vh);
    font-weight: 600;
    outline: none;

    &:hover,
    &:active {
      color: $dark-blue;
    }

    &:disabled {
      cursor: initial;
    }
  }

  .display-error {
    text-align: center;
    display: inline-block;
    margin-left: 2rem;
    color: $search-error-icon-color;

    .la-Error {
      display: none;
    }
  }

  &.isNegativeNewsPod {
    display: flex;
    justify-content: space-between;
    padding: 0 1.6rem;
    text-align: center;

    .display-count-negativity-risk {
      padding: 1.6rem 0 0 0;

      &-title {
        font-size: 1.6rem;
        font-weight: bold;
        margin-bottom: 0.2rem;
      }

      &-level {
        font-size: 1.3rem;
        color: $negativity-default-color;
        text-transform: capitalize;

        &>div {
          display: inline-block;
        }

        &.hasRisk {
          font-size: 1.6rem;
          font-weight: bold;
        }

        &.low {
          color: $negativity-level-pill-low;
        }

        &.medium {
          color: $negativity-level-pill-medium;
        }

        &.high {
          color: $negativity-level-pill-high;
        }
      }
    }
  }

  &.isSanctionsPod {
    height: auto !important;
    line-height: normal;
  }
}

.display-date-range {
  color: $subfield-text-color;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 1.3rem;
  padding: 0.6rem 0 1.6rem;
  margin-top: auto;

  .horizontal-divider {
    display: none;
  }

  a {
    color: $button-link-primary-color;
  }
}

.podContent {
  &.withNegativeNewsRisk {
    display: flex;
    justify-content: space-between;

    .count-container {
      display: flex;
      flex-direction: column;
      flex: 0 0 25%;
      max-width: 25%;

      .display-count {
        flex-direction: row;
        height: auto;
        align-items: baseline;


        &-negativity-risk {
          padding-top: 1.5rem;
        }
      }

      .card-disclaimer {
        margin-left: 0;
        margin-top: 1rem;
      }
    }

    .loader-overlay {
      position: relative;
    }

    .donut-chart {
      display: flex;
      justify-content: center;
      align-items: center;
      flex: 0 0 35%;
      max-width: 35%;
      padding-top: 0.5rem;
    }

    .terms-container {
      flex: 0 0 40%;
      max-width: 40%;
    }

    .negative-terms-container {
      padding-top: 5rem;
      padding-right: 2.5rem;

      @media screen and (min-width: 1366px) {
        padding-top: 1.5rem;
      }
    }
  }

  .chart-container {
    display: flex;

    .chart-right-part {
      display: flex;
      flex-direction: column;
      padding-left: 5rem;
      justify-content: center;

      .text-info {
        font-size: $font12;
        line-height: 1;
      }
    }
  }
}


.redirect-info a {
  cursor: pointer;
}

.download-box-container {
  display: flex;
}