/**
 * MODAL SCSS STYLES
 *
 * @version 0.1
 */

.app-modal {

    @include full-screen(fixed);

    background-color: $popup-overlay-background-color;
    font-size: 1.4rem;
    z-index: $z-app-modal;
    height: auto;

    // Homepage -> Disclaimer popup(Risk Scores preference)
    // make this popup appear on top of everything from the page
    &__for-applyRiskScoresPreference {
        z-index: 20000;
    }

    &__wrapper {
        position: absolute;
        text-align: center;
        top: 50%;
        transform: translateY(-50%);
        width: 100%;

        .xsmall {
            width: 30%;
        }

        .small {
            width: 50%;
        }

        .medium {
            width: 65%;
        }

        .large {
            width: 80%
        }

        &.adHocSearch {
            .app-modal__content-box {
                width: 60%;
            }
        }

        &.applyAdminChanges,
        &.discardAdminChanges,
        &.deleteNoteFromDocument,
        &.deleteDocumentFromReport,
        &.addToReport,
        &.deleteCustomNewsQuery {
            .app-modal__content-box {
                width: 40%;
            }
        }

        &.confirmation-modal {
            .small {
                width: 40%;
            }
        }

        &.uploadEntities {
            .small {
                width: 45%;
            }
        }

        // User/Admin preferences -> Person Check -> Custom News Search popup
        // User/Admin preferences -> Person Check -> Custom News Search / Created by Admin
        // User/Admin preferences -> Person Check -> Edit (language) Negative News Terms popup
        &.addCustomNewsQuery,
        &.editCustomNewsQuery,
        &.viewCustomQuery,
        &.userPreferencesEditNegativeSearchTerms {
            .app-modal__content-box {
                width: 50%;
            }
        }

        // Report Builder -> Print Reports/Email Reports/ Download Reports, Download Your Report, Print Reports Combined, Print Reports separate popups
        // Result List page -> Print/Email/Download popups
        // Document view page -> Print/Email/Download popups
        &.printReports,
        &.emailReports,
        &.downloadReports,
        &.downloadReport,
        &.printReportsSeparate,
        &.printReportsCombined,
        &.emailReportsSeparate,
        &.emailReportsCombined,
        &.downloadReportsSeparate,
        &.downloadReportsCombined,
        &.printResultList,
        &.emailResultList,
        &.downloadResultList,
        &.printElasticDocument,
        &.emailElasticDocument,
        &.downloadElasticDocument,
        &.downloadBatchScreening,
        &.editSearchMultipleEntities {
            .app-modal__content-box {
                width: 40%;
            }
        }

        &.createAlerts {
            .app-modal__content-box {
                width: 60%;
            }
        }

        &.saveSearch {
            .app-modal__content-box {
                width: 38%;
            }
        }

        // Homepage -> Disclaimer popup(Risk Scores preference)
        &.applyRiskScoresPreference {
            .app-modal__content-box {
                width: 69.7rem;

                .app-modal__close-button {
                    display: none !important;
                }
            }

            .popup-modal {
                &-body {
                    padding: 0;
                }

                &__risk-scores-body {
                    .risk-scores-top-message,
                    .risk-scores-bottom-message {
                        font-size: $font16;
                        padding: 0 $popup-overall-padding 0;

                        a {
                            color: $button-link-primary-color;
                            outline: none;
                            &:hover {
                                text-decoration: underline;
                            }
                        }
                    }
                    .risk-scores-bottom-message{
                        padding-top: 1rem;
                    }

                    .risk-scores-image {
                        background-color: $gray-bg-color;
                        .negative-score-image {
                            max-width: 100%;
                            height: 32.4rem;
                        }

                    }
                }
            }
        }

        // History -> Clear History popup
        &.deleteAllHistory {
            .app-modal__content-box {
                width: 37%;
            }
        }
    }

    &__content-box {
        background-color: $default-bg-color;
        display: inline-block;
        position: relative;
        text-align: left;
    }

    &__content {

        .modal-header {
            color: $default-text-color;
            height: 6rem;
            padding: 2rem;
            position: relative;
        }

        .modal-body {

            .divider {
                border-top: 1px dotted $default-border-color;
                width: inherit;
                margin: 1rem 2rem 1rem;
            }

            .field-name {
                margin-left: 2rem;
            }

            .radio-list {

                padding: 1rem 2rem 1rem;

                &__item {

                    .item-title {
                        margin-left: 1rem;
                    }
                }
            }

            .modal-check {
                margin: 1rem 2rem 1rem;

                .item-title {
                    margin-left: 1rem;
                }
            }

            .modal-file-name {
                color: $default-text-color;
                font-size: 1.4rem;
                margin-bottom: 2rem;
                padding: 1rem 2rem;
                position: relative;
                width: 100%;

                .text-field-standard-lg {
                    display: inline-block;
                    width: inherit;
                }
            }

            .modal-warning {
                background-color: $popup-bg-color;
                font-size: 1.4rem;
                margin: 0 2rem;
                padding: 1rem;

                &:before {
                    @include popup-message-vertical-strip($popup-user-error-color)
                }
            }

            .uploading-progress-messages {
                padding-bottom: 4rem;
                position: relative;
                text-align: center;
                top: -1.5rem;
            }
        }
    }

    &__close-button {
        background: none;
        border: none;
        color: $popup-close-icon-color;
        cursor: pointer;
        font-size: $popup-close-icon-size;
        position: absolute;
        text-align: center;
        margin: $popup-overall-padding $popup-overall-padding $popup-header-bottom-padding;
        right: 0;
        z-index: $z-app-modal-close-button;

        &:hover {
            opacity: .5;
        }
    }

    &__buttons {
        display: flex;
        justify-content: center;
        height: 9.5rem;

        &__right-aligned {
            justify-content: flex-end;
        }

        .button-secondary-lg {
            font-weight: bold;
            min-width: 123px;
            height: 45px;

            &__right-aligned {
                @extend .button-secondary-lg;
                margin: 2rem 2rem 0 1rem;
            }
        }

        .button-primary-lg {
            font-weight: bold;
            margin: 2rem 1rem 0 1rem;
            min-width: 123px;
            height: 45px;

            &__right-aligned {
                @extend .button-primary-lg;
                margin-right: 0.7rem;
            }
        }

        .button-secondary-sm,
        .button-secondary-lg {
            margin-top: 2rem;
            margin-right: $popup-overall-padding;
        }

        .button-primary-sm,
        .button-primary-lg {
            margin-top: 2rem;
            margin-right: 1.5rem;
        }

        // buttons that don't apply the general height rules
        .button-primary-sm,
        .button-secondary-sm {
            height: $button-small-height;
        }

        .disabled {
            background-color: $button-color-disabled;
            border: 0.1rem solid $button-color-disabled;
            color: white;
            cursor: not-allowed;
        }

        [data-track="applyAdminChanges-modal-save-changes-button"] {
            margin-right: 1.5rem;
        }
    }
}