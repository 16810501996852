.breadcrumbs-container {
  background-color: $white;
  height: 5.3rem;
  padding: 1.5rem 3rem 1.5rem;

  .breadcrumb {
    font-size: 1.4rem;
    color: $button-link-primary-color;
    &:hover {
      text-decoration: underline;
    }
  }

  .la-ChevronRight {
    margin: 1.2rem;
    font-size: 1.1rem;
  }

}
.hide {
  display: none
}


