.selected-articles-count {
  cursor: pointer;
  margin-right: 1rem;

  .count {
    color: $button-link-primary-color;
    font-size: 1.5rem;
    line-height: 3rem;
    font-weight: bold;
    display: inline-block;
  }

  .la-TriangleDown {
    color: $button-link-primary-color;
    font-size: 1.2rem;
    font-weight: bolder;
    padding-left: .8rem;
    display: inline-block;

    &.fromLeft {
      padding-left: 0;
      padding-right: .8rem;
    }

    &.not-visible {
      display: none;
    }
  }
}


.selected-articles-dropdown-container {
  position: relative;
  display: inline-block;

  &.empty {
    .selected-articles-count {
      .count {
        color: $button-secondary-color;
      }
      .la-TriangleDown {
        color: $button-secondary-color;
      }
    }
  }
}

.selected-articles-dropdown {
  display: none;
  position: absolute;
  background-color: $white;
  border: 1px solid #bcbec0;
  border-radius: 4px;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  min-width: 35rem;
  max-width: 50rem;
  max-height: 28rem;
  overflow-y: auto;
  z-index: 4;

  .selected-articles-dropdown-header {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    padding: 1.2rem 1.2rem;

    .selected-entities{
      font-size: 1.4rem;
      font-weight: bold;
      color: $default-text-color;
    }

    .button-secondary-sm {
      min-width: 9.7rem;
      height: 2.5rem;
      white-space: nowrap;
      margin-left: 1rem;
      color: $button-link-primary-color;
      border:none;

      &:hover {
        text-decoration: underline;
        box-shadow: none;
      }

      &:disabled {
        color: $default-border-color;

        &:hover {
          cursor: not-allowed;
          text-decoration: none;
        }
      }

      &:active{
        background-color: $white;
      }
    }
  }

  .selected-articles-dropdown-no-entities {
    padding: 0.6rem 1.2rem;
    font-size: 1.3rem;
    margin-bottom: 0.5rem;
  }

  .selected-articles-dropdown-item {
    font-size: 1.3rem;
    padding: 0.6rem 1.2rem;
    display: flex;
    flex-direction: row;
    justify-content: space-between;

    &:last-child {
      margin-bottom: 0.5rem;
    }


    .selected-articles-dropdown-item-title {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    .la-CloseRemove {
      font-size: 1rem;
      margin-right: 1rem;
      background-color: $white;
      outline: none;
      border: none;
      color: #bcbec0;
    }

    &:hover {
      background-color: $hover-color;

      .la-CloseRemove {
        background-color: $hover-color;

        &:hover {
          cursor: pointer;
        }

        &:active {
          opacity: 0.5;
        }
      }
    }
  }
}


.selected-articles-dropdown-container:hover {
  .la-TriangleDown::before {
    content: "\e607";
  }
  .la-TriangleDown.withoutFlip:before {
    content: "\e60a";
  }
  .selected-articles-dropdown {
    display: block;
  }
}

.select-all-popover {

  position: absolute;
  background-color: $white;
  top: 5rem;
  left: -5rem;
  z-index: 100;
  width: auto;
  border: 0.1rem solid $default-border-color;
  border-radius: 0.5rem;
  filter: drop-shadow(0 3px 6px #00000029);
  padding: 1rem 1rem;

  &__input {
    display: inline-flex;
    align-items: center;
      justify-content: space-between;
      white-space: nowrap;
  }
}