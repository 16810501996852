.legal-sources-options.dropdown {
  align-items: center;

  .loading-icon {
    position: relative;
    margin-left: 2rem;
    margin-right: 1rem;
  }

  .la-InfoHover {
    margin-left: 1rem;
  }
}