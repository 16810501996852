.esg-ratings-document-body {
  a {
    color: $button-link-primary-color;
    font-size: $font14;

    &:hover {
      text-decoration: underline;
    }
  }

  .embedded-message {
    @include alert-message($light-popup-message-bg-color, $info-banner-color);
    position: relative;
    margin: -1rem 0 3rem;

    &__icon {
      color: $info-banner-color;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
    }

    &__message {
      border-left: 0.1rem solid $info-banner-color;
      padding-left: 1.5rem;
      max-height: 5rem;
      overflow-y: auto;
    }

    &.dark-mode {
      background: $popup-bg-info-dark;
      color: $popup-text-color-dark;
      border-bottom: 0.1rem solid $popup-border-color-info-dark;

      .embedded-message {
        &__message {
          border-left: 0.1rem solid $popup-border-color-info-dark;
        }
      }
    }
  }

  .single-article-content-logo-container {
    display: inline-block;

    span {
      display: block;

      &.single-article-content-logo-powered-by {
        font-size: $font14;
        font-weight: bold;
        line-height: 2rem;
        color: $subfield-text-color;
      }

      &.single-article-content-logo-consensus {
        font-size: $font12;
        color: $tertiary-color;
        line-height: 1.6rem;
      }
    }

    .single-article-content-logo {
      width: 13rem;
      margin: 0 !important;
    }
  }

  .esg-ratings-links {
    display: inline-block;
    float: right;

    a {
      display: block;
      line-height: 2.2rem;
    }
  }

  .single-article-title {
    color: $dark-navy;
    font-size: 3.2rem;
    margin-top: 0;
    margin-bottom: 1rem;
  }

  .single-article-subTitle {
    font-size: $font16;
    color: $esg-ratings-subtitle-color;
    font-weight: normal !important;
    margin-bottom: 1rem;
  }

  .esg-ratings {
    margin-top: 2.5rem;

    .esg-ratings-company-source-information {
      line-height: 2.4rem;

      [name="csrhub-notice"],
      .CSRHubLogo,
      p {
        display: none;
      }

      .hit {
        background-color: initial;
      }
    }

    .esg-ratings-overall-information {
      display: flex;
      padding-top: 4.5rem;

      .esg-ratings-overall {
        display: inline-block;
        padding-left: 3.5rem;
        padding-top: 1.5rem;
        width: 100%;

        h4 {
          font-size: $font20;
          border-bottom: none;
          padding-bottom: 0;
        }

        .esg-ratings-title {
          font-size: $font16;
          color: $default-text-color;
        }

        .esg-ratings-source-date {
          >* {
            vertical-align: middle;
          }

          .source-count {
            font-size: $font16;
          }

          .date-range {
            padding-left: 1rem;
            font-size: $font16;
          }
        }

        ul.esg-ratings-legend {
          padding-top: 1rem;
          width: 100%;

          li {
            display: inline-block;
            width: 15.1%;
            vertical-align: middle;
            text-align: center;
            border-top: 0.8rem solid $white;
            padding-top: 1rem;

            &.Rating_0 {
              border-color: $esg-ratings-0-color;
            }

            &.Rating_30 {
              border-color: $esg-ratings-30-color;
            }

            &.Rating_40 {
              border-color: $esg-ratings-40-color;
            }

            &.Rating_50 {
              border-color: $esg-ratings-50-color;
            }

            &.Rating_60 {
              border-color: $esg-ratings-60-color;
            }

            &.Rating_80 {
              border-color: $esg-ratings-80-color;
            }

            &:not(:first-child) {
              margin-left: .2rem;
            }
          }
        }
      }

      .react-tooltip-clickable-link {
        pointer-events: auto !important;

        &:hover {
          visibility: visible !important;
          opacity: 1 !important;
        }
      }

      .icon-box {
        border: none;
        background: transparent;

        div {
          font-size: $font18;
          color: $button-link-primary-color;
        }
      }
    }

    .__react_component_tooltip {
      max-width: 35vw;
    }

    .esg-ratings-categories {
      display: flex;
      margin-top: 4rem;

      .esg-ratings-category {
        flex: 25%;
        position: relative;

        &:not(:first-child) {
          &:before {
            content: '';
            position: absolute;
            margin: 3rem 0 0;
            height: calc(100% - 3rem);
            display: block;
            width: .1rem;
            background: $default-border-color-lighter;
          }
        }

        .esg-ratings-category-title {
          font-size: $font18;
          margin-bottom: 1em;
          text-align: center;
        }

        .donut-container {
          max-width: 13rem;
          margin-left: auto;
          margin-right: auto;

          +.esg-ratings-subcategory {
            margin-top: 3rem;
          }
        }

        .esg-ratings-subcategory {
          margin-left: auto;
          margin-right: auto;
          width: 12rem;

          &:not(:last-of-type) {
            padding-bottom: 3rem;
          }

          .esg-ratings-subcategory-title {
            font-size: $font14;
            line-height: 1.7rem;
            height: 3.4rem;
            margin-bottom: 0.5rem;
          }

          .esg-ratings-subcategory-rating {
            font-size: 1.4rem;
            font-weight: bold;
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .view-more-less-link {
      color: $button-link-primary-color;
      font-size: $font14;
      margin: 4rem 0 1rem;
      text-align: center;
      background: $gray-bg-color;
      border-radius: 0 0 .8rem .8rem;
      font-weight: bold;

      &:hover {
        cursor: pointer;

        .view-more-less-text {
          text-decoration: underline;
        }
      }

      .view-more-less-text {
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
          cursor: pointer;
        }
      }

      .icon {
        vertical-align: middle;
        padding-left: .5rem;
        color: $button-link-primary-color;

        &:hover {
          cursor: pointer;
        }
      }
    }

    .copyright {
      color: $subfield-text-color;
      font-size: $font14;
      text-align: center;
      margin-top: 4rem;
    }
  }
}