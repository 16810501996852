.manage-alerts-header {
  display: flex;
  justify-content: space-between;
  background-color: $white;
  height: 6rem;
  padding: 0 3rem 0;
  font-size: 2.4rem;
  line-height: 3.5rem;

  .icon-box {
    padding: 0;
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: 0;
    background-color: $white;

    .la-TriangleLeft {
      position: relative;
      right: 0.2rem;
      font-size: 2.5rem;
      cursor: pointer;

      &:hover {
        color: $disabled-text-color;
      }
    }
  }

  &-title {
    display: inline-block;
  }

  &-download-cta {
    display: flex;
    align-items: center;
    padding: 0 1.3rem;


    .la-Download {
      font-size: 2.5rem;
      color: $button-color-click;
      margin-right: .8rem;
    }

    &:disabled {
      .la-Download {
        color: $button-color-disabled;
      }
    }
  }
}

.manage-alerts-body {
  background-color: $white;
  margin: 1rem 3rem 0;

  .tabs {
    border-bottom: 1px solid $light-border-color;
    background-color: $white;
    margin: 0;
    padding: 0 2rem 0;

    .tab {
      padding: 1rem;
      font-size: 1.5rem;
    }
  }
}

.pagination-container {
  text-align: center;
  color: $default-text-color;
  margin: 4.3rem 0 6rem 0;
  ul.pagination {
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0 1.5rem;
    a {
      color: $default-text-color;
      &:hover {
        position: relative;
        &:after {
          content: " ";
          border-bottom: 0.1rem solid $default-text-color;
          position: absolute;
          transform: translate(-50%);
          bottom: 0;
          left: 50%;
          margin: 0 auto;
          width: calc(100% + 0.4rem);
        }
      }
      @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
        /* IE11 specific styles */  
        &:hover {
          &:after {
            bottom: -0.2rem;
          }
        }
      }
    }
    li {
      display: inline-block;
      padding: 0.4rem 1.05rem;
      cursor: pointer;
      font-size: $font16;
    }
    li.active {
      box-sizing: border-box;
      font-weight: bold;
      a {
        position: relative;
        &:after {
          content: " ";
          border-bottom: 0.2rem solid $secondary-warning-color;
          position: absolute;
          transform: translate(-50%);
          bottom: -0.2rem;
          left: 50%;
          margin: 0 auto;
          width: calc(100% + 0.4rem);
        }
      }
    }
    li.previous > a {
      display: flex;
      font-size: $font24;
      -webkit-text-stroke: 0.05rem $caret-enabled-color;
      &:after {
        content: "\e609";
        position: relative;
        font-family: "GVS_Icons", sans-serif;
        font-size: $font24;
        border: none;
      }
    }
    li.next > a {
      display: flex;
      font-size: $font24;
      -webkit-text-stroke: 0.05rem $caret-enabled-color;
      &:after {
        content: "\e608";
        position: relative;
        font-family: "GVS_Icons", sans-serif;
        font-size: $font24;
        border: none;
      }
    }
    li.disabled,
    li.disabled > a {
      color: $button-color-disabled;
      -webkit-text-stroke: 0.05rem $button-color-disabled;
      cursor: default;
    }
  }
}
