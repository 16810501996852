/**
 * MAIN SCSS STYLES
 *
 * This file imports all other SCSS stylesheets from the app.
 *
 * @version 0.1
 */

// Base styles
@import 'react-calendar/dist/Calendar.css';

@import './../../../baseStyles/variables/variables';
@import './../../../baseStyles/mixins/mixins';
@import './../../../baseStyles/reset/reset';
@import './../../../baseStyles/defaultScss/defaultScss';
@import './../../../baseStyles/pendo/pendo.style.scss';

// Page components styles
@import './../ErrorPage/errorPage.style';
@import './../StartPage/startPage.style';
@import './../StartPage/loaderPage.style';
@import './../StartPage/components/dnbSearch.style';
@import '../MainSearch/components/elasticDocumentsList.style';
@import '../MainSearch/components/elasticSingleDocument.style';
@import '../MainSearch/components/resultsSnapshot.style';
@import '../MainSearch/components/countPods.style';
@import '../MainSearch/components/resultsList.style';
@import '../MainSearch/components/activeFilterList.style';
@import '../MainSearch/components/noResultsDocumentItem.style';
@import '../MainSearch/components/resultsListHeader.style';
@import '../MainSearch/components/articleActions.style';
@import '../MainSearch/components/singleArticleHeader.style';
@import '../MainSearch/components/selectedDocumentsDropdown.style';
@import '../MainSearch/components/duplicateArticlesList.style';
@import '../MainSearch/components/negativeNewsVisualisations/resultsList/negativeNewsResultsGraph.style';
@import '../MainSearch/components/negativeNewsVisualisations/negativityLevel/negativityLevelPill.style';
@import '../MainSearch/components/negativeNewsVisualisations/negativityTerms/negativityTerms.style';
@import '../MainSearch/components/negativeNewsVisualisations/negativityTerms/negativityTermsSnapshot.style';
@import '../MainSearch/components/negativeNewsVisualisations/snapshot/donutChart/negativeNewsDonutChart.style';
@import '../MainSearch/HOC/withDisclaimer.style';
@import '../ReportBuilder/components/ReportsList/reportsList.style';
@import '../ReportBuilder/components/ReportBuilderHeader/reportBuilderHeader.style';
@import '../UserPreferances/userPreferences.style';
@import '../UserPreferances/components/similarNameMatch.style';
@import '../UserPreferances/components/fuzzyNameThreshold.style';
@import '../UserPreferances/components/suggestedNames.style';
@import '../UserPreferances/components/userPreferencesSections.style';
@import '../UserPreferances/components/reportBuilderContentTypes.style';
@import '../UserPreferances/components/negativeNewsLanguageSelection.style';
@import '../UserPreferances/components/negativityLevels.style';
@import '../UserPreferances/components/customCodeInputBox.style';
@import '../UserPreferances/components/adminCostCodeContainer.style';
@import '../UserPreferances/components/customNewsSearches.style';
@import '../UserPreferances/components/sourcesSearchOptions.style';
@import '../UserPreferances/components/legalSourcesNamePartyEntity.style';
@import '../UserPreferances/components/excludeNews.style';
@import '../UserPreferances/components/_sanctionsAndWatchlists.style';
@import '../AdminPreferences/adminPreferences';
@import '../ReportBuilder/components/ReportBuilderActions/reportActions.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/regularReportComponents/contentComponents/reportCategoryList.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/regularReportComponents/contentComponents/reportDocumentEditNote.style.scss';
@import '../ReportBuilder/components/ReportsList/ReportTypes/sharedComponents/Notes/reportNotes.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/sharedComponents/Footer/reportFooter.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/sharedComponents/Header/components/reportEditableTitle.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/regularReportComponents/contentComponents/connectedReports.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/combinedReportComponents/content.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/combinedReportComponents/contentComponents/reportInfo.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/regularReportComponents/content.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/regularReportComponents/contentComponents/categoriesOrder.style';
@import '../ReportBuilder/components/ReportsList/ReportTypes/batchReportComponents/content.style';
@import '../ReportBuilder/reportBuilderMain';
@import '../Alerts/createAlerts.style';
@import '../ManageAlerts/manageAlerts.style';
@import '../UboDocoument/uboDocument.style';
@import '../ManageAlerts/components/manageAlertsActions.style';
@import '../ManageAlerts/components/manageAlertsTableHeader.style';
@import '../ManageAlerts/components/manageAlertsSingleAlert.style';
@import '../UserPreferances/components/legalSourcesDropdown.style';
@import '../UboDocoument/uboDocument';
@import '../EsgRatings/esgRatingsDocument';
@import '../EntityView/entityView.style.scss';
@import '../EntityView/components/EntitiesUsed/entitiesUsed.style.scss';

// Reusable components styles
@import './../../reusable/Modal/modal.style';
@import './../../reusable/Modal/hoc/withDownloadMultipleReportsModal.style';
@import './../../reusable/ConfirmationModal/confirmationModal.style';
@import './../../reusable/LoadingSpinner/loadingSpinner.style';
@import './../../reusable/Header/header.style';
@import './../../reusable/BackToTop/backToTop.style';
@import './../../reusable/Footer/footer.style';
@import './../../reusable/MessagePopup/messagePopup.style';
@import './../../reusable/Wrappers/SpinnerWrapper/spinnerWrapper.style';
@import './../../reusable/PreviousPageLink/previousPageLink';
@import './../../reusable/GoogleTranslate/googleTranslate';
@import './../../reusable/Popover/popover';
@import './../../reusable/Tag/_superscriptTag.scss';
@import './../../reusable/SearchBar/searchBar.style';
@import './../../reusable/SearchBarHeader/searchBarHeader.style';
@import './../../reusable/IconButtonSearchType/iconButtonSearchType.style';
@import './../../reusable/SearchTypeFilter/searchTypeFilter.style';
@import './../../reusable/Breadcrumbs/breadcrumbs.style';
@import './../../reusable/BackgroundMessage/backgroundMessage.style';
@import './../../reusable/GoogleTranslate/googleTranslate';
@import './../../reusable/LoginHistoryBanner/loginHistorybanner.style';
@import './../../reusable/AdminPermissionBanner/adminPermissionBanner.style';
@import './../../reusable/SingleArticleSidebar/singleArticleSidebar.style';
@import './../../reusable/PopupModal/popupModal.style';
@import './../../reusable/Buttons/buttons.style';
@import './../../reusable/PillList/pillList';
@import './../../reusable/PillList/loadingPillList.style';
@import './../../reusable/ToggleForFuzzyNames/toggleForFuzzyName.style';
@import './../../reusable/DateSelect/dateSelect.style';
@import './../../reusable/PageSizeSelector/pageSizeSelector.style';
@import './../../reusable/BooleanTerms/booleanTerms.style';
@import './../../reusable/TabbedContent/tabs';
@import './../../reusable/Dropdown/dropdown';
@import '../../reusable/SearchBox/searchBox.style';
@import '../../reusable/FilterSearchBox/filterSearchBox.style';
@import '../../reusable/ErrorMessage/errorMessage.style';
@import './../../reusable/Form/form';
@import '../../reusable/AdHocSearch/adHocSearch.style';
@import '../../reusable/AdminLock/adminLock';
@import '../../reusable/Table/table';
@import '../../reusable/ArticleNavigation/articleNavigation';
@import '../../reusable/UserPreferencesBanner/userPreferencesBanner.style';
@import './../../reusable/InformationModal/informationModal.style';
@import './../../reusable/InformationIcon/informationIcon.style';
@import './../../reusable/Slider/slider.style';
@import './../../reusable/UBO/ubo';
@import './../../reusable/EditSubcategories/editSubcategories.style';
@import './../../reusable/EditableText/editableText.style';
@import './../../reusable/AutsaveMessage/autosavingMessage.style';
@import './../../reusable/PopupModal/Components/modalTabbedContent.style';
@import './../../reusable/PopupModal/Components/modalReorderItems.style';
@import './../../reusable/PopupModal/Components/_modalAlertGDPRMessage.style.scss';
@import './../../reusable/PopupModal/Components/_modalUploadEntities.style.scss';
@import './../../reusable/SearchBar/components/DateRange/dateRangesWrapper.style';
@import '../../reusable/SuggestedNames/suggestedNames.style';
@import './../../reusable/BOV/bovDocumentView.style';
@import './../../reusable/BOV/bovTreeView.style';
@import '../../reusable/Header/templates/themeSwitcher';
@import '../../reusable/DragAndDrop/dragAndDropBox';
@import '../../reusable/DragAndDrop/dragAndDropUploadedItem';
@import '../../reusable/GridTable/GridTable.style';
@import '../../reusable/GridTable/components/cell-types/cellTypes.style';
@import '../../reusable/CommentsSection/commentsSection.style';
@import '../../reusable/GridTable/components/_riskTooltip.style.scss';
@import '../../reusable/SimpleList/simpleList.style.scss';
@import '../../reusable/SimpleTable/simpleTable.style.scss';
@import '../../reusable/CustomModals/MarketingOverlayModal/_marketingOverlayModal.scss';
@import './../../reusable/ModalPagination/_modalPagination.scss';
@import './../../reusable/MarketingTooltip/_marketingTooltip.style.scss';
@import './../../reusable/Snackbar/_snackbar.style.scss';
@import './../../reusable/SnackbarWithAutohide/_snackbarWithAutohide.style.scss';
@import './../../reusable/NotificationsContainerLayout/_notificationsContainerLayout.style.scss';
@import "../../reusable/AlertMessage/_alertMessage.style.scss";
@import "../../reusable/CustomDropdown/_customDropdown.style.scss";
@import '../MainSearch/components/sanctionsRisk/snapshot/SanctionsPodContent/_sanctionsPodContent.style.scss';
@import '../MainSearch/components/sanctionsRisk/snapshot/SanctionsTable/_sanctionsTable.style.scss';
@import '../MainSearch/components/sanctionsRisk/snapshot/SanctionsTable/cell-types/_cellTypes.style.scss';
@import '../../reusable/SortComponent/sortComponent.style';

.skip-to-content-invisible {
    position: absolute;
    left: -1000px;
    top: 0;
    z-index: 99;
    a {
        position: absolute;
        left: -1000px;
    }
}

.skip-to-content-visible {
    width: 27.5rem;
    height: 8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    padding: 2.8rem 3.3rem;
    top: 0;
    left: 0;
    background: $secondary-button-text-color;
    z-index: 120;

    a {
        color: $white;
        font-size: 1.6rem;
        outline: 0;
        white-space: normal;
    }
}
.customIcon {
    color: #fff;
    margin-right: 1.5rem;
    font-size: 2.5rem;
}
