.user-preferences-sanctions-and-watchlists {
  .toggle-sanctions {
    display: flex;
    justify-content: flex-start;
    align-items: center;
    margin: 2rem 0;
    gap: 1.2rem;
  }

  .sanctions-subsection-label {
    font-size: 1.4rem;
    margin-bottom: 1.2rem;
  }

  .user-preferences-category-container__list {
    &.sanctions-authorities-list {
      column-gap: 2rem;

      .user-preference-list-item {
        width: 15%;
      }
    }
  }

  .user-preferences-category-container__search {
    input {
      font-size: $font14;
      width: 42.4rem;
      height: 4.3rem;
      padding: 1.2rem 1.6rem;
    }

    button {
      &:disabled {
        background-color: transparent;
      }
    }
  }

  .user-preferences-category-container__description {
    h3 {
      margin: 1rem 0;
    }
  }

  .user-preferences-category-container__authorities {
    margin: 1rem auto 1.5rem auto;
    width: 100%;
    height: 44rem;
    overflow: auto;

    &__risks {
      display: flex;
      justify-content: flex-end;
      align-items: center;
      grid-gap: 6.5rem;
      padding-right: 3rem;

      @supports (-moz-appearance:none) {
        padding-right: 2rem;
      }
    }

    &__simple-table {

      &__text-header-cell {
        display: flex;
        align-items: center;
        font-weight: 700;
        gap: 0.8rem;
        font-size: $font14;

        .sort-action-btn {
          min-height: 2.8rem;
          background-color: $white;
          color: $caret_inactive-color;
          border: 0;
          font-size: 1.4rem;
          font-weight: normal;
          cursor: pointer;
          outline: none;
          text-align: left;
          margin-right: 1rem;
          display: flex;
          align-items: center;

          &.la-TriangleUp {
            &.la-TriangleUp-active {
              color: $caret-active-color;
            }
          }

          &.la-TriangleDown {
            margin-top: -1.5rem;

            &.la-TriangleDown-active {
              color: $caret-active-color;
            }
          }
        }
      }

      &__text-cell {
        display: flex;
        gap: 0.8rem;

        p {
          overflow: hidden;
          white-space: nowrap;
          text-overflow: ellipsis;
          font-size: $font16;
          max-width: 26rem;
        }
      }

      &__radio-header-cell {
        display: flex;
        justify-content: space-evenly;
        align-items: center;
        padding: 0 1rem;
        gap: 1.5rem;

        &__input {
          display: flex;
          align-items: center;
          gap: 0.8rem;
        }
      }

      &__radio-cell {
        display: flex;
        justify-content: space-around;
        align-items: center;
        padding: 0 1rem;

        input {
          z-index: 1;
          margin-top: 0.5rem;
          cursor: pointer;
        }
      }

      &__filter-dropdown {
        display: flex;
        align-items: center;
        cursor: pointer;

        &.disabled {
          pointer-events: none;
          cursor: auto;
          color: $placeholder-color;
        }

        &__button {
          background-color: #D5DDE5;
          padding: 0.2rem 1rem 0.2rem;
          color: #00172E;
          border-radius: 1.5rem;
          display: flex;
          font-size: 1.4rem;
          white-space: nowrap;
          gap: 1rem;

          .la-TriangleUp,
          .la-TriangleDown {
            display: flex;
            align-items: center;
          }

          &.active {
            background-color: $button-color-active;
            color: $white;

            .la-TriangleUp,
            .la-TriangleDown {
              color: $white;
            }
          }
        }

        &__content {
          position: absolute;
          background-color: $white;
          top: 8rem;
          left: 0;
          z-index: 200;
          width: 28rem;
          border: 0.1rem solid $default-border-color;
          border-radius: 0.5rem;
          filter: drop-shadow(0 3px 6px #00000029);
          padding: 1rem;
          max-height: 32rem;
          overflow-y: auto;

          .user-preference-filter-reset-button {
            display: flex;
            justify-content: flex-start;
        
            button {
              background: none;
              border: none;
              outline: none;
              text-align: left;
              color: $button-link-primary-color;
              cursor: pointer;
              width: 19rem;
              margin: 0.9rem 0 1rem 0.5rem;
              font-size: $font16;
        
              &:hover {
                color: $primary-color-hover;
                text-decoration: underline;
              }
        
              &:active {
                opacity: 0.5;
                text-decoration: underline;
              }
        
              &:disabled {
                cursor: initial;
                color: $subfield-text-color;
                pointer-events: none;
                text-decoration: none;
              }
            }
          }

          .user-preference-checkbox {
            display: flex;
            justify-content: flex-start;
            align-items: center;
            gap: 2rem;

            input {
              z-index: 1;
              height: 1.8rem;
              width: 1.8rem;
              margin: 0.8rem 1rem;
              cursor: pointer;
            }

            label {
              overflow: hidden;
              white-space: nowrap;
              text-overflow: ellipsis;
              font-size: $font14;
              max-width: 28rem;
              padding: 0.8rem 0.9rem 0.8rem 1rem;
              color: #455A64;
              font-weight: 400;
            }
          }
        }
      }
    }
  }

  .user-preferences-category-container__reset {
    display: flex;
    justify-content: flex-end;

    button {
      background: none;
      border: none;
      outline: none;
      text-align: left;
      padding: 0 0 1.5rem 0;
      color: $button-link-primary-color;
      cursor: pointer;
      width: 19rem;
      margin-top: 0.9rem;

      &:hover {
        color: $primary-color-hover;
        text-decoration: underline;
      }

      &:active {
        opacity: 0.5;
        text-decoration: underline;
      }

      &:disabled {
        cursor: initial;
        color: $subfield-text-color;
        pointer-events: none;
        text-decoration: none;
      }
    }
  }
}