/**
 * ERROR PAGE STYLES
 *
 * @version 0.1
 */


.error-page {

    font-size: 1rem;
    margin: 15rem auto 0;
    width: 90%;

    .error-404,
    .error-403,
    .error-500 {
        display: flex;
        flex-direction: column;
        align-items: center;

        .embedded-message{
            align-items: center;
            position: relative;
            font-size: 1.5rem;
            height: 8rem;
            padding: 1.5rem;
            margin: 1rem auto;
            display: flex;
            overflow: hidden;
            @include alert-message($popup-bg-warning-light, $popup-user-error-color );
            width: auto;
            min-width: 76rem;

            &__icon{
                padding-right: 1.5rem;
                display: flex;
                align-items: center;
            }

            &__message{
                padding-left: 1.5rem;
                max-height: 5rem;
                overflow-y: auto;
                border-left: 0.1rem solid $popup-border-color-warning-light;
            }

            &.dark-mode{
                background-color: $popup-bg-warning-dark;
                color: $popup-text-color-dark;
                border-bottom: 0.1rem solid $popup-border-color-warning-dark;

                .embedded-message__message{
                    border-left: 0.1rem solid $popup-border-color-warning-dark;
                }
            }
        }
    }

    &__navigation {
        margin: 0 auto;
        position: relative;
        width: 76rem;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 4rem;

        .button-secondary-sm {
            background-color: transparent;
        }
    }

}