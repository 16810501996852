.article-results {
  .pagination-container {
    text-align: center;
    color: $default-text-color;
    margin: 4.3rem 0 4rem 0;
    ul.pagination {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 0 1.5rem;
      a {
        color: $default-text-color;
        &:hover {
          position: relative;
          &:after {
            content: " ";
            border-bottom: 0.1rem solid $default-text-color;
            position: absolute;
            transform: translate(-50%);
            bottom: 0;
            left: 50%;
            margin: 0 auto;
            width: calc(100% + 0.4rem);
          }
        }
        @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {  
          /* IE11 specific styles */  
          &:hover {
            &:after {
              bottom: -0.2rem;
            }
          }
        }
      }
      li {
        display: inline-block;
        padding: 0.4rem 1.05rem;
        cursor: pointer;
        font-size: $font16;
      }
      li.active {
        box-sizing: border-box;
        font-weight: bold;
        a {
          position: relative;
          &:after {
            content: " ";
            border-bottom: 0.2rem solid $secondary-warning-color;
            position: absolute;
            transform: translate(-50%);
            bottom: -0.2rem;
            left: 50%;
            margin: 0 auto;
            width: calc(100% + 0.4rem);
          }
        }
      }
      li.previous > a {
        display: flex;
        font-size: $font24;
        -webkit-text-stroke: 0.05rem $caret-enabled-color;
        &:after {
          content: "\e609";
          position: relative;
          font-family: "GVS_Icons", sans-serif;
          font-size: $font24;
          border: none;
        }
      }
      li.next > a {
        display: flex;
        font-size: $font24;
        -webkit-text-stroke: 0.05rem $caret-enabled-color;
        &:after {
          content: "\e608";
          position: relative;
          font-family: "GVS_Icons", sans-serif;
          font-size: $font24;
          border: none;
        }
      }
      li.disabled,
      li.disabled > a {
        color: $button-color-disabled;
        -webkit-text-stroke: 0.05rem $button-color-disabled;
        cursor: default;
      }
    }
  }

  .embedded-message {
    position: relative;
    background-color: $popup-bg-critical-light;
    font-size: 1.5rem;
    height: 8rem;
    margin-left: 1.5rem;
    margin-top: 2rem;
    width: 100%;
    display: flex;

    &__icon {
      margin-left: 2.7rem;
      align-self: center;
    }

    &__message {
      margin-left: 3.5rem;
      margin-top: 2rem;
      max-height: 5rem;
      overflow-y: auto;
    }

    &.error-occurred:before {
      content: "";
      position: absolute;
      top: 2.2rem;
      left: 6.6rem;
      height: 45%;
      width: 0.1rem;
      background-color: $popup-system-error-color;
    }

    &.dark-mode {
      background-color: $popup-bg-critical-dark;
      color: $popup-text-color-dark;
    }
  }

  .article-list {
    font-weight: 200;
    font-size: 1.5rem;
    list-style: none;
    list-style-position: inside;
    position: relative;
    padding-left: 2.5rem;

    .article-row {
      margin-top: 2rem;
      min-height: 10rem;
      display: flex;
      flex-direction: row;

      &:first-child {
        margin-top: 0;
      }

      .custom-checkbox {
        display: inline-block;
        line-height: 2.9rem;

        .checkbox-label {
          &:before {
            display: inline-block;
            position: relative;
            top: -2rem;
            margin-right: 1rem;
            z-index: 0;
          }
        }
        input {
          @extend .checkbox-label;
          position: relative;
          cursor: pointer;
          z-index: 1;
          top: 0;
          
          &:disabled {
            cursor: initial;
          }
        }
      }

      .index-of-article {
        margin-right: 1.8rem;
        font-size: 1.5rem;
        font-weight: bold;
        line-height: 2.9rem;
        color: $header-menu-dropdown-hover;
        padding-top: 0.2rem;
      }

      .article-item-content {
        display: flex;
        flex-direction: column;
        width: 100%;

        .article-list-head {
          display: flex;
          flex-direction: row;
          min-height: 4rem;
          margin-bottom: 1.4rem;

          .article-title {
            font-size: 1.8rem;
            color: $button-link-primary-color;
            font-style: normal;
            font-weight: bold;
            white-space: normal;
            width: 90%;
            border: none;
            padding-bottom: 0;

            &.disabled {
              color: $disabled-text-color;
              opacity: 0.5;
              &:hover {
                cursor: not-allowed;
                color: $disabled-text-color;
                text-decoration: none;
              }
            }

            &:hover {
              color: $button-link-primary-color;
              text-decoration: underline;
              cursor: pointer;
            }

            &:active {
              opacity: 0.5;
              text-decoration: underline;
            }

            a {
              @include multi-line-ellipsis(29px, 2);
              display: block;
              line-height: 1.3;
              color: $button-link-primary-color;
            }

            .visited-article {
              color: $link-visited-color;
            }
          }
        }
        .article-information {
          display: flex;
          flex-direction: row;
          align-items: center;
          column-gap: 1.2rem;
          row-gap: 1rem;
          flex-wrap: wrap;
          margin: 1rem 0;
          line-height: 1.2;

          &-data {
            display: flex;
            align-items: center;
            column-gap: 1.2rem;
            font-size: 1.6rem;
            line-height: 1.5;
            color: $default-text-color;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              margin-right: 1.2rem;
            }
          }

          .article-word-count {
            padding: 0 1rem 0;
            line-height: 2rem;
            border-left: .1rem solid $default-border-color;
            border-right: .1rem solid $default-border-color;

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              margin: 0 1rem;
            }
          }

          .article-no-word-count {
            margin: 1rem;
            border-left: 1px solid $default-border-color;
          }

          .article-date {
            text-align: right;
          }
        }

        .article-sub-information {
          margin-bottom: 1rem;
        }

        .article-numbers,
        .article-address,
        .article-list-name {
          margin-bottom: 0.5rem;

          > div.truncated-line-article-address,
          > div.truncated-line-article-numbers,
          div.truncated-line-article-list-name {
            > div {
              @include multi-line-ellipsis(2rem, 1);
            }
          }

          .__react_component_tooltip.show {
            max-width: 60%;
            font-size: 1.2rem;
          }
        }

        .article-list-name {
          display: flex;
          align-items: center;

          &-label {
            text-transform: uppercase;
            line-height: 1.2;
            white-space: nowrap;
          }
        }

        .articles-list-name-content {
          flex-grow: 1;
          padding-left: .5rem;
        }

        .article-address + .article-numbers {
          margin-top: 0.5rem;
        }

        .article-summary {
          font-size: 1.6rem;
          font-weight: 500;
          line-height: 1.5;
          color: $default-text-color;
          margin-bottom: 1rem;
          min-width: 20rem;
          padding: 1.5rem 0 1rem 0;
          word-break: break-word; // Non standard, for webkit only
          word-wrap: break-word;
          overflow-wrap: break-word;
        }

        .article-summary-ubo {
          display: flex;
          font-size: 1.5rem;
          line-height: 2rem;
          margin-bottom: 1rem;
          min-width: 20rem;
          padding: 0.5rem 0 1rem 0;
          word-break: break-word; // Non standard, for webkit only
          word-wrap: break-word;
          overflow-wrap: break-word;
          max-width: 75%;

          .article-summary-content:last-of-type {
            text-align: center;
          }

          .article-summary-content {
            width: calc(100% / 3);

            .article-summary-content-headOffice,
            .article-summary-content-text {
              display: inline;
            }
            .article-summary-content-headOffice {
              font-weight: bold;
            }
          }
        }

        .duplicate-documents {
          padding-left: 0.3rem;
          font-size: 1.2rem;
        }

        &-wrapper {
          width: 100%;
          display: flex;
          background-color: $default-bg-color;
          border: 0.2rem solid $default-bg-color;
          border-radius: 0.8rem;
          box-shadow: 0.8rem 0.8rem 0.8rem 0 rgba(0, 0, 0, 0.03);
          padding: 2.4rem 3.2rem 2.7rem 3.6rem;
          transition: all 0.2s ease;

          &.selected {
            border: 0.2rem solid $button-link-primary-color;
          }
        }

        &-checkbox-wrapper {
          display: flex;
          padding-top: 2.2rem;
        }
      }

      &__buttons {
        display: flex;
        flex-direction: column;
        align-content: center;
        align-items: center;

        &__preview-article {
          border: none;
          color: $button-link-primary-color;
          font-size: $font15;
          font-weight: bold;
          white-space: nowrap;
          background-color: transparent;
          outline: none;
          margin-top: 3.7rem;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &:active,
          &:focus {
            color: #003a65;
          }
        }
      }
    }
  }
}
