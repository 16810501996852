.rg-comments-cell {
    justify-content: center;
    padding-left: 0 !important;
}

.entity-blocked-icon {
    margin-right: 0.5rem;
}

.entity-blocked-tooltip {
    margin-bottom: 0.6rem;
}

.grid-table-cell-count-template {
    display: flex;
    width: 100%;
    margin: 1.5rem 0;
    &:not(.non-clickable) {
        color: $primary-bg-color;

        &:hover {
            cursor: pointer;
        }
    }
}
.esg-ratings-cell {
    .highcharts-background {
        fill: transparent;
    }
}

.esgRatings {
    width: 3.6rem;
    display: flex;
    justify-content: center;
}

.grid-table-batch-header-template {
    color: rgba(0, 0, 0, 0.87);
    font-weight: 800;
    white-space: nowrap;
    text-overflow: ellipsis;
    overflow: hidden;
    position: relative;
    padding-right: 22px;

    .sort-btn-container {
        position: absolute;
        bottom: 0;
        right: 0;
        height: 24px;
    }
    .sort-action-btn {
        right: 0;
        top: 0;
        margin: 0;
        padding: 0;
        background-color: transparent;
        height: 14px;
        min-height: 14px;
        cursor: default;
        &.la-TriangleDown {
            bottom: -10px;
        }
    }

    @supports (-webkit-line-clamp: 2) {
        white-space: initial;
        text-overflow: ellipsis;
        overflow: hidden;
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
    }
}

//this should apply only for the last header's text (the comment header) because otherwise that text won't split on two rows
.rg-pane.rg-pane-top:not(.rg-pane-left) {
    .grid-table-batch-header-template:last-of-type {
        word-break: break-word;
        padding-right: 0;
    }
}

//(DIL-9230) Chrome v118 broke the stickiness of columns/rows of the GridTable. This is a temporary fix until the ReactGrid library will be updated to v5 to fix the issue
.rg-pane.rg-pane-top {
    z-index: 1;
}

.rg-pane.rg-pane-left {
    z-index: 2;
}

.rg-pane.rg-pane-top.rg-pane-left, .rg-pane.rg-pane-top.rg-pane-right {
    z-index: 3;
}
//(DIL-9230)

//overwriting any unwanted black border from the bottom of the header row
.rg-batchHeader-cell {
    border-bottom: 1px solid $default-border-color-lighter !important;
}

.tooltips.context-menu-toolip {
    max-width: 18rem;
    font-size: 1.1rem;
    font-weight: 400;
}

.grid-table-entity-name-template {
    display: flex;
    align-items: center;
    gap: 0.8rem;
    flex: 1;
    padding-right: 0.5rem;

    .la-Edit {
        font-size: 1.8rem;
        margin-left: auto;
        display: none;

        &:hover {
            cursor: pointer;
        }
    }

    &:hover {
        .la-Edit {
            display: flex;
        }
    }

    .changes-saved {
        display: flex;
        background: url(~/public/assets/CheckMarkSaveChanges.svg) no-repeat center;
        width: 2.5rem;
        height: 2.5rem;
        animation: fade 3s linear forwards;
        margin-left: auto;
    }

    @keyframes fade {
        0%,
        100% {
            opacity: 0;
        }
        10%,
        80% {
            opacity: 1;
        }
    }

    .text-field-standard-sm {
        width: 15rem;
        height: 2rem;
    }

    .edit-input-wrapper {
        position: relative;

        .edit-input-error-message {
            position: absolute;
            left: 0;
            top: 100%;
            font-size: $font10;
            line-height: 1.2;
            color: $secondary-warning-color;
        }
    }
}

.rg-comments-cell {
    .la-AddComment {
        &:before {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    .la-CommentsList,
    .la-CommentsListInactive {
        margin-left: 0.7rem;
        cursor: pointer;

        &:before {
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    .la-AddCommentInactive {
        &::before {
            visibility: hidden;
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    &:hover {
        .la-AddCommentInactive:before {
            visibility: visible;
            cursor: pointer;
        }
    }

    .comments-action-button {
        background: inherit;
        border: none;
        cursor: pointer;
    }
}

.dil-dropdown.download-action-button {
    border: none;
    box-shadow: 0 0.3rem 0.6rem $button-dropdown-box-shadow;
    padding:0.25rem 1rem;
    .dropdown__content {
        cursor:pointer;
        .dropdown__row {
            padding: 0.25rem 1rem;
        }
        .dropdown__row:hover {
            border-radius: 0.5rem;
        }
    }
}

.dil-dropdown.multiple-entities-refresh-dropdown {
    border: none;
    box-shadow: 0 0.3rem 0.6rem $button-dropdown-box-shadow;
    padding:0.25rem 1rem;
    .dropdown__content {
        cursor:pointer;
        .dropdown__row {
            padding: 0.25rem 1rem;
        }
        .dropdown__row:hover {
            border-radius: 0.5rem;
        }
    }
}

.dil-dropdown.multiple-entities-comment-section {
    border: none;
    box-shadow: 0 0.3rem 0.6rem $button-dropdown-box-shadow;

    .dropdown__content {
        .comments-section-wrapper {
            width: 42rem;

            .comments-list-wrapper {
                border-top-left-radius: 0.6rem;
                border-top-right-radius: 0.6rem;

                &__content {
                    max-height: 15rem;
                    overflow-y: auto;
                    overflow-wrap: anywhere;
                    scrollbar-width: thin;

                    &::-webkit-scrollbar {
                        width: 5px;
                    }
                }

                .comment-wrapper {
                    &:hover {
                        .comment-actions-wrapper {
                            visibility: visible;
                        }
                    }

                    .comment-actions-wrapper {
                        visibility: hidden;
                    }

                    .comment-actions-wrapper.loading {
                        visibility: visible;
                    }

                    .comment-content-wrapper {
                        max-width: 75%;
                        overflow-wrap: anywhere;
                    }
                }
            }

            .comments-input-wrapper {
                border-bottom-left-radius: 0.6rem;
                border-bottom-right-radius: 0.6rem;
            }
        }
    }
}

.entity-view-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    cursor: pointer;

    .header-text {
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: normal;
    }
}
