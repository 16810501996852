.snackbar {
    display: flex;
    flex-direction: row;
    min-height: 2rem;
    background-color: $dark-navy;
    color: white;
    border: none;
    align-items: center;
    padding: 0.2rem 0.2rem 0.2rem 2rem;
    z-index: 80;
    width: 100%;
    height: 100%;
  
    .snackbar-content {
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      width: 100%;
      height: 2rem;
      font-size: 1.4rem;
      transition: width 1s ease-out;
      align-items: center;
    }

    .snackbar-icon-container {
      margin-right: 1.4rem;
    }
  
    .snackbar-icon-container, .snackbar-close-icon-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      width: 10%;
      width: 4rem;
      height: 4rem;
      margin: 0 0.7rem;
    }

    .snackbar-close-icon-container {
      cursor: pointer;
      width: 2rem;
      height: 2rem;
      border-radius: 50%;
      background-color: #223a50;
      padding: 0.5rem;
      .icon {
        width: 1rem;
        height: 1rem;
        padding-bottom: 1.1rem;
      }
    }
}