.note-on-article {
  margin: 1rem 0 1rem;
  display: flex;
  flex-direction: column;

  &.dragging{
    color: #d0d0d2;
    background-color: #fff;
    cursor: move;

    .existing-note-on-article-buttons {
      background-color: $white;
      color: #d0d0d2;

      .button-primary-sm {
        background-color: $white;
        color: #d0d0d2;
        border: 1px solid $default-border-color;
      }
      .button-secondary-sm {
        background-color: $white;
        color: #d0d0d2;
        border: 1px solid $default-border-color;
      }
    }
  }


  .existing-note-on-article {
    background-color: $existing-note;
    display: flex;
    justify-content: space-between;
    min-height: 10rem;
    padding-top: 1rem;
    padding-bottom: 1rem;
    padding-left: 7rem;

    &--content {
        display: flex;
        flex-flow: column wrap;
        width: 80%;
    }

    &--header {
      font-size: $font16;
      font-weight: 600;
    }

    &--note {
      font-size: 1.5rem;
      word-break: break-word;
    }
  }

  .existing-note-on-article-actions {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    margin-bottom: 1rem;

    .existing-note-on-article-button {
        @include link-button($button-link-primary-color);
        font-size: 1.5rem;

        &:hover {
            color: $primary-color-hover;
            text-decoration: underline;
          }

        &:last-child {
          margin-right: 3rem;
        }
    }

    .vertical-divider {
        height: 1rem;
        margin: 1rem 0 0;
    }
  }
}

.preview-article-box {
  max-height: 30rem;
  overflow-y: auto;
  overflow-x: hidden;
  margin: 0 2rem;
  padding: 1rem;
  border: 1px solid $default-border-color;
}

.add-note-to-article {
  padding: 2rem;
  font-size: 1.5rem;


  .text-area-standard {
    width: 100%;
    height: 6.9rem;
    display: block;
  }

  .add-note-to-article-buttons {
    padding-top: 1.8rem;
    justify-content: flex-end;
    display: flex;
    margin-left: auto;

    .button-primary-sm {
      margin-right: .5rem;
    }

    .button-secondary-sm {
      margin-left: .5rem;
    }

  }
}