.batch-report {
  background-color: $white;

  .columns {
    display: grid;
    grid-template-rows: repeat(20, auto);
    grid-template-columns: repeat(5, 1fr);
    grid-auto-flow: column;
    grid-gap: 1rem;
    column-count: 5;
  }

  .batch-entry {
    font-size: $font14;
    color: $default-text-color;
    font-family: Lato, Lato-Regular, sans-serif;
    line-height: 2.14;
    margin: 0 0 0.4rem 1.9rem;
    display: flex;
    align-items: center;
    gap: 0.5rem;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;

    &_error {
      color: $batch-report-entity-error-color;
      font-weight: bold;
    }

    &_error-dot {
      display: flex;
      min-height: 1rem;
      min-width: 1rem;
      border-radius: 50%;
      background-color: $batch-report-entity-error-color;
      align-items: center;
    }
    &_index {
      width: 8%;
    }
    &_query {
      width: 80%;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}

.header-message {
  display: flex;
  align-items: center;
  gap: 1rem;
}
