.tag {
    display: flex;
    font-size: 1rem;
    align-items: center;
    border-radius: 1rem;
    font-weight: 400;
    height: 1.2rem;
    width: 3rem;
    justify-content: center;
    margin-left: 0.3rem;
}

.blue-tag {
    @extend .tag;

    background: #abdef3;
    color: $default-text-color;
}

.red-tag {
    @extend .tag;

    background: $red-tag;
    color: $button-text-color;
    height: 1.6rem;
    width: 100%;
}

.red-gradient-tag {
    @extend .tag;
    @extend .red-tag;

    background: $red-tag;
    background: linear-gradient(90deg, $red-tag 0%, $red-tag-gradient-2 100%);
}

.white-tag {
    @extend .tag;

    background: $default-bg-color;
    color: $default-text-color;
}
