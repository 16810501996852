.user-preferences-suggested-names {
  .user-preferences-suggested-names-topic {
    padding-top: 2rem;
    font-weight: bold;
  }

  .include-suggested-names-match {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin: 1rem 0 2rem;

    .custom-toggle {
      margin-right: 2rem;
    }
  }
}
