/**
 * BACK TO TOP SCSS STYLES
 *
 * @version 0.1
 */

.back-to-top {
    background-color: $rebranding-backToTop-bg-color;
    border: 0.1rem solid $rebranding-backToTop-bg-color;
    border-radius: 0.5rem;
    bottom: 16rem;
    color: $white;
    cursor: pointer;
    font-size: 2rem;
    height: 4.6rem;
    position: fixed;
    right: 1.5rem;
    width: 4.6rem;
    z-index: $z-back-to-top;

    &:hover {
        background-color: #0097a7;
        border: 1px solid #0097a7;
    }
    &:active {
        background-color: $white;
        border: 1px solid #0097a7;
        color: #0097a7;
    }
}

// Dark theme
.dark-theme {
    .back-to-top {
        background-color: $dark-theme-background;
        border: .1rem solid $dark-theme-color;
        font-weight: bold;

        &:hover {
            background-color: $dark-theme-background;
            border: .2rem solid $dark-theme-color;
        }

        &:active {
            color: $dark-theme-color;
        }
    }
}