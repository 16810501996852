.sanctions-column-empty {
  padding-bottom: 0;
}

.risk {
  &-score-container {
    .__react_component_tooltip.type-dark,
    .tooltips.type-light {
      width: 35.3rem;
      background-color: $tooltip-risk-background-color;
      padding: 2.8rem 1.7rem .2rem 2.3rem;
      opacity: 1;
      box-shadow: 0 .2rem .2rem $shadow-color-risk-tooltip;
      white-space: pre-wrap;

      &.border.place-bottom,
      &.place-bottom {
        &:after,
        &:before {
          border-bottom-color: $tooltip-risk-background-color;
          left: 10%;
        }
      }

      &.border.place-top,
      &.place-top {
        box-shadow: 0 -.2rem .2rem $shadow-color-risk-tooltip;

        &:after,
        &:before {
          border-top-color: $tooltip-risk-background-color;
          left: 10%;
        }
      }
    }
  }

  &-tooltip {
    &-container {
      .display-count-negativity-risk {
        color: $default-text-color;

        &-category {
          font-size: $font18;
          font-weight: bold;
          line-height: 1;
          margin-bottom: 1.5rem;
          color: $default-text-color-lighter;
        }

        &-total-articles {
          font-size: $font24;
          font-weight: bold;
          line-height: 1;
          margin-bottom: .5rem;

          &-label {
            font-size: $font13;
            margin-bottom: 1.5rem;
          }
        }

        &-level {
          font-size: $font12;
          line-height: 1;
          color: $button-text-color;
          display: inline-block;
          padding: .6rem 1.3rem;
          margin-bottom: 1.2rem;
          border-radius: 2em;

          &.low {
            background-color: $negativity-level-pill-low;
          }

          &.medium {
            background-color: $negativity-level-pill-medium;
          }

          &.high {
            background-color: $negativity-level-pill-high;
          }
        }

        &-percentage-list {
          width: 13.5rem;
          display: flex;
          flex-direction: column;
          gap: 1rem;
          margin-bottom: 1.8rem;
        }

        &-percentage-list-item {
          display: flex;
          justify-content: space-between;
          font-size: $font13;

          &-label {
            display: flex;
            align-items: center;
            column-gap: 1rem;
          }

          &-percentage {
            font-weight: bold;
          }
        }
      }

      .card-disclaimer {
        font-size: $font15;
        font-weight: normal;
        color: $caret-enabled-color;
        padding: 0;

        a {
          color: $caret-enabled-color;
          font-weight: normal;

          &:hover {
            text-decoration: none;
          }
        }
      }
    }
  }
}