.filter-search-box {

  .text-field-standard-sm {
    padding-right: 4rem;
  }

  .la-Search {
    background-color: $white;
    border: none;
    font-size: 2.2rem;
    color: $placeholder-color;
    outline: 0;
    margin-left: -4rem;
    position: relative;
    top: .4rem;
  }

  .la-CloseRemove  {
    border: none;
    font-size: 1.5rem;
    cursor: pointer;
    color: $placeholder-color;
    outline: 0;
    margin-left: -4rem;

    &:hover {
      opacity: .5;
    }
  }

  .la-ClearSearch {
    background-color: $white;
    border: none;
    font-size: 2.2rem;
    cursor: pointer;
    color: $placeholder-color;
    outline: 0;
    margin-left: -4rem;
    position: relative;
    top: .5rem;
    transform: scale(0.8);

    &:hover {
      opacity: .5;
    }
  }
}


