/**
 * GLOBAL SCSS SETTINGS
 *
 * This file contains the default SCSS styles for the app.
 *
 * @version 0.1
 */

html {
  font-size: 62.5%; // makes em/rem calculations easier
}

body {
  background-color: $body-main-bg-color;
  font-family: $secondary-font-stack;
  font-size: 15px;
  line-height: 22px;
  padding-bottom: 4rem;
}

* {
  font-family: $secondary-font-stack;
}

input[type="text"]::-ms-clear {
  display: none;
  width: 0;
  height: 0;
}

@media print {
  .app-wrapper {
    display: block;
  }
  .app-wrapper-non-web-print {
    display: none;
  }
  body {
    color: #000000 !important;
    width: 100%;
    -webkit-print-color-adjust: exact;
    background-color: #ffffff !important;
    font-size: 13px;
  }

  h1 {
    font-size: 36px;
    font-weight: 400;
    line-height: 50px;
  }

  h2 {
    font-size: 24px;
    font-weight: 400;
    line-height: 35px;
  }

  h3 {
    font-size: 21px;
    font-weight: 400;
    line-height: 32px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #009ddb;
  }

  h4 {
    font-size: 18px;
    font-weight: 400;
    line-height: 29px;
    padding-bottom: 1rem;
    border-bottom: 1px solid #009ddb;
  }

  p {
    margin: 1.2rem 0;
  }

  .SS_DocumentHeader {
    display: none;
  }

  #publication_date {
    display: none;
  }

  .noprint {
    display: none;
  }

  .skiptranslate {
    display: none;
  }
}

@media screen {
  #print-portal {
    display: none;
  }
}

button {
  font-family: $secondary-font-stack;
}

select {
  background: url("~/public/assets/TriangleDown.svg") no-repeat right #fff;
  /* For Chrome & Safari & FireFox */
  -moz-appearance: none;
  -webkit-appearance: none;
  background-position-x: calc(100% - 10px);
  background-size: 15px;
  border-radius: 2px;
  border: 1px solid $default-dropdown-border-color;
  padding: 0 3rem 0 0.5rem;

  /* For IE11 - remove the default dropdown arrow */
  &::-ms-expand {
    display: none;
  }
}

.hit {
  background-color: $keyword-highlight-bg-color;
}

.ui.inline.loader.active,
.ui.inline.loader.visible {
  z-index: 10 !important;

  &:before {
    border-color: lightgrey;
  }

  &:after {
    border-top-color: #132a43;
  }
}

.ui.large.loader {
  z-index: 10 !important;
}

.ui.medium.loader {
  z-index: 10 !important;
}

.ui.small.loader {
  z-index: 10 !important;
}

.custom-radio {
  font-size: 1.4rem;
  font-family: $secondary-font-stack;

  input[type="radio"] {
    position: absolute;
    opacity: 0;
    padding-left: 2rem;

    + .radio-label {
      &:before {
        content: "";
        border-radius: 100%;
        font-size: 1rem;
        line-height: 1rem;
        text-align: center;
        border: 1px solid $rebranding-uploadWizard-disabled-text;
        display: inline-block;
        width: 1.3rem;
        height: 1.3rem;
        position: relative;
        top: 0.5rem;
        margin-right: 1rem;
        vertical-align: top;
        transition: all 250ms ease;
        padding-left: 0.1rem;
      }
      &.disabled {
        pointer-events: none;
      }
    }

    &:checked {
      + .radio-label {
        font-weight: bold;

        &:before {
          font-weight: normal;
          content: "\25cf";
          color: $white;
          background-color: $primary-border-color;
          border: 0.1rem solid $primary-border-color;
        }
      }
    }

    &:hover {
      + .radio-label {
        cursor: pointer;
        font-weight: bold;

        &:before {
          color: $primary-border-color;
          border: 0.1rem solid $primary-border-color;
          background-color: $checkbox-hover-bg-color;
        }
      }
    }

    &:not(:checked) {
      + .radio-label {
        &:before {
          color: $dark-blue;
          border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
        }
      }

      &:hover {
        + .radio-label {
          cursor: pointer;
          font-weight: bold;

          &:before {
            content: "";
            border: solid 0.1rem $primary-border-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
      &:focus {
        + .radio-label {
          &:before {
            box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.3rem $dark-blue;
            outline: none;
          }
        }
      }
    }

    &:disabled {
      pointer-events: none;
      + .radio-label {
        color: $radio-disabled-color;
        pointer-events: none;
        cursor: initial;

        &:before {
          pointer-events: none;
          background-color: $white;
          border: 0.1rem solid $radio-disabled-color;
          color: $white;
        }

        &:hover {
          pointer-events: none;
          cursor: initial;
        }
      }

      &:checked {
        + .radio-label {
          &:before {
            background-color: $radio-disabled-color;
            color: $radio-disabled-color;
          }
        }
      }
    }
    + .radio-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }

    &:focus {
      + .radio-label {
        &:before {
          box-shadow: 0 0 0 0.2rem $white, 0 0 0 0.3rem $dark-blue;
          outline: none;
        }
      }
    }
  }

  .custom-radio-coming-soon-label {
    padding-left: 2.3rem;
    font-size: 1.2rem;
    font-style: italic;
    color: $placeholder-color;
  }
}

.custom-checkbox {
  font-size: 1.4rem;
  font-family: $secondary-font-stack;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    + .checkbox-label {
      &:before {
        content: "";
        font-size: 1rem;
        text-align: center;
        background-size: contain;
        border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
        display: inline-block;
        width: 1.4rem;
        height: 1.4rem;
        position: relative;
        top: 0.5rem;
        margin-right: 1rem;
        line-height: 1rem;
        vertical-align: top;
        transition: all 250ms ease;
        border-radius: 0.3rem;
        background-color: $white;
        padding-right: 1.2rem;
      }
    }

    &:checked {
      + .checkbox-label {
        font-weight: bold;
        &:before {
          background-size: contain;
          background-position: 0 0.1rem;
          background-repeat: no-repeat;
          background-image: url("~/public/assets/tick.svg");
          color: $white;
          border: 0.1rem solid $button-link-primary-color;
          background-color: $button-link-primary-color;
          border-radius: 0.2rem;
        }

        .label-text {
          font-weight: bold;
          & + .filter-count {
            font-size: 1.4rem;
            font-weight: bold;
          }
        }
      }

      &:hover {
        + .checkbox-label {
          cursor: pointer;
          font-weight: bold;

          &:before {
            background-size: contain;
            background-position: 0 0.1rem;
            background-repeat: no-repeat;
            background-image: url("~/public/assets/Check_Mark.svg");
            color: $checkbox-hover-bg-color;
            border: solid 0.1rem $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
    }

    &:not(:checked) {
      + .checkbox-label {
        &:before {
          color: $white;
          border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
        }
      }

      &:hover {
        + .checkbox-label {
          cursor: pointer;
          font-weight: bold;

          &:before {
            content: "";
            color: $button-link-primary-color;
            border: 0.1rem solid $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
    }

    &:focus {
      + .checkbox-label {
        &:before {
          outline: 1px solid $button-link-primary-color;
          border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
          outline-offset: 2px;
        }
      }
    }

    &:disabled {
      &:checked {
        + .checkbox-label {
          pointer-events: none;
          color: $default-border-color;

          &:before {
            border: 1px solid $default-border-color;
            color: $white;
            cursor: unset;
            background-color: $default-border-color;
          }

          &:hover {
            cursor: unset;
            &:before {
              cursor: unset;
              background-color: $gray-bg-color;
              border: 1px solid $default-border-color;
            }
          }
        }
      }

      &:not(:checked) {
        + .checkbox-label {
          pointer-events: none;
          color: $default-border-color;

          &:before {
            border: 1px solid $default-border-color;
            color: $white;
            cursor: unset;
            background-color: $white;
          }

          &:hover {
            cursor: unset;
            &:before {
              cursor: unset;
              border: 1px solid $default-border-color;
            }
          }
        }
      }
    }
    + .checkbox-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.custom-checkbox-container .custom-checkbox:hover {
  cursor: pointer;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    &:not(:disabled) {
      &:checked {
        + .checkbox-label {
          &:before {
            cursor: pointer;
            font-weight: bold;

            background-size: contain;
            background-position: 0 0.1rem;
            background-repeat: no-repeat;
            background-image: url("~/public/assets/Check_Mark.svg");
            color: $checkbox-hover-bg-color;
            border: solid 0.1rem $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;

          }
        }
      }

      &:not(:checked) {
        + .checkbox-label {
          cursor: pointer;
          font-weight: bold;

          &:before {
            content: "";
            color: $button-link-primary-color;
            border: 0.1rem solid $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
    }

    &:disabled {
      &:checked {
        + .checkbox-label {
          cursor: unset;

          &:before {
            cursor: unset;
            background-color: $gray-bg-color;
            border: 1px solid $default-border-color;
          }
        }
      }

      &:not(:checked) {
        + .checkbox-label {
          cursor: unset;

          &:before {
            cursor: unset;
            border: 1px solid $default-border-color;
          }
        }
      }
    }
  }
}

.sources-new-label {
  position: relative;
  top: -5px;
}

.user-preference-checkbox {
  font-size: 1.5rem;
  font-family: $secondary-font-stack;

  input[type="checkbox"] {
    position: absolute;
    opacity: 0;

    + .preference-checkbox-label {
      &:before {
        content: "";
        font-size: 1rem;
        text-align: center;
        border-radius: 2px;
        background-size: contain;
        border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
        display: inline-block;
        width: 1.8rem;
        height: 1.8rem;
        position: relative;
        margin-right: 1rem;
        line-height: 1rem;
        vertical-align: top;
        transition: all 250ms ease;
      }
    }

    &:checked {
      + .preference-checkbox-label {
        font-weight: bold;

        &:before {
          background-image: url("~/public/assets/tick.svg");
          background-size: contain;
          background-position: 0 0.2rem;
          background-repeat: no-repeat;
          border: 0.1rem solid $button-link-primary-color;
          background-color: $button-link-primary-color;
        }
      }

      &:hover {
        + .preference-checkbox-label {
          cursor: pointer;
          font-weight: bold;

          &:before {
            background-image: url("~/public/assets/Check_Mark.svg");
            background-size: contain;
            background-position: 0 0.2rem;
            background-repeat: no-repeat;
            color: $button-link-primary-color;
            border: solid 0.1rem $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
    }

    &:not(:checked) {
      + .preference-checkbox-label {
        &:before {
          color: $white;
          border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
        }
      }

      &:hover {
        + .preference-checkbox-label {
          cursor: pointer;
          font-weight: bold;
          &:before {
            content: "";
            color: $button-link-primary-color;
            border: 1px solid $button-link-primary-color;
            background-color: $checkbox-hover-bg-color;
          }
        }
      }
    }

    &:focus {
      + .preference-checkbox-label {
        &:before {
          outline: 0.1rem solid $button-link-primary-color;
          border: 0.1rem solid $rebranding-uploadWizard-disabled-text;
          outline-offset: 2px;
        }
      }
    }

    &:disabled {
      cursor: not-allowed;
      font-weight: normal;

      &:hover {
        + .preference-checkbox-label {
          font-weight: normal;
        }
      }

      + .preference-checkbox-label {
        color: $checkbox-disabled-color;
        font-weight: normal;

        &:before {
          font-weight: normal;
          border: 0.1rem solid $checkbox-disabled-color;
          color: $white;
          background-color: $checkbox-disabled-color;
        }

        &:hover {
          cursor: not-allowed;
          font-weight: normal;
        }
      }
      &:checked {
        + .preference-checkbox-label {
          &:before {
            background-image: url("~/public/assets/tick.svg");
            background-size: contain;
            background-position: 0 0.2rem;
            background-repeat: no-repeat;
            border: 0.1rem solid $checkbox-disabled-color;
            background-color: $checkbox-disabled-color;
          }
        }
      }
      &:not(:checked) {
        font-weight: normal;
        + .preference-checkbox-label {
          &:before {
            content: "";
            border: 0.1rem solid $checkbox-disabled-color;
            background-color: white;
          }
        }
      }
    }
    + .preference-checkbox-label {
      &:empty {
        &:before {
          margin-right: 0;
        }
      }
    }
  }
}

.custom-toggle {
  width: 3.2rem;
  position: relative;
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;

  .custom-toggle-checkbox {
    display: none;

    &:checked {
      + .custom-toggle-label {
        background-color: rgb(13, 71, 161, 0.38);

        &:before {
          border: none;
          color: $button-color-active;
          content: " ";
          font-family: "GVS_Icons", sans-serif !important;
          font-size: 1.5rem;
          right: 0;
          text-align: center;
          background-color: $primary-border-color;
        }
      }
    }
  }

  .custom-toggle-label {
    background-color: rgba(0, 0, 0, 0.38);
    border-radius: 0.7rem;
    cursor: pointer;
    display: block;
    height: 1.4rem;
    overflow: hidden;
    padding: 0;

    &:before {
      background: white;
      border: 0.25px solid $ubo-suggestions-stroke-color;
      box-shadow: 0 0.15rem 0.19rem 0.05rem rgba(0, 0, 0, 0.2);
      border-radius: 50%;
      bottom: 0;
      color: $shadow-color;
      content: " ";
      display: block;
      font-family: "GVS_Icons", sans-serif !important;
      margin: -2px;
      position: absolute;
      text-align: center;
      top: -1px;
      transition: width 0.3s ease-in;
      width: 2rem;
      height: 2rem;
    }
  }

  &.disabled {
    pointer-events: none;
    opacity: 0.7;
  }
}

em {
  font-style: normal;
  font-weight: 700;
}

pre {
  white-space: normal;
}

h1 {
  font-size: 36px;
  font-weight: 400;
  line-height: 50px;
}

h2 {
  font-size: 24px;
  font-weight: 400;
  line-height: 35px;
}

h3 {
  font-size: 21px;
  font-weight: 400;
  line-height: 32px;
}

h4 {
  font-size: 18px;
  font-weight: 400;
  line-height: 29px;
}

.font-body {
  font-size: 15px;
}

.font-secondary-body {
  font-size: 14px;
}

.font-footer {
  font-size: 12px;
}

.font10 {
  font-size: 10px;
}

.validationAlertMessage {
  color: $secondary-warning-color;
  font-size: $font14;
}

.horizontal-divider {
  width: 100%;
  border-style: solid;
  border-top-width: 1px;
  opacity: 0.1;
}

.vertical-divider {
  height: 100%;
  border-style: solid;
  opacity: 0.3;
  width: 0;
  border-left-width: 1px;
}

.application-config-fetch-error {
  text-align: center;
  font-size: 4.2rem;
  margin-top: 200px;
}

.action-link {
  color: $link-color;
  cursor: pointer;

  &:hover {
    color: $link-hover-color;
  }
}
.app-wrapper {
  position: relative;
  min-height: 100vh;
}
.app-wrapper-non-web-print {
  position: relative;
  min-height: 100vh;
}

.app-content-wrapper {
  margin: 0 auto;
  min-width: $break-point-medium;
  min-height: 100vh;
}

.app-content-wrapper,
.root {
  padding-bottom: 7rem;
  padding-top: $header-height;
}

.visibility-hidden {
  visibility: hidden;
}

.visibility-shown {
  visibility: visible;
}

.hidden {
  display: none;
  visibility: hidden;
}

.visible {
  display: block;
  visibility: visible;
}

/**
 * TEXT AREAS - Lexis standard styles
 * Note: Disabled state on all textareas - add class "disabled" anywhere after the default classes
 */

.text-area-standard {
  border: 0.1rem solid $default-border-color-darker;
  border-radius: 3.6px;
  font-family: $secondary-font-stack;
  font-size: 1.4rem;
  line-height: 1.5rem;
  max-height: 100%;
  max-width: 100%;
  padding: 1rem;
  resize: none;

  &::placeholder {
    font-style: italic;
  }

  &:-ms-input-placeholder {
    font-style: italic;
  }

  &:hover {
    border: solid 1px $text-area-hover-border-color;
  }

  &:focus {
    border: solid 1px $text-area-focus-border-color;
    outline: 0;
  }

  &:disabled {
    border: solid 1px $default-border-color;
  }

  &.textarea-error:focus {
    border-color: $secondary-warning-color;
  }
}

.textarea-error,
.textarea-error:focus {
  border-color: $secondary-warning-color;
}

/**
 * TEXT FIELDS - Lexis standard styles
 * Note: Disabled state on all text fields - add class "disabled" anywhere after the default classes
 * Text Field Large: text-field-standard-lg
 * Used in dedicated form pages. (Advanced Search, calculators, etc)
 * Text Field Small: text-field-standard-sm
 * Used in toolbars, dialogs, and compact spaces.
 * Text field with error state: text-field-error
 * Used for showing error state on inputs
 */

.text-field-standard-lg {
  border: 0.1rem solid $default-border-color-darker;
  border-radius: 2px;
  font-family: $secondary-font-stack;
  font-size: 1.4rem;
  line-height: 1.5rem;
  height: 4rem;
  width: 29.1rem;
  padding: 1.3rem 1rem 1.5rem;

  &::placeholder {
    font-style: italic;
  }

  &:-ms-input-placeholder {
    font-style: italic;
  }

  &:hover {
    border: solid 1px $input-focus-color;
  }

  &:focus {
    border: solid 1px $dark-blue;
    outline: 0;
  }

  &:disabled {
    border: solid 1px $default-border-color;
  }
}

.text-field-standard-sm {
  border: 0.1rem solid $default-border-color-darker;
  border-radius: 2px;
  font-family: $secondary-font-stack;
  font-size: 1.4rem;
  line-height: 1.5rem;
  height: 3.2rem;
  width: 26.1rem;
  padding: 1rem 1rem 1.1rem;

  &::placeholder {
    font-style: italic;
  }

  &:-ms-input-placeholder {
    font-style: italic;
  }

  &:hover {
    border: solid 1px $input-focus-color;
  }

  &:focus {
    border: solid 1px $dark-blue;
    outline: 0;
  }

  &:disabled {
    border: solid 1px $default-border-color;
  }
}

.text-field-error,
.text-field-error:focus {
  border-color: $secondary-warning-color;
  outline: none;

  &:hover {
    border-color: $secondary-warning-color !important;
  }
}

/**
 * BUTTONS - Lexis standard styles
 * Note: Disabled state on all buttons - add class "disabled" anywhere after the default classes
 *
 * --Large buttons usage--
 * Button Primary Large:   button-primary-lg
 * Button Secondary Large: button-secondary-lg
 *
 * --Small buttons usage--
 * Button Primary Small:   button-primary-sm
 * Button Secondary Small: button-secondary-sm
 *
 */

.header-link-button {
  border: none;
  background-color: $primary-border-color;
  color: $white;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  width: 12rem;
  height: 4rem;
  margin: 1rem;

  &:hover {
    background-color: $uploadWizard-link-light-color;
    border: 1px solid $primary-border-color;
    cursor: pointer;
  }

  &:active {
  }

  &:disabled,
  &.disabled {
    background-color: $rebranding-uploadWizard-disabled-text;
    color: $black;
    cursor: not-allowed;
  }
}

.link-button {
  background: none;
  border: none;
  outline: none;
  text-align: left;
  color: $button-link-primary-color;
  cursor: pointer;
  height: auto;

  &:hover {
    color: $primary-color-hover;
  }

  &:active {
  }

  &:disabled,
  &.disabled {
    background-color: $rebranding-uploadWizard-disabled-text;
    color: $black;
    cursor: not-allowed;
  }
}

.button-green-lg {
  width: 111px;
  height: 38px;
  background-color: $button-green-active;
  border: 1px solid $button-green-hover;
  border-radius: 2px;
  color: $white;
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: bold;
  margin: 0 0.2rem;
  vertical-align: middle;

  &:hover {
    background-color: $button-green-hover;
    border: 1px solid $button-green-hover;
  }
  &:active {
    background-color: $button-green-active;
    border: 1px solid $button-green-active;
    color: $white;
  }
  &:disabled,
  &.disabled {
    background-color: $button-color-disabled;
    border: 0.1rem solid $button-color-disabled;
    color: $white;
    cursor: default;
  }
}

.button-primary-lg {
  padding: $button-padding;
  width: auto;
  height: $button-default-height;
  border-radius: 0.5rem;
  background-color: $button-color-click;
  border: solid 0.3rem $button-color-click;
  font-size: 1.5rem;
  font-weight: bold;
  color: $primary-button-text-color;
  display: inline-block;
  cursor: pointer;
  outline: none;

  span {
    font-size: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: $primary-button-text-color;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem $button-focus-border;
  }

  &:hover:not(:disabled):not(.disabled) {
    box-shadow: 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    border: solid 0.3rem $button-color-active;
    background-color: $button-color-active;
  }

  &:disabled,
  &.disabled {
    background-color: $button-color-disabled;
    border: solid 0.3rem $button-color-disabled;
    cursor: not-allowed;
  }
}

.button-primary-sm {
  padding: $button-padding;
  width: auto;
  height: $button-default-height;
  border-radius: 0.5rem;
  border: 0.1rem solid $button-color-click;
  background-color: $button-color-click;
  color: $white;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  outline: none;

  span {
    font-size: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: $white;
  }

  &.loading {
    min-width: 8.5rem;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem $button-focus-border;
    outline: none;
  }

  &:hover:not(:disabled):not(.disabled) {
    box-shadow: 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    background-color: $button-color-active;
    border: 0.1rem solid $button-color-active;
  }

  &:disabled,
  &.disabled {
    background-color: $button-color-disabled;
    border: 0.1rem solid $button-color-disabled;
    cursor: not-allowed;
  }
}

.button-secondary-lg {
  padding: $button-padding;
  width: auto;
  height: $button-default-height;
  border-radius: 0.5rem;
  border: solid 0.2rem $button-color-click;
  background-color: $white;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  outline: none;

  span {
    width: 69px;
    height: 11px;
    font-size: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: $button-color-active;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem $button-focus-border;
    outline: none;
  }

  &:hover:not(:disabled):not(.disabled) {
    box-shadow: 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    color: $button-color-active;
    border: 0.2rem solid $button-color-active;
  }

  &:disabled,
  &.disabled {
    background-color: $default-bg-color;
    border: 0.1rem solid $button-color-disabled;
    color: $button-color-disabled;
    cursor: not-allowed;

    span {
      color: $button-color-disabled;
    }
  }
}

.button-secondary-sm {
  padding: $button-padding;
  width: auto;
  height: $button-default-height;
  border-radius: 0.5rem;
  border: solid 0.2rem $button-color-click;
  background-color: $white;
  color: $button-color-click;
  font-size: 1.5rem;
  font-weight: bold;
  display: inline-block;
  cursor: pointer;
  outline: none;

  span {
    width: 6.9rem;
    height: 1.1rem;
    font-size: 1.5rem;
    font-style: normal;
    font-stretch: normal;
    font-weight: bold;
    line-height: normal;
    letter-spacing: 0.2px;
    text-align: left;
    color: $white;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem $button-focus-border;
    outline: none;
  }

  &:hover:not(:disabled):not(.disabled) {
    box-shadow: 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    color: $button-color-active;
    border: 0.2rem solid $button-color-active;
  }

  &:disabled,
  &.disabled {
    background-color: $default-bg-color;
    border: 0.1rem solid $button-color-disabled;
    color: $button-color-disabled;
    cursor: not-allowed;
  }

  span {
    color: $button-color-disabled;
  }
}

.add-to-report-button {
  border: 0.1rem solid $button-color-active;
  color: $button-color-active;
  width: 16.8rem;
  height: $button-default-height;
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  background-color: $white;
  display: inline;
  float: right;
  outline: none;

  .add-to-report-content {
    display: flex;
    flex-direction: row;

    .la-ZoomIn {
      font-size: 2.5rem;
      color: $button-color-active;
      padding: 0 1rem 0;
    }

    .add-to-report-name {
      line-height: 2.3rem;
    }
  }

  &.add-to-report-button-anim {
    background-color: $white;
  }

  &:hover {
    box-shadow: 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    color: $button-color-active;
    border: 0.1rem solid $button-color-active;
  }

  &:focus {
    box-shadow: 0 0 0 0.3rem $button-focus-border;
    outline: none;
  }

  &:disabled {
    cursor: initial;
    border-radius: 0.5rem;
    border: solid 0.2rem $default-border-color;
    color: $default-border-color;
  }
}

.delete-report-button {
  width: 16.8rem;
  height: $button-default-height;
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  background-color: $white;
  display: inline-block;
  float: right;
  outline: none;
  box-shadow: 0 0 0 0.3px #132a43, 0 0 0 3px #29b6f6;

  &:hover {
    color: $delete-article-hover-border-color;
    border: 1px solid $delete-article-hover-border-color;
    cursor: pointer;
  }

  &:active {
    border: 1px solid $secondary-border-color;
    color: $secondary-border-color;
  }

  &:focus {
    border: 1px solid $delete-article-focus-border-color;
    color: $delete-article-focus-border-color;
    opacity: 1.2;
    outline: none;
  }

  &:disabled {
    cursor: initial;
    opacity: 0.4;
  }
}

.circle-animation {
  border: 0.1rem solid $button-color-active;
  border-radius: 50%;
  width: 3rem;
  height: 3rem;
  color: $button-color-active;
  font-size: 1.4rem;
  white-space: nowrap;
  background-color: $button-color-active;
  display: inline-block;
  float: right;
  outline: none;
  -webkit-transform: translateX(3.15rem);
  -moz-transform: translateX(3.15rem);
  -ms-transform: translateX(3.15rem);
  -o-transform: translateX(3.15rem);
  transform: translateX(3.15rem);
}

.link-button {
  background: none;
  border: none;
  color: $link-color-dark;
  cursor: pointer;
  font-family: $secondary-font-stack;
  outline: none;

  &:hover {
    color: $dark-blue;
  }

  &:active {
    color: $dark-blue;
  }
}

/**
 * Required filed red star
 */
.required-field-star {
  &:after {
    color: $secondary-color;
    content: "*";
  }
}

/**
 * Nexis Icons
 */

[class^="la-"],
[class*=" la-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: "GVS_Icons", sans-serif !important;
  font-style: normal;
  color: #6d6e71;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.la-DeliveryComplete:before {
  content: "\e987";
}
.la-SortBy:before {
  content: "\e986";
}
.la-AddToReport:before {
  content: "\e984";
}
.la-LanguageSelector:before {
  content: "\e985";
}
.la-SWAnalyticsSuite:before {
  content: "\e983";
}
.la-SWStateNet:before {
  content: "\e982";
}
.la-ListRavel:before {
  content: "\e981";
}
.la-Biblio:before {
  content: "\e980";
}
.la-Focus:before {
  content: "\e97d";
}
.la-Judge:before {
  content: "\e97e";
}
.la-Opposition:before {
  content: "\e97f";
}
.la-Empty:before {
  content: "\e97c";
}
.la-FilterToggle:before {
  content: "\e97b";
}
.la-SWUIBuilder:before {
  content: "\e979";
}
.la-Sleep:before {
  content: "\e97a";
}
.la-ProductSelect:before {
  content: "\e978";
}
.la-SWCounselBenchmarking:before {
  content: "\e962";
}
.la-SWCourtlink:before {
  content: "\e963";
}
.la-SWDossier:before {
  content: "\e964";
}
.la-SWGetPrint:before {
  content: "\e965";
}
.la-SWICW:before {
  content: "\e966";
}
.la-SWIntelligize:before {
  content: "\e967";
}
.la-SWLaw360:before {
  content: "\e968";
}
.la-SWLawSchool:before {
  content: "\e969";
}
.la-SWLexisCom:before {
  content: "\e96a";
}
.la-SWLexMachina:before {
  content: "\e96b";
}
.la-SWLPA:before {
  content: "\e96c";
}
.la-SWMedMal:before {
  content: "\e96d";
}
.la-SWProfileSuite:before {
  content: "\e96e";
}
.la-SWPublicRecords:before {
  content: "\e96f";
}
.la-SWPublisher:before {
  content: "\e970";
}
.la-SWResearch:before {
  content: "\e971";
}
.la-SWSecuritiesMosaic:before {
  content: "\e972";
}
.la-SWShepBriefcheck:before {
  content: "\e973";
}
.la-SWShepBrieflink:before {
  content: "\e974";
}
.la-SWTax:before {
  content: "\e975";
}
.la-SWVSA:before {
  content: "\e976";
}
.la-Amendments:before {
  content: "\e977";
}
.la-Powerpoint:before {
  content: "\e961";
  color: #e64a19;
}
.la-LeaveZone:before {
  content: "\e960";
}
.la-TopicDigest:before {
  content: "\e95f";
}
.la-Exchange:before {
  content: "\e95e";
}
.la-CollapseTree:before {
  content: "\e959";
}
.la-ExpandTree:before {
  content: "\e95b";
}
.la-IndexContent:before {
  content: "\e903";
}
.la-NewAmended:before {
  content: "\e957";
}
.la-AddItemToGroup:before {
  content: "\e956";
}
.la-AddPublicationAbove:before {
  content: "\e95a";
}
.la-AddPublicationBelow:before {
  content: "\e95c";
}
.la-DragDrop:before {
  content: "\e95d";
}
.la-References:before {
  content: "\e955";
}
.la-ShareFork:before {
  content: "\e954";
}
.la-AdvanceSearch:before {
  content: "\e953";
}
.la-NavigateTerms:before {
  content: "\e952";
}
.la-QandA:before {
  content: "\e951";
}
.la-Video:before {
  content: "\e950";
}
.la-ClauseBank:before {
  content: "\e94f";
}
.la-GoogleDrive:before {
  content: "\e94c";
}
.la-Home:before {
  content: "\e94d";
}
.la-Share:before {
  content: "\e94e";
}
.la-LiveChat:before {
  content: "\e94b";
}
.la-SharePage:before {
  content: "\e94a";
}
.la-DraftingChecklists:before {
  content: "\e949";
}
.la-AddDashboard:before {
  content: "\e944";
}
.la-Draft:before {
  content: "\e93b";
}
.la-Redo:before {
  content: "\e93c";
}
.la-Undo:before {
  content: "\e93d";
}
.la-DeliveryOptions:before {
  content: "\e948";
}
.la-Index:before {
  content: "\e947";
}
.la-SocialFacebook:before {
  content: "\e938";
}
.la-SocialFlickr:before {
  content: "\e939";
}
.la-SocialGooglePlus:before {
  content: "\e93a";
}
.la-SocialInstagram:before {
  content: "\e93e";
}
.la-SocialLinkedIn:before {
  content: "\e93f";
}
.la-SocialPinterest:before {
  content: "\e940";
}
.la-SocialRSS:before {
  content: "\e941";
}
.la-SocialTumblr:before {
  content: "\e942";
}
.la-SocialTwitter:before {
  content: "\e943";
}
.la-SocialYouTube:before {
  content: "\e945";
}
.la-UnattachedCitation:before {
  content: "\e946";
}
.la-LexMachinaStats:before {
  content: "\e937";
  color: #77b800;
}
.la-ItemAdd:before {
  content: "\e935";
}
.la-ItemRemove:before {
  content: "\e936";
}
.la-DraftingNote:before {
  content: "\e933";
}
.la-InternationalContent:before {
  content: "\e934";
}
.la-MaximizeTop:before {
  content: "\e931";
}
.la-MinimizeRight:before {
  content: "\e932";
}
.la-Format:before {
  content: "\e930";
  color: #939598;
}
.la-LexLibraryPracticeArea:before {
  content: "\e92f";
}
.la-RefWork:before {
  content: "\e92e";
}
.la-Menu:before {
  content: "\e92d";
}
.la-Resources:before {
  content: "\e92c";
}
.la-ShepNeutral:before {
  content: "\e925";
  color: #8f2b8c;
}
.la-ChickletPrintBlue:before {
  content: "\e926";
  color: #003399;
}
.la-ChickletPrintGreen:before {
  content: "\e927";
  color: #0b9c00;
}
.la-ChickletPrintLightBlue:before {
  content: "\e928";
  color: #009ddb;
}
.la-ChickletPrintOrange:before {
  content: "\e929";
  color: #e98310;
}
.la-ChickletPrintRed:before {
  content: "\e92a";
  color: #af002b;
}
.la-ChickletPrintYellow:before {
  content: "\e92b";
  color: #ffcc00;
}
.la-ThreeColumn:before {
  content: "\e923";
}
.la-TwoColumn:before {
  content: "\e924";
}
.la-ExploreContent:before {
  content: "\e922";
}
.la-DuplicateCitations:before {
  content: "\e921";
  color: #77b800;
}
.la-ToolsResources:before {
  content: "\e920";
}
.la-ArrowDown:before {
  content: "\e91f";
}
.la-Analysis:before {
  content: "\e905";
}
.la-AtGlance:before {
  content: "\e906";
}
.la-Books:before {
  content: "\e907";
}
.la-Bulletins:before {
  content: "\e908";
}
.la-Calculators:before {
  content: "\e909";
}
.la-CasesReportsPD:before {
  content: "\e90a";
}
.la-ChecklistProcessFlows:before {
  content: "\e90b";
}
.la-Clauses:before {
  content: "\e90c";
}
.la-Commentary:before {
  content: "\e90d";
}
.la-DraftingMaterials:before {
  content: "\e90e";
}
.la-Forms:before {
  content: "\e90f";
}
.la-InHouseToolKit:before {
  content: "\e910";
}
.la-Journals:before {
  content: "\e911";
}
.la-JournalSummaries:before {
  content: "\e912";
}
.la-LegalAnalysis:before {
  content: "\e913";
}
.la-LegalNews:before {
  content: "\e914";
}
.la-LegislationPD:before {
  content: "\e915";
}
.la-PracticalGudiance:before {
  content: "\e916";
}
.la-PracticeNotes:before {
  content: "\e917";
}
.la-Precedents:before {
  content: "\e918";
}
.la-QA:before {
  content: "\e919";
}
.la-Quantum:before {
  content: "\e91a";
}
.la-Regulatory:before {
  content: "\e91b";
}
.la-StartingPoint:before {
  content: "\e91c";
}
.la-Tools:before {
  content: "\e91d";
}
.la-Trackers:before {
  content: "\e91e";
}
.la-ListGraphical:before {
  content: "\e904";
}
.la-PracticeCenter:before {
  content: "\e958";
}
.la-ChevronLeft:before {
  content: "\e900";
}
.la-ChevronRight:before {
  content: "\e901";
}
.la-Help:before {
  content: "\e902";
}
.la-UIVariation:before {
  content: "\e685";
}
.la-Support:before {
  content: "\e683";
}
.la-Profile:before {
  content: "\e684";
}
.la-InfoHover:before {
  content: "\e682";
}
.la-InfoFilled:before {
  content: url("~/public/assets/InfoFilled.svg");
}
.la-Dropbox:before {
  content: "\e681";
}
.la-HidePane:before {
  content: "\e67f";
}
.la-ShowPane:before {
  content: "\e680";
  color: #0077cc;
}
.la-SupplementalContent:before {
  content: "\e67e";
}
.la-CopyAdvanced:before {
  content: "\e67b";
}
.la-RecycleBin:before {
  content: "\e67c";
}
.la-Restore:before {
  content: "\e67d";
}
.la-NotApplicable:before {
  content: "\e67a";
}
.la-TransactionalRollup:before {
  content: "\e677";
  color: #77b800;
}
.la-ShowLess:before {
  content: "\e678";
  color: #0077cc;
}
.la-ShowMore:before {
  content: "\e679";
  color: #0077cc;
}
.la-leg-citator-available:before {
  content: "\e672";
  color: #009ddb;
}
.la-DecreaseFont:before {
  content: "\e673";
}
.la-IncreaseFont:before {
  content: "\e674";
}
.la-ListFull:before {
  content: "\e675";
}
.la-ListTitleView:before {
  content: "\e676";
}
.la-QuickCite:before {
  content: "\e671";
  color: #ed1c24;
}
.la-CaseBase:before {
  content: "\e670";
  color: #009ddb;
}
.la-DraftNow:before {
  content: "\e66f";
  color: #0076a4;
}
.la-Permalink:before {
  content: "\e66e";
}
.la-ICWDownArrow:before {
  content: "\e66d";
}
.la-MacroDoc:before {
  content: "\e66c";
}
.la-SaveUpdates:before {
  content: "\e66a";
}
.la-SaveToNewFolder:before {
  content: "\e66b";
}
.la-Manage:before {
  content: "\e669";
}
.la-MarkAll:before {
  content: "\e667";
}
.la-MarkSelected:before {
  content: "\e668";
}
.la-Correct:before {
  content: "\e664";
  color: #77b800;
}
.la-ICWArrow:before {
  content: "\e665";
}
.la-Incorrect:before {
  content: "\e666";
  color: #ed1c24;
}
.la-Check:before {
  content: "\e663";
}
.la-RemoveClause:before {
  content: "\e661";
  color: #ed1c24;
}
.la-Excel:before {
  content: "\e662";
  color: #1f7244;
}
.la-ExpandPane:before {
  content: "\e62c";
}
.la-CollapsePane:before {
  content: "\e658";
}
.la-More:before {
  content: "\e659";
}
.la-Play:before {
  content: "\e65a";
}
.la-Pause:before {
  content: "\e65b";
}
.la-Error:before {
  content: "\e65c";
  color: #ed1c24;
}
.la-Delete:before {
  content: "\e65d";
}
.la-UpdateView:before {
  content: url("~/public/assets/UpdateView.svg");
}
.la-WarningIcon:before {
  content: url("~/public/assets/Error.svg");
}
.la-Warning:before {
  content: "\e65e";
  color: #ed1c24;
}
.la-Word:before {
  content: "\e65f";
  color: #295497;
}
.la-SaveBadge:before {
  content: "\e660";
  color: #4f7b00;
}
.la-Clause:before {
  content: "\e623";
}
.la-ZoomOut:before {
  content: "\e600";
}
.la-ZoomIn:before {
  content: "\e601";
}
.la-VSA:before {
  content: "\e602";
}
.la-UpdateFailed:before {
  content: "\e603";
  color: #ed1c24;
}
.la-Update:before {
  content: "\e604";
}
.la-Unsubscribe:before {
  content: "\e605";
}
.la-UnspecifiedSource:before {
  content: "\e606";
}
.la-TriangleUp:before {
  content: "\e607";
}
.la-TriangleRight:before {
  content: "\e608";
}
.la-TriangleLeft:before {
  content: "\e609";
}
.la-TriangleDown:before {
  content: "\e60a";
}
.la-Topics:before {
  content: "\e60b";
}
.la-TableOfContents:before {
  content: "\e60c";
}
.la-Sources:before {
  content: "\e60d";
}
.la-ShepWarningStat:before {
  content: "\e60e";
  color: #af002b;
}
.la-ShepWarning:before {
  content: "\e60f";
  color: #af002b;
}
.la-ShepTrailHighlight:before {
  content: "\e610";
}
.la-ShepSignalError:before {
  content: "\e611";
  color: #8f2b8c;
}
.la-ShepQuestioned:before {
  content: "\e612";
  color: #e98310;
}
.la-ShepPositive:before {
  content: "\e613";
  color: #0b9c00;
}
.la-ShepInfo:before {
  content: "\e614";
  color: #003399;
}
.la-ShepCaution:before {
  content: "\e615";
  color: #ffcc00;
}
.la-Shepards:before {
  content: "\e616";
  color: #af002b;
}
.la-ShepardizedCite:before {
  content: "\e617";
}
.la-ShepAnalysis:before {
  content: "\e618";
  color: #003399;
}
.la-SharedByOthers:before {
  content: "\e619";
}
.la-SharedByMe:before {
  content: "\e61a";
}
.la-Search:before {
  content: "\e61b";
}
.la-SavedToFolder:before {
  content: "\e61c";
}
.la-ResearchMap:before {
  content: "\e61d";
}
.la-RecentlyViewed:before {
  content: "\e61e";
}
.la-ProfileSuite:before {
  content: "\e61f";
}
.la-ProductSelectChevron:before {
  content: "\e620";
}
.la-PrintablePage:before {
  content: "\e621";
}
.la-Print:before {
  content: "\e622";
}
.la-PDF:before {
  content: "\e624";
  color: #ed1c24;
}
.la-Options:before {
  content: "\e625";
}
.la-Notifications:before {
  content: "\e626";
}
.la-Notes:before {
  content: "\e627";
}
.la-NewUpdates:before {
  content: "\e628";
  color: #009ddb;
}
.la-News:before {
  content: "\e629";
}
.la-NativeView:before {
  content: "\e62a";
}
.la-Move:before {
  content: "\e62b";
}
.la-MedMal:before {
  content: "\e62d";
}
.la-MarginNote:before {
  content: "\e62e";
}
.la-LPAAndLNPG:before {
  content: "\e62f";
}
.la-LockedNote:before {
  content: "\e630";
}
.la-ListView:before {
  content: "\e631";
}
.la-LegislativeAlerts:before {
  content: "\e632";
}
.la-LegalTopicSummary:before {
  content: "\e633";
  color: #e98310;
}
.la-LegalIssueTrail:before {
  content: "\e634";
}
.la-LastColumn:before {
  content: "\e635";
}
.la-JumpUp:before {
  content: "\e636";
}
.la-JumpToTop:before {
  content: "\e637";
}
.la-JumpDown:before {
  content: "\e638";
}
.la-Info:before {
  content: "\e639";
}
.la-IncludeLegalPhraseEq:before {
  content: "\e63a";
}
.la-History:before {
  content: "\e63b";
}
.la-Highlight:before {
  content: "\e63c";
}
.la-FullScreen:before {
  content: "\e63d";
}
.la-Folder:before {
  content: "\e63e";
}
.la-FirstColumn:before {
  content: "\e63f";
}
.la-Filter:before {
  content: "\e640";
}
.la-FavoriteFull:before {
  content: "\e641";
  color: #ed9838;
}
.la-FavoriteEmpty:before {
  content: "\e642";
}
.la-ExternalLink:before {
  content: "\e643";
}
.la-Explore:before {
  content: "\e644";
}
.la-ExcludeLegalPhraseEq:before {
  content: "\e645";
}
.la-EntitySearch:before {
  content: "\e646";
}
.la-Email:before {
  content: "\e647";
}
.la-Edit:before {
  content: "\e648";
}
.la-Download:before {
  content: "\e649";
}
.la-DocumentList:before {
  content: "\e64a";
}
.la-Document:before {
  content: "\e64b";
}
.la-CopyToFolder:before {
  content: "\e64c";
}
.la-Copy:before {
  content: "\e64d";
}
.la-ContentType:before {
  content: "\e64e";
}
.la-CloseRemove:before {
  content: "\e64f";
}
.la-ClearSearch:before {
  content: "\e64f";
}
.la-Calendar:before {
  content: "\e650";
}
.la-ArrowUp:before {
  content: "\e651";
}
.la-ArrowLeft:before {
  content: "\e652";
}
.la-Annotation:before {
  content: "\e653";
}
.la-Alerts:before {
  content: "\e654";
}
.la-AddToSearch:before {
  content: "\e655";
}
.la-AccessLater:before {
  content: "\e656";
}
.la-AccessNow:before {
  content: "\e657";
}

.la-CopyBatch:before {
  content: "\e97c";
}

.la-AlertIcon:before {
  content: "\e988";
  color: #e43935;
}

.la-DialogBubble:before {
  content: "\e989";
}

.la-AddComment:before {
  content: url("~/public/assets/AddComment.svg");
}

.la-CommentsList:before {
  content: url("~/public/assets/CommentsList.svg");
}

.la-AddCommentInactive:before {
  content: url("~/public/assets/AddCommentInactive.svg");
}

.la-CommentsListInactive:before {
  content: url("~/public/assets/CommentsListInactive.svg");
}

.la-Comment-submit-button:before {
  content: url("~/public/assets/submitArrow.svg");
} 

.la-Comment-submit-button_disabled:before {
  content: url("~/public/assets/submitArrow.svg");
  filter: invert(70%);
} 
.la-WarningYellow:before {
  content: url("~/public/assets/WarningYellow.svg");
}

.la-ShareView:before {
  content: url("~/public/assets/ShareIcon.svg");
}

.la-Columns:before {
  content: url("~/public/assets/ColumnsIcon.svg")
}

.icon-double-chevron-left {
  i:last-child {
    margin-left: -2.3rem;
  }
}
.icon-double-chevron-right {
  i:first-child {
    margin-right: -2.3rem;
  }
}

.modal-container {
  padding-top: 2px;
  position: relative;
}

.bold {
  font-weight: bolder;
}

// Tooltips across all pages
.__react_component_tooltip.type-dark,
.tooltips.type-light {
  border-radius: 0.5rem;
  background-color: $tooltip-background-color;
  color: $white;
  font-size: $font14;
  font-family: $secondary-font-stack;
  line-height: 1.2;
  padding: 1rem 1.5rem;

  &.ubo-no-data-tooltip {
    left: 4rem;
    max-width: 30rem;
    &:before {
      left: 2rem;
    } 
    &:after {
      left: 2rem;
    }
  }

  &,
  &.border {
    border: 0;
  }

  &.small {
    max-width: 18rem;
    white-space: break-spaces;
  }

  &.border.place-bottom,
  &.place-bottom {
    &:after,
    &:before {
      border-bottom-color: $tooltip-background-color;
    }

    @-moz-document url-prefix() {
      &:before {
        border-bottom-width: 1rem !important;
      }
    }

    &:before {
      top: -7px;
    }
  }

  &.border.place-left,
  &.place-left {
    &:after,
    &:before {
      border-left-color: $tooltip-background-color;
    }

    @-moz-document url-prefix() {
      &:before {
        border-left-width: 1rem !important;
      }
    }

    &:before {
      right: -7px;
    }
  }

  &.border.place-right,
  &.place-right {
    &:after,
    &:before {
      border-right-color: $tooltip-background-color;
    }

    @-moz-document url-prefix() {
      &:before {
        border-right-width: 1rem !important;
      }
    }

    &:before {
      left: -7px;
    }
  }

  &.border.place-top,
  &.place-top {
    &:after,
    &:before {
      border-top-color: $tooltip-background-color;
    }
    @-moz-document url-prefix() {
      &:before {
        border-top-width: 1rem !important;
      }
    }

    &:before {
      bottom: -7px;
    }
  }

  &.entity-name-tooltip {
    max-width: 40rem;
  }
}

/**
 * Banner type messages default styling
 * Note: This is the old cookie banner styling that was extended by the rest of the banners in the application (e.g. Role change banner, user preferences update banner)
 */

.diligence-banner-message {
  @include alert-message($light-popup-message-bg-color, $info-banner-color);
  position: relative;

  &__icon {
      color: $info-banner-color;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
  }

  &__message {
      border-left: 0.1rem solid $info-banner-color;
      padding-left: 1.5rem;
  }

  &__hyperlink {
    color: $link-secondary-color;
    text-decoration: none;

    &:hover {
      text-decoration: underline;
    }
  }

  &__button {
    background: transparent;
    border: none;
    cursor: pointer;

    &:hover {
      color: $popup-dismiss-button-hover;
    }

    &:active {
      color: $popup-dismiss-button-hover;
    }
  }

  &.dark-mode {
      background: $popup-bg-info-dark;
      color: $popup-text-color-dark;
      border-bottom: 0.1rem solid $popup-border-color-info-dark;

      &__message {
        border-left: 0.1rem solid $popup-border-color-info-dark;
    }
  }
}

.draggable-icon {
  background: url("~/public/assets/DragAndDrop.svg");
  width: 1.5rem;
  height: 1.5rem;
  min-width: 1.5rem;
}

.category-overlay {
  background-color: $transparent50;
  bottom: 0;
  height: 100%;
  padding-right: 4rem;
  position: absolute;
  width: 100%;
  z-index: $z-overlay;

  .ui.massive.loader {
    align-items: center;
  }
}

.add-to-multiple-entities-btn {
  display: flex;
  align-items: center;

  .la-ZoomIn {
    font-size: 2rem;
    padding-right: .8rem;
    position: relative;
    vertical-align: middle;
    font-weight: bold;
  }
}

.save-search {
  display: flex;
  align-items: center;

  .la-SaveUpdates {
    font-size: 2rem;
    padding-right: .8rem;
    position: relative;
    vertical-align: middle;
    font-weight: normal;
  }

  .dots-circle-spinner {
    display: none;
    height: 2rem;
    width: 2rem;
    line-height: 1;
    align-items: center;
    border-radius: 1em;
    transition: all 150ms linear 0s;
    transform: scale(0);
    opacity: 0;
    box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    margin-right: .8rem;

    &.loading {
      display: flex;
      transform: scale(0.25);
      opacity: 1;
      -webkit-animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
      animation: 1.5s linear 150ms normal infinite forwards running dots-circle-rotation;
    }
  }

  @-webkit-keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em, -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em, 1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em, -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em, 1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    }
  }

  @keyframes dots-circle-rotation {
    100% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    }
    87.5% {
      box-shadow: 2em 0em 0 -0.4375em, 1.41421356em 1.41421356em 0 -0.375em, 0em 2em 0 -0.3125em, -1.41421356em 1.41421356em 0 -0.25em, -2em 0em 0 -0.1875em, -1.41421356em -1.41421356em 0 -0.125em, 0em -2em 0 -0.0625em, 1.41421356em -1.41421356em 0 0em;
    }
    75% {
      box-shadow: 2em 0em 0 -0.375em, 1.41421356em 1.41421356em 0 -0.3125em, 0em 2em 0 -0.25em, -1.41421356em 1.41421356em 0 -0.1875em, -2em 0em 0 -0.125em, -1.41421356em -1.41421356em 0 -0.0625em, 0em -2em 0 0em, 1.41421356em -1.41421356em 0 -0.4375em;
    }
    62.5% {
      box-shadow: 2em 0em 0 -0.3125em, 1.41421356em 1.41421356em 0 -0.25em, 0em 2em 0 -0.1875em, -1.41421356em 1.41421356em 0 -0.125em, -2em 0em 0 -0.0625em, -1.41421356em -1.41421356em 0 0em, 0em -2em 0 -0.4375em, 1.41421356em -1.41421356em 0 -0.375em;
    }
    50% {
      box-shadow: 2em 0em 0 -0.25em, 1.41421356em 1.41421356em 0 -0.1875em, 0em 2em 0 -0.125em, -1.41421356em 1.41421356em 0 -0.0625em, -2em 0em 0 0em, -1.41421356em -1.41421356em 0 -0.4375em, 0em -2em 0 -0.375em, 1.41421356em -1.41421356em 0 -0.3125em;
    }
    37.5% {
      box-shadow: 2em 0em 0 -0.1875em, 1.41421356em 1.41421356em 0 -0.125em, 0em 2em 0 -0.0625em, -1.41421356em 1.41421356em 0 0em, -2em 0em 0 -0.4375em, -1.41421356em -1.41421356em 0 -0.375em, 0em -2em 0 -0.3125em, 1.41421356em -1.41421356em 0 -0.25em;
    }
    25% {
      box-shadow: 2em 0em 0 -0.125em, 1.41421356em 1.41421356em 0 -0.0625em, 0em 2em 0 0em, -1.41421356em 1.41421356em 0 -0.4375em, -2em 0em 0 -0.375em, -1.41421356em -1.41421356em 0 -0.3125em, 0em -2em 0 -0.25em, 1.41421356em -1.41421356em 0 -0.1875em;
    }
    12.5% {
      box-shadow: 2em 0em 0 -0.0625em, 1.41421356em 1.41421356em 0 0em, 0em 2em 0 -0.4375em, -1.41421356em 1.41421356em 0 -0.375em, -2em 0em 0 -0.3125em, -1.41421356em -1.41421356em 0 -0.25em, 0em -2em 0 -0.1875em, 1.41421356em -1.41421356em 0 -0.125em;
    }
    0% {
      box-shadow: 2em 0em 0 0em, 1.41421356em 1.41421356em 0 -0.4375em, 0em 2em 0 -0.375em, -1.41421356em 1.41421356em 0 -0.3125em, -2em 0em 0 -0.25em, -1.41421356em -1.41421356em 0 -0.1875em, 0em -2em 0 -0.125em, 1.41421356em -1.41421356em 0 -0.0625em;
    }
  }
}

.sanctions-pod-sort-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 1rem;

  &:hover {
    cursor: pointer;
  }
}

.sanctions-pod-header-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 1.5rem;
    padding-left: 0.3rem;
 }

.sanctions-pod-count-container {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
}

.sanctions-pod-count {
  font-weight: 600;
  font-family: Lato-Light, Lato, sans-serif;
}

.risk-score-container {
  display: flex;
  align-items: center;
  gap: 1rem;
  width: 100%;
  height: 100%;
  padding-left: 2rem;
  padding-bottom: 1rem;

  .risk-score {
    text-transform: capitalize;
    font-size: medium;
    font-weight: bold;
  }

  .doughnut {
      border-radius: 1.4rem;
      height: 1.4rem;
      width: 1.4rem;

      &.low {
          border: 0.2rem solid $negativity-level-pill-low;
      }

      &.medium {
          border: 0.2rem solid $negativity-level-pill-medium;
      }

      &.high {
          border: 0.2rem solid $negativity-level-pill-high;
      }

  }

  &:hover:not(.non-clickable) {
      cursor: pointer;
  }

}
