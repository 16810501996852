.single-article-view {
  padding-bottom: 7rem;
  background-color: $gray-bg-color-darker-document;

  .__react_component_tooltip {
    &.type-light,
    &.type-dark {
      opacity: 1;
    }
  }

  #Dil_DocumentTitle, #Dil_DocumentSource, #Dil_PublicationDate {
    display: none;
  }

  .tooltips {
     opacity: 1;
  }

  .single-article-result {
    font-family: $secondary-font-stack;

    &__general {
      display: flex;
      padding: 1.6rem 3rem 1.6rem;
      font-size: 1.5rem;
      line-height: 2.8rem;
      background-color: $gray-bg-color-darker-document;

      .single-article-content {
        border-radius: 1rem;
        box-shadow: .8rem .8rem .8rem #00000008;
        border: .1rem solid $document-view-content-border-color;
        width: 70%;
        background: $white;
        padding: 5rem 4rem 5rem;
        word-break: break-word;

        &__logo{
          display: block;
          margin-left: auto;
          margin-right: auto;
          max-width: 50%;
        }

        &.bov-document-body {
          padding: 3rem 4rem 5rem;
          min-height: 50rem;

          .box-error-message {
            margin-left: 0;
          }

          &.error {
            width: 100%;
            padding: 4rem;

            & ~ div {
              display: none;
            }

            .box-error-message{
              position: relative;
              width: 100%;
              margin: 0;

              &:before {
                @include popup-message-vertical-strip($popup-system-error-color)
              }
            }

            .tabs-view-article-title {
              display: none;
            }
          }

          .tabs-view-article-title {
            margin: 0 !important;
            padding: 0 0 0;
            text-align: left !important;
            font-size: 21px;
            font-weight: bold;
          }

          .article-view-disclaimer{
            position: unset;
            margin-top: 1rem;
          }
        }

        .single-article-date {
          margin: 2rem 0 1.4rem .5rem;

          &-formatted {
            margin: 1.4rem 0 0;
          }
        }

        .single-article-title {
          font-size: $font32;
          line-height: 1.2;
          font-weight: bold;
          margin-top: 2rem;
          text-align: center;
        }

        .single-article-content-logo {
          text-align: left;
          margin: 1rem 0 2rem;
          max-width: 100%;
          max-height: 80px;
        }

        .single-article-subTitle {
          text-align: center;
          font-weight: bold;
          color: $esg-ratings-subtitle-color;
          line-height: 1.2;
          margin-top: 1.2rem;
        }

        .loader {
          margin-top: 5rem;
        }

        .single-article-summary {
          line-height: 2.8rem;
          word-break: break-word;
          margin-top: 5rem;
          font-size: 1.4rem;

          a {
            color: $button-link-primary-color;
          }

          body {
            background-color: $white; //IE11 applies the app body color on the article body tag
          }

          h3 {
            font-size: $font24;
            line-height: 2.8rem;
            font-weight: bold;
            padding-top: 5.7rem;
            border-bottom: .4rem solid $default-text-color;
            margin-bottom: 2.7rem;
          }

          .SS_DataTable {
            margin-bottom: 3.5rem;

            td,
            th {
              padding: .3rem 0;
            }
          }

          .SS_DownloadTable {
            font-weight: bold;
            color: $default-text-color;
            padding: .7rem 2rem;
            background-color: $white;
            border-radius: .5rem;
            border: solid .2rem $button-color-click;

            &.disabled {
              cursor: initial;
              opacity: 0.5;
              pointer-events: none;
            }
          }
        }
      }
    }
  }
}