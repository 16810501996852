.comments-section-wrapper {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: auto;

    .comments-list-wrapper {
        display: flex;
        flex-direction: column;
        padding-top: 3rem;
        background-color: $white;

        &__header {
            display: flex;
            justify-content: space-between;
            margin-bottom: 2.5rem;
            padding: 0 1.7rem 0 3.4rem;

            .comments-list-username {
                font-size: $font18;
                font-weight: bold;
                color: $default-text-color;
            }

            .comments-list-close-button {
                border: none;
                background-color: inherit;
                font-size: 1.8rem;
                cursor: pointer;
            }
        }

        &__content {
            display: flex;
            flex-direction: column;

            .comment-wrapper {
                padding: 0 1.7rem 2rem 3.4rem;
                border-bottom: 0.1rem solid $default-section-divider-color;

                &:not(:first-of-type) {
                    padding-top: 2rem;
                }

                &:last-of-type {
                    padding-bottom: 2.5rem;
                    border-bottom: none;
                }
            }
        }
    }

    .comments-input-wrapper {
        display: flex;
        flex-direction: column;
        padding: 1.2rem 2.4rem 1.6rem;
        background-color: $comment-section-greyed-color;
        position: relative;

        .validationAlertMessage {
            font-size: 1.3rem;
        }

        .comment-input-textarea-wrapper {
            position: relative;

            .comment-input-textarea {
                width: 100%;
                margin-top: 1.2rem;
                height: 7.3rem;
                color: $comment-section-textarea-color;
                font-size: 1.3rem;
                border: 0.1rem solid $default-border-color;
                padding: 1rem 3.3rem 1rem 1rem;
                resize: none;

                &::placeholder {
                    color: $comment-section-textarea-color;
                    font-size: 1.3rem;
                }
            }
        }

        .la-Comment-submit-button {
            position: absolute;
            top: 3.7rem;
            left: 36rem;
            background: none;
            border: none;
            transition: all ease-in-out 0.1s;

            &_disabled {
                position: absolute;
                top: 3.7rem;
                left: 36rem;
                background: none;
                border: none;
                &:hover {
                    cursor: not-allowed;
                    transform: none;
                }
            }
           

            &:hover {
                cursor: pointer;
                transform: scale(1.08);
            }
        }

        .characters-remaining {
            align-self: flex-end;
            font-style: italic;
            color: $subfield-text-color;
            font-size: 1.3rem;
        }
    }
}

.comment-wrapper {
    display: flex;
    justify-content: space-between;

    .comment-content-wrapper {
        display: flex;
        flex-direction: column;

        .comment-content {
            font-size: $font14;
            color: $comment-title-text-color;
        }

        .comment-timestamp {
            font-size: $font14;
            color: $popup-close-icon-color;
        }
    }

    .comment-actions-wrapper {
        display: flex;

        .comment-action-button {
            @include link-button($button-link-primary-color);
            font-size: 1.3rem;
            white-space: nowrap;
            &:hover {
                 color: $button-secondary-color;
            }
        }

        .loader {
            position: relative;
            top: 2rem;
            left: -3rem;
        }
    }
}