.duplicate-articles-section {
  margin: 1rem 0 -2rem;
  display: flex;
  flex-direction: column;

  &__show-documents {
    display: flex;
    align-items: center;
    cursor: pointer;
    vertical-align: baseline;
    margin: 0 auto 1rem;

    @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
      justify-content: center;
    }
  }

  &__documents-number{
    font-size: 1.6rem;
    line-height: 1.5;
    color: $button-link-primary-color;

    &:hover{
      text-decoration: underline;
    }
  }
  .la-ExpandTree,
  .la-CollapseTree{
    margin: 0 1rem 0 6rem;

    &::before {
      font-size: 2rem;
      color: $default-text-color;
    }
  }

  .duplicate-articles-list{
    padding: 0 0 2rem 0;
    background-color: $gray-bg-color;

    .article-row{
      margin-top: 1rem;

      .article-item-content-wrapper {
        padding: 1.5rem 3.3rem 1.2rem;

        .article-list-head {
          margin-bottom: 1.2rem;

          .article-title{
            font-size: 1.6rem;
          }
        }

        .article-information-data {
          font-size: 1.4rem;
          align-items: flex-start;

          .article-source,
          .article-date {
            margin: 0;
          }
        }
      }

      .custom-checkbox{
        margin-right: 3rem;

        .checkbox-label{
          z-index: 1;
        }

      }

      .article-information {
        @extend .article-title
      }
    }
  }
}