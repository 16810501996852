.search-bar {
  background-color: $default-bg-color;
  border: .1rem solid $default-border-color;
  box-shadow: 0 .3rem .7rem 0 rgba(0, 0, 0, 0.05);
  display: flex;
  height: 6.8rem;
  justify-content: space-between;
  margin: 0 auto 2.8rem;
  position: relative;
  border-radius: .5rem;

  &-error {
    margin: 0 auto 4.6rem;
  }

  .search-bar-select {
    background: $default-bg-color;
    border: none;
    padding: 0 1rem;
  }

  .search-bar-options {
    display: flex;
  }

  .la-SWResearch {
    background-color: $white;
    border: none;
    color: $white;
    cursor: pointer;
    font-size: 3.2rem;
    outline: 0;
    width: 8.5rem;
    border-bottom-right-radius: .4rem;
    border-top-right-radius: .4rem;
    box-shadow: 0 3px 7px 0 rgba(0, 0, 0, 0.05);
    background-image: linear-gradient(53deg, $header-diligence-specific-color 27%, $header-diligence-specific-color-lighter 107%);

    &:disabled {
      color: #bcbec0;
      cursor: not-allowed;
    }
  }
  .search-bar-loader {
    position: absolute;
    z-index: 100;
    right: 10rem;
    top: 2rem;
  }

  .search-dropdown {
    display: flex;

    .sources-count {
      cursor: pointer;
      height: 100%;
      display: flex;
      align-items: center;
      padding: 1rem 1rem 1rem .3rem;

      &-opened {
        .sources-count-label {
          &::after {
            content: "\e607";
          }
        }
      }
    }

    .sources-count-label {
      display: flex;
      justify-content: space-around;
      align-items: center;
      padding-left: 2rem;
      padding-right: 1.4rem;
      width: 18.5rem;
      height: 3.5rem;
      font-size: $font14;
      font-weight: bold;
      color: $button-secondary-color-darken;
      background-color: rgba(39, 95, 99, 0.05);
      border: .2rem solid $button-secondary-color-darken;
      border-radius: 10rem;

      &__text {
        overflow: hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
      }

      &:hover {
        cursor: pointer;
      }

      &::after {
        display: flex;
        align-items: center;
        font-family: 'GVS_Icons', sans-serif;
        content: "\e60a";
        font-size: 1.4rem;
        font-weight: 900;
        color: $button-secondary-color-darken;
        margin-left: .6rem;
      }

      &.selected {
        &::after {
          content: "\e607";
        }
      }
    }

    .sources-count-arrow {
      display: none;
    }

    .selected-sources-dropdown {
      display: block;
      position: absolute;
      top: 8rem;
      right: 0;
      min-width: 32rem;
      min-height: 30rem;
      padding: 1.6rem 2rem;
      border: none;
      border-radius: 0.5rem;
      box-shadow: 0 3px 10px 0 rgba(0, 0, 0, 0.16);
      background-color: $white;
      overflow-y: auto;
      z-index: 91;

      .selected-sources-dropdown-buttons{
        display: block;

        .selected-entities {
          font-size: $font16;
          font-weight: bold;
          color: $default-text-color;
        }

        .select-all-button, .clear-all-button {
          font-size: $font14;
          line-height: 1;
          padding: 0;
          color: $button-link-primary-color;
          border: none;
          background-color: $white;
          cursor: pointer;

          &:hover {
            text-decoration: underline;
          }

          &:disabled {
            color: $default-text-color;
            cursor: not-allowed;
            text-decoration: none;
          }
        }

        .select-all-button {
          padding-right: 0.6rem;
          border-right: 0.1rem solid $default-text-color;
        }

        .clear-all-button {
          padding-left: 0.6rem;
        }
      }

      .selection-warning-label {
        font-size: $font14;
        color: $secondary-warning-color;
      }

      li .user-preference-checkbox {
        font-size: $font14;
        line-height: 1.5;
        color: $default-text-color;

        input[type="checkbox"] {
          + .preference-checkbox-label {
            &:before {
              width: 1.4rem;
              height: 1.4rem;
              margin-top: 0.5rem;
              background-position: 0 0.1rem !important;
            }
          }
        }
      }

      .selected-sources-warning li .user-preference-checkbox {
        input[type="checkbox"] {
          + .preference-checkbox-label:before {
            border-color: $secondary-warning-color;
          }
        }
      }
    }

    .user-preferences-category-container__list {
      display: block;
      padding-top: 1rem;
      padding-bottom:0;

      .user-preference-list-item {
        width: 100%;
        margin: 0.5rem;
      }
    }
  }

  .search-box-container .la-CloseRemove {
    padding-right: 1rem;
    height: 100%;
    background: transparent;
    cursor: pointer;
  }

  .search-input-placeholder {
    font-size: 1.8rem;
    font-style: italic;
    color: $placeholder-color;
  }
}

.popup-modal__updateCheckbox {
  display: flex;
  margin: 0 auto 1.8rem;

  &.searchBar {
    position: absolute;
    right: 16rem;
    justify-content: flex-end;

    @media screen and (min-width: 1366px) {
      right: 12.5%;
    }

    .user-preference-checkbox {
      background: rgba(244, 244, 245, 0.94);

      label:before {
        margin-top: .2rem;
      }
    }
  }
}

.dark-theme {
  .popup-modal__updateCheckbox {
    &.searchBar {
      .user-preference-checkbox {
        background: rgba(0, 24, 46, 0.94);

        label {
          color: $dark-theme-color;

          &:before {
            border: .1rem solid $dark-theme-color;
          }
        }

        input[type="checkbox"] {
          &:hover + .preference-checkbox-label:before {
            background-color: $dark-theme-color;
          }

          &:checked + .preference-checkbox-label:before {
            background-color: $dark-theme-background;
          }

          &:hover:checked + .preference-checkbox-label:before {
            color: $dark-theme-background;
            border: solid 0.1rem $dark-theme-background;
            background-color: $dark-theme-color;
          }

          &:focus + .preference-checkbox-label:before {
            outline: solid 0.1rem $dark-theme-color;
          }
        }
      }
    }
  }
}

