.user-preferences-category-container {

  h2 {
    font-size: $font20;
    font-weight: bold;
    margin-bottom: 0;
  }

  h3,
  .user-preferences-category-container__category-categoryName {
    font-size: $font18;
    font-weight: bold;
  }

  h3.user-preferences-negativeNews-language-topic {
    margin-bottom: -2rem;
  }

  .user-preferences-category-container__row__topic {
    font-size: $font16;
    font-weight: normal;
  }

  .user-preferences-category-container__topic {
    font-size: $font18;
  }

  .section-enable {
    padding: 3rem;
  }

  a {
    color: $button-link-primary-color;
  }

  a:hover {
    text-decoration: underline;
  }

  .sort-date-range {
    cursor: pointer;

    &>select:disabled {
      cursor: not-allowed;
    }
  }

  .dropdown {
    display: flex;
    padding-bottom: 1rem;
    cursor: pointer;
    flex-direction: row;
    align-items: flex-end;

    &>select:disabled {
      cursor: not-allowed;
    }

  }

  select {
    width: 46.6rem;
    height: 4rem;
    vertical-align: bottom;
  }

  .selection-buttons {

    .vertical-divider {
      opacity: 0.1;
      margin: 0 0.5rem 0;
      padding: 0;
    }

    .selection-action-button {
      color: $primary-bg-color;
      background: $white;
      border: none;
      text-align: left;
      cursor: pointer;
      padding-left: 0;

      &:hover {
        text-decoration: underline;
      }

      &:disabled {
        color: $disabled-text-color;
        text-decoration: none;
        cursor: initial;
      }

      &:focus {
        outline: 0;
      }
    }
  }

  &__news_searches_sources-wrapper {
    display: inline-block;
  }

  &__note {
    font-size: $font14;
    margin-bottom: 0.5rem;
    width: 100%;
    display: flex;
  }

  &__segment {
    align-items: center;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1.5rem;

    &.general-settings,
    &.person-check,
    &.company-check,
    &.report-builder,
    &.delivery-settings {
      line-height: 2rem;
      margin-bottom: 0;
      padding-bottom: 0;
      padding-top: 1.5rem;
    }

    &.general-settings {
      margin-bottom: 1rem;
    }

    &.delivery-settings-container {
      padding-bottom: 1rem;

      &__title {
        padding-top: 1.5rem;
      }

    }
  }

  &__explanation {
    margin: .2rem 0;
  }

  &__topic {
    margin-bottom: 1rem;
    font-weight: bolder;
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    padding-top: 0;
  }

  &__row {
    display: flex;
    margin-bottom: 0;
    align-items: baseline;
    justify-content: flex-start;

    .section-lock {
      margin-left: auto;
    }

    &__topic {
      font-weight: bolder;
    }


    .horizontal-divider {
      margin: 0.5rem 0 0.5rem;
      opacity: 0.1;

      &.general-settings {
        margin: 3.5rem 0;
      }
    }

    .category-block {
      margin-top: 1rem;
    }

  }


  &__category {
    margin-bottom: 1rem;
    margin-top: 1rem;
    display: flex;
    flex-direction: row;
    align-content: center;
    width: 100%;

    &-categoryName {
      padding: .2rem 0;
    }

    .terms-information {
      border: 0;
      font-size: 2rem;
      background-color: white;
      outline: 0;
      cursor: pointer;

      &:disabled {
        background-color: transparent;
        cursor: initial;
      }
    }

    .category-container-buttons {
      margin-left: auto;
      width: auto;
      display: flex;
      align-items: center;

      .btn {
        margin-left: 1em;
      }
    }

    .button-secondary-sm {
      &:disabled {
        cursor: initial;
        background-color: $default-bg-color;
        color: $button-color-disabled;
        border: 1px solid $button-color-disabled;
      }
    }
  }

  &__list {
    flex-direction: row;
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start;
    padding-bottom: 1rem;
    padding-top: 0;

    .user-preference-list-item {
      width: 30%;
      margin: 1rem 0 1rem;

      .user-preferences-list-action {
        width: 4rem;
        background: none;
        border: none;
        outline: none;
        text-align: right;
        color: $button-link-primary-color;
        cursor: pointer;

        &:hover {
          color: $primary-color-hover;
          text-decoration: underline;
        }

        &:active {
          opacity: 0.5;
          text-decoration: underline;
        }

        &:disabled {
          cursor: initial;
          text-decoration: none;
        }
      }
    }
  }

  &.similar-name-match-container,
  &.report-builder-container,
  &.delivery-settings {
    padding-bottom: 1rem;

    .user-section-lock {
      margin-top: -1rem;
    }
  }

  &.number-of-articles-container {
    padding-bottom: 3rem;
    padding-left: 1.5rem;
  }

  &.delivery-settings-container {

    .admin-lock-wrapper {
      display: block;
      position: relative;

      .label {
        padding-top: 2rem;
        padding-bottom: 0.5rem;
      }

      &.is-locked {
        &:not(.is-editable) {
          background-color: #e9e9ea;
          margin-left: -1.6rem;
          margin-right: -1rem;
          padding-left: 1.6rem;
          padding-right: 1rem;
          cursor: not-allowed;

          .custom-toggle {
            pointer-events: none;
          }
        }
      }

      .user-section-lock {
        position: absolute;
        top: 0.3rem;
        left: 35rem
      }

      &:not(.is-locked):not(.is-editable) {
        span.user-section-lock {
          display: none;
        }
      }
    }

  }

  &.person-check-container {
    .person-check-container__title {
      display: inline-block;
      padding-top: 1rem;
    }
  }

  &.company-check-container {
    .company-check-container__title {
      display: inline-block;
      padding-top: 1rem;
    }
  }

  &.report-builder-container {
    .user-section-lock {
      margin-left: auto;
    }
  }

  .sub-section-separator__no-margins {
    background-color: $document-view-content-border-color;
    height: .1rem;
    margin: 1rem -1rem 1rem -1.6rem;
  }

  .user-preferences-search-results {
    padding-top: 1rem;
  }

  .user-preferences-category-container {
    .validation-error {
      font-size: $font12;
      color: $secondary-warning-color;
    }
  }
}