//colors
$primary-color: #3579f8 !default;
$bg-color: #ffffff !default;
$sticky-bg-color: #ffffff !default;
$shadow-color: #000000 !default;
$resize-line-color: #74b9ff !default;
$resize-hint-bg-color: $resize-line-color !default;
$resize-handle-gb-color: $primary-color !default;
$cell-border-color: #111111 !default;
$cell-editor-bg-color: #ffffff !default;
$cell-editor-border-color: $primary-color !default;
$fillhandle-action-border-color: #000000 !default;
$partial-area-gb-color: $primary-color !default;
$partial-area-border-color: $primary-color !default;
$checkbox-checked: $primary-color !default;
$checkbox-unchecked: #eeeeee !default;
$checkbox-hover: #cccccc !default;
$checkbox-mark: #ffffff !default;

$dropdown-focused-item-bg-color: lighten(
    $color: $primary-color,
    $amount: 35,
) !default;
$dropdown-menu-bg-color: #ffffff !default;

//shadow
$shadow-blur-radius: 3px !default;
$shadow-spread-radius: 1px !default;
$shadow-length: 1px !default;
$cell-editor-shadow: 1px 1px 6px !default;

//context-menu
$context-menu-shadow: 0px 0px 8px 2px !default;

//opacity
$opacity-80: 0.8 !default;
$opacity-35: 0.35 !default;
$opacity-20: 0.2 !default;
$opacity-15: 0.15 !default;
$opacity-10: 0.1 !default;
$opacity-06: 0.06 !default;
$opacity-01: 0.01 !default;

//sizes
$cell-font-size: 1em !default;
$context-menu-font-size: 1em !default;

$cell-padding: 0 4px !default;
$cell-focus-border-width: 2px !default;
$celleditor-border-width: 2px !default;
$celleditor-padding-top: 1px !default;

$fill-handle-area: 40px !default;
$fill-handle-square: 6.5px !default;
$fill-handle-border-width: 1px !default;

$resize-handle-area: 11px !default;
$resize-handle-line-width: 6px !default;
$line-size: 2px !default;

$partial-area-border-width: 1px !default;
$partial-area-selected-border-width: 1px !default;

$checkbox-size: 18px !default;

$context-menu-option-padding: 8px 20px 8px 15px !default;

// cell templates variables
$template-checkbox-size: 20px !default;
$template-font-size: 1em !default;
$template-bg-color: transparent !default;
$template-padding: 0 !default;
$template-margin: 0 !default;
$template-content-invalid: rgb(255, 0, 0) !default;

@import '../../../../node_modules/@silevis/reactgrid/styles.scss';

.grid-table-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-bottom: 2rem;

    .table-actions {
        display: flex;
        align-items: center;
        gap: 1rem;

        .vertical-divider {
            max-height: 1.5rem;
        }
    }

    .grid-table-header-container {
        display: flex;
        justify-content: space-between;
        width: 90vw;
        .selection-header {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            margin: 2rem 0 1.5rem 2rem;

            .custom-checkbox {
                margin: 0.3rem 2rem 0 0;
            }
            .selected-articles-count .count {
                cursor: default;
            }
        }
    }

    .entity-name-tooltip {
      .batch-screening-entity-name-tooltip-text {
        p {
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
  
          &:not(:last-child) {
            margin-bottom: 0.5rem;
          }
        }
      }
    }

    .negative-news-negativity-changed-tooltip {
        width: 18rem;
    }
}

.grid-table {
    $cell-font-size: $font15;
    $template-font-size: $font15;

    //table selection
    $partial-area-border-color: #4183c4;
    $partial-area-border-width: 0px;
    $partial-area-selected-border-width: 0px;
    $partial-area-gb-color: lighten(
        $color: #4183c4,
        $amount: 35,
    );
    $primary-bg-color: #4183c4;
    //resize handle
    $resize-handle-area: 0.7rem;
    $resize-handle-line-width: 0.3rem;
    $resize-hint-bg-color: $primary-color;
    $resize-handle-gb-color: #bcbcbc;
    //focus management
    $cell-focus-border-width: 0;

    //default multiple entities table height
    height: 62vh;
    //smaller multiple entities table height for laptop screens
    @media (max-height: 868px) {
        height: 52vh;
    }

    &__less-than-nine-entries,
    &__more-than-nine-entries {
        overflow: auto;
        width: 90%;
        max-height: 62vh;
    }

    &__without-selection {
        margin-top: 2rem;
    }

    .reactgrid > .reactgrid-content {
        -webkit-box-shadow: 8px 8px 20px -18px $shadow-color;
        box-shadow: 8px 8px 20px -18px $shadow-color;
        .rg-context-menu-option > .disabled {
            cursor: default;
            color: $disabled-text-color;
        }
        #resetTable {
            color: $primary-color;
            border-top: 0.1rem solid #e3e3e3;
        }
    }

    .skeleton-loading {
        display: inline-block;
        height: 0.8rem;
        position: relative;
        overflow: hidden;
        background-color: #e0e0e0;
        width: 4.5rem;

        &::after {
            position: absolute;
            top: 0;
            right: 0;
            bottom: 0;
            left: 0;
            transform: translateX(-100%);
            background-image: linear-gradient(
                90deg,
                rgba(#fff, 0) 0,
                rgba(#fff, 0.2) 20%,
                rgba(#fff, 0.5) 60%,
                rgba(#fff, 0)
            );
            animation: shimmer 1.5s infinite;
            content: '';
        }

        @keyframes shimmer {
            100% {
                transform: translateX(100%);
            }
        }
    }

    @import '../../../../node_modules/@silevis/reactgrid/styles.scss';
}

.grid-table-header-right {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.entity-view-search-box-container {
    width: 100%;
}

.grid-table-custom-filtering-area {
    margin-left: 2rem;

    .custom-filtering-area-container {
            display: flex;
            align-items: center;
            justify-content: flex-end;
    }
}
