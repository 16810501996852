.modal_pagination {
    position: absolute;
    top: 49.3rem;
    left: 2rem;
    display: flex;
    align-items: left;
    justify-content: center;
    padding: 1rem;

    &__btn {
        border: none;
        background: $gray-bg-color-darker;
        width: 5rem;
        height: 5rem;
        border-radius: 50%;
        font-weight: bold;
        color: black;
        font-size: 2rem;
        cursor: pointer;
        margin: 0 1rem;
        transition: all ease-in-out 0.2s;
        &:hover {
            background-color: $button-color-hover;
            color: white;
            transform: scale(1.001);
        }
        &._disabled {
            visibility: hidden;
        }
    }

    &__dots-container {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    &__dot {
        transition: all ease-in-out 0.2s cubic-bezier(0.075, 0.82, 0.165, 1);
        width: 0.7rem;
        height: 0.7rem;
        border-radius: 50%;
        background-color: black;
        margin: 0 0.5rem;
    }

    &__dot--active {
        transition: all ease-in-out 0.2s;
        width: 1.2rem;
        height: 1.2rem;
        background-color: white;
        border: 0.063rem solid black;
    }
}
