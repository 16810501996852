.reports-list-wrapper {
  font-family: $secondary-font-stack;
  padding-bottom: 7rem;

  .reports-list-main {
    width: 100%;

    .report-row-wrapper {
      width: 100%;
      padding: 0 3rem 0;
      position: relative;

      .reports-list-row {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        padding: 1rem 3.5rem 1rem;
        margin: 2rem 0 0;
        font-size: 1.5rem;
        height: 11.6rem;
        width: 100%;
        outline: none;
        border: 0;
        background-color: $white;
        cursor: pointer;
        border-left: 0.6rem solid $white;

        // left checkbox
        .report-header-select-box {
          width: 2%;
        }

        .report-row {
          display: flex;
          flex-direction: row;
          justify-content: space-between;
          align-items: center;
          margin-left: 0;
          width: 100%;

          &_batch {
            margin-left: 2%;

            @media screen and (max-width: 1515px) {
              margin-left: 1%;
            }

            .report-title {
              .truncated-title {
                width: auto;
              }
            }
          }

          .la-TriangleDown {
            font-size: 2.5rem;
            width: 2%;
          }

          .la-TriangleRight {
            font-size: 2.5rem;
            width: 2%;
          }

          .la-Folder {
            font-size: 2.5rem;
            width: 2%;
          }

          .reports-list-column {
            .report-list-download-button {
              width: auto;
              background: none;
              border: none;
              font-size: 1.5rem;
              outline: none;
              padding: 0.5rem;
              text-align: right;
              color: $button-link-primary-color;
              cursor: pointer;

              &:hover {
                color: $primary-color-hover;
                text-decoration: underline;
              }

              &:active {
                opacity: 0.5;
                text-decoration: underline;
              }
            }

            .report-list-download-expiration-message {
              font-size: 1.3rem;
            }

            .circle-green {
              display: inline-block;
              border-radius: 50%;
              width: 1rem;
              height: 1rem;
              margin-right: 0.5rem;
              background-color: $button-green-hover;
            }
          }

          .expiryDate {
            width: 12%;
            text-align: left;
          }

          .downloadActions {
            width: 25%;
          }

          .loader-report-build-wrapper {
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            font-size: 1.3rem;
            width: 15%;
            margin: 3.1rem 1rem 2.6rem;

            .loader-report-build-text {
              width: 90%;
            }

            .loader-report-build {
              .ui.medium.loader {
                width: 10%;
                position: relative;
              }
            }
          }

          .reports-list-row-actions {
            display: flex;
            flex-direction: column;
            justify-content: flex-end;
            width: 20%;

            .la-Delete {
              font-size: 2rem;
            }

            .report-list-action-button {
              background: none;
              border: none;
              outline: none;
              padding: 0.5rem;
              text-align: right;
              color: $button-link-primary-color;
              cursor: pointer;
              display: flex;
              flex-direction: row;
              justify-content: flex-end;
              align-items: baseline;
              align-self: flex-end;

              &_delete {
                background: none;
                border: none;
                outline: none;
                padding: 0.5rem;
                text-align: left;
                color: $button-link-primary-color;
                cursor: pointer;
                vertical-align: bottom;
                width: 100%;

                &:hover {
                  color: $primary-color-hover;
                  text-decoration: underline;
                }

                &:active {
                  opacity: 0.5;
                  text-decoration: underline;
                }

                &:disabled {
                  cursor: initial;
                  text-decoration: none;
                }
              }

              &_reorder {
                @extend .report-list-action-button_delete;
              }
            }
          }
        }

        @keyframes currentAnimation {
          0% {
            background-color: $white;
          }
          50% {
            background-color: $dropped-drag-and-drop-color;
          }
          100% {
            background-color: $white;
          }
        }

        &.expanded {
          border-left: 0.6rem solid $active-border-color;
        }

        &.currentReport {
          animation: currentAnimation 2s;
          animation-iteration-count: 1;
        }

        &:hover {
          border-left: 0.6rem solid $active-border-color;
        }
      }
    }
  }
}

//@TODO move in a shared style file

.report-loader {
  height: 80px;
  background-color: $white;
}
