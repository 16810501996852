.select-all-checksgroup {
  .select-all {
    padding-bottom: 1rem;
    margin-bottom: 1rem;
    input {
      cursor: pointer;
    }
    label {
      cursor: pointer;
      font-size: 1.4rem;
      &.checkbox-label:before {
        margin-right: 1.6rem;
      }
    }
  }
  .check-option {
    display: flex;
    flex-direction: row;
    padding-left: 0.2rem;

    label {
      flex: 4;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label-text {
        flex: auto;
        font-size: 1.4rem;
      }
      .filter-count {
        font-size: 1.2rem;
        position: absolute;
        right: 0;
        margin-right: 0.3rem;
      }
    }
    input {
      cursor: pointer;
    }
    label {
      cursor: pointer;
    }
  }
  .toggle-visibility {
    cursor: pointer;
    margin-bottom: 1rem;
    color: $button-link-primary-color;
    margin-left: -2.5rem;
  }
}

.search-input {
  display: flex;
  flex-direction: row;
  border: 1px solid $default-border-color;
  border-radius: 2px;
  font-size: 1.4rem;
  line-height: 1.5rem;
  height: 4rem;
  padding: 1rem;
  margin: 1.5rem 1rem 2.5rem 0;

  &:hover {
    border: solid 1px $input-focus-color;
  }

  &:focus {
    border: solid 1px $dark-blue;
    outline: 0;
  }

  &:disabled {
    border: solid 1px $default-border-color;
  }

  > input {
    font-family: $secondary-font-stack;
    width: 90%;
    border: 0;
    outline: 0;

    &::placeholder {
      font-style: italic;
    }

    &:-ms-input-placeholder {
      font-style: italic;
    }
  }

  .la-SWResearch {
    font-size: 3rem;
    margin: -1rem;
    background-color: $white;
    border: none;
    color: $reviewCompanies-subscription-warning;
    cursor: pointer;
    outline: 0;
    width: 5rem;

    &:hover {
      color: $delete-article-hover-border-color;
    }
  }
}

.filter-radio-group {
  font-size: 1.6rem;
  .filter-count {
    float: right;
    font-size: 1.4rem;
    position: absolute;
    right: 0;
  }
  .toggle-visibility {
    cursor: pointer;
    font-weight: bold;
    margin-bottom: 1rem;
    color: $button-link-primary-color;
    margin-left: -2.5rem;

    &:hover {
      text-decoration: underline;
    }
  }
}
.filter-checks-group {
  @extend .filter-radio-group;
}

.filter-pod-content {
  padding-right: 1.3rem;
  .checkboxes-wrapper {
    margin-bottom: 2rem;
    margin-top: 0.2rem;
    outline: none;
    position: relative;
    padding-left: 2.7rem;

    &__content {
      overflow-y: auto;
    }
  }

  .radios-wrapper {
    @extend .checkboxes-wrapper;

    &__content {
      height: min-content &__default-list {
        height: min-content;
      }

      .horizontal-divider {
        margin: 1rem 0;
      }
      .custom-radio {
        position: relative;
        margin: 0 0 0 2rem;
        width: 80%;
        span {
          white-space: normal;
          flex-direction: column;
        }

        input[type="radio"] {
          position: absolute;
          margin: 0.5rem;
          + .radio-label {
            &:before {
              position: absolute;
              left: -1.7rem;
            }
          }
        }
      }

      .radio-option:not(.custom-radio) {
        input[type="radio"] + label {
          margin-left: 2rem;
        }
      }
    }

    .date-range-select {
      display: flex;
      flex-direction: column;
      height: 18rem;
      padding-bottom: 0.5rem;
      padding-left: 0.2rem;

      .date-select {
        .la-Calendar {
          position: relative;
        }
        .react-calendar {
          margin-top: 0;
          top: 0;
          left: 22rem;
        }

        &.disabled {
          pointer-events: none;
          cursor: not-allowed;

          .la-Calendar {
            color: $disabled-text-color;
          }
          input {
            color: $placeholder-color;
          }
        }
      }

      // OK button
      .button-primary-sm {
        width: 15.8rem;
      }

      // OK button
      //IE 11 only
      @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
        .button-primary-sm {
          width: 16.8rem;
        }
      }

      // OK button
      //FF only
      @-moz-document url-prefix() {
        .button-primary-sm {
          width: 15rem;
        }
      }
    }
  }

  .filter-checks-group {
    .check-option {
      padding-bottom: 1rem;
      position: relative;

      input {
        position: absolute;
        margin-top: 0.5rem;
      }
      label {
        padding-left: 0.5rem;
        padding-right: 4.8rem;
      }
    }

    &.with-inline-sort-filter {
      position: relative;

      .inline-sort-filter {
        position: absolute;
        right: 0.1rem;

        .sort-option {
          border: none;
          line-height: 1.8rem;
          outline: none;
          background: none;
          font-size: 1.4rem;

          &:not(.selected) {
            color: #4183c4;

            &:hover {
              color: #4183c4;
              cursor: pointer;
            }
          }

          &.selected {
            font-weight: bold;
          }

          &:first-of-type {
            padding-top: 0;
            padding-bottom: 0;
          }

          &:last-of-type {
            padding: 0;

            &:before {
              content: "|";
              padding-right: 0.7rem;
              color: black;
              font-weight: normal;
            }
          }
        }
      }
    }
  }

  .filter-radio-group {
    @extend .filter-checks-group;

    .radio-option,
    .default-list-radio-option {
      @extend .check-option;

      input {
        cursor: pointer;
      }
      label {
        cursor: pointer;
      }
    }
  }
}

.text-area-highlight {
  padding-bottom: 1.5rem;
  h3 {
    align-items: center;
    justify-content: space-between;
    display: flex;
    margin-bottom: 1.5rem;
    .inline-spinner {
      background: url("~/public/assets/spinner.gif") no-repeat 50% 50%/100% 100%;
      display: inline-block;
      height: 2rem;
      margin-left: 1rem;
      width: 2rem;
    }
  }
  textarea {
    border-color: $default-border-color-darker;
    height: 28rem;
    max-width: calc(100% - 0.1rem);
    width: calc(100% - 0.1rem);
  }
  &__buttons {
    display: flex;
    justify-content: flex-end;
    margin-top: 1.5rem;
    button {
      margin-left: 1.5rem;
    }
  }
}

.skeleton-loading-wrapper {
  display: flex;
  flex-direction: column; // Ensure rows are stacked vertically
  padding-left: 6rem;

  .label-container {
      display: flex;
      justify-content: space-between;
      width: 100%;
      margin-bottom: 0.625rem; // Optional: Add spacing between rows
  }

  .filter-label-loading {
      flex-grow: 1;
      width: 70%;
  }

  .filter-count-loading {
      flex-shrink: 0;
      width: 3.5rem;
      margin-left: 3rem;
      text-align: right;
      padding-right: 0;
  }

  .skeleton-loading {
      display: inline-block;
      height: 0.8rem;
      position: relative;
      overflow: hidden;
      background-color: #e0e0e0;
      width: 4.5rem;

      &::after {
          position: absolute;
          top: 0;
          right: 0;
          bottom: 0;
          left: 0;
          transform: translateX(-100%);
          background-image: linear-gradient(
              90deg,
              rgba(255, 255, 255, 0) 0%,
              rgba(255, 255, 255, 0.2) 20%,
              rgba(255, 255, 255, 0.5) 60%,
              rgba(255, 255, 255, 0)
          );
          animation: shimmer 1.5s infinite;
          content: '';
      }
  }

  @keyframes shimmer {
      100% {
          transform: translateX(100%);
      }
  }
}

.nested-checkbox-group {
  .option-info {
    padding-bottom: 1rem;
    .option-controls {
      width: 2.5rem;
      .expand-icon {
        margin-top: 0.5rem;
        cursor: pointer;
      }
      .no-expand-icon {
        display: inline-block;
        width: 1.5rem;
      }
    }
    display: flex;
    flex-direction: row;
    padding-left: 1rem;
    label {
      flex: 4;
      display: flex;
      flex-direction: row;
      justify-content: space-between;
      .label-text {
        flex: auto;
        font-size: 1.4rem;
        max-width: 17rem;
        padding-right: 3rem;
      }
      .filter-count {
        font-size: 1.4rem;
        position: absolute;
        right: 0;
        margin-right: -0.5rem;
      }
    }
  }
}

.geography-postfilter,
.subject-postfilter,
.industry-postfilter,
.esgFactors-postfilter {
  padding-right: 0.6rem;

  .checkboxes-wrapper {
    .nested-checkbox-group {
      margin-left: -1rem;
    }

    .partial-checked {
      opacity: 0.5;
      margin-top: 0.5rem;
    }

    .option-children {
      padding-left: 2.5rem;
    }

    .expand-icon {
      float: left;
    }
  }
}

.dunsFilter-postfilter {
  margin-left: 3.2rem;
  margin-right: 3.9rem;

  .search-input .la-SWResearch {
    font-size: 1.8rem;
    font-weight: bold;
    background-color: $white;
    color: $button-color-click;
    width: 5rem;

    &:hover {
      color: $button-color-hover;
    }
  }

  .validationAlertMessage {
    margin-left: 0 !important;
  }

  .edit-third-party__about__input-group__warning {
    border-color: $secondary-warning-color;
  }
}
