.sanctions-table-container {
    margin-top: 2rem;
    margin-bottom: 0.5rem;
    max-height: 40rem;
    overflow: auto;
    border-top: 0.1rem solid #EDEDED;
    background-color: $dark-cell-color;

    .rg-cell:not(.rg-sanctionsHeader-cell):not(:has(div.disabled)) {
        cursor: pointer;

        .hovered{
            text-decoration: underline;
            color: $button-link-primary-color;
        }
    }

    .rg-cell-focus {
        border: none !important;
        height: 0 !important;
        width: 0 !important;
    }
    &::-webkit-scrollbar {
        -webkit-appearance: none;
        width: 1rem;
        height: 1rem;
    }
     
    &::-webkit-scrollbar-thumb {
        cursor: pointer;
        border-radius: 0.5rem;
        background: rgba(0,0,0,.25);
        -webkit-transition: color .2s ease;
        transition: color .2s ease;
    }
    &::-webkit-scrollbar-track {
        background: rgba(0,0,0,.1);
        border-radius: 0;
    }
}