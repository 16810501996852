/**
 * HEADER SCSS STYLES
 *
 * @version 0.1
 */

.diligence-header {
  background-color: $rebranding-header-wrapper-bg;
  color: $white;
  position: fixed;
  z-index: $z-message-popups;
  width: 100%;
  height: $header-height;
  top: 0;

  .grid-header {
    background: url(~/public/assets/ProductSwitcherGrid.svg) no-repeat center;
    background-size: auto;
    display: block;
    text-indent: -9999px;
    height: 100%;
    margin: 0;
    padding: 1.8rem;
  }

  &.google-translate-toolbar-on {
    top: 38px;
  }

  &__menu-wrapper {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;

    //prevents the submenu from showing under the logo in situations where there is no menu
    .diligence-header__placeholder {
      border-top: 1px solid black;
      padding-top: 5.9rem;
    }

    .diligence-header-menu-left {
      display: flex;
      white-space: nowrap;

      .diligence-header__logo {
        min-width: 21rem;
        height: $header-height;
        padding: 1rem 1rem 1rem 1rem;
        margin-left: 1.5rem;
        z-index: 1;
        background: url("~/public/assets/RebrandedDiligenceLogo.svg") no-repeat center;
        
        &.diligence-plus {
          background: url("~/public/assets/NexisDiligencePlusLogo.svg") no-repeat center;
        }
      }
    }

    .diligence-header-menu-center {
      justify-content: space-between;
      display: flex;
      min-width: 35%;
      width: 45%;

      .diligence-header__menu-item {
        width: 100%;
      }
    }

    .diligence-header-menu-right,
    .diligence-header-menu-left {
      display: flex;
      justify-content: flex-end;

      .diligence-header-list-items {
        display: flex;
        max-width: 100%;

        .diligence-header__menu-item {
          flex-basis: auto;
          list-style: none;
          padding: 0;
          margin: 0;

          &__language {
            position: relative;
          }

          &__google-anonymized,
          &__manage-alerts,
          &__public-records,
          &__report-builder,
          &__batch-upload {
            display: flex;
            align-items: center;
            min-width: min-content;

            &:hover {
              background-color: $rebranding-header-nav-item-hover;
            }
          }

          &__batch-upload {
            .diligence-header__menu-link {
              display: flex;
              justify-content: flex-end;

              span {
                padding: 0 0.4rem;
              }

              .red-gradient-tag {
                position: absolute;
                top: -0.8rem;
              }
            }
          }

          &__user {
            display: flex;
            align-items: center;
            min-width: 7rem;
            flex: 1;
            overflow: hidden;
          }

          &__public-records,
          &__user {
            &:hover {
              background-color: $rebranding-header-nav-item-hover;
              .dropdown-content__public-record {
                display: flex;
                flex-direction: column;
                background-color: $rebranding-header-nav-item-hover;
              }
              .dropdown-content__user,
              .dropdown-content__productSwitcher {
                display: flex;
                flex-direction: column;
                background-color: $rebranding-header-nav-item-hover;

                .diligence-header__menu-link--language {
                  .la-Check {
                    color: $white;
                    font-weight: bold;
                    float: right;
                  }

                  &:hover {
                    .dropdown-content__language-dropdown {
                      display: flex;
                      flex-direction: column;
                      margin-left: -20rem;
                      margin-top: -1rem;
                      background-color: $rebranding-header-nav-item-hover;

                      li {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        padding: 1rem;
                        width: 100%;
                      }

                      li:hover {
                        cursor: pointer;
                        background-color: $rebranding-header-menu-dropdown-hover;
                      }
                    }
                  }
                }
              }
            }
          }

          &__productSwitcher {
            max-width: 20rem;
            min-width: 3rem;
            overflow: hidden;
            white-space: nowrap;
            text-overflow: ellipsis;
            margin-left: 1.5rem;

            .dropdown-second-column {
              left: 24rem !important;
              height: 25.1rem;
              margin-left: -0.85rem;
            }

            &:hover {
              background-color: $rebranding-header-nav-item-hover;
              .dropdown-content__public-record {
                display: flex;
                flex-direction: column;
                background-color: $rebranding-header-nav-item-hover;
              }
              .dropdown-content__user,
              .dropdown-content__productSwitcher {
                display: flex;
                flex-direction: column;
                background-color: $rebranding-header-nav-item-hover;
                margin-left: 1.5rem;
                width: auto;
                padding: 1rem;

                a {
                  width: 23rem;
                }

                .diligence-header__menu-link--dropdown-item--productSwitcher {
                  padding: 0;

                  .icon-span {
                    padding: 0.7rem 1.4rem 0.7rem 1.4rem;
                    // TODO: alternative to important
                    background-size: 2.9rem 2.9rem !important;
                    background-position: 0.7rem !important;
                    width: 100%;
                    height: 3.7rem;
                    border-radius: 0.5rem;
                    margin-bottom: 0.15rem;

                    sup {
                      width: 6px;
                      height: 10px;
                      font-family: "Lato-Light", sans-serif;
                      font-size: 8px;
                      font-weight: 300;
                      font-style: normal;
                      font-stretch: normal;
                      line-height: 1.25;
                      letter-spacing: normal;
                      text-align: left;
                      color: var(--white);
                    }
                  }

                  .selected-product {
                    background-color: $product-switcher-item-color;
                  }

                  .default-product {
                    background-color: $product-switcher-item-color;
                  }

                  .product-span {
                    height: 17px;
                    font-family: "Lato", sans-serif;
                    font-size: 14px;
                    font-weight: normal;
                    font-style: normal;
                    font-stretch: normal;
                    line-height: 1.21;
                    letter-spacing: normal;
                    text-align: left;
                    color: $white;
                  }
                }

                .diligence-header__menu-link--language {
                  .la-Check {
                    color: $white;
                    font-weight: bold;
                    float: right;
                  }

                  &:hover {
                    .dropdown-content__language-dropdown {
                      display: flex;
                      flex-direction: column;
                      margin-left: -20rem;
                      margin-top: -1rem;

                      li {
                        font-size: 1.4rem;
                        line-height: 2rem;
                        padding: 1rem;
                        width: 100%;
                      }
                    }
                  }
                }
              }
            }
          }

          .dropdown-content {
            background-color: $rebranding-header-nav-item-hover;
            display: none;
            flex-direction: column;
            font-size: 1.4rem;
            position: absolute;
            z-index: 10;
            min-width: 20rem;

            ul {
              border: none;
            }

            a,
            li {
              font-size: 1.4rem;
              line-height: 2rem;
              padding: 1rem;
            }

            a:hover,
            li:hover {
              cursor: pointer;
            }

            &.dropdown-content__productSwitcher {
              left: 0;

              a:hover,
              li:hover {
                color: $white;
              }
            }

            &.dropdown-content__user {
              right: 0;
              top: $header-height;

              a:hover,
              li:hover {
                color: $white;
              }

              a.disabled {
                cursor: not-allowed;
                pointer-events: none;
              }
            }

            &.dropdown-content__public-record {
              width: auto;
              top: $header-height;

              a:hover,
              li:hover {
                color: $white;
              }
            }

            &.dropdown-content__language-dropdown {
              left: 0;

              a:hover,
              li:hover {
                color: $white;
              }
            }
          }
        }
      }
    }
  }

  &-theme-switcher {
    display: flex;
    align-items: center;
    padding-right: 3.5rem;
  }
}

// Dark theme
.dark-theme {
  .diligence-header {
    background-color: $dark-navy;
  }
}

.submenu {
  background-color: $gray-bg-color;
  border-bottom: 1px solid $header-submenu-border;
  border-top: 3px solid $header-submenu-border-green;

  &__link {
    color: $header-subheader-link;
    font-size: 2rem;
    line-height: 6rem;
    padding: 0 5.5rem;
    text-decoration: none;

    span {
      display: inline-block;
    }
  }
}

.diligence-header__menu-link {
  color: $white;
  cursor: pointer;
  display: flex;
  font-size: 1.4rem;
  padding: 0.8rem 1rem 0.8rem;
  text-decoration: none;
  line-height: initial;

  &.diligence-header__menu-link__public-records,
  &.diligence-header__menu-link__user {
    cursor: auto;
  }

  .notranslate {
    overflow: hidden;
    white-space: pre;
    text-overflow: ellipsis;
  }

  .la-Profile {
    display: none;
  }

  .la-ExternalLink {
    font-size: 1.8rem;
    padding-left: 0.5rem;
    vertical-align: middle;
  }

  &__user {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;

    &:hover {
      background-color: $rebranding-header-nav-item-hover;
    }

    &:active {
      background-color: $rebranding-header-nav-item-hover;
    }
  }

  &__productSwitcher {
    &:hover {
      background-color: $rebranding-header-nav-item-hover;
    }

    &:active {
      background-color: $rebranding-header-nav-item-hover;
    }
  }

  &--dropdown-item {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1rem;

    &:hover {
      background-color: $rebranding-header-menu-dropdown-hover;
    }

    .la-ExternalLink {
      color: $white;
    }
  }

  &--dropdown-item--productSwitcher {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1rem;

    .icon-span:hover {
      background-color: $rebranding-header-menu-dropdown-hover !important;
    }

    .la-ExternalLink {
      color: $white;
    }
  }

  &--language {
    font-size: 1.4rem;
    line-height: 2rem;
    padding: 1rem;

    &:hover {
      background-color: $rebranding-header-menu-dropdown-hover;
    }

    .la-ExternalLink {
      color: $white;
    }
  }
}

.diligence-header-logout-link-wrapper {
  cursor: not-allowed;
}