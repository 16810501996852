.report-actions {
  display: flex;
  margin: .5rem 0 .5rem;

  .report-actions-wrapper{
    display: inline-block;
  }

  .__react_component_tooltip {
    max-width: 21rem;

    .type-light{
      opacity: 1;
    }
  }

  .tooltips {
    opacity: 1;
  }

  .icon-box {
    padding: 0;
    display: inline-block;
    border: none;
    cursor: pointer;
    outline: 0;
    background-color: $white;

    .la-Print {
      padding: 0 1rem 0 1rem;
      font-size: 3rem;
    }

    .la-Email {
      font-size: 3rem;
      padding: 0 1rem 0 1rem;
      border-left: 1px solid $light-border-color;
    }

    .la-Download {
      font-size: 3rem;
      padding: 0 1rem 0 1rem;
      border-left: 1px solid $light-border-color;
    }

    .la-Delete {
      font-size: 3rem;
      padding: 0 1rem 0 1rem;
      border-left: 1px solid $light-border-color;
    }

    &:active {
      opacity: 0.5;
    }

    &:disabled {
      opacity: 0.5;
      cursor: initial;
      pointer-events: none;
    }
  }
}
