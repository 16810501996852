.negativeNews-graph {
  &-wrapper {
    margin: 1.9rem 0 0;
    padding-left: 2.2rem;

    &-content {
      background-color: $default-bg-color;
      border-radius: .8rem;
      box-shadow: .4rem .3rem .8rem 0 rgba(0, 0, 0, 0.06);
    }
  }

  &-toggle {
    display: flex;
    justify-content: center;
    background: $toggle-button-color;
    padding: .4rem 0;
    font-size: 1.2rem;
    font-weight: bold;
    color: $button-secondary-lighter;
    transition: all .3s ease-out;
    border-radius: .8rem;

    &:hover {
      cursor: pointer;
      background: darken($toggle-button-color, 5%);
    }

    &-label {
      margin-right: .3rem;

      &-wrapper {
        display: flex;
        align-items: center;
      }
    }

    &.extended {
      border-radius: 0 0 .8rem .8rem;
    }
  }

  &-content {
    min-height: 0;
    height: 0;
    transition: all .3s ease-out;
    overflow: hidden;
    visibility: hidden;
    opacity: 0;
    width: 100%;
    position: relative;

    .negativeNews-graph-container {
      padding: 5rem 1rem 2rem 1rem;
      width: 100%;

      .highcharts-container {
        max-width: 100%;
        width: 100%;
      }

      .negativeNews-graph-no-data {
        text-align: center;
      }
    }

    &.extended {
      min-height: 28rem;
      height: auto;
      visibility: visible;
      opacity: 1;
    }

    &.hasNegativeTerms {
      display: flex;
    }
  }

  &-legend {
    padding: 2.5rem 2rem 0;
  }

  &-elements {
    flex: 0 0 65%;
    min-width: 0;

    @media screen and (min-width: 1366px) {
      flex: 0 0 70%;
    }

    @media screen and (min-width: 1600px) {
      flex: 0 0 80%;
    }
  }

  &-terms {
    flex: 0 0 35%;
    min-width: 0;

    @media screen and (min-width: 1366px) {
      flex: 0 0 30%;
    }

    @media screen and (min-width: 1600px) {
      flex: 0 0 20%;
    }
  }
}