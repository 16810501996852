
.tabs {
    border-bottom: 1px solid $light-border-color;
    background-color: $gray-bg-color;
    display: flex;
    margin: 1rem 0 0;
    padding: 0 16rem 0;

    .tab {
        border-bottom: 3px solid transparent;
        bottom: -1.5px;
        cursor: pointer;
        font-size: 1.3rem;
        padding: 1rem;
        position: relative;

        &:hover {
            text-decoration: underline;
        }

        &.active {
            border-bottom: 3px solid $secondary-color;
            cursor: auto;
            font-weight: bold;
            text-decoration: none;
        }

        &.empty {
            color: $default-border-color;
            cursor: default;
            &:hover {
                text-decoration: none;
            }
        }
    }
}
//Specifics
//History page
.history-page .tabs {
    padding-top: 3rem;
}