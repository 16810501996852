.google-translate {
    display: inline-block;
    min-width: 15rem;

    &.spinner-wrapper.loading {
        background-attachment: scroll;
    }

    .spinner-wrapper__content {
        display: inline-block;
    }

    &__element-wrapper {
        display: flex;
        min-height: 3rem;
    }

    #google-translate-element {
        margin: auto;
        font-weight: normal;
    }

}