.results-list-container {
  display: flex;
  flex-direction: column;
  position: relative;

  .results-list-header-container {
    height: auto;

    .results-list-header {
      background-color: $white;
      padding: 0 2.7rem 1.9rem;
      border-bottom: 0.1rem solid $default-border-color;
      display: flex;
      align-items: flex-end;
      font-size: 2.4rem;
      line-height: 3.5rem;

      .results-list-row {
        display: flex;
        flex: 1 1;
        white-space: nowrap;
        font-size: 2.4rem;
        line-height: 3.2rem;

        @media screen and (-webkit-min-device-pixel-ratio: 0) {
          b {
            padding-right: 0.5rem;
          }
        }

        .results-text {
          /* autoprefixer: ignore next */
          -webkit-box-orient: vertical;
          display: -webkit-box;
          -webkit-line-clamp: 2;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: normal;
          font-weight: bold;
          font-size: 2.4rem;
          line-height: 3.2rem;

          @media screen and (-ms-high-contrast: active),
          (-ms-high-contrast: none) {
            white-space: nowrap;
            flex: 1 1;
          }
        }

        .results-edit-wrapper {
          display: flex;
          align-items: flex-end;

          .edit-search-link {
            font-size: 1.5rem;
            font-weight: bold;
          }
        }
      }
    }
  }

  .results-list-subcontainer {
    display: flex;
    height: 100%;
    width: 100%;
    background-color: $gray-bg-color-darker;
    min-height: 60rem;

    .search-filter-column {
      position: relative;

      .filters-toggle-wrapper {
        position: fixed;
        left: 30.5rem;
        top: 24.8rem;
        width: 13rem;
        height: 4rem;
        background-color: $white;
        border: solid 0.5px #d7dadb;
        border-right: 0;
        border-bottom-left-radius: 2rem;
        -webkit-transform: rotate(270deg) scale(1);
        -ms-transform: rotate(270deg) scale(1);
        transform: rotate(270deg) scale(1);
        -webkit-transform-origin: center;
        -ms-transform-origin: center;
        transform-origin: center;
        z-index: 84;
        cursor: pointer;
        @include slideTransition(left);

        @media screen and (min-width: 1366px) {
          left: 34.5rem;
        }

        &.collapsed {
          left: -4.5rem;
        }

        .filters-toggle {
          padding: 0.8rem 1.1rem 0.8rem;
        }

        .filters-toggle-text {
          display: flex;
          justify-content: center;
          font-size: $font14;
          font-weight: bold;
          color: $button-link-primary-color;
          line-height: 2.2rem;

          .la-FilterToggle {
            color: $button-link-primary-color;
            font-size: 2.2rem;
            margin-right: 1rem;
          }
        }
      }
    }

    .results-content-column {
      width: 100%;
      margin-left: 39rem;
      margin-right: 3rem;
      @include slideTransition(margin);

      @media screen and (min-width: 1366px) {
        margin-left: 43rem;
      }

      &.expanded-view {
        margin-left: 20rem;
        margin-right: 20rem;
      }
    }

    .results-list-content {
      flex: 1;
      -ms-flex: 1;
      /* IE 10 */
      flex-basis: 75%;
      min-width: 0;

      .result-list-sidebar-main-content {
        font-size: 1.6rem;
        width: 100%;
        margin-top: 2.3rem;
      }

      .sticky {
        position: sticky;
        top: 8rem;
        background: $gray-bg-color-darker;
        z-index: 100;
        padding-top: 2rem;
      }
    }
  }

  .google-translate {
    #google-translate-element {
      display: flex;
      height: 3.6rem;
    }

    .goog-te {
      &-combo {
        height: 100%;
        padding: 0 4rem 0 1.5rem;
        border: 0.1rem solid $header-submenu-border;
        border-radius: 0.3rem;
      }

      &-gadget-simple {
        display: flex;
        align-items: center;
        height: 100%;
        border: none;
        padding: 0 4rem 0 1.5rem;
      }

      &-menu-value {
        font-size: 1.4rem;
        margin: 0 0 0 0.9rem;
        position: relative;

        &::after {
          font-family: "GVS_Icons", sans-serif;
          position: absolute;
          content: "\e60a";
          top: 0.5rem;
          right: -2.7rem;
          font-size: 1.3rem;
          line-height: 1;
          font-weight: bold;
        }

        span {
          &:not(:first-child) {
            display: none;
          }
        }

        img {
          display: none;
        }
      }
    }
  }
}

.hit {
  background-color: $keyword-highlight-bg-color;
}

#hit-selected {
  font-weight: lighter;
  color: #fff;
  background-color: #0067b1;
  padding: 2px;
  border-radius: 5px;
}