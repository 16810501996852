.regular-report {
  background-color: $white;

    // reorder categories
    .ui.segment {
      padding: 0;
    }
    .article-list-category-wrapper {
      background-color: $white;
      padding-bottom: 3rem;
      width: inherit;

      .article-list-category-name {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 1rem 4rem 0;
        margin-bottom: -.8rem;

        .article-list-category-title {
          font-size: $font18;
          text-align: center;
          font-weight: 600;
          line-height: 4.5rem;
          .new-label {
            float: right;
            margin-top: 1rem;
          }
        }

      }
      .loader{
        position: static;
        margin-left: 4.8rem;
        margin-top: 1rem;
      }
      .count {
        margin-top: -0.5rem;
        padding-left: 4rem;
        font-size: 14px;
        color: $tertiary-color;
      }
      .horizontal-divider {
        margin: 1rem 4rem 0;
        width: inherit;
      }
    }

}