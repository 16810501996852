/**
 * CONFIRMATION MODAL SCSS STYLES
 *
 * @version 0.1
 */

.app-confirmation-modal {

  @include full-screen(fixed);

  background-color: $popup-overlay-background-color;
  font-size: 1.4rem;
  z-index: $z-app-modal;
  color: $popup-color-default;

  &__wrapper {
    position: absolute;
    text-align: center;
    top: 50%;
    transform: translateY(-50%);
    width: 100%;
  }

  &__content-box {
    background-color: $default-bg-color;
    display: inline-block;
    position: relative;
    text-align: left;
    width: 50rem;
  }

  &__content {
    height: 60%;

    .modal-header {
      color: $default-text-color;
      padding: $popup-overall-padding $popup-overall-padding $popup-header-bottom-padding;
      position: relative;
    }

    .modal-description {
      width: inherit;
      padding: 0 $popup-overall-padding 0;
      color: $default-text-color;
      font-size: $popup-text-font-size;
    }
  }

  &__close-button {
    background: none;
    border: none;
    color: $modal-close-button-color;
    cursor: pointer;
    font-size: $popup-close-icon-size;
    position: absolute;
    text-align: center;
    padding: 3.6rem $popup-overall-padding $popup-header-bottom-padding;
    right: 0;
    z-index: $z-app-modal-close-button;

    &:hover {
      opacity: .5;
    }
  }

  &__buttons {
    float: right;
    margin: $popup-overall-padding;

    .button-secondary-sm {
      width: 10rem;
      margin-left: 1.5rem;

      &:focus {
        box-shadow: 0 0 0 0.3rem $button-focus-border;
        outline: none;
      }

      &:hover {
        box-shadow: 0 0 0 0.3rem $button-hover-border;
        cursor: pointer;
      }

      &:active {
        background-color: $button-color-active;
      }
    }
    .button-primary-sm {
      width: 10rem;

      &:focus {
        box-shadow: 0 0 0 0.3rem $button-focus-border;
        outline: none;

      }

      &:hover {
        box-shadow: 0 0 0 0.3rem $button-hover-border;
        cursor: pointer;
      }

      &:active {
        background-color: $button-color-active;
        border: 0.1rem solid $button-color-active;
      }
    }
  }

  &.modal-leave-search {
    .app-confirmation-modal__content-box {
      width: 35%;
    }
    .app-confirmation-modal__buttons {
      display: flex;
      justify-content: flex-end;

      .button-secondary-sm,
      .button-primary-sm {
        width: auto;
        height: 4.4rem;
      }

      .button-secondary-sm:active {
          background-color: $white;
          color: $button-color-active;
          border: 0.2rem solid $button-color-active;
      }

    }
  }

}