.article-view-disclaimer {
  @include alert-message($light-popup-message-bg-color, $info-banner-color);
  position: relative;

  &__icon {
      color: $info-banner-color;
      padding-right: 1.5rem;
      display: flex;
      align-items: center;
  }

  &__message {
      border-left: 0.1rem solid $info-banner-color;
      padding-left: 1.5rem;
  }

  &.dark-mode {
      background: $popup-bg-info-dark;
      color: $popup-text-color-dark;
      border-bottom: 0.1rem solid $popup-border-color-info-dark;

      .article-view-disclaimer {
        &__message {
          border-left: 0.1rem solid $popup-border-color-info-dark;
        }
      }
  }
}

.single-article-content {
  .article-view-disclaimer{
    top: -3rem;
  }
}

.preview-article-box-header{
  .article-view-disclaimer{
    top: -5rem;
  }
}

.slider-modal-header {
    .article-view-disclaimer{
        margin: 4rem 0 5rem;

        @include alert-message($light-popup-message-bg-color, $info-banner-color);
        position: relative;
      
        &__icon {
            color: $info-banner-color;
            padding-right: 1.5rem;
            display: flex;
            align-items: center;
        }
      
        &__message {
            border-left: 0.1rem solid $info-banner-color;
            padding-left: 1.5rem;
        }
      
        &.dark-mode {
            background: $popup-bg-info-dark;
            color: $popup-text-color-dark;
            border-bottom: 0.1rem solid $popup-border-color-info-dark;

            .article-view-disclaimer {
              &__message {
                border-left: 0.1rem solid $popup-border-color-info-dark;
              }
            }
        }
      }
}