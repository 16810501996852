.reports-list-header {
    align-items: center;
    background-color: $white;
    display: flex;
    flex-wrap: wrap;
    font-size: 1.4rem;
    padding: 0 3rem 0;

    .reports-list-title {
        padding: 0 0 1.5rem;

        >h1 {
            font-size: 2.4rem;
            line-height: 3.5rem;
        }
    }

    .horizontal-divider {
        width: 100%;
    }

    .actions-buttons {
        height: 5.2rem;
    }

    .message-notification-wrapper {
        width: 100%;

        .message-notification {
            @include alert-message($light-popup-message-bg-color, $info-banner-color);
            position: relative;

            &.dark-mode {
                background: $popup-bg-info-dark;
                color: $popup-text-color-dark;
                border-bottom: 0.1rem solid $popup-border-color-info-dark;

                .message-notification {
                    &__message {
                      border-left: 0.1rem solid $popup-border-color-info-dark;
                    }
                  }
            }
          
            &__icon {
                color: $info-banner-color;
                padding-right: 1.5rem;
                display: flex;
                align-items: center;
            }
          
            &__message {
                border-left: 0.1rem solid $info-banner-color;
                padding-left: 1.5rem;
            }

            &__message-container {
                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                }
            }
        }

    }
}

.reports-list-columns {
    display: flex;
    width: calc(100% - 3rem);
    margin-top: 1.5rem;
    background-color: #f4f4f5;
    margin-left: 3rem;

    .reports-list-header-column {
        &__title {
            width: 44%;
            padding: 1rem 0 1rem 3.5rem;

        }

        &__update {
            width: 25%;
            padding: 1rem 4.5rem 1rem 1rem;

        }

        &__expires {
            width: 25%;
            padding: 2rem 0 2rem;

        }

        &__history {
            width: 25%;
            padding: 1rem 1.2rem 1rem;

        }
    }

}

.resize-header {
    height: 7rem;
}