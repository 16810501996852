.marketing_overlay_modal_overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 9998;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 1rem;
}

.marketing_overlay_modal {
    transition: background-image 0.8s ease-in-out;
    animation: slide-in 0.5s ease-in-out forwards;
    background-repeat: no-repeat;
    position: fixed;
    top: 20%;
    left: 37%;
    transform: translate(-50%, -50%);
    z-index: 9999;
    color: white;
    max-width: 65rem;
    min-width: 50rem;
    height: 58rem;
    border-radius: 0.5rem;
    overflow: hidden;
    display: flex;
    flex-direction: column;

    &__upper {
        display: flex;
        flex-direction: column;
        height: 100%;

        //upgrade pill
        & > div {
            display: inline-block;
            font-size: 1.8rem;
            color: white;
            background: linear-gradient(90deg, $red-tag 0%, $red-tag-gradient-2 100%);
            border-radius: 1.25rem;
            padding: 0.188rem 0.938rem;
            text-align: center;
            text-transform: uppercase;
            width: 18rem;
            font-weight: 700;
            margin-left: 3rem;
            margin-top: 3.3rem;
        }
    }

    &__lower {
        background-color: white;
        width: 97.5%;
        height: 100%;
        border: 0.063rem solid $dark-blue;
        border-bottom-left-radius: 0.6rem;
        border-bottom-right-radius: 0.6rem;
        margin-left: 0.6rem;
    }

    &_title {
        font-size: 3.4rem;
        font-weight: 700;
        width: 35rem;
        line-height: 1.2;
        margin-left: 3.5rem;
        margin-top: 2rem;
    }

    &_description {
        color: black;
        font-size: 1.7rem;
        padding: 3rem;
        height: 2rem;
    }

    &_close_button {
        position: absolute;
        margin: 3.3rem;
        font-size: 2.7rem;
        cursor: pointer;
        color: white;
        max-height: fit-content;
    }

    &_learn_more_button {
        position: absolute;
        bottom: 0;
        right: 0;
        padding: 1rem;
        font-size: 1.5rem;
        cursor: pointer;
        color: white;
        font-weight: 500;
        background-color: $secondary-color;
        border-radius: 0.313rem;
        margin-bottom: 3.2rem;
        margin-right: 4rem;
        border: none;
        height: 4rem;
        max-width: 25rem;
        min-width: 20rem;
        transition: all 0.1s;
        &:hover {
            color: white;
            text-decoration: none;
            transform: scale(1.01);
        }
    }

    &_closed {
        animation: slide-out 0.5s ease-in-out forwards;
    }
}

@keyframes slide-in {
    from {
        transform: translateY(-100%);
    }
    to {
        transform: translateX(0);
    }
}

@keyframes slide-out {
    from {
        transform: translateY(0);
    }
    to {
        transform: translateY(100%);
    }
}
