.admin-preferences-page {

  //these sections use AdminLock with isSectionLock, position is required for it to work
  .section-enabled,
  .section-disabled,
  .user-preferences-block,
  .user-preferences-category-container,
  .addExtraTerms,
  .text-area-highlight {
    position: relative;
  }

  .admin-lock {
    margin: 0 0 0 1rem;
  }

  .sub-section-separator {
    height: 0.2rem;
    background-color: $gray-bg-color;
    margin: 1rem 0;
  }

  .text-area-highlight {
    &__buttons {
      button {
        margin-right: 0.1rem;
      }
    }
  }

  #generalSettings {

    .section-separator {
      margin: 1rem -0.9rem 1rem -1.6rem;
    }

    .container-section {
      padding: 0;
    }

  }

}