.single-article-header-container {
  .single-article-header {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    width: auto;
    background: $white;
    border-bottom: 1px solid $light-border-color;

    .single-article-header-title {
      display: flex;
      flex-direction: row;
      padding: 0 3rem 2rem;

      .icon-box {
        padding: 0;
        display: inline-block;
        border: none;
        cursor: pointer;
        outline: 0;
        background-color: $white;

        .la-TriangleLeft {
          position: relative;
          right: 0.2rem;
          font-size: 2.5rem;
          cursor: pointer;

          &:hover {
            color: $disabled-text-color;
          }
        }
      }

      .truncated-title {
        @include multi-line-ellipsis(35px, 2);
        font-size: 2.4rem;
      }
    }
  }

  // tooltip for full title
  #full-title {
    max-width: 100%;
  }
}

.single-article-header-actions-bar {
  background-color: $gray-bg-color-darker-document;
  z-index: $z-header;
  box-shadow: none;
  transition: all 0.3s ease-in-out;

  &.js-is-sticky--change {
    width: 100%;
    -webkit-box-shadow: 0 0.6rem 0.4rem -0.4rem rgba(0, 0, 0, 0.15);
    -moz-box-shadow: 0 0.6rem 0.4rem -0.4rem rgba(0, 0, 0, 0.15);
    box-shadow: 0 0.6rem 0.4rem -0.4rem rgba(0, 0, 0, 0.15);
    background-color: $default-bg-color;

    .single-article-header-content {
      .single-article-header-actions {
        .icon-box {
          background-color: $default-bg-color;
        }
      }
    }
  }

  .single-article-header-content {
    display: flex;
    justify-content: space-between;
    margin: 0 3rem 0;
    flex-direction: row;

    .single-article-header-actions {
      display: flex;
      width: 40rem;
      height: 6rem;
      justify-content: flex-start;
      align-items: center;

      .icon-box {
        padding: 0;
        display: inline-block;
        border: none;
        cursor: pointer;
        outline: 0;
        background-color: $gray-bg-color;
        transition: all 0.3s ease-in-out;

        .la-Print {
          padding: 0 1rem 0 0;
          font-size: 3rem;
        }

        .la-Email {
          font-size: 3rem;
          padding: 0 1rem 0 1rem;
          border-left: 1px solid $header-submenu-border;
        }

        .la-Download {
          font-size: 3rem;
          padding: 0 1rem 0 1rem;
          border-left: 1px solid $header-submenu-border;
          border-right: 1px solid $header-submenu-border;
        }

        &:active {
          opacity: 0.5;
        }

        &:disabled {
          opacity: 0.5;
          cursor: initial;
        }
      }

      .hits-navigator {
        display: flex;
        align-items: center;
        margin-left: 1.3rem;
        white-space: nowrap;
        font-size: $font14;
        font-weight: bold;

        .hits-navigator-buttons {
          display: inline-block;
          margin: 0 1rem;
          border: 0.1rem solid $default-border-color;
          border-radius: 2px;

          .hits-button {
            width: 3.4rem;
            height: 3.2rem;
            border: none;
            font-size: 2.4rem;
            background-color: inherit;

            &:hover {
              background-color: $navigation-button-color-hover;
              cursor: pointer;
            }

            &:focus {
              outline: none;
            }

            &:disabled {
              background-color: inherit;

              .la-TriangleUp,
              .la-TriangleDown {
                color: $button-color-disabled;
                cursor: initial;
              }
            }
          }

          .previous {
            .la-TriangleUp {
              color: $button-color-click;
            }
          }

          .next {
            .la-TriangleDown {
              color: $button-color-click;
            }
          }
        }
      }

      .add-to-report-btn {
        margin-left: 1.4rem;
        border-radius: 0.3rem;
        border: solid 0.2rem $rebranding-backToTop-bg-color;
        background-color: $rebranding-backToTop-bg-color;
        color: $white;
        line-height: 1.2rem;
        box-shadow: none;
        padding: 0 2.7rem;
        font-size: 1.4rem;

        &:hover {
          box-shadow: 0 0 0 0.03rem $button-color-hover,
            0 0 0 0.3rem $button-hover-border;
        }

        .add-to-report-content {
          min-width: initial;

          .icon {
            display: none;
          }
        }
      }

      .la-ZoomIn {
        color: $white;
      }
    }

    .action-right {
      display: flex;
      align-items: center;

      a {
        color: $button-link-primary-color;
      }
    }
  }

  .google-translate {
    #google-translate-element {
      display: flex;
      height: 3.6rem;
    }

    .goog-te {
      &-combo {
        height: 100%;
        padding: 0 4rem 0 1.5rem;
        border: 0.1rem solid $header-submenu-border;
        border-radius: 0.3rem;
      }

      &-gadget-simple {
        display: flex;
        align-items: center;
        height: 100%;
        border: none;
        padding: 0 4rem 0 1.5rem;
      }

      &-menu-value {
        font-size: 1.4rem;
        margin: 0 0 0 0.9rem;
        position: relative;

        &::after {
          font-family: "GVS_Icons", sans-serif;
          position: absolute;
          content: "\e60a";
          top: 0.5rem;
          right: -2.7rem;
          font-size: 1.3rem;
          line-height: 1;
          font-weight: bold;
        }

        span {
          &:not(:first-child) {
            display: none;
          }
        }

        img {
          display: none;
        }
      }
    }
  }
}