.report-list-categories-container{
  padding: 2rem 3.5rem 2rem 4.5rem;
  transition: height 1s ease-in-out 10s;
  display: flex;
  flex-direction: column;
  background-color: $gray-bg-color;
  border: .1rem solid  $gray-bg-color;
  margin: 0;


  &_title{
    font-size: $font15;
    font-weight: bolder;
    line-height: 2.13;
    text-align: left;
  }
  &_list{
    font-size: $font16;
    line-height: 1.38;
    margin-top: 1rem;
  }
  &_buttons{
    align-self: flex-end;
    margin-top: 1.5rem;

    .button-primary-sm{
      margin-right: 1.5rem;
    }

  }
}