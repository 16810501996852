.dnb-search-wrapper {
  margin: 0 16rem 1.8rem;

  @media screen and (min-width: 1366px) {
    width: 75%;
    margin: 0 auto 1.8rem;
  }

  &__container {
    background-color: $default-bg-color;
    border: 1px solid $light-border-color;
    box-shadow: 3px 4px 8px 0 rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 1.5rem;
    width: 45rem;
    color: $default-text-color;

    &__header {
      display: flex;
      flex-direction: row;
      width: 100%;

      .custom-toggle {
        height: 2.2rem;
        margin-right: 1rem;
      }
    }

    &__content {
      margin: 1rem 4.3rem 1rem;

      &__input {
        display: flex;
        flex-direction: column;
        color: $placeholder-color;
        margin: 1rem -2.5rem 1rem;

        input {
          font-family: $secondary-font-stack;
          border: 1px solid $light-border-color;
          border-radius: 2px;
          font-size: 1.4rem;
          line-height: 1.5rem;
          height: 4rem;
          margin-top: 1rem;
          text-align: center;
          width: 11rem;

          &:hover {
            border: solid 1px $input-focus-color;
          }

          &:focus {
            border: solid 1px $dark-blue;
            outline: 0;

            &::placeholder {
              color: $default-bg-color;
            }

            &:-ms-input-placeholder {
              color: $default-bg-color;
            }
          }

          &:disabled {
            border: solid 1px $default-border-color;
          }

          &::placeholder {
            font-style: italic;
            color: $placeholder-color;
          }

          &:-ms-input-placeholder {
            font-style: italic;
            color: $placeholder-color;
          }
        }
      }
    }
  }
}
