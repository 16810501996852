.popup-modal {
  &__tabbed-content {
    min-height: 54.8vh;

    .tabs-wrapper {
      margin-bottom: 1.5rem;

      .tabs {
        background-color: white;
        padding: 0 $popup-overall-padding 0;
        margin: 0 -3rem;

        .tab {
          font-size: 1.7rem;
          padding: unset unset 1rem;
          margin-right: 2rem;
        }
      }
    }
  }
}