.highcharts-container  {
  .highcharts-title {
    top: 11rem !important;
  }

  .highcharts-subtitle {
    top: 14.2rem !important;
  }

  .highcharts-data-labels {
    opacity: 1 !important;

    .donut-graph-label {

      .donut-graph-negativity-risk-label {
        display: flex;
        align-items: center;

        &__dot {
          height: 1rem;
          width: 1rem;
          border-radius: 50%;
          margin-right: .5rem;

          &.low {
            background-color: $negativity-level-pill-low;
          }

          &.medium {
            background-color: $negativity-level-pill-medium;
          }

          &.high {
            background-color: $negativity-level-pill-high;
          }
        }
      }

      &__percent {
        font-size: 1.4rem;
        font-weight: bold;
      }
    }
  }
}