#graph-legend {

  .container {
    margin: 2rem 0;
    display: flex;
    justify-content: left;
  }

  .legend {
    font-size: 1.2rem;
    font-weight: bold;
    padding: 0 2rem;
    display: flex;
    flex-direction: row;
    align-items: center;

    span {
      display: inline-block;
      margin-right: 0.5rem;
      line-height: 1;
    }

    &.activeCompany {
      padding-left: 0;

      span {
        background-color: $bov-graph-legend-active-company;
        border-radius: 50%;
        padding: 0.7rem;
        margin-right: 0.7rem;
      }
    }

    &.inactiveCompany {

      span {
        background-color: $bov-graph-legend-inactive-company;
        border-radius: 50%;
        padding: 0.7rem;
        margin-right: 0.7rem;
      }
    }

    &.familyRelationship {
      padding-left: 3rem;

      span:before {
          border-bottom: 0.1rem dashed black;
          content: '';
          width: 2rem;
          background-color: antiquewhite;
          position: absolute;
          margin-left: -2.7rem;
          margin-top: 0.5rem;
      }
    }

    &.professionalRelationship {
      padding-left: 3rem;

      span:before {
          border-bottom: 0.1rem solid black;
          content: '';
          width: 2rem;
          background-color: antiquewhite;
          position: absolute;
          margin-left: -2.7rem;
          margin-top: 0.5rem;
      }
    }
  }
}

#bov-simple-graph-container {
  width: 100%;
  height: 82rem;
  overflow: hidden;
  text-align: center;
  position: relative;

  .bov-graph-controls {
    position: absolute;
    background-color: #FFF;
    right: 1rem;
    top: 20rem;
    border: 0.1rem solid $bov-graph-zoom-color;
    border-radius: 0.5rem;
    max-width: 3.5rem;
    padding: 0.2rem;
    text-align: center;
    box-shadow: $default-border-color 0 0 0.4rem;

    .zoom-control {
      display: inline-block;
      color: $bov-graph-zoom-color;
      line-height: 3.5rem;
      font-weight: bold;
      font-size: 2.5rem;
      cursor: pointer;
      border-bottom: 0.1rem solid $default-border-color;

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .tooltips {
    font-size: 1.6rem;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
}

#simple-graph-container {
  .nodes {
    .root-node {
      &.circle {
        fill: $bov-graph-main-company-fill-active;
        stroke: $bov-graph-main-company-stroke-active;
        stroke-width: 0.25rem;
      }

      &.title {
        fill: $bov-graph-default-text-color;
        font-weight: bold;
        text-anchor: middle;
        transform: translate(0, -1rem);
        pointer-events: none;
      }

      &.tax-id {
        fill: $bov-graph-default-text-color;
        font-size: 1rem;
        text-anchor: middle;
        pointer-events: none;
      }

      &.icon {
        pointer-events: none;
      }
    }

    &.active {
      .circle {
        fill: $bov-graph-main-company-fill-active;
        stroke: $bov-graph-main-company-stroke-active;
      }
    }

    &.inactive {
      .circle {
        fill: $bov-graph-main-company-fill-inactive;
        stroke: $bov-graph-main-company-stroke-inactive;
      }
    }

    &.unknown {
      .circle {
        fill: $bov-graph-main-company-fill-unknown;
        stroke: $bov-graph-main-company-stroke-unknown;
      }
    }

    .person-node {
      &.circle {
        fill: $bov-graph-person-node-fill;
        stroke-width: 0.3rem;
      }
    }

    .person-node-title {
      font-weight: bold;
    }

    .company-node {
      &.rect {
        rx: 0.4rem;
        ry: 0.4rem;
        stroke-width: 0.15rem;
        font-weight: bold;
        stroke: $bov-graph-company-node-stroke;
        fill: $bov-graph-company-node-fill;
      }

      &.title {
        fill: $bov-graph-default-text-color;
        font-weight: bold;
        text-anchor: middle;
        transform: translate(0, -1rem);
        pointer-events: none;
      }

      &.tax-id {
        fill: $bov-graph-default-text-color;
        font-size: 1.3rem;
        text-anchor: middle;
        pointer-events: none;
      }
    }
  }

  .paths-wrapper {
    .path {
      .link {
        stroke: $bov-graph-links-color;
        stroke-width: 0.2rem;
        fill: none;
      }

      .link.PROFESSIONAL {
        stroke-dasharray: none;
      }

      .link.FAMILY {
        stroke-dasharray: 0.2rem;
      }
    }
  }
}

.hovercard-container {
  padding: 1rem;
  pointer-events: none;
  position: fixed;
  opacity: 0;
  background-color: $white;
  width: 23rem;
  border-style: solid;
  border-width: 0.1rem;
  border-color: $bov-graph-hovercard-rect-stroke;

  .hovercard-header {
    h6 {
      font-size: 1.2rem;
      font-weight: bold;
      line-height: 1.5rem;
      color: $black;
    }
  }

  .hovercard-body {
    .hovercard-content {
      margin: 0;
      font-size: 1.2rem;
    }
  }
}

.bov-parsing-error {
  @include alert-message($light-popup-message-bg-color, $popup-user-error-color);
  position: relative;

    &__icon {
        color: $popup-user-error-color;
        padding-right: 1.5rem;
        display: flex;
        align-items: center;
    }
  
    &__message {
        border-left: 0.1rem solid $popup-user-error-color;
        padding-left: 1.5rem;
    }
  
    &.dark-mode {
        background: $popup-bg-info-dark;
        color: $popup-text-color-dark;
        border-bottom: 0.1rem solid $popup-border-color-warning-dark;

        .bov-parsing-error {
          &__message {
            border-left: 0.1rem solid $popup-border-color-info-dark;
          }
        }
    }
}