
.loader-container{
  position: relative;
  top: 25rem;

  &__header{
    h3{
      width: 50%;
      margin: auto;
      text-align: center;
    }
  }

  &__elements{
    display: flex;
    justify-content: center;
    align-items: center;
    height: auto;
    margin-top: 4rem;

    .loader{
      border-radius: 50%;
      background-color: $button-link-primary-color;
      margin-right: 2.5rem;
      animation: scaleUp 1.5s infinite ease-in;
    }

    #loader1{
      width: 0.8rem;
      height: 0.8rem;
    }

    #loader2{
      width: 1.3rem;
      height: 1.3rem;
      animation-delay: 0.2s;
    }

    #loader3{
      width: 1.5rem;
      height: 1.5rem;
      animation-delay: 0.5s;
    }
  }

  @keyframes scaleUp {
    0%, 20%, 100%{
      transform: scale(1);
    }
    5%{
      transform: scale(1.5);
    }
  }
}
