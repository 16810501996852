.editable-text{
    max-width: 24rem;
    height: 3rem;
    margin-bottom: 2.5rem;
    margin-left: 1rem;

  .characters-count {
    font-size: 12px;

    &.remaining {
      float: right;
      margin-right: 1.6rem;
      color: $placeholder-color;
      font-style: italic;
    }

    &.max-limit {
      color: $secondary-warning-color;
      float: left;
      font-size: $font14;
    }
  }
}