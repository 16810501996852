.user-preferences-category-container__list{
  padding-top: .2rem;
}

.user-preferences-category-container{
   &_row {
     width: 100%;

    &_list-item {
      display: flex;
      flex-direction: row;
      align-items: center;
      width: 100%;
      padding: 0.5rem 0 0.5rem 0;

      @keyframes droppedAnimation {
        0%   {background-color: inherit;}
        50%  {background-color: $dropped-drag-and-drop-color;}
        100%    {background-color: inherit;}
      }

      &.dropped {
        animation: droppedAnimation 2s;
        animation-iteration-count: 1;
      }

      &.drop-top {
        border-top: 2px solid $header-menu-dropdown-hover;
      }
      &.drop-bottom {
        border-bottom: 2px solid $header-menu-dropdown-hover;
      }

      &.dragging {
        color: $disabled-drag-and-drop-color;
        background-color: inherit;
        cursor: move;
      }
      &_title {
        padding-left: 0.5rem;

        &:after{
          background-color: $dropped-drag-and-drop-color;
          animation: droppedAnimation 2s;
          animation-iteration-count: 1;
        }
      }

      .draggable-icon{
        margin: 0 1rem 0 1.5rem;
      }
    }

     &.selected-row,
     &:hover {
       cursor: move;
       background-color: $selected-drag-and-drop-color;
     }

     &.disabled {
       pointer-events: none;
       cursor: not-allowed;
       &:hover {
         border: none;
         cursor: initial;
         background-color: $disabled-text-color;
       }
     }

     &.drag-and-drop-disabled {
       pointer-events: none;
       cursor: not-allowed;

       &:hover {
         cursor: initial;
       }
     }
  }
}

.user-preferences-category-container_row  {
  position: relative;

  &.dragging{
    border: 1px dashed $default-border-color;
    background-color: inherit;

    &_list-item.dragging {
      background-color: inherit;
      cursor: move;
    }
  }
}