.negative-terms-container.snapshot {
  .negative-terms-container-headers,
  .negative-terms-container-item {
    display: flex;
    align-items: center;

    span.negative-terms-container-headers-label {
      p {
        display: inline-block;
      }
    }

    span.negative-terms-container-headers-label,
    div.negative-terms-container-column {
      font-weight: bold;
      font-size: 1.4rem;

      &:first-child {
        flex: 0 0 35%;

        p {
          display: inline-block;

          &.languageCode {
            color: $negativity-pill-language-color;
            padding-left: 0.5rem;
          }
        }

        &.label {
          min-width: 0;

          p {
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            max-width: calc(100% - 3rem);
            vertical-align: top;
          }
        }
      }

      &:nth-child(2) {
        text-align: center;
        flex: 0 0 20%;

        .negative-terms-container-item-negativity-pill {
          width: fit-content;
          margin: 0 auto;
          color: $negativity-pill-language-color;

          @media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
            .negativityLevelPillWrapper {
              display: inline-flex;
            }
          }
        }
      }

      &:nth-child(3) {
        text-align: center;
        flex: 0 0 20%;

        &.count {
          color: #3d3d3f;
        }
      }

      &:nth-child(4) {
        text-align: center;
        flex: 0 0 25%;
      }
    }
    
    .negative-terms-container-column {
      &-term {
        color: $button-link-primary-color;

        &:hover {
          cursor: pointer;
          text-decoration: underline;
        }
      }
    }
  }

  .negative-terms-container-headers {
    border-bottom: 1px solid $header-submenu-border;
    padding-bottom: 1rem;
  }
}