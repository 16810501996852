.modal-upload-entities {
    display: flex;
    justify-content: flex-start;

    .create-new-view {
        display: flex;
        flex-direction: column;
        position: relative;
        color: $comment-title-text-color;
        white-space: nowrap;
        width: 30rem;
        padding-top: 2.5rem;
        margin-bottom: 12.5rem;
        margin-left: 2rem;
        height: 10rem;
        align-items: flex-end;
        .input-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 4.2rem;
            line-height: 5.2rem;
            background-color: $white;
            border: 0.1rem solid $pill-selection-icon-default;
            border-radius: 0.3rem;
            min-width: 12rem;
            max-width: 30rem;
            padding: 0 1.4rem;
            width: 100%;
            flex-direction: column;
        }
        .label {
            width: 100%;
        }
        input:disabled {
            color: $disabled-text-color;
            border-color: $disabled-text-color;
            cursor: default;
        }
        .characters-count {
            font-size: $font12;
            width: 28.1rem;
            margin-top: 1rem;

            &.remaining {
                float: right;
                padding-right: 0.6rem;
                color: $placeholder-color;
                font-style: italic;
                text-align: right;
            }

            &.max-limit {
                color: $secondary-warning-color;
                float: left;
                font-size: $font14;
                white-space: normal;
            }
        }
    }
}