﻿.popup-modal {
    display: flex;
    flex-direction: column;
    max-height: 65vh;
    color: $popup-color-default;

    &.createAlerts {
        .popup-modal-body {
            margin-top: 1.1rem;

            .validationAlertMessage {
                display: block;
            }

            .popup-modal__comments {
                .text-area-standard {
                    width: 90%;
                }
            }
        }
    }

    .remainingCharactersMessage {
        display: inline;
        float: right;
        font-size: $font14;
        font-style: italic;
        color: $placeholder-color;
    }

    .validationAlertMessage {
        display: inline;
    }

    &.addCustomNewsQuery,
    &.editCustomNewsQuery,
    &.viewCustomQuery {
        .popup-modal-header {
            padding-bottom: 0.5rem;
        }

        .popup-modal-body {
            .popup-modal__description {
                font-size: $font14;
                margin: 0 0 1.5rem;
            }
        }
    }

    .popup-modal-header {
        padding: $popup-overall-padding $popup-overall-padding $popup-header-bottom-padding;
        font-size: 24px;
        font-weight: 400;

        .dismissible-message {
            width: 100%;
            font-size: 1rem;
            height: 0;
            opacity: 0;
            top: 0;
            overflow: hidden;
            position: relative;
            padding: 0.2rem 0 0.2rem;
            transition: all 0.2s linear;
            background-color: $popup-bg-color;

            &:before {
                @include popup-message-vertical-strip($popup-user-error-color);
            }

            &.slide-down {
                height: auto;
                margin: 1rem 0 1rem;
                opacity: 1;
            }

            &__text {
                display: inline-block;
                margin-right: 10rem;
                align-items: center;
                font-size: 1.4em;
                padding: 1.5rem;

                h3 {
                    font-size: 1.5rem;
                    margin-bottom: 1rem;
                }
            }

            &__button {
                background-color: $popup-bg-color;
                border-left-color: $white;
                display: inline-block;
                font-size: 1.4em;
                height: 100%;
                line-height: inherit;
                outline: 0;
                position: absolute;
                align-items: center;
                right: 0;
                top: 0;

                &:hover {
                    background-color: $default-bg-color;
                }
            }
        }
    }

    .popup-modal-body {
        padding: 0 $popup-overall-padding;
        overflow: auto;

        .alert-delivery-options {
            &_send-to {
                display: flex;
                flex-direction: column;
                margin-bottom: 2rem;

                .text-field-standard-lg {
                    width: 90%;
                    height: 4.8rem;
                    margin: 0.5rem 0;
                }
            }

            &_labels {
                font-size: $font16;
                font-weight: bold;
            }
        }

        .create-alert-alert-frequency-container {
            &_body {
                display: flex;
                flex-direction: column;

                .create-alert_label {
                    font-size: $font16;
                    font-weight: bold;
                }

                .create-alert_select {
                    height: 3.5rem;
                    margin: 1.5rem 1rem 0 0;
                    width: 12rem;
                }

                .create-alert-sending-options-container_send-as {
                    display: flex;
                    flex-direction: column;
                    margin: 2.5rem 0;

                    .create-alert-send-as {
                        display: flex;
                        flex-direction: column;
                    }

                    .create-alert-format {
                        display: flex;
                        flex-direction: column;
                        margin-top: 1.5rem;
                    }
                }

                .toggle-no-results-alerts {
                    display: flex;

                    &_message {
                        margin: -0.5rem 0 0 1.5rem;
                    }
                }

                &__frequency__options__connector {
                    margin-right: 0.5rem;
                }
            }
        }

        .indent-field {
            margin-left: 1.3rem;
        }

        .popup-modal {
            &__result-list {
                padding: 0 0 $popup-section-bottom-padding;

                .custom-radio {
                    margin-top: 0.2rem;
                    margin-bottom: 0.2rem;
                }
            }

            &__columns-list {
                display: flex;
                flex-direction: column;
                align-items: flex-start;

                a {
                    color: $button-link-primary-color;
                }

                .custom-checkbox {
                    display: flex;
                    align-items: center;
                    margin: 0.5rem 1rem;

                    input[type='checkbox']+.checkbox-label:before {
                        top: 0.4rem;
                        height: $font16;
                        width: $font16;
                    }
                }

                .checkbox-label:not(.no-left-padding) {
                    padding-left: 1.4rem;
                }

                .no-left-padding {
                    padding-left: 0;
                    margin-left: 0;
                }
            }

            &__report-list {
                padding: 0 0 $popup-section-bottom-padding;

                .divider {
                    border-top: 1px dotted $default-border-color;
                }

                .reports-list-titles {
                    margin: 1rem 0 1rem;

                    .reports-list-title {
                        position: relative;

                        input[type='checkbox'] {
                            z-index: 1;
                            cursor: pointer;
                            top: 0.5rem;
                        }
                    }
                }

                .select-all-buttons {
                    .button-select-reports {
                        width: auto;
                        background: none;
                        border: none;
                        outline: none;
                        padding: 0.5rem;
                        text-align: right;
                        color: $button-link-primary-color;
                        font-size: 1.6rem;
                        cursor: pointer;

                        &:hover {
                            color: $primary-color-hover;
                            text-decoration: underline;
                        }

                        &:active {
                            opacity: 0.5;
                            text-decoration: underline;
                        }

                        &:disabled {
                            color: #d2d4d5;
                            cursor: not-allowed;
                            text-decoration: none;
                        }
                    }
                }
            }

            &__file-format {
                padding: 0 0 $popup-section-bottom-padding;

                .popup-field-name {
                    font-weight: bold;
                    padding-bottom: 0.8rem;
                }

                .custom-radio {
                    font-size: $font16;
                    margin-top: 0.2rem;
                    margin-bottom: 0.2rem;
                }
            }

            &__email-info {
                padding: 0 0 $popup-section-bottom-padding;

                .popup-field-name {
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                }

                .text-field-standard-lg {
                    margin: 0.5rem 0 1rem;
                    width: 100%;
                }

                .text-area-standard {
                    width: 100%;
                    margin: 0.5rem 0 0;
                    min-height: 6.5rem;
                }

                &-to {
                    padding-bottom: $popup-section-bottom-padding;

                    .popup-field-subname {
                        color: $subfield-text-color;
                    }
                }

                &-subject {
                    padding-bottom: 1rem;
                }
            }

            &__comments {
                padding: 0 0 $popup-section-bottom-padding;

                .popup-field-name {
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                }

                .text-area-standard {
                    width: 100%;
                    min-height: 6.5rem;
                    margin: 0.5rem 0 0;
                }
            }

            &__contentSources {
                position: relative;

                .popup-field-name {
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                }

                .text-area-standard {
                    width: 100%;
                    min-height: 6.5rem;
                    margin: 0.5rem 0 0;
                }

                .contentSources-warning {
                    color: red;
                }
            }

            &__file-name {
                padding: 0 0 $popup-section-bottom-padding;

                .popup-field-name {
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                }

                .text-field-standard-lg {
                    width: 100%;
                    margin: 0.5rem 0 0;
                }

                &~.popup-modal__comments {
                    padding-top: 1rem;
                }

                .download-expired-entities {
                    margin-bottom: 2.5rem;
                }
            }

            &__entity-name {
                padding: 0 0 $popup-section-bottom-padding;

                .popup-field-name {
                    padding-bottom: 0.5rem;
                    font-weight: bold;
                }

                .text-field-standard-lg {
                    width: 100%;
                    margin: 0.5rem 0 0;
                }
            }

            &__warning {
                .modal-warning {
                    background-color: $popup-bg-color;
                    font-size: 1.4rem;
                    margin: 0 2rem;
                    padding: 1rem;

                    &:before {
                        @include popup-message-vertical-strip($popup-user-error-color);
                    }
                }

                .divider {
                    border-top: 1px dotted $default-border-color;
                    margin: 0 2rem 0;
                }
            }

            &__description {
                margin: 0 0 $popup-section-bottom-padding 0;
                font-size: $popup-text-font-size;

                p {
                    margin-bottom: 2rem;
                }
            }

            &__explanation {
                padding: 0;

                .explanation {
                    font-size: $popup-text-font-size;
                }

                .horizontal-divider {
                    opacity: 0;
                    border-top: 1px dotted $black;
                }
            }

            &__editable-title {
                font-size: $font16;
                font-weight: bold;
                cursor: pointer;
                margin-top: 1.2rem;

                &.readOnly {
                    pointer-events: none;
                    cursor: not-allowed;
                }

                .la-Edit {
                    display: inline;
                    font-size: 1.8rem;
                    margin-left: 0.5rem;
                }
            }

            &__editable-title-input {
                font-size: $font16;
                font-weight: 400;
            }

            &__delivery-content-options {
                padding: 0 0 $popup-section-bottom-padding;

                &__header {
                    font-size: $font16;
                    color: $button-link-primary-color;
                    line-height: 24px;
                    margin-bottom: 1rem;
                    font-weight: bold;

                    &:hover {
                        cursor: pointer;
                        text-decoration: underline;

                        .icon {
                            cursor: pointer;
                            display: inline-block;
                            text-decoration: none;
                        }
                    }

                    .icon {
                        color: $primary-color-hover;
                    }
                }

                &__body {
                    margin-top: 0.5rem;

                    &__options-container {
                        .option {
                            font-size: $font16;
                            margin: 0.2rem 0;

                            input {
                                margin-right: 1rem;
                            }

                            label[data-disabled='true'] {
                                color: $disabled-text-color;
                            }
                        }

                        &__warning {
                            .option input[type='checkbox'] {
                                +label:before {
                                    border-color: $secondary-warning-color;
                                }
                            }
                        }

                        &__children {
                            margin-left: 2rem;
                        }
                    }

                    &__warning {
                        font-size: $font14;
                        color: $secondary-warning-color;
                        line-height: 24px;
                        margin-bottom: 0.5rem;
                    }
                }
            }

            &__addHoc-scrollable-area {
                &__sources {
                    margin: 0 2rem 0;
                    padding-top: 1rem;

                    .source-type-container {
                        &__header {
                            display: flex;
                            justify-content: space-between;

                            .admin-lock {
                                margin-left: 1.5rem;
                                font-size: 3rem;
                                color: $tertiary-color;
                            }
                        }
                    }

                    .user-preferences-category-container__list {
                        padding: 0;
                    }
                }

                &__dateRanges {
                    margin: 1rem 0 0 0;
                    padding-top: 1rem;
                    display: flex;
                    flex-direction: column;

                    .horizontal-divider {
                        opacity: 0;
                        margin-top: 1rem;
                    }

                    .dateRanges-container-name {
                        margin-top: 1rem;
                    }

                    .dateRanges-container {
                        display: flex;
                        flex-direction: row;
                        justify-content: space-between;

                        .dateRanges-explanation {
                            width: 45%;

                            .explanation {
                                color: $placeholder-color;
                            }
                        }

                        .dateRanges-dropdown {
                            width: 50%;

                            .proximity-options {
                                width: 100%;

                                select {
                                    width: inherit;
                                    height: 4rem;
                                }
                            }

                            .legal-sources-options {
                                width: 100%;

                                select {
                                    width: inherit;
                                    height: 4rem;
                                }

                                &>select:disabled {
                                    cursor: not-allowed;
                                    background: none;
                                }
                            }

                            .news-sources-dropdown {
                                width: 100%;

                                select {
                                    width: inherit;
                                    height: 4rem;
                                }

                                &>select:disabled {
                                    cursor: not-allowed;
                                    background: none;
                                }
                            }

                            .date-ranges-dropdown {
                                display: block;

                                .sort-date-range {
                                    width: 100%;

                                    select {
                                        width: inherit;
                                        height: 4rem;
                                    }
                                }

                                .custom-date-picker {
                                    position: relative;

                                    .date-select {
                                        font-size: 1.4rem;
                                        width: 50%;

                                        &:first-child {
                                            margin-right: 0.5rem;
                                        }

                                        input {
                                            float: left;
                                            line-height: 2.8rem;
                                            font-size: 1.4rem;
                                            padding: 0;
                                            height: 2.8rem;
                                            width: 65%;
                                            max-width: inherit;
                                        }

                                        i.la-Calendar {
                                            width: 20%;
                                            font-size: 2.8rem;
                                            margin-left: 0.5rem;
                                            color: $primary-color-hover;
                                            position: relative;
                                        }

                                        .date-select-object {
                                            left: 0;
                                            margin-top: 0;
                                        }
                                    }
                                }
                            }
                        }

                        .admin-lock {
                            margin-left: 0.5rem;
                            font-size: 3rem;
                            color: $tertiary-color;
                        }
                    }
                }

                &__default-sources {
                    @extend .popup-modal__addHoc-scrollable-area__dateRanges;
                    margin: 0;
                    padding: 0;

                    .default-sources-container {
                        @extend .dateRanges-container;
                        margin: 1.5rem 0;

                        .default-sources-explanation {
                            @extend .dateRanges-explanation;

                            .default-legal-sources,
                            .default-news-sources {
                                font-size: 1.4rem;
                                font-weight: 700;
                            }

                            .explanation {
                                @extend .explanation;
                                color: $popup-color-default;
                            }
                        }

                        .default-sources-dropdown {
                            @extend .dateRanges-dropdown;
                            position: relative;

                            span.user-section-lock {
                                font-size: 3rem;
                            }
                        }

                        .default-sources-dropdown-locked {
                            position: absolute;
                            top: -0.1rem;
                            right: 0;

                            span>button::before {
                                position: relative;
                            }
                        }
                    }
                }

                &__cost-code {
                    @extend .popup-modal__addHoc-scrollable-area__dateRanges;
                    margin: 0;
                    padding: 0;

                    .cost-code-container-main {
                        @extend .dateRanges-container;

                        .cost-code-explanation {
                            @extend .dateRanges-explanation;

                            .cost-code {
                                font-size: 1.4rem;
                                font-weight: 700;
                            }

                            .explanation {
                                @extend .explanation;
                                color: $popup-color-default;
                            }
                        }

                        .cost-code-dropdown {
                            @extend .dateRanges-dropdown;

                            .user-preferences-category-container__row {
                                display: none;
                            }

                            .cost-code-options {
                                padding: 0;
                                width: 100%;

                                select {
                                    width: inherit;
                                    height: 4rem;
                                    vertical-align: bottom;
                                }
                            }
                        }

                        .user-specified-cost-code-warnings {
                            padding-top: 0.5rem;

                            .validationMessage {
                                padding-bottom: 0.5rem;
                            }
                        }

                        .user-specified-cost-input-container {
                            input {
                                width: 100%;
                            }

                            .button-primary-sm {
                                margin-left: 1.5rem;
                            }

                            .custom-cost-code-inputBox {
                                padding-bottom: 0;
                            }
                        }

                        .max-number-of-characters {
                            display: flex;
                            justify-content: flex-end;
                            color: $placeholder-color;
                            font-style: italic;
                            font-size: 1.2rem;
                        }
                    }
                }
            }

            &__updateCheckbox {
                font-size: 1.3rem;
                margin: 0;
                z-index: 100;
                position: absolute;
                bottom: $popup-overall-padding;
            }

            &__edit-negative-custom-news-query {
                &__textarea {
                    @extend .popup-modal__edit-negative-search-terms__textarea;
                    padding-top: 0.5rem !important;

                    &__error-message {
                        @extend .popup-modal__edit-negative-search-terms__textarea__error-message;
                        margin-bottom: 0.5rem;
                    }

                    textarea {
                        color: $default-text-color;
                        border: dotted 0.2rem $popup-textarea-border-color;
                        border-radius: 0.4rem;
                        padding: 1.2rem;
                        margin-top: 0.1rem;
                        resize: none !important;

                        &:focus {
                            outline: none !important;
                            border: solid 0.2rem $popup-textarea-border-color;
                        }
                    }
                }

                &__action {
                    margin: 0 0 0 5px;
                    font-size: 14px;
                    font-weight: normal;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.21;
                    letter-spacing: normal;
                    text-align: left;
                    background: none;
                    border: none;
                    outline: none;
                    color: $button-link-primary-color;
                    cursor: pointer;
                    float: right;
                    padding-top: 1.3rem;

                    .edit-button {
                        display: flex;

                        .la-Edit {
                            padding-right: 0.3rem;
                        }

                        .edit-label {
                            text-decoration: underline;
                        }
                    }
                }
            }

            &__edit-negative-search-terms {
                .tabs {
                    background-color: $default-bg-color;
                    border: none;
                    padding: 0 1rem 0;
                    border-bottom: 1px solid $version-color;

                    .tab {
                        font-size: $font15;
                        margin-right: 7rem;
                    }
                }

                &__textarea {
                    width: 100%;
                    padding: 1.5rem 0;
                    display: flex;
                    flex-direction: column;
                    align-items: flex-end;
                    overflow: auto;

                    &__error-message {
                        width: 100%;
                        color: $secondary-warning-color;
                        margin-bottom: 0.5rem;
                    }

                    &__reset-message {
                        .embedded-message {
                            position: relative;
                            font-size:1.5rem;
                            height: 8rem;
                            width: 100%;
                            padding: 1.5rem;
                            margin-bottom: 1.5rem;

                            &__icon {
                                padding-right: 1.5rem;
                                display: flex;
                                align-items: center;
                            }

                            &__message {
                                padding-left: 1.5rem;
                                min-width: 70%;
                                max-height: 5rem;
                                overflow-y: auto;

                                &__bolder {
                                    font-weight: bolder;
                                }
                            }

                            &.info {
                                @include alert-message($light-popup-message-bg-color, $info-banner-color);

                                .embedded-message__message {
                                    border-left: 0.1rem solid $popup-border-color-info-light;
                                }

                                &.dark-mode {
                                    background-color: $popup-bg-info-dark;
                                    color: $popup-text-color-dark;
                                    border-bottom: 0.1rem solid $popup-border-color-info-dark;

                                    .embedded-message__message {
                                        border-left: 0.1rem solid $popup-border-color-info-dark;
                                    }
                                }
                            }
                        }
                    }

                    textarea {
                        border: 0.1rem solid $default-border-color-darker;
                        width: 100%;
                        min-height: 20rem;
                        resize: vertical;

                        &::placeholder {
                            color: $placeholder-color;
                        }

                        &.textarea-error,
                        &:focus.textarea-error {
                            outline: none !important;
                            border-color: $secondary-warning-color;
                        }
                    }

                    &__reset {
                        background: none;
                        border: none;
                        outline: none;
                        text-align: left;
                        padding: 0 0 1.5rem 0;
                        color: $button-link-primary-color;
                        cursor: pointer;
                        width: 19rem;
                        margin-top: 0.9rem;

                        &:hover {
                            color: $primary-color-hover;
                            text-decoration: underline;
                        }

                        &:active {
                            opacity: 0.5;
                            text-decoration: underline;
                        }

                        &:disabled {
                            cursor: initial;
                            text-decoration: none;
                        }
                    }
                }
            }
        }

        .addHoc-scrollable-area {
            max-height: calc(65vh - 10rem);

            &__message {
                &__admin-lock {
                    background-color: $light-blue-bg-color;
                    margin: 0 1rem 0 2rem;
                    padding: 0 1rem 0;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .admin-lock {
                        color: $tertiary-color;
                    }
                }
            }

            .section-disabled {
                background-color: $light-border-color;
                cursor: not-allowed;
            }
        }

        // Entity View Upload Entities popup

        .batch-upload-drag-and-drop {
            .la-CloseRemove {
                border: 0;
                display: inline-block;
                padding: 0;
                background: transparent;
                color: $placeholder-color;
                outline: none;
                cursor: pointer;
                font-weight: 600;
            }

            .batch-upload-actions {
                display: flex;
                justify-content: space-between;
                align-items: flex-start;
                width: 100%;
                margin-top: 1.5rem;

                &__download-template {
                    display: flex;

                    a {
                        font-size: $font14;
                        line-height: 1.4rem;
                        height: 1.4rem;
                        color: $button-link-primary-color;
                        margin-right: 0.5rem;
                    }

                    .la-InfoHover {
                        color: $button-link-primary-color;
                        height: 1.5rem;
                    }

                    .table-format-example-tooltip {
                        width: 64.2rem;
                        min-height: 15rem;
                        box-shadow: 0 0 1.2rem #00000057;
                        opacity: 1;

                        &::after {
                            left: 20.6%;
                        }
                    }
                }

                .cancel-btn {
                    &:disabled {
                        border-width: 0.2rem;
                    }
                }
            }
        }

        .batch-upload-format-example {
            display: flex;
            flex-direction: column;
            margin-bottom: 0.5rem;
            font-size: 1rem;

            &__title {
                margin: 0.5rem 0;
                display: inline-block;
            }

            table {
                width: 60rem;
                height: 10rem;
                line-height: 1.5;
                font-size: 0.9rem;
            }

            table tr th,
            td {
                border: 0.1rem solid $default-border-color-lighter;
                padding: 0.4rem 1rem;
            }

            table thead tr {
                height: 2rem;
            }

            table thead th {
                background-color: #f9f9f9;
                text-align: center;
            }

            table tr,
            td {
                height: 2.5rem;
            }

            table tr:first-child {
                font-weight: bold;
            }
        }

        .radio-list {
            display: inline-block;

            .custom-radio {
                margin: 0.5rem 0;

                label {
                    display: flex;
                    flex-direction: row;

                    &:before {
                        line-height: 0;
                        padding-left: 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                    }
                }
            }
        }

        div:last-of-type:not(.items-order-menu):not(.tooltips):not(.error):not(.warning):not(.info):not(.edit-subcategories-list-item):not(.user-preference-checkbox):not(.selected-sources-dropdown-content):not(.alert-message-info):not(.share-view-user-selection-item):not(.dropdown-popover__options__option):not(.dropdown-container),
        div:only-child:not(.items-order-menu):not(.tooltips):not(.error):not(.warning):not(.info):not(.edit-subcategories-list-item):not(.user-preference-checkbox):not(.selected-sources-dropdown-content):not(.alert-message-info):not(.share-view-user-selection-item):not(.dropdown-container) {
            padding-bottom: 0.1rem;
            margin-bottom: 0;
        }

        // checked radio buttons and checkboxes not bolded
        .custom-radio input[type='radio']:checked+.radio-label,
        .custom-radio input[type='radio']:not(:checked):hover+.radio-label,
        .custom-checkbox input[type='checkbox']:checked+.checkbox-label,
        .custom-checkbox input[type='checkbox']:hover+.checkbox-label,
        .user-preference-checkbox input[type='checkbox']:checked+.preference-checkbox-label,
        .user-preference-checkbox input[type='checkbox']:hover+.preference-checkbox-label {
            font-weight: normal !important;
        }

        // checked radio buttons circle position
        .custom-radio input[type='radio']+.radio-label:before {
            height: 1.4rem;
            padding-left: 0;
            width: 1.4rem;
        }

        /* For IE11 - add top padding */
        @media all and (-ms-high-contrast: none),
        (-ms-high-contrast: active) {
            .custom-radio input[type='radio']+.radio-label:before {
                padding-top: 0.1rem;
            }
        }

        .embedded-message {
            grid-column: auto / span 4;
            position: relative;
            width: 100%;
            font-size: 1.5rem;
            margin: 1rem 0;
            padding: 1.5rem;
            height: 8rem;
            display: flex;
            overflow: hidden;

            a:hover {
                cursor: pointer;
            }

            &__message {
                padding-left: 1.5rem;
                min-width: 70%;
                max-height: 5rem;
                overflow-y: auto;
            }

            &__icon {
                padding-right: 1.5rem;
                display: flex;
                align-items: center;
            }

            &__link {
                display: flex;
                align-items: center;
                justify-content: flex-end;
                text-align: center;
                width: min-content;
            }

            &.error {
                @include alert-message($popup-bg-critical-light, $popup-system-error-color);

                .embedded-message__message {
                    border-left: 0.1rem solid $popup-system-error-color;
                    width: 100%;
                }

                &.dark-mode {
                    background-color: $popup-bg-critical-dark;
                    color: $popup-text-color-dark;
                    border-bottom: 0.1rem solid $popup-border-color-error-dark;

                    .embedded-message {
                        &__message {
                            border-left: 0.1rem solid $popup-border-color-error-dark;
                        }
                    }
                }
            }

            &.info {
                @include alert-message($light-popup-message-bg-color, $info-banner-color);

                .embedded-message__message {
                    border-left: 0.1rem solid $info-banner-color;
                }

                &.dark-mode {
                    background-color: $popup-bg-info-dark;
                    color: $popup-text-color-dark;
                    border-bottom: 0.1rem solid $popup-border-color-info-dark;

                    .embedded-message {
                        &__message {
                            border-left: 0.1rem solid $popup-border-color-info-dark;
                        }
                    }
                }
            }

            &.warning {
                @include alert-message($popup-bg-warning-light, $popup-border-color-warning-light);

                .embedded-message__message {
                    border-left: 0.1rem solid $popup-border-color-warning-light;
                }

                &.dark-mode {
                    background-color: $popup-bg-warning-dark;
                    color: $popup-text-color-dark;
                    border-bottom: 0.1rem solid $popup-border-color-warning-dark;

                    .embedded-message {
                        &__message {
                            border-left: 0.1rem solid $popup-border-color-warning-dark;
                        }
                    }
                }
            }
        }

        .notifications-container {
            margin-bottom: 1.3rem;
        }
    }

    &:not(.adHocSearch) {
        .popup-modal-body {

            @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
                max-height: calc(65vh - 10rem);
            }
        }
    }

    .popup-field-subname {
        font-size: 1.2rem;
        line-height: 1.7rem;
    }

    .popup-action-title {
        font-size: $popup-text-font-size;
        padding: 0;
    }

    .popup-field-name {
        font-size: $popup-text-font-size;
    }

    .preview-article-box {
        overflow: auto;
        margin: 0 0 $popup-section-bottom-padding;
        padding: 1rem;
        border: 1px solid $default-border-color;

        &::-webkit-scrollbar {
            height: 10px;
            width: 10px;
        }

        //hides Download Table button
        .SS_DownloadTable {
            display: none;
        }

        .preview-article-box-header {
            padding: 5rem 2rem 3rem;

            &__logo {
                display: block;
                margin-left: auto;
                margin-right: auto;
                width: 50%;
            }

            .preview-article-box-header-title {
                margin: 0 0 2rem;
                text-align: center;
            }

            .preview-article-box-header-logo {
                text-align: left;
                margin: 1rem 0 2rem;
                max-width: 100%;
                width: auto;
                align-self: flex-start;
                max-height: 80px;
            }

            .preview-article-box-header-subTitle {
                text-align: center;
                font-weight: bold;
                padding-bottom: 4rem;

                .vertical-divider {
                    margin: 0 1rem 0;
                }

                .preview-article-box-header-date {
                    margin-bottom: 1rem;
                    margin-top: 2rem;
                }
            }
        }

        &.article-edit-note {
            max-height: 32rem;
        }

        .preview-no-article-found {
            font-size: 1.7rem;
            color: $secondary-color;
        }
    }

    .add-note-to-article {
        padding: 0 0 1rem;

        span {
            font-size: $popup-text-font-size;
            font-weight: bold;
        }

        .validationAlertMessage {
            display: block;
        }

        .text-area-standard {
            width: 100%;
            height: 6.9rem;
            display: block;
            margin: 1rem 0 0;

            &.textarea-error {
                margin-top: 0.5rem;
            }
        }

        .add-note-to-article-buttons {
            padding-top: 1.8rem;
            justify-content: flex-end;
            display: flex;
            margin-left: auto;

            .button-primary-sm {
                margin-right: 0.5rem;
            }

            .button-secondary-sm {
                margin-left: 0.5rem;
            }
        }
    }

    &.previewUboArticle {
        .tabs {
            padding: 0;
            margin-top: 0;
            background-color: transparent;
        }

        .preview-article-box {
            max-height: none;
        }
    }

    &.printResultList,
    &.emailResultList,
    &.downloadResultList,
    &.printReports,
    &.emailReports,
    &.downloadReports,
    &.downloadReport,
    &.emailReportCategory,
    &.printReportCategory,
    &.downloadReportCategory {

        .custom-checkbox,
        .custom-radio,
        .popup-field-name,
        .popup-action-title,
        .popup-modal__description {
            font-size: $font16;
            color: $default-text-color;
            line-height: 24px;
        }
    }

    &.printResultList,
    &.printReports,
    &.printReportCategory {
        .popup-modal-body {
            height: 300px;
        }
    }

    &.downloadReports,
    &.downloadReportCategory,
    &.downloadResultList,
    &.downloadReport {
        .popup-modal-body {
            height: 400px;
        }
    }

    &.emailResultList,
    &.emailReports,
    &.emailReportCategory {
        .popup-modal-body {
            height: 475px;
        }
    }

    &.emailResultList,
    &.emailReports,
    &.emailReportCategory,
    &.downloadResultList,
    &.downloadReports,
    &.downloadReport,
    &.downloadReportCategory {
        .validationAlertMessage {
            font-size: $font14;
            color: $secondary-warning-color;
            line-height: 24px;
            display: inline;
        }
    }

    &.previewArticle,
    &.previewUboArticle,
    &.viewCustomQuery {
        .popup-modal-body {
            padding-bottom: $popup-overall-padding;
        }
    }

    &.deleteCustomNewsQuery {
        .popup-modal-body .popup-modal__description p {
            margin: 0;
            height: 1.5rem;
        }
    }

    &.addToReport {
        .custom-radio:first-of-type label {
            line-height: 2.3rem;
        }
    }

    // History -> Clear History popup
    &.deleteAllHistory {
        .popup-modal-body .popup-modal__delete-history-body {
            font-size: $font16;
            margin-right: 5rem;
        }
    }

    &.saveSearch {
        .popup-modal-body {
            padding-bottom: 6.4rem;
        }
    }

    &.__entity_view_content_types {
        display: flex;
        flex-direction: row;
    }

    //Entity View - Share view styles
    &__share-view-user-searchbar {
        height: 4rem;
        display: flex;
        margin: 1rem auto;

        .share-view-user-search {
            width: 100%;
        }

        .share-view-user-search-input {
            width: 97%;
            margin: 0 auto;
            height: 4rem;
            border: 0.1rem solid $border-light-with-opacity;
            border-radius: 0.3rem;
            padding-left: 1.4rem;

            &:focus {
                outline: none;
            }

            &::placeholder {
                font-style: italic;
            }
        }

        .share-view-user-search-submit {
            border: none;
            background-color: $button-color-click;
            font-size: $font15;
            color: $dark-cell-color;
            border-radius: 0.4rem;
            width: 18%;
            font-weight: 500;
        }
    }

    .share-view-user-selection-container {
        margin-bottom: 2.2rem;
        margin-top: 1.2rem;
        min-height: 36rem;
    }

    &__share-view-user-selection {
        .share-view-user-selection-header {
            width: 97%;
            background-color: $gray-bg-color-darker-document;
            border-bottom: 0.1rem solid $border-light-with-opacity;
            border-radius: 0.2rem;
            padding: 1.5rem;
            color: $secondary-button-text-color;
            font-weight: 600;

            &-inner {
                p:nth-child(1) {
                    margin-left: 1.8rem;
                    margin-right: 1.2rem;
                }

                p:nth-child(2) {
                    border-right: 0.1rem solid $default-text-color;
                    border-color: $border-dark-with-opacity;
                    padding-right: 1.2rem;
                }
            }
        }

        input[type='checkbox'] {
            transform: scale(1.2);
        }

        .share-view-user-selection-clear-all {
            font-weight: 600;
            border: none;
            background: none;
            cursor: pointer;
            color: $secondary-button-text-color;
            margin-left: 0.6rem;

            &-disabled {
                margin-left: 0.6rem;
                font-weight: 600;
                border: none;
                background: none;
                cursor: not-allowed;
                color: $button-color-disabled;
            }
        }

        #share-view-user-selection-select-all {
            cursor: pointer;
        }

        .share-view-user-selection-item {
            width: 97%;
            background-color: $gray-bg-color-darker-document;
            border-bottom: 0.1rem solid $border-light-with-opacity;
            border-radius: 0.2rem;
            padding: 1.5rem;
            display: flex;
            color: $secondary-button-text-color;

            input[type='checkbox'] {
                cursor: pointer;
                transform: scale(1.2);
            }

            div {
                display: flex;
                flex-direction: column;
                margin-left: 2rem;
                color: $default-text-color;

                h5 {
                    font-size: $font15;
                    font-weight: 900;
                }

                h6 {
                    font-weight: 100;
                    color: $placeholder-color;
                }
            }
        }

        div {
            display: flex;
        }

        .share-view-no-search-results {
            height: 30rem;
            display: flex;
            flex-direction: column;
            margin-top: 4rem;

            img {
                width: 9rem;
                margin: 0 auto;
                margin-top: 4rem;
            }

            p {
                text-align: center;
                width: 100%;
                font-size: $font15;
            }
        }
    }

    .primary-list {
        list-style: disc;
        padding-left: 2rem;
    }
    .secondary-list {
        list-style: circle;
        padding-left: 2rem;
        margin-bottom: 2rem;

        &.last {
            margin-bottom:0;
        }
    }
}