.batch-screening-container {
    .icon-box {
        padding: 0;
        display: flex;
        align-items: center;
        justify-content: center;
        border: none;
        cursor: pointer;
        outline: 0;
        background-color: transparent;

        .la-Download,
        .la-Delete,
        .la-Redo,
        .la-Alerts,
        .la-AddToReport {
          @include batch-screening-action-button();
        }

        .la-ShareView {
            @include batch-screening-action-button();
            height: 2.5rem;
            width: 2.5rem;
        }

        .la-Redo {
            transform: rotate(90deg);
        }

        .la-CopyBatch {
            @include batch-screening-action-button();
            width: 2.5rem;
        }

        .la-TriangleDown {
            position: relative;
            top: 0.6rem;
            right: 0.2rem;
        }

        .la-TriangleUp {
            position: relative;
            top: 0.6rem;
            right: 0.2rem;
        }

        .disabled {
            background-color: transparent;
            cursor: not-allowed;
            opacity: 0.25;
        }

        @-moz-document url-prefix() {
            &[disabled] {
                pointer-events: none;
            }
        }
    }

    .batch-screening-header {
        display: flex;
        justify-content: space-between;
        background-color: $white;
        height: 6rem;
        padding: 0 3rem 0;

        h2 {
            display: inline-block;
        }

        .batch-screening-buttons-container,
        .batch-screening-header-container,
        .batch-screening-limit-container {
            display: flex;
            gap: 1.4rem;
        }

        .batch-screening-header-upload-entities {
            &:hover:not(:disabled):not(.disabled),
            &.disabled {
                color: $white;
            }
        }

        .feedback-button {
            padding: 0 1.5rem;

            .la-DialogBubble {
                margin-right: 0.8rem;
                vertical-align: bottom;
            }
        }
    }

    .batch-screening-entity-name-search-icon {
        opacity: 0;
        margin-left: 0.8rem;
    }

    .batch-screening-entity-name-container {
        display: flex;
        justify-content: flex-start;
        align-items: center;

        &:hover {
            & .batch-screening-entity-name-search-icon {
                opacity: 1;
            }

            & .batch-screening-entity-name {
                text-decoration: underline;
                color: $dark-blue;
                cursor: pointer;
            }
        }

        .batch-screening-entity-name {
            font-weight: 600;
            max-width: 15.5rem;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
    
            &.blocked {
                color: $button-color-remove;
            }
    
            &:hover {
                text-decoration: underline;
                color: $dark-blue;
                cursor: pointer;
            }
        }
    }

    .batch-screening-error-icon {
        font-weight: bold;

        &:hover {
            cursor: pointer;
        }
    }

    #grid-table-entity-name-tooltip-error {
        max-width: 30vw;
    }

    .empty-table-batch-screening {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        background-color: $light-cell-color;
        border-radius: 0 0 1rem 1rem;
        padding: 3rem 0;

        img {
            height: 7rem;
            opacity: 0.8;
        }

        span {
            max-width: 33.5rem;
            font-size: $font16;
            text-align: center;

            a {
                margin: 0 0.5rem;
            }
        }
    }

    #tooltip-table-actions {
        max-width: 30vw;
    }

    // CreateViewFilter styles
    .create-view-filter-container {
        display: flex;
        align-items: center;
        position: relative;
        height: 100%;
        color: $comment-title-text-color;
        white-space: nowrap;

        .view-name-container {
            cursor: pointer;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 3.2rem;
            background-color: $white;
            border: 0.1rem solid #bcbec0;
            border-radius: 0.2rem;
            min-width: 12rem;
            max-width: 20rem;
            padding: 0 1rem;
            margin-left: 1.5rem;

            p {
                padding-right: 0.8rem;
                text-overflow: ellipsis;
                overflow: hidden;
                white-space: nowrap;
                -webkit-user-select: none; /* Safari */
                -ms-user-select: none; /* IE 10 and IE 11 */
                user-select: none; /* Standard syntax */
            }
        }

        .active {
            color: $button-link-primary-color;
            border-color: $button-link-primary-color;
        }

        .create-view-popover {
            display: block;
            position: absolute;
            background-color: $white;
            top: 3.2rem;
            right: 0;
            z-index: 100;
            width: 45rem;
            border: 0.1rem solid $default-border-color;
            border-radius: 0.5rem;
            filter: drop-shadow(0 3px 6px #00000029);
            padding: 2.5rem 2rem;

            &__header {
                display: flex;
                justify-content: space-between;

                &__title {
                    display: flex;
                    flex-direction: row;
                    align-items: center;
                }

                &__buttons {
                    &__reset-view {
                        border: none;
                        color: $button-link-primary-color;
                        font-size: $font15;
                        background-color: transparent;
                        outline: none;
                        cursor: pointer;
                        white-space: normal;

                        &:hover {
                            text-decoration: underline;
                        }

                        &:active,
                        &:focus {
                            color: #003a65;
                        }
                        &:disabled {
                            cursor: not-allowed;
                            color: $disabled-text-color;
                            text-decoration: none;
                        }
                    }
                }
            }
            &__container{
                display: flex;
                flex-direction: column;
                margin: 1.6rem 0;

                &__input {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    gap: 1.5rem;

                    .text-field-standard-sm {
                        width: 28.1rem;
                    }

                    button {
                        span.la-ZoomIn:before {
                            display: flex;
                            align-items: center;
                            justify-content: center;
                        }
                    }
                }
                &__text-container{
                    width: 28.1rem;


                }
                .characters-count {
                    font-size: $font12;
                    width: 28.1rem;

                    &.remaining {
                        float: right;
                        padding-right: 0.6rem;
                        color: $placeholder-color;
                        font-style: italic;
                        text-align: right;
                    }

                    &.max-limit {
                        color: $secondary-warning-color;
                        float: left;
                        font-size: $font14;
                        white-space: normal;
                    }
                }
            }
            &__saved-views {
                &__list {
                    max-height: 25rem;
                    overflow-y: auto;
                    &__item {
                        display: flex;
                        justify-content: space-between;
                        align-items: center;
                        margin: 1.5rem 0;

                        p {
                            cursor: pointer;
                        }
                    }
                }
            }

            .information-icon {
                margin-left: 1rem;
            }
        }

        .saved-views-list-item-actions {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            width: 6rem;
        }
    }

        // ManageColumns styles
        .manage-columns-filter-container {
            display: flex;
            align-items: center;
            position: relative;
            height: 100%;
            color: $comment-title-text-color;
            white-space: nowrap;
    
            .manage-columns-button {
                cursor: pointer;
                display: flex;
                justify-content: space-between;
                align-items: center;
                height: 3.2rem;
                background-color: $white;
                border: 0.1rem solid #bcbec0;
                border-radius: 0.2rem;
                min-width: 12rem;
                max-width: 20rem;
                padding: 0 1rem;
                margin-left: 1.5rem;
                gap: 1rem;
    
                p {
                    padding-right: 0.8rem;
                    text-overflow: ellipsis;
                    overflow: hidden;
                    white-space: nowrap;
                    -webkit-user-select: none; /* Safari */
                    -ms-user-select: none; /* IE 10 and IE 11 */
                    user-select: none; /* Standard syntax */
                }
            }
    
            .active {
                color: $button-link-primary-color;
                border-color: $button-link-primary-color;
            }
    
            .manage-columns-popover {
                display: block;
                position: absolute;
                background-color: $white;
                top: 3.2rem;
                right: 0;
                z-index: 100;
                width: 25rem;
                border: 0.1rem solid $default-border-color;
                border-radius: 0.5rem;
                filter: drop-shadow(0 3px 6px #00000029);
                padding: 1.5rem 1rem;
                gap: 1rem;

                .custom-checkbox-container {
                    padding: 0.6rem 1.2rem 0.6rem 1rem;
                    display: flex;
                    flex-direction: row;
                    align-items: center;

                    &:hover {
                        background-color: $list-item-hover-bg-color;
                        border-radius: 0.4rem;
                    }

                    &.dragging {
                        border: 1px dashed $default-border-color;
                        background-color: $white;
                    }

                    @keyframes columnDroppedAnimation {
                        0% {
                            background-color: $white;
                        }
                        50% {
                            background-color: $list-item-drop-highlight-color;
                        }
                        100% {
                            background-color: $white;
                        }
                    }

                    &.dropped {
                        animation: columnDroppedAnimation 2s;
                        animation-iteration-count: 1;
                        border-radius: 0.4rem;
                    }

                    &.drop-top {
                        border-top: 2px solid $header-menu-dropdown-hover;
                    }

                    &.drop-bottom {
                        border-bottom: 2px solid $header-menu-dropdown-hover;
                    }

                    input[disabled] {
                        pointer-events: none;
                    }

                    label {
                        color: #455A64;
                        text-wrap: wrap;
                        cursor: inherit;
                    }

                    .draggable-icon {
                        margin-right: 1.2rem;

                        &.disabled {
                            visibility: hidden;
                        }

                        &:hover {
                            cursor: move;
                        }
                    }
                }

                .link-button {
                    padding: 0.1rem 0.6rem;
                }

                &.disabled {
                    pointer-events: none;
                }

                .loading-overlay {
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: 100%;
                    width: 100%;
                    pointer-events: none;
                    opacity: 0.5;
                    background-color: $white;
                }
            }
        }
}
