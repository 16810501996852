
.user-preferences-page {
  display: flex;
  flex-direction: column;
  padding-bottom: 7rem;

  .user-preferences-header {
    background-color: $white;
    min-height: 6rem;
    padding: 0 3rem 1rem;
    width: 100%;
    font-size: 2.4rem;
    line-height: 3.5rem;

    &__notice {
      align-items: center;
      background-color: $popup-bg-color;
      display: flex;
      font-size: 1.4rem;
      height: 6rem;
      justify-content: space-between;
      margin-top: 1rem;
      padding-left: 1.5rem;
      position: relative;

      &:before {
        @include popup-message-vertical-strip($popup-user-error-color)
      }

      &--button {
        background-color: $popup-bg-color;
        border: 0;
        cursor: pointer;
        height: 100%;
        min-width: 10rem;
        padding: 0;
        text-align: center;
        border-left: 0.2rem solid $popup-dismiss-vertical-divider-color;

        &:hover {
          background-color: $popup-dismiss-button-hover;
        }

        &:active{
          background-color: $popup-dismiss-button-active;
        }
      }
      &.collapse {
        height: 0;
        transition: height ease 1s;
        visibility: hidden;
      }
    }

  }

  .user-preferences-menu {
    flex-direction: column;
    width: 10%;
    margin: 0 0 1.5rem 3rem;
    position: fixed;

    .horizontal-divider {
      width: 16.9rem;
      height: .2rem;
      opacity: 0;
    }

    .user-preference-button {
      width: 16.9rem;
      height: 5.2rem;
      background: $white;
      border: none;
      padding: 1rem;
      border-left: 3px solid $white;
      text-align: left;
      color: black;
      cursor: pointer;
      font-weight: normal;

      a {
        color: black;
      }

      &:hover {
        text-decoration: underline;
        border-left: 3px solid $preferences-active-button-border-color;
      }

      &:focus {
        outline: 0;
      }
    }
    .active {
      border-left: 3px solid $preferences-active-button-border-color;
    }

  }

  .user-preferences {
    margin: 0 5rem auto 18rem;

    .user-preferences-block {
      font-weight: 400;
      height: auto;
      background-color: $white;
      margin: 2rem;
      padding: 0 1rem 0 1.6rem;
      border-radius: 1rem;

      > div {
        padding-top: 1rem;
      }

      .user-preferences-category-container{
        &__list {
          .user-preference-checkbox {
            display: inline-block;

            input[type="checkbox"] {
              position: absolute;
              opacity: 0;

              + .preference-checkbox-label {
                font-weight: normal;
                &:before {
                  width: 1.4rem;
                  height: 1.4rem;
                  top: 0.5rem;
                }
              }
            }
          }
        }

        .container-section {
          padding: 1.5rem 0 .5rem 0;

          &__segment{
            padding-bottom: 1.5rem;

          }
        }
        .section-lock,
        .user-section-lock{
          float: right;
        }

        .user-preferences-negativeNews-languages__header__toggle-edit__icon {
          font-size: $font24;
          vertical-align: middle;
        }

        .section-enabled:not(.inside-section), 
        >h2,
        .container-section__segment,
        .user-preferences-category-container__options,
        .delivery-settings,
        .delivery-settings-container__title {
          padding-left: 1.5rem;
        }

        .section-enabled .container-section__segment, .section-disabled .container-section__segment {
          padding-left: 0;
        }

        .section-enabled .section-separator {
          margin-left: -3.1rem;
        }

        .section-disabled:last-child {
          border-radius: 0 0 1rem 1rem;
        }
      }

      .cost-code-container {
        display: flex;
        flex-direction: row;

        .cost-code-options {
          padding: 0 1rem 1rem 0;

          select {
            width: 46.6rem;
            height: 4rem;
            vertical-align: bottom;
          }
        }
      }
      
      .toggle-snapshot{
        display: flex;
        align-items: center;
        padding-bottom: 3.6rem;

        .custom-toggle {
          margin-right: 1rem;
        }
      }

      .toggle-delivery-settings {
        @extend .toggle-snapshot;
        padding-bottom: unset;
      }
      &.section-disabled {
        background-color: $light-border-color;
        cursor: not-allowed;
      }

    }
  }

  .sort-date-range {
    margin-top: 1.1rem;
  }

  .custom-date-picker {
    padding: 0.5rem 0 0 2rem;
    margin-top: -1.1rem;
    label {
      font-size: 1.4rem;
    }
  }

  .section-disabled {
    background-color: $light-border-color;
    margin: 0 -1rem 0 -1.6rem;
    overflow: hidden;
    padding: 0 1rem 0 3.1rem;
    cursor: not-allowed;
    color: $placeholder-color;

    .toggle-delivery-settings,
    .custom-toggle,
    .toggle-text {
      opacity: 0.7;
    }      
  }

  .section-disabled.inside-section {
    padding-left: 1.5rem;
  }

  .section-disabled.section-master-lock {
    opacity: 1;
    padding-left: 1.5rem;
    border-radius: 1rem;
  }

  .input-section-disabled {
    opacity: 0.7;
    cursor: not-allowed;
  }

  .admin-lock {
    margin: 0 1rem;
  }

  .section-separator {
    background-color: $document-view-content-border-color;
    height: .1rem;
    margin: 0 -0.9rem 0 -1.6rem;
  }
  .sub-section-separator {
    background-color: $document-view-content-border-color;
    height: .1rem;
    margin: 1rem -1.3rem 1rem -3.1rem;
    &__no-margin-top {
      margin-top: 0;
    }
  }

  .date-ranges-dropdown {
    padding-bottom: 1rem;
  }

  .text-area-highlight {
    &__buttons {
      button {
        margin-right: 2rem;
      }
    }
  }

  .admin-apply-preferences-controls {
    display: flex;
    align-items: center;
    padding-top: 1.5rem;

    button {
      margin-left: 1rem;
    }
  }

  .toggle-exclude-low-quality-documents {
    @extend .toggle-snapshot;
    padding-bottom: unset;
  }

}