.sanctions-pod {
    display: flex;
    flex-direction: column;
    height: auto;

    .card-disclaimer {
        margin-left: 0;
        padding-top: 0.5rem;
    }

    .risk-score-container {
        display: flex;
        align-items: center;
        height: fit-content;
        width: fit-content;
        padding-left: 2rem;
        text-transform: capitalize;
        font-size: 1.4rem;
        font-weight: 700;

        &.high {
            color: $negativity-level-pill-high;
        }
        &.medium {
            color: $negativity-level-pill-medium; 
        }
        &.low {
            color: $negativity-level-pill-low;
        }
    }
}