.drop-area {
    width: 100%;
    height: 21rem;
    border: .2rem dashed #9E9E9E;
    border-radius: .6rem;
    display: flex;
    justify-content: center;
    flex-wrap: wrap;

    .drop-area-content {
        width: 26.5rem;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;

        .drop-area-content-header {
            font-size: $font16;
            font-weight: bold;
            line-height: 1.2;
            text-align: center;
            color: $default-text-color;
            margin-bottom: 0.5rem;
        }

        .drop-area-content-description {
            font-size: $font14;
            line-height: 1.8rem;
            color: $default-text-color;
            text-align: center;
            margin-top: .5rem;

        }

        .upload-btn {
            font-size: $font16;
            color: $dark-blue;
            font-weight: bold;
            border-bottom: .1rem solid;
            line-height: 2.4rem;
            cursor: pointer;
        }
    }

    &.dragging {
        background: #ECEFF1 0% 0% no-repeat padding-box;
        border-color: #11A0AA !important;

        .drop-area-content {
            path {
                fill: #263237 !important;
            }
        }
    }

    &.error {
        border-color: #E8171F;
    }
}