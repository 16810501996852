.user-preferences-category-container__options {
  .admin-lock-wrapper {
    display: block;
    position: static;

    .label {
      padding-top: 2rem;
      padding-bottom: 0.5rem;
    }

    .custom-radio {
      display: inline-block;
      margin-bottom: 2rem;

      &:last-of-type {
        padding-left: 5rem;
      }
    }

    &.is-locked {
      &:not(.is-editable) {
        background-color: #e9e9ea;
        margin-left: -1.6rem;
        margin-right: -1rem;
        padding-left: 1.6rem;
        padding-right: 1rem;
        cursor: not-allowed;

        .custom-radio {
          pointer-events: none;
        }

        .section-lock {
          margin-top: -4rem;
        }
      }
    }

    .user-section-lock {
      position: absolute;
      top: 0;
      right: 0;
    }

    &:not(.is-locked):not(.is-editable) {
      span.section-lock {
        display: none;
      }
    }
  }

  .admin-lock-wrapper.with-section-lock.is-editable {
    position: relative;
    display: block;
    .section-lock {
      position: absolute;
      top: -0.5rem;
      right: 0;
      height: 100%;
    }
  }

}