.date-select-object {
  position: absolute;
  margin-top: 3.4rem;
  z-index: 100;

  &.react-calendar {
    border: none;
    border-radius: 0.4rem;
    box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
    width: 30rem;
  }
}

.calendar-container.with-top {
  position: absolute;
  top: 0;
  left: -2rem;
  max-width: 28rem;
  z-index: 100;

  .date-select-object {
    position: static;
  }
}

.error-message {
  color: $secondary-color;
  font-size: 12px;
}
.date-select {
  font-size: 1.4rem;
  input {
    float: left;
    line-height: 2.8rem;
    font-size: 1.4rem;
    padding: 0.1rem 0.5rem;
    border: 0.1rem solid $pill-selection-icon-default;
    border-radius: 0.3rem;
    height: 3.5rem;
    max-width: 17rem;
  }
  i.la-Calendar {
    font-size: 2.8rem;
    margin-left: 0.5rem;
    color: $dark-navy;
    position: absolute;
  }
  label {
    padding-right: 0.5rem;
    display: block;
  }
  &:after {
    height: 0;
    display: block;
    content: " ";
    clear: both;
  }
  &:hover,
  &.is-open {
    .date-view {
      border-color: $pill-selection-icon-selected;
    }
    i.la-Calendar {
      color: $pill-selection-icon-selected;
    }
  }
  &.with-error {
    color: red;
    i {
      color: red;
    }
  }

  .react-calendar__tile--now {
    background: transparent;
  }

  .react-calendar__tile:disabled {
    cursor: not-allowed;
    &:hover {
      background-color: #f0f0f0 !important;

      abbr {
        background-color: #f0f0f0 !important;
      }
    }
  }
  .react-calendar__month-view__weekdays {
    padding-left: 1rem;
    padding-right: 1rem;
    text-transform: capitalize;
    font-size: 1.4rem;
    font-weight: bold;
    color: #414141;
    margin-bottom: 0.5rem;
  }
  .react-calendar__navigation {
    .react-calendar__navigation__label {
      font-size: $font16;
      font-weight: bold;
      color: $default-text-color;
    }

    .la-ChevronLeft:before {
      content: "\e609";
    }

    .la-ChevronRight:before {
      content: "\e608";
    }

    .la-ChevronLeft,
    .la-ChevronRight {
      font-size: 1.4rem;
      font-weight: 1000;
      color: $default-text-color;
    }
  }

  .react-calendar__tile.react-calendar__month-view__days__day {
    padding: 0;
    abbr {
      border-radius: 0.4rem;
      width: 4rem;
      height: 4rem;
      display: block;
      line-height: 4rem;
      text-align: center;
    }
    &:hover {
      background-color: transparent !important;
      abbr {
        background-color: $hover-color;
      }
    }
  }
  .react-calendar__navigation {
    button:enabled {
      &:focus,
      &:hover {
        background-color: transparent;
      }
      &:hover {
        i {
          color: $primary-color-hover;
        }
      }
    }

    button:disabled {
      background-color: transparent;
      cursor: not-allowed;
      color: $button-color-disabled;

      .la-ChevronLeft,
      .la-ChevronRight {
        color: $button-color-disabled;
      }
    }
  }
  .react-calendar__month-view__days {
    padding: 0 1rem 1rem;
    font-size: 1.4rem;
    color: $character-count-color;
  }
  .react-calendar__navigation {
    margin-bottom: 0rem;
  }
  .react-calendar__tile--active {
    background: transparent;

    abbr {
      background: #0d47a1;
    }

    &:hover {
      abbr {
        background: #0d47a1 !important;
      }
    }
  }
  .react-calendar__navigation button {
    min-width: 3rem;
  }

  .react-calendar__month-view__days__day--weekend:not(.react-calendar__month-view__days__day--neighboringMonth) {
    color: $character-count-color;
    &.react-calendar__tile--active {
      color: $white;
    }
  }

  .react-calendar__year-view {
    padding: 0 1rem 1rem;

    .react-calendar__tile {
      padding: 1.4rem 0.7rem 1.4rem 2rem;
      text-align: left;
      border-radius: 0.4rem;

      &:hover {
        background-color: $hover-color;
      }

      &:disabled {
        border-radius: 0 !important;
      }
    }

    .react-calendar__tile--hasActive {
      background-color: #0d47a1;
      color: $white;

      &:hover {
        background: #0d47a1;
      }
    }
  }

  .react-calendar__decade-view {
    padding: 0 1rem 1rem;
    .react-calendar__tile {
      padding: 0;

      display: block;
      border-radius: 0.4rem;
      line-height: 4rem;

      &:hover {
        background-color: transparent !important;

        abbr {
          background-color: $hover-color;
        }
      }
    }

    .react-calendar__tile--hasActive {
      background: transparent;
      abbr {
        background-color: #0d47a1;
        color: $white;
      }

      &:hover {
        abbr {
          background: #0d47a1;
        }
      }
    }
  }
}

.date-range-select {
  display: flex;
  flex-direction: row;
  .disabled {
    pointer-events: none;
    color: graytext;
    input,
    .la-Calendar {
      color: graytext;
    }
  }
  .date-select {
    flex: 1;
  }
  .date-select:first-child {
    margin-right: 2rem;
  }
}
.custom-date-selected.date-ranges-dropdown {
  display: flex;

  .custom-date-picker {
    flex: 1;
    max-width: 45rem;
    min-width: 45rem;
  }
}

.date-ranges-dropdown {
  display: inline-block;
}

.categories-date-range {
  &.with-lock {
    .admin-lock.unlocked:disabled {
      display: none;
    }
  }

  .toggle-text {
    display: flex;
    flex-direction: row;
    color: $button-link-primary-color;
    cursor: pointer;
    .caret {
      margin: 5px 5px 0 0;
      cursor: pointer;
      color: $button-link-primary-color;
    }
  }

  // Adhoc
  &.from-adhoc {
    .title {
      font-size: 1.5rem;
    }
    .content {
      display: flex;
      flex-direction: row;
    }
    .content-text {
      font-size: 1.3rem;
      color: $tertiary-color;
      width: 45%;
      padding-right: 4rem;
    }
    .sort-date-range {
      select {
        height: 4rem;
        width: 100%;
      }

      & > select:disabled {
        cursor: not-allowed;
      }
    }
    .groupDateSelect {
      flex: 10;
      .section-lock {
        display: block;
        width: 5%;
        position: relative;
      }
      .date-range-select .date-select {
        margin-right: 4rem;
      }
      .content {
        .date-ranges-dropdown {
          display: block;
          width: 50%;
        }
      }
    }
    .category-date-select {
      padding: 1.5rem 0;
      display: flex;
      flex-direction: row;
      h4 {
        font-size: 1.5rem;
        font-weight: bold;
      }
      .category-text {
        width: 45%;
      }
    }
    .sub-section-separator {
      background-color: $gray-bg-color;
      height: 0.2rem;
      margin: 1rem 1.6rem 1rem 0;
    }
    .group-date-component {
      position: relative;
      .admin-lock-wrapper {
        width: 50%;
        .user-section-lock {
          position: relative;
          top: auto;
          right: auto;
        }
      }
    }
    .admin-lock-wrapper.with-section-lock.is-locked {
      position: relative;
      .groupDateSelect {
        margin-right: -5%;
      }
      .group-date-component {
        position: relative;
        .admin-lock-wrapper {
          margin-right: -5%;
          .admin-lock {
            position: absolute;
            top: 0rem;
            left: -1rem;
            &:after {
              display: none;
            }
          }
        }
      }
      .section-lock {
        position: relative;
        width: 5%;
        display: block;
        .admin-lock {
          position: absolute;
          top: 2.5rem;
          left: -1rem;
          &:after {
            display: none;
          }
        }
      }
    }
    .date-ranges-dropdown {
      display: block;
      width: 100%;
    }
  }

  // Preferences
  &.from-preferences {
    .date-select-object {
      margin-top: 4.5rem;
    }

    .date-range-select .date-select {
      margin-right: 4rem;
    }
    .admin-lock-wrapper {
      .groupDateSelect {
        padding-top: 1.5rem;
        padding-bottom: 1.5rem;
        flex: 10;
      }
    }

    .title {
      display: inline-block;
      font-size: 1.8rem;
      margin-bottom: 1.5rem;
      .autosave-msg {
        & > span {
          display: inline-block !important;
        }
      }
    }
    .content-text {
      margin-bottom: 0;
    }

    .category-date-select {
      &-title {
        display: flex;
        align-items: baseline;

        h4 {
          font-size: 1.8rem;
          font-weight: bold;
          margin-bottom: 0.5rem;
        }
      }
      p {
        margin-bottom: 0.5rem;
      }
    }
    .group-date-component {
      position: relative;
      .sort-date-range select {
        width: 30rem;
      }
      .admin-lock-wrapper .user-section-lock {
        position: relative;
        bottom: 0;
        .admin-lock {
          position: absolute;
          bottom: 0.8rem;
        }
      }
    }
    .admin-lock-wrapper {
      position: static;
    }
    .admin-lock-wrapper.with-section-lock.is-editable {
      position: relative;
      display: block;
      .section-lock {
        position: absolute;
        top: 0;
        right: 0;
        height: 100%;
      }
    }
    .admin-lock-wrapper.is-locked:not(.is-editable) {
      background-color: $light-border-color;
      margin-left: -1.6rem;
      margin-right: -1rem;
      padding-left: 1.6rem;
      padding-right: 1rem;
      cursor: not-allowed;
    }
  }
}
