.page-size-container {
  text-align: center;
  font-family: $secondary-font-stack;
  padding: 2rem 0;
  span {
    font-size: $font16;
    font-weight: bold;
    line-height: 2.13;
    padding: 1rem;
  }
  select {
    width: 7.3rem;
    height: 4.8rem;
    margin: 0 0 0 0.3rem;
    font-size: $font16;
    padding: 1.5rem 1.1rem;
    border: none;
    border-radius: 0.8rem;
    background-color: $select-bg-color;
  }
}
