.report-note-wrapper {
  background-color: $white;
  padding: 2rem 4rem 4rem;

  .report-note-header-title {
      font-size: $font18;
      font-weight: bold;
      line-height: 35px;
      display: inline-block;
  }

  .existing-report-note-container {
    display: flex;
    justify-content: space-between;
    background-color: $existing-note;
    padding: 1rem 1.5rem;
    min-height: 12.5rem;

    .report-note-text {
        width: 80%;
        word-wrap: break-word;
        font-size: 1.5rem;
    }

    .report-note-actions {
        display: flex;
        padding-right: 2.5rem;
    }
  }

  .input-note-container {
    position: relative;
    border: .1rem solid $default-border-color-darker;
    border-radius: 3.6px;

    .text-area-standard {
        position: relative;
        border: none;
        width: 80%;
        height: 12.5rem;
    }

    .input-note-actions {
        display: flex;
        position: absolute;
        top: 1rem;
        right: 4rem;
    }
}

    .report-note-button {
        @include link-button($button-link-primary-color);
        font-size: 1.5rem;

        &:hover {
            color: $button-link-primary-color;
            text-decoration: underline;
        }
        
        &:disabled {
            color: $button-color-disabled;
            cursor: not-allowed;
            text-decoration: none;
        }
    }

    .vertical-divider {
        height: 1rem;
        margin: 1rem 0 0;
    }
}