.pill-list-loading{
  display: flex;
  flex-wrap: wrap;
  height: 3rem;

  &__item{
      height: 2rem;
      margin: 1rem 1rem 1rem 0;
      border-radius: 20px;

      //animation
      animation-duration: 1.5s;
      animation-fill-mode: forwards;
      animation-iteration-count: infinite;
      animation-timing-function: linear;
      animation-name: placeHolderShimmer;
      background: #f6f7f8;
      background: linear-gradient(to right, #eeeeee 8%, #dddddd 18%, #eeeeee 33%);
      background-size: 800px 104px;

      @keyframes placeHolderShimmer {
        0%{ background-position: -468px 0; }
        100%{ background-position: 468px 0; }
      }
  }
}
