.my-search-preferences-wrapper {
  display: flex;
  justify-content: flex-end;
  margin: .6rem 0 1.7rem;

  .my-search-preferences-button {
    cursor: pointer;
    color: $button-color-click;
    font-family: $secondary-font-stack;
    font-size: 1.4rem;
    font-weight: bold;
    line-height: 1.21;
    outline: none;
    padding: .8rem 2.2rem;
    border: 0.2rem solid $border-homepage-button;
    background-color: transparent;
    border-radius: 10rem;
  }
}