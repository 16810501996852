.admin-cost-code-container {
  margin-bottom: 3rem;
  padding-left: 1.5rem;

  .user-preference-checkbox {
    margin: 1rem 0 1rem;

    input[type="checkbox"] {
      position: absolute;
      opacity: 0;

      + .preference-checkbox-label {
        &:before {
          width: 1.4rem;
          height: 1.4rem;
          top: 0.5rem;
        }
      }
    }
  }

  .add-predefined-cost-codes {
    margin-top: 1rem;

    .text-field-standard-lg {
      width: 51rem;
      height: 4.5rem;
    }
    .button-primary-sm {
      padding: 0 1rem;
      height: 4.3rem;
      margin-left: 1rem;
      font-weight: normal;
    }

    .duplicate-error {
      color: $secondary-color;
      font-size: 1.4rem;
    }

    .cost-code-table-header {
      margin: 1.5rem 0 0;
      padding: 1rem 0 1rem;
    }

    .cost-code-table-body {

      max-height: 53rem;
      overflow-y: auto;

      .cost-code-line-item {
        font-size: 1.5rem;
        padding: 1rem 0 1rem;
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        &__name {
          text-align: center;
        }

        &__button {
          background: none;
          border: none;
          outline: none;
          color: $button-link-primary-color;
          cursor: pointer;

          &__delete {
            background: none;
            border: none;
            outline: none;
            color: $button-link-primary-color;
            padding-left: 0;
            cursor: pointer;

            &:hover {
              color: $primary-color-hover;
              text-decoration: underline;
            }
            &:active {
              opacity: 0.5;
              text-decoration: underline;
            }
            &:disabled {
              cursor: initial;
              text-decoration: none;
            }
          }
        }

      }

      .greenAnimation {
        animation: currentAnimation 2s;
        animation-iteration-count: 1;
      }

      @keyframes currentAnimation {
        0%   {background-color: $white;}
        50%  {background-color: $dropped-drag-and-drop-color;}
        100% {background-color: $white;}
      }
    }
  }

  .cost-code-table-content {
    width: 57rem;
  }
}