.admin-lock {
  cursor: pointer;
  font-size: 3rem;
  height: 4.2rem;
  margin: 0 0 0 1rem;
  outline: none;
  padding: 0;
  width: 4.2rem;

  &.unlocked {
    background-color: $white;
    border: 1px solid $light-border-color;
    color: $default-text-color;
    position: relative;
    &:hover {
      background-color: $button-color-active;
      color: $white;
      &:after {
        background-color: $button-color-active;
      }
    }
    // placing mask on right of lock, simulating an open lock
    &:after {
      background-color: white;
      content: '';
      height: 0.7rem;
      position: absolute;
      right: 1rem;
      top: 1.25rem;
      width: 1.4rem;
    }
  }

  &.locked {
    background-color: $button-color-active;
    border: 1px solid $button-color-active;
    color: $white;
    &:hover {
      background-color: $button-color-hover;
      border: 1px solid $button-color-hover;
      color: $white;
    }
  }

  &:disabled {
    background-color: transparent;
    border: 1px solid transparent;
    color: $default-text-color;
    pointer-events: none;
    &:after {
      background-color: transparent;
    }
  }
}

.admin-lock-wrapper {
  position: relative;
  display: flex;
}

// section locks have 'border' right, with the height of the container, with same button hover effect
.section-lock {
  .admin-lock {
    &.locked {
      &:after {
        border-right: 0.9rem solid $button-color-active;
        content: "";
        height: 100%;
        position: absolute;
        right: -0.89rem;
        top: 0;
        transition: height 0.2s ease-out;
      }
      &:hover {
        &:after {
          border-right: 9px solid $button-color-hover;
        }
      }
    }
  }
}