/**
 * INFORMATION ICON SCSS STYLE
 *
 * @version 0.1
 */

.information-icon {
  &:hover {
    cursor: pointer;
  }

  &.la-InfoHover {
    margin-left: 0.5rem;
    font-size: 1.7rem;
  }
}