.user-preferences-negativeNews-languages {

  .user-preferences-negativeNews-language-topic {
    font-weight: bold;
    padding: 1rem 0 .5rem;
  }

  &__header {
    display: flex;
    justify-content: space-between ;

    &__toggle-edit{
      display: flex;
      justify-content: flex-end ;
      margin: 0 .5rem .5rem 0;

      &__icon {
        font-size: larger;
      }

      &__label {
        margin: 0 1rem;
      }

      .custom-toggle{
        height: 2.2rem;
      }
    }
  }

  .user-preferences-category-container {
    &__list {
      .user-preference-list-item {
        width: 25%;
      }
    }
  }

  &__list-item {
    display: flex;
    justify-content: space-between;

    &__action {
      width: 5rem;
      background: none;
      border: none;
      outline: none;
      text-align: right;
      color: $button-link-primary-color;
      cursor: pointer;
      margin-right: 25%;

      .edit-button {
        display: inline;
      }
    }
  }

}


.multiple-languages {
  display: flex;
  width: 100%;
  flex-direction: column;
  margin: 2rem 0 1rem;

  .user-preferences-negativeNews-languages__list-item {
    margin-top: 1rem;
    justify-content: unset;
  }

}