
.pill-list {
  display: flex;
  flex-wrap: wrap;

  .pill-list-container {
    display: flex;
    flex-wrap: wrap;
    margin-right: 1rem;

    .pill-item {
      display: flex;
      flex-direction: row;
      margin: 0 .9rem .9rem 0;
      align-items: center;
      height: 3.2rem;
      background-color: $default-bg-color;
      border: .1rem solid $pill-border-color;
      padding: .7rem 1.2rem .8rem;

      &__selected {
        border: .1rem solid $primary-border-color;
        border-radius: 6rem;
      }

      label {
        font-family: $secondary-font-stack;
        color: $pill-color-default;
        border-radius: 2px;
        font-size: 1.4rem;
        white-space: nowrap;
      }
    }
  }

  &.with-fadeout {
    .pill-item {
      opacity: 1;
      transition: opacity 2s;
      &.fade-out {
        opacity: 0;
      }
    }
  }

  .toggle {
    color: $dark-blue;
    font-size: 1.5rem;
    margin: .5rem 1rem 1rem 0;
    height: 3.2rem;
    padding: 0 1rem 0 0;
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }

  .toggle-selection {
    height: 3.2rem;
    width: 12rem;
    background-color: #fff;
    font-weight: bold;
    color: #132a43;
    border-radius: 0.5rem;
    border: 0.1rem solid $button-color-click;
    font-size: 1.5rem;
    cursor: pointer;
    outline: none;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;

    &:hover {
      box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
      cursor: pointer;
    }

    span {
      margin-left: .5rem;
    }
  }

  .la-CloseRemove {
    border: 0;
    height: 3.2rem;
    line-height: 3.2rem;
    color: $button-color-click;
    outline: none;
    cursor: pointer;
  }

  .la-ZoomIn {
    @extend .la-CloseRemove;
    transform: rotate(45deg);
  }
}

// Dark theme
.dark-theme {
  .pill-list {
    .pill-list-container {
      .pill-item {
        background-color: $dark-theme-background;
        border: .1rem solid $dark-theme-color;

        &__selected {
          border: .2rem solid $dark-theme-color;
        }

        &:not(&__selected) {
          padding: .6rem 1.3rem .7rem;
        }

        label {
          color: $dark-theme-color;
        }
      }
    }

    .toggle-selection {
      background-color: $dark-theme-background;
      color: $dark-theme-color;
      border: .1rem solid $dark-theme-color;

      &:hover {
        border: .1rem solid $dark-theme-background;
        box-shadow: 0 0 0 .2rem $dark-theme-color;
      }
    }

    .la-CloseRemove {
      color: $dark-theme-color;
    }

    .toggle {
      color: $dark-theme-color;
    }
  }
}
