.theme-switcher {
  &-wrapper {
    display: flex;
    align-items: center;
    padding: .7rem 1rem;
    background-color: $theme-switcher-bg-color;
    border-radius: 10rem;

    &::before,
    &::after {
      display: flex;
      transition: all .3s ease-in-out;
      font-family: 'Theme_Switcher_Icons', sans-serif !important;
    }

    &::before {
      font-size: 1.6rem;
      content: '\e901';
      color: $header-switcher-color;
    }

    &::after {
      font-size: 2rem;
      content: '\e900';
      color: $white
    }

    &.dark-theme {
      &::before {
        color: $white
      }

      &::after {
        color: $header-switcher-color;
      }
    }
  }

  &-switch {
    position: relative;
    display: inline-block;
    width: 3.8rem;
    height: 2.1rem;
    margin: 0 .8rem;
  }

  &-input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &-slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: $rebranding-header-wrapper-bg;
    border: .1rem solid $theme-switcher-border-color;
    transition: all .4s;
    border-radius: 10rem;

    &::before {
      position: absolute;
      content: "";
      height: 1.3rem;
      width: 1.3rem;
      left: .4rem;
      bottom: .3rem;
      background-color: white;
      -webkit-transition: .4s;
      transition: .4s;
      border-radius: 50%;
    }
  }
}

input:checked + .theme-switcher-slider {
  background-color: $rebranding-header-wrapper-bg;

  &::before {
    transform: translateX(1.5rem);
  }
}

// Dark theme
.dark-theme {
  .theme-switcher-wrapper {
    background-color: $theme-switcher-bg-color-lighter;

    .theme-switcher-slider {
      background-color: $dark-theme-background;
    }
  }
}