.add-to-report-btn {
  border: solid 0.2rem $button-color-click;
  border-radius: 0.5rem;
  color: $button-color-hover;
  height: $button-default-height;
  font-size: 1.5rem;
  font-weight: bold;
  white-space: nowrap;
  background-color: white;
  outline: none;
  padding: $button-padding;

  .add-to-report-content {
    display: flex;
    flex-direction: row;
    justify-content: center;
    min-width: 12.2rem;
    max-width: min-content;
    text-align: center;
    .icon {
      font-size: 1.7rem;
      color: $button-color-hover;
      padding: 0.4rem 0.5rem 0 0;
      &.la-ZoomIn {
        font-size: 2.5rem;
        padding-top: 0;
      }
    }

    .add-to-report-name {
      line-height: 2.3rem;
    }
  }

  &.add-to-report-button-anim {
    background-color: $primary-bg-color;
  }

  &:hover {
    box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
    cursor: pointer;
  }

  &:active {
    background-color: white;
  }

  &:focus {
    box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-focus-border;
  }

  &:disabled {
    cursor: initial;
    opacity: .5;
    pointer-events: none;
  }

  // this is for stupid IE
  &.btn-loading {
    .add-to-report-content {
      text-align: center;
      .ui.centered.inline.loader.active {
        display: inline-block;
        width: 100%;
      }
    }
  }

  &.btn-added-msg {
    color: $button-color-hover;
    &:hover {
      box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
    }
  }

  &.btn-added {
    background-color: $white;

    &:hover {
      box-shadow: 0 0 0 0.03rem $button-color-hover, 0 0 0 0.3rem $button-hover-border;
    }
  }

  &.btn-not-added {
    padding-left: 1.5rem;
  }

  &.readonly {
    background-color: $button-color-disabled;
    border: .1rem solid $button-color-disabled;
    color: $white;
    cursor: not-allowed;

    &:disabled {
      opacity: 1;
      pointer-events: auto;

      .add-to-report-content {
        pointer-events: none;
      }

      &:hover {
        box-shadow: none;
      }
    }

    .la-ZoomIn {
      color: $white;
    }
  }
}

.alert-edit-btn {
  .btn-caret::before {
    display: inline-block;
    font-family: 'GVS_Icons', sans-serif;
    font-size: 1.2rem;
    content: "\e60a";
    padding-left: .3rem;
    color: $rebranding-dark-blue;
  }
}

.open {
  .with-caret {
    .btn-caret::before {
      content: "\e607";
    }
  }
}

.notification-clear-all-button {
  background-color: $dark-theme-button-bg;
  color: white;
  button { all: unset; }
  border-radius: 3rem;
  border: none;
  position: absolute;
  top: -1.2rem;
  right: -0.6rem;
  font-size: 1.2rem;
  padding: 0.4rem 1.2rem;
  cursor: pointer;
}