/**
 * FOOTER PAGE SASS STYLES
 *
 * @version 0.1
 */

.footer {
  background-color: $white;
  border-top: 1px solid $light-border-color;
  bottom: 0;
  height: $footer-height;
  min-width: 1024px;
  position: fixed;
  width: 100%;
  z-index: $z-footer;

  &__wrapper {
    display: flex;
    flex-direction: row;
    justify-content: center;
    flex-wrap: nowrap;
    margin-top: 1.5rem;

    .footer__links-left {
      margin: 0 1rem 0;

      .ot-sdk-show-settings {
        border: none !important;
        background: none !important;
      }

      .footer__link,
      .ot-sdk-show-settings {
        color: #757575 !important;
        font-size: 1.4rem !important;
        height: 100%;
        line-height: 4.5rem;
        padding: 0 1rem !important;
        text-decoration: none;
        vertical-align: middle;

        &:hover {
          text-decoration: underline;
        }

        .footer__logo--lexis {
          display: inline-block;
          float: left;
          height: 4.5rem;
          width: 13rem;
          margin-right: 2.5rem;
        }
      }
    }

    .footer__logo--lexis {
      display: inline-block;
      float: left;
      height: 4.5rem;
      width: 13rem;
      margin-right: 2.5rem;
    }
  }

  .footer__links-right {
    margin: 0 5rem 0 1.5rem;

    .footer__relx-link {
      .footer__logo--relx {
        display: inline-block;
        float: right;
        height: 4.5rem;
        width: 12.5rem;
      }
    }
  }

  .version {
    bottom: 0;
    color: $version-color;
    font-size: 1.2rem;
    height: 100%;
    line-height: 4.5rem;
    padding: 0 0.5rem;
    position: absolute;
    right: 0;
    z-index: -1;
  }

  &.transparent-background {
    background-color: transparent;
    border-top: none;
  }
}

// Dark theme
.dark-theme {
  .footer {

    &:not(.transparent-background) {
      background-color: $dark-navy;
      border-top: 0.1rem solid $dark-navy;
    }

    .footer__link,
    .ot-sdk-show-settings {
      color: $dark-theme-color !important;
    }
  }
}