.article-navigation {
  display: flex;
  flex-direction: row;
  font-size: $font14;
  line-height: 2.2rem;
  margin-top: 0.4rem;
  margin-left: 2rem;
  .back {
    border-left: 1px solid $default-border-color;
    padding-left: 0.5rem;
    margin-left: 0.5rem;
    margin-right: 0.5rem;
    height: 2.2rem;
  }
  .counter {
    margin-left: 0.5rem;
  }
  .navigation-button {
    cursor: pointer;
    &.disabled {
      opacity: 0.5;
      cursor: not-allowed;
    }
  }
  .caret-left, .caret-right {
    margin-top: 0.6rem;
  }
}

$caret-height: 10px;

.caret-left {
  width: $caret-height;
  height: $caret-height;
  border: 1px solid transparent;
  border-left-color: $button-secondary-color;
  border-top-color: $button-secondary-color;
  transform: rotate(-45deg);
}

.caret-right {
  width: $caret-height;
  height: $caret-height;
  border: 1px solid transparent;
  border-left-color: $button-secondary-color;
  border-top-color: $button-secondary-color;
  transform: rotate(135deg);
}
