.suggested-names {
  font-size: 14px;
  line-height: 1.7;
  color: $default-text-color;

  .suggested-names-wrapper {
    .label {
      font-weight: bold;
      display: inline-block;
    }

    .error {
      color: $startPage-error-border;
    }

    .default-message {
      padding-top: 0.2rem;
    }

    &__container {
      margin: 1rem 0 0;

      .pill-list {
        margin: 2rem 0 1rem;
        width: 100% !important;
        justify-content: space-between;
        flex-direction: column;

        .pill-list-container {
          max-width: 100%;

          .pill-list__item {
            max-width: 100%;

            .pill-item {
              max-width: 100%;
              margin-bottom: 1rem;
              border-radius: 6rem;

              &:not(:first-of-type) {
                margin-left: 1rem;
              }

              .selection-icon {
                width: 1.6rem;
                height: 1.6rem;
                color: $pill-border-color;
                border: 0.1rem solid $pill-border-color;
                border-radius: 50%;
                background: $white;
                margin-right: 0.8rem;

                &.check {
                  border: 0.1rem solid $pill-selection-icon-selected;
                  background: $pill-selection-icon-selected
                    url("~/public/assets/Checkmark.svg") no-repeat center;
                  background-size: auto 0.75rem;
                }
              }

              label {
                max-width: calc(100% - 1.5rem);
                white-space: nowrap;
                overflow: hidden;
                text-overflow: ellipsis;

                @media screen and (-ms-high-contrast: active),
                  (-ms-high-contrast: none) {
                  max-width: 100%;
                }
              }
            }

            @media screen and (-ms-high-contrast: active),
              (-ms-high-contrast: none) {
              flex-grow: 0;
              flex-shrink: 0;
              flex-basis: auto;
            }
          }
        }

        .toggle-selection {
          font-size: 1.4rem;
          padding: $button-padding;
          width: auto;
          margin: 1rem auto 1rem 0;
          height: $button-default-height;

          @media screen and (-ms-high-contrast: active),
            (-ms-high-contrast: none) {
            width: 19rem;
          }
        }
      }

      .pill-list-loading {
        margin: 1rem 0 2rem;
      }
    }
  }

  .hide-message {
    padding-top: 0.2rem;

    a {
      color: $button-link-primary-color;
      border-bottom: 0.1rem solid $button-link-primary-color;
    }
  }
}

// Dark theme
.dark-theme {
  .suggested-names {
    .suggested-names-wrapper__container {
      .pill-list .pill-list-container .pill-list__item {
        .selection-icon {
          border: 0.1rem solid $dark-theme-color;
          background: $dark-theme-background;

          &.check {
            border: 0.1rem solid $dark-theme-color;
            background: $dark-theme-color
              url("~/public/assets/CheckmarkDark.svg") no-repeat center;
          }
        }
      }
    }

    .hide-message a {
      color: $white;
      border-bottom: 0.1rem solid $white;
    }
  }
}
