.manage-alerts-table-header {
  background-color: $white;
  display: flex;
  flex-direction: row;
  font-size: 1.4rem;
  font-weight: bold;
  padding: 2.5rem 1rem 2.5rem;
  border-top: 1px solid $default-border-color;
  border-bottom: 1px solid $default-border-color;

  .alerts-table-column {
    &__name {
      width: 16%;
    }

    &__search-term {
      width: 8%;
    }

    &__sources {
      width: 20%;
    }

    &__frequency {
      width: 9%;
    }

    &__status {
      width: 7%;
    }

    &__recipients {
      width: 20%;
    }

    &__created {
      width: 11%;
    }

    &__actions {
      width: 9%;
    }

    &__publicRecordName {
      width: 22%;
    }

    &__lastRun {
      width: 15%;
    }
  }
}