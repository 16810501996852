.articles-list-footer {
  background-color: $default-bg-color;
  height: 10rem;

  .report-buttons-group {
    margin: 0 auto 2.9rem;
    padding-top: 1.8rem;
    justify-content: center;
    display: flex;

    .button-primary-lg {
      margin-right: 1rem;
    }

    .button-secondary-lg {
      margin-left: 0.5rem;
    }
  }
}