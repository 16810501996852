.snapshots-wrapper {
  .snapshot-show-hide {
    position: absolute;
    display: flex;
    white-space: nowrap;
    right: 2rem;
    margin-right: 1rem;

    .snapshot-show-hide-text {
      color: $pill-color-default;
      font-size: 1.4rem;
      font-weight: bold;
      margin-left: 1rem;
    }
  }

  .count-pods-container {
    display: flex;
    flex-wrap: wrap;
    min-height: 31rem;
    position: relative;
    justify-content: center;

    .count-pods-header {
      display: flex;
      flex-wrap: nowrap;
      flex-direction: column;
      justify-content: space-between;
      font-size: 2.3rem;
      white-space: nowrap;
      order: 1;
      width: 100%;

      .count-pods-header-results {
        background-color: $default-bg-color;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        padding: 0.5rem 3rem 1.8rem;
        width: 100%;

        .count-pods-header-text-container {
          display: flex;
          flex-direction: column;
          width: 60%;
          overflow: hidden;
        }

        // Results test label
        .count-pods-header-results-for {
          font-weight: bold;
          font-size: 2.4rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 2.2rem;
          min-height: auto;
          max-width: 60%;
          padding-bottom: 0.3rem;
        }

        .count-pods-header-results-for {
          display: flex;
          align-items: center;
        }

        .count-pods-header-results-for-text {
          font-weight: normal;
          font-size: 2.4rem;
          white-space: nowrap;
          text-overflow: ellipsis;
          overflow: hidden;
          line-height: 2.2rem;
          min-height: auto;
          max-width: 60%;
          padding-bottom: 0.3rem;
        }

        .risk-scores-disclaimer {
          display: inline-block;
          padding: 0.5rem 1rem;
          font-weight: 600;
          font-size: 1.2rem;
          white-space: nowrap;
          max-width: fit-content;
          color: $button-link-primary-color;

          &:hover {
            cursor: pointer;
            text-decoration: underline;
          }
        }

        .snapshot-action-buttons-wrapper {
          display: flex;
        }

        .snapshots-results {
          font-weight: 700;
          padding: 1.5rem 0 1.5rem;
          line-height: 2.4rem;
          font-size: 2rem;
          text-align: center;
        }

        .button-primary-lg {
          font-size: 1.6rem;

          &#download-snapshot-button {
            margin-right: 1.4rem;
          }
        }

        &+.count-pods-header-suggested-names:empty {
          padding-top: 0;
        }

        .save-search {
          margin-right: 1.4rem;
        }
      }

      .add-to-multiple-entities-btn {
        margin-right: 1.4rem;
      }

      .count-pods-header-fuzzy-names,
      .count-pods-header-suggested-names {
        display: flex;
        flex-direction: row;
        font-size: 1.5rem;
        flex-wrap: wrap;
        padding-top: 1.6rem;
        padding-right: 3rem;
        padding-left: 3rem;

        .pill-list {
          width: auto !important;

          .pill-item {
            &__selected {
              padding: 0.7rem 1.4rem 0.8rem 1.6rem;
              font-weight: bold;
            }
          }
        }

        .count-pods-header-fuzzy-names-label,
        .count-pods-header-suggested-names-label {
          font-size: 1.6rem;
          font-weight: bold;
          margin-right: 1.5rem;
          padding: 0.5rem 0 0.5rem;
        }
      }

      .count-pods-header-fuzzy-names+.count-pods-header-suggested-names {
        padding-top: 0;

        &:empty {
          padding-top: 0;
          margin-top: -0.8rem;
        }
      }
    }

    .count-pods-separator {
      width: 100%;
      border-style: solid;
      border-top-width: 1px;
      opacity: 0.3;
      order: 2;
      margin-right: 0.5rem;
      margin-left: 0.5rem;
    }

    .count-pods-body {
      display: grid;
      grid-template-columns: repeat(4, 1fr);
      grid-auto-flow: dense;
      gap: 2rem;
      max-width: 220rem;
      margin-top: 1rem;
      padding: 1rem 2rem 7rem;
      order: 3;

      .embedded-message {
        grid-column: auto / span 4;
        position: relative;
        width: 100%;
        font-size: 1.5rem;
        margin: 1rem 0;
        padding: 1.5rem;
        height: 8rem;
        display: flex;
        overflow: hidden;

        a:hover {
          cursor: pointer;
        }

        &__message {
          padding-left: 1.5rem;
          width: 70%;
          max-height: 5rem;
          overflow-y: auto;
        }

        &__icon {
          padding-right: 1.5rem;
          display: flex;
          align-items: center;
        }

        &__link {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          text-align: center;
          width: 30%;
        }

        &.error-occurred {
          @include alert-message($popup-bg-critical-light, $popup-system-error-color);

          .embedded-message__message {
            border-left: 0.1rem solid $popup-system-error-color;
            width: 100%;
          }

          &.dark-mode {
            background-color: $popup-bg-critical-dark;
            color: $popup-text-color-dark;
            border-bottom: 0.1rem solid $popup-border-color-error-dark;

            .embedded-message {
              &__message {
                border-left: 0.1rem solid $popup-border-color-error-dark;
              }
            }
          }
        }

        &.informative {
          @include alert-message($light-popup-message-bg-color, $info-banner-color);

          .embedded-message__message {
            border-left: 0.1rem solid $info-banner-color;
          }

          &.dark-mode {
            background-color: $popup-bg-info-dark;
            color: $popup-text-color-dark;
            border-bottom: 0.1rem solid $popup-border-color-info-dark;

            .embedded-message {
              &__message {
                border-left: 0.1rem solid $popup-border-color-info-dark;
              }
            }
          }
        }
      }
    }
  }

  #searchTerm-tooltip {
    max-width: 20vw;
  }

  #searchTerm-tooltip p {
    word-wrap: break-word;
  }
}