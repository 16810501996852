.regular-report-connected-reports{
  display: flex;
  flex-wrap: wrap;
  background-color: $white;
  padding: 1rem 4rem 0;
  width: inherit;
  font-size: 14px;
  margin-bottom: 2rem;

  .parent-link {
    color: $button-link-primary-color;
    margin-left: .5rem;
    cursor: pointer;
    word-break: break-all;
    -ms-word-break: break-all;
    display: inline-block;

     &:hover{
       text-decoration: underline;
     }
  }
}