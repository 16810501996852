.dil-dropdown {
  position: absolute;
  background: $white;
  z-index: 100;
  border: solid 0.1rem #bcbec0;
  border-radius: 0.4rem;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.16);
  .dropdown__row {
    display: block;
    width: 21.6rem;
    font-size: $font14;
    line-height: 3rem;
    padding: 0 0.5rem;
    color: $default-text-color;
    .la-icon {
      float: left;
      color: $default-text-color;
      margin: 0.8rem 0.5rem 0 0;
    }
    &:first-child {
      margin-top: 0.5rem
    }
    &:last-child {
      margin-bottom: 0.5rem;
    }
    &:hover {
      background-color: $hover-color; 
    }
  }
}